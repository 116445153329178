import React, { useMemo, useState } from 'react';
import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { Message } from '../../../../../API/activityHistory';
import Table, { ColumnConfig } from '../../../Table';
import { Button, Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';


const statusInfoMap: Record<string, [string, string]> = {
    'delivered': [colors.webexuiGreen, 'Delivered'],
    'not_delivered': [colors.webexuiRed, 'Not delivered'],
    'processing': [colors.webexuiBrown, 'Processing'],
}

export interface EmailLogsTableProps {
    data: Message[];
}

const ROWS_COUNT_TO_SHOW = 10;

const EmailLogsTable: React.FC<EmailLogsTableProps> = ({ data }) => {
    const classes = useStyles();
    const [visibleCount, setVisibleCount] = useState(ROWS_COUNT_TO_SHOW);

    const columns: ColumnConfig<Message>[] = useMemo(() => [
        {
            label: 'Subject',
            render: m => m.subject,
            className: classes.subjectColumn,
        },
        {
            label: 'Status',
            render: m => {
                const [color, name] = statusInfoMap[m.status];

                return (
                    <div className={classes.statusContainer}>
                        <div
                            className={classes.circle}
                            style={{ backgroundColor: color }}
                        />
                        <Typography>{name ?? m.status}</Typography>
                    </div>
                )
            },
        },
        {
            label: 'Open count',
            render: m => m.opens_count,
        },
        {
            label: 'Click count',
            render: m => m.clicks_count,
        },
        {
            label: 'Date',
            render: m => new Date(m.created_at).toLocaleString(),
            className: classes.dateColumn,
        },
    ], [classes]);

    const handleShowMore = () => {
        setVisibleCount(prevCount => prevCount + ROWS_COUNT_TO_SHOW);
    };

    return (
        <Grid container justifyContent='center'>
            <Grid item xs={12}>
                <Table
                    data={data.slice(0, visibleCount)}
                    columns={columns}
                    entityIdSelector={m => m.id}
                />
            </Grid>
            {visibleCount < data.length && (
                <Grid item>
                    <Button
                        className={classes.showMore}
                        onClick={handleShowMore}
                        variant='text'
                        color='default'
                    >
                        Show more
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

export default EmailLogsTable;
