import { fork, all, put } from 'redux-saga/effects';
import userSagas from './User/sagas';
import rolesSagas from './Roles/sagas';
import referencesSagas from './References/sagas'
import locationSagas from './Location/sagas'
import applicationsSagas from './Applications/sagas'
import contactsSagas from './Contacts/sagas';

export default function* rootSaga() {
  yield all([
    fork(userSagas),
    fork(rolesSagas),
    fork(referencesSagas),
    fork(locationSagas),
    fork(applicationsSagas),
    fork(contactsSagas)
  ]);
}

