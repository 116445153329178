import React, { useState, useEffect } from 'react';
import Main from '../Main/Main';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { reduxForm, Field, reset } from 'redux-form';
import { LabeledSelectbox, LabeledTextbox, LabeledDisplayText } from '../RenderFields/RenderFields';
import { languages } from '../../Constants';
import { CountryOption, WebsiteProps } from './types';
import { getRelevantWebsite, updateRelevantWebsite, RelevantWebsite, RelevantWebsiteCriteria, RelevantWebsiteCriterias, RelevantWebsiteVariant, getRelevantWebsites, RelevantWebsites } from '../../API/relevantWebsite';
import { AppState } from '../../Store';
import { SpinnerButton } from '@danfoss/webex-ui/dist/mui';
import Button from '@material-ui/core/Button';
import FormLeaveConfirm from '../FormLeaveConfirm';
import { connect, useDispatch, useSelector } from 'react-redux';
import RichTextEditor from '../RenderFields/RichTextEditor/RichTextEditor';
import { Spinner } from '@danfoss/webex-ui';
import queryString from 'query-string';
import Download from '../Download';
import DataLoader from '../DataLoader';
import { useAuth0 } from '@auth0/auth0-react';
import { Roles } from '../../Store/Roles/types';
import DOMPurify from 'dompurify';

// @ts-ignore
import * as XLSX from 'xlsx';
import settings from '../../Config/settings';
import { ApplicationErrors, ApplicationWarnings, ImportProgress, XlsxEncryptionError, XlsxParsingError } from '../ImportPopup/types';
import ImportWarningsPopup from '../ImportPopup/ImportWarningsPopup';
import ImportConfirmPopup from '../ImportPopup/ImportConfirmPopup';
import { validate, validateImportedData, isUrl } from './validation';
import { FormatClassNames } from '../RenderFields/RichTextEditor/types';
import ImportErrorsPopup from '../ImportPopup/ImportErrorsPopup';
import { prepareForCompare } from '../../Utils';
import ModalPopup from '../Modal/ModalPopup';
import ApplicationPopup from '../Application/Translation/ApplicationPopup';

function asyncValidate(values: any, dispatch: any, props: any) {

	return new Promise((resolve, reject) => {
		const errors = validate(values, props);

		if (Object.keys(errors).length === 0) {
			resolve(true);
		}
		reject(errors);
	});
}

const Website: React.FC<WebsiteProps> = ({ resetForm, setLeaveConfirmValues }) => {
	const classes = useStyles();
	const location = useLocation();
	const navigate = useNavigate();
	const { getAccessTokenSilently } = useAuth0();
	const dispatch = useDispatch();
	const locationAPICountries = useSelector((state: AppState) => state.location.countries);
	const userRoles = useSelector((state: AppState) => state.userRoles);

	const [selectedLanguage, setSelectedLanguage] = useState("");
	const [countries, setCountries] = useState([] as CountryOption[]);
	const [selectedWebsite, setSelectedWebsite] = useState({} as RelevantWebsite);
	const [selectedWebsitePublished, setSelectedWebsitePublished] = useState({} as RelevantWebsite);
	const [variant, setVariant] = useState({} as RelevantWebsiteVariant);
	const [selectedCountry, setSelectedCountry] = useState("");
	const [isDataChanged, setIsDataChanged] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [showLeaveConfirm, setShowLeaveConfirm] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [importedData, setImportedData] = useState({} as RelevantWebsite)
	const [importTypeProgress, setImportTypeProgress] = useState(ImportProgress.NotStarted);
	const [importedWebsites, setImportedWebsites] = useState({ errors: [], generalError: '', warnings: [] } as { errors: ApplicationErrors[], generalError?: string, warnings: ApplicationWarnings[] });
	const [isDraft, setIsDraft] = useState("");
	const [showApplicationForm, setShowApplicationForm] = useState(false);

	// Get querystring parameters
	const parsed = queryString.parse(window.location.search);

	useEffect(() => {

		const getRelWebsiteWithCriteria = async () => {

			const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
			const relevantWebsites = await getRelevantWebsites(accessTokenMyDanfossApi);
			setSelectedWebsitePublished(relevantWebsites.relevant_websites.find(web => web.published && web.id === (parsed && parsed.id) as string) || {} as RelevantWebsite)

			let draft = isDraft !== '' ? isDraft : (parsed && parsed.isdraft) as string;

			if (draft && isDraft === '') {
				setIsDraft(draft);
			}
			else if (!draft) {
				draft = (relevantWebsites.relevant_websites.find(web => !web.published && web.id === (parsed && parsed.id) as string) !== undefined) ? "true" : "false";
				setIsDraft(draft);
			}

			const relevantWebsite = await getRelevantWebsite((parsed && parsed.id) as string, draft, accessTokenMyDanfossApi);

			// Set fallback values on first load
			if (!selectedCountry && !selectedLanguage) {
				setVariant({ display_name: relevantWebsite.fallback_display_name, description: relevantWebsite.fallback_description, url: relevantWebsite.fallback_url, criteria: [] })
			}

			setSelectedWebsite(relevantWebsite);

			setIsLoading(false)
		}

		getRelWebsiteWithCriteria()

	}, [isSaving]);

	useEffect(() => {

		// Get variant based on new language/Country combination
		let variants: RelevantWebsiteVariant[];
		let newVariant: RelevantWebsiteVariant = {} as RelevantWebsiteVariant;

		if (selectedCountry) {

			variants = selectedWebsite.variants.reduce((websiteVariants: RelevantWebsiteVariant[], websiteVariant: RelevantWebsiteVariant) => {

				websiteVariant.criteria.forEach(variantCriteria => {
					if (variantCriteria[0].path === "user.user_metadata.country" && variantCriteria[0].values.indexOf(selectedCountry) > -1) {
						websiteVariants.push(websiteVariant);
					}
				});

				return websiteVariants
			}, []);

			if (variants.length > 1) {

				if (selectedLanguage) {
					// Find the variant that has the selected language as a criteria
					newVariant = variants.find(variant => (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.language" && variantCriteria.values.indexOf(selectedLanguage) > -1)) || {} as RelevantWebsiteVariant;
				} else {
					// If a criteria has been set where only country is set.
					newVariant = variants.find(variant => (variant.criteria[0] as RelevantWebsiteCriteria[]).length === 1) || {} as RelevantWebsiteVariant;
				}

			} else {
				if (selectedLanguage) {
					// Find the variant that has the selected language as a criteria
					newVariant = variants.find(variant => (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.language" && variantCriteria.values.indexOf(selectedLanguage) > -1)) || {} as RelevantWebsiteVariant;
				} else {
					if ((variants[0] && variants[0].criteria[0] as RelevantWebsiteCriteria[] || []).length === 1) {
						newVariant = variants[0];
					}
				}

			}

		}
		else if (selectedLanguage) {
			variants = selectedWebsite.variants.reduce((websiteVariants: RelevantWebsiteVariant[], websiteVariant: RelevantWebsiteVariant) => {

				websiteVariant.criteria.forEach(variantCriteria => {
					if (variantCriteria[0].path === "user.user_metadata.language" && variantCriteria[0].values.indexOf(selectedLanguage) > -1) {
						websiteVariants.push(websiteVariant);
					}
				});

				return websiteVariants
			}, []);

			// Find the variant where there is only one criteria, which will be the language itself
			newVariant = variants.find(variant => (variant.criteria[0] as RelevantWebsiteCriteria[]).length === 1) || {} as RelevantWebsiteVariant;
		} else if (selectedCountry === '' && selectedLanguage === '') {
			newVariant = {
				display_name: selectedWebsite.fallback_display_name,
				description: selectedWebsite.fallback_description,
				url: selectedWebsite.fallback_url,
				criteria: []
			}

			setVariant(variant);
		}

		let emptyVariant = {} as RelevantWebsiteVariant;

		// If a variant is found, set it in local state for further use.
		if (newVariant) {
			setVariant(newVariant);
		} else {
			emptyVariant = {
				display_name: "",
				description: "",
				url: "",
				criteria: [
					[
						{
							"path": "user.user_metadata.country",
							"values": [
								selectedCountry || ""
							]
						},
						{
							"path": "user.user_metadata.language",
							"values": [
								selectedLanguage || ""
							]
						}
					]
				]
			}

			setVariant(emptyVariant);
		}

		setLeaveConfirmValues(newVariant ? newVariant : emptyVariant, newVariant ? newVariant : emptyVariant, true, resetForm, document.getElementById('formSubmitButton'))

	}, [selectedLanguage, selectedCountry]);

	useEffect(() => {

		setCountries((locationAPICountries || [])?.map(country => {
			return { id: country.alpha2, text: country.name }
		}))

	}, [locationAPICountries]);



	const handleCiteriaChange = (type: string, value: string) => {
		if (isDataChanged) {
			setIsDataChanged(false);
		}

		type === "Language" ? setSelectedLanguage(value) : setSelectedCountry(value);
	}

	const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		setIsSaving(true);

		let modifiedWebsite = { ...selectedWebsite };

		const extractedVariant = modifiedWebsite.variants.filter(websiteVariant => {

			if (selectedLanguage && selectedCountry) {
				const websiteVariantCriteria = (websiteVariant.criteria[0] as RelevantWebsiteCriteria[]).filter((wvc: RelevantWebsiteCriteria) => (wvc.path === "user.user_metadata.country" && wvc.values.includes(selectedCountry)) || (wvc.path === "user.user_metadata.language" && wvc.values.includes(selectedLanguage)))

				if ((websiteVariant.criteria[0] as RelevantWebsiteCriteria[]).length === 2 && websiteVariantCriteria.length === 2) {
					return true
				}
			} else if (!selectedLanguage && selectedCountry) {
				const websiteVariantCriteria = (websiteVariant.criteria[0] as RelevantWebsiteCriteria[]).filter((wvc: RelevantWebsiteCriteria) => (wvc.path === "user.user_metadata.country" && wvc.values.includes(selectedCountry)))

				if ((websiteVariant.criteria[0] as RelevantWebsiteCriteria[]).length === 1 && websiteVariantCriteria.length === 1) {
					return true
				}
			} else if (selectedLanguage && !selectedCountry) {
				const websiteVariantCriteria = (websiteVariant.criteria[0] as RelevantWebsiteCriteria[]).filter((wvc: RelevantWebsiteCriteria) => (wvc.path === "user.user_metadata.language" && wvc.values.includes(selectedLanguage)))

				if ((websiteVariant.criteria[0] as RelevantWebsiteCriteria[]).length === 1 && websiteVariantCriteria.length === 1) {
					return true
				}
			} else {
				return false
			}
		}
		)

		if (extractedVariant.length > 0) {
			extractedVariant[0].display_name = variant.display_name;
			extractedVariant[0].description = variant.description;
			extractedVariant[0].url = variant.url;
		} else {
			// If either language nor country is selected, we are working on the fallback version
			// Otherwise if language or country is selected, but we didn't find a variant matching
			// We have a new variant that needs to be added to the list of variants.
			if (!selectedLanguage && !selectedCountry) {
				modifiedWebsite.fallback_description = variant.description;
				modifiedWebsite.fallback_display_name = variant.display_name;
				modifiedWebsite.fallback_url = variant.url;
			} else {

				let criteria = [] as RelevantWebsiteCriterias[];
				criteria.push([] as RelevantWebsiteCriteria[]);

				if (selectedCountry) {
					(criteria[0] as RelevantWebsiteCriteria[]).push({ path: "user.user_metadata.country", values: [selectedCountry] });
				}

				if (selectedLanguage) {
					(criteria[0] as RelevantWebsiteCriteria[]).push({ path: "user.user_metadata.language", values: [selectedLanguage] });
				}

				modifiedWebsite.variants.push(
					{
						display_name: variant.display_name,
						description: variant.description,
						url: variant.url,
						criteria
					}
				)
			}
		}

		const updateWebsite = async () => {

			const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);

			await updateRelevantWebsite(modifiedWebsite.id, modifiedWebsite, accessTokenMyDanfossApi, true);
			setIsDataChanged(false);


		}

		updateWebsite().then(resp => {
			setIsDraft("true");
			setIsSaving(false);
		})

		setLeaveConfirmValues(variant, variant, false, resetForm, document.getElementById('formSubmitButton'))

	}

	const handleResetClick = () => {
		dispatch(reset('websiteCriteria'));
		setVariant({} as RelevantWebsiteVariant);
		setSelectedLanguage("");
		setSelectedCountry("");
	}

	const handleChange = (type: string, value: string) => {
		let newVariant = { ...variant };

		switch (type) {
			case 'name':
				newVariant.display_name = value;
				break;
			case 'description':
				newVariant.description = value;
				break;
			case 'url':
				newVariant.url = value;
				break;
		}

		setVariant(newVariant);
		setIsDataChanged(true);
		setLeaveConfirmValues(variant, newVariant, true, resetForm, document.getElementById('formSubmitButton'))
	}

	const validateForm = () => {

		if (Object.keys(variant).length === 0 || variant.display_name.trim() === "" || variant.description === "<p><br></p>" || !isUrl(variant.url) || (!variant.url.startsWith("http://") && !variant.url.startsWith("https://"))) {
			return true;
		}

		return false;
	}

	const getDataItems = () => {
		const englishTranslations = { display_name: selectedWebsite.fallback_display_name, description: selectedWebsite.fallback_description }

		let dataset: any[] = [];

		// Add general settings on first tab
		dataset.push(
			{
				sheetName: "General",
				dataSet: {
					columns: [{ title: "General settings" }],
					data: [
						[{ value: "id" }, { value: selectedWebsite.id }],
						[{ value: "name" }, { value: selectedWebsite.name }],
						[{ value: "app_type" }, { value: selectedWebsite.app_type }],
						[{ value: "fallback_url" }, { value: selectedWebsite.fallback_url }],
						[{ value: "fallback_display_name" }, { value: selectedWebsite.fallback_display_name }],
						[{ value: "fallback_description" }, { value: selectedWebsite.fallback_description }]
					]
				}
			}
		);

		// Add the different variants
		selectedWebsite.variants.forEach(variant => {

			const variantCountry = (variant.criteria[0] as RelevantWebsiteCriteria[]).find((variantCriteria) => variantCriteria.path === "user.user_metadata.country")?.values[0] || null;
			const variantLanguage = (variant.criteria[0] as RelevantWebsiteCriteria[]).find((variantCriteria) => variantCriteria.path === "user.user_metadata.language")?.values[0] || null;
			const sheetName = `${(variantCountry || '') + (variantLanguage ? '_' : '') || ''}${variantLanguage || ''}`;

			dataset.push(
				{
					sheetName: sheetName,
					dataSet: {
						columns: [{ title: "Description" }, { title: "English" }, { title: sheetName }],
						data: [
							[{ value: "website name" }, { value: englishTranslations.display_name }, { value: variant.display_name || '' }],
							[{ value: "website description" }, { value: englishTranslations.description }, { value: variant.description || '' }],
							[{ value: "website url" }, { value: "url" }, { value: variant.url || '' }],
							[{ value: "website criteria" }, { value: "criteria" }, { value: JSON.stringify(variant.criteria) }]
						]
					}
				}
			);

		});

		return dataset;
	}

	const handlePublishClick = (id: string) => {
		return navigate(`/publish/website?id=${id}`);
	}

	const handleImportClick = (e: any) => {
		e.preventDefault();

		const files = e.target.files, f = files[0];
		const reader = new FileReader();

		reader.onload = function (e) {
			const data = e.target?.result;
			try {
				const readData = XLSX.read(data, { type: 'binary' });
				let dataToImport = {} as RelevantWebsite;

				readData.SheetNames.forEach((sheet) => {

					// Read sheetdata based on sheetname
					const sheetData = readData.Sheets[sheet];

					// Convert exceldata to json
					const sheetDataAsJson = XLSX.utils.sheet_to_json(sheetData, { header: 1 });

					if (Object.keys(dataToImport).length === 0) {

						// Get the different texts
						const id = sheetDataAsJson[1] as string[];
						const name = sheetDataAsJson[2] as string[];
						const app_type = sheetDataAsJson[3] as string[];
						const fallback_url = sheetDataAsJson[4] as string[];
						const fallback_display_name = sheetDataAsJson[5] as string[];
						const fallback_description = sheetDataAsJson[6] as string[];

						// Generate data to send
						dataToImport = {
							"name": DOMPurify.sanitize(name[1]),
							"fallback_display_name": DOMPurify.sanitize(fallback_display_name[1]),
							"fallback_description": DOMPurify.sanitize(fallback_description[1]),
							"fallback_url": DOMPurify.sanitize(fallback_url[1]),
							"app_type": DOMPurify.sanitize(app_type[1]),
							"variants": [],
							"id": DOMPurify.sanitize(id[1]),
							"published": false
						};

					} else {

						// Get the different texts
						const name = sheetDataAsJson[1] as string[];
						const description = sheetDataAsJson[2] as string[];
						const url = sheetDataAsJson[3] as string[];
						const criteria = sheetDataAsJson[4] as string;

						const variant = { display_name: DOMPurify.sanitize(name[2]), description: DOMPurify.sanitize(description[2]), url: DOMPurify.sanitize(url[2]), criteria: JSON.parse(DOMPurify.sanitize(criteria[2])) } as RelevantWebsiteVariant;

						// Generate data to send
						if (variant.description !== '' || variant.display_name !== '' || variant.url !== '') {
							dataToImport = { ...dataToImport, variants: [...dataToImport.variants, variant] }
						}

					}

				});

				setImportedData(dataToImport);

				const { errors, warnings } = validateImportedData(dataToImport, selectedWebsite);

				if (errors.length > 0) {
					console.log('Errors', errors);
				}

				if (warnings.length > 0) {
					console.log('Warnings', warnings);
				}

				setImportTypeProgress((errors.length > 0 && ImportProgress.HandleErrors) || (warnings.length > 0 && ImportProgress.HandleWarnings) || ImportProgress.ConfirmImport)
				setImportedWebsites({ errors, warnings })
			}
			catch (error: unknown) {
				let generalError = '';
				if ((error as Error).message.includes('Encrypted file missing /EncryptionInfo')) {
					generalError = XlsxEncryptionError;
				}
				else {
					generalError = XlsxParsingError.replace('{error-message}', (error as Error).message);
				}
				setImportTypeProgress(ImportProgress.HandleErrors);
				setImportedWebsites({
					errors: [],
					generalError
					, warnings: []
				})
			}

		};

		e.target.value = '';

		reader.readAsBinaryString(f)
	}

	const cancelImport = () => {
		setImportedWebsites({ errors: [], warnings: [] });
		setImportTypeProgress(ImportProgress.NotStarted);
		setImportedData({} as RelevantWebsite);
		setIsSaving(false);
	}

	const continueImport = () => {

		setIsSaving(true);

		const importData = async () => {
			const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
			await updateRelevantWebsite(selectedWebsite.id, importedData, accessTokenMyDanfossApi, true);

		}

		importData().then(resp => {
			setIsDraft("true");
			setIsSaving(false);
			setShowLeaveConfirm(false);
		});

		setImportTypeProgress(ImportProgress.NotStarted);
	}

	const handleUndoChangesClick = (id: string) => {
		return navigate(`/website/undochanges?id=${id}`);
	}

	const getChangedVariants = (draftVariants: RelevantWebsiteVariant[]) => {

		const changedVariants = [] as RelevantWebsiteVariant[];

		if (selectedWebsitePublished) {

			if (selectedWebsitePublished.fallback_display_name !== selectedWebsite.fallback_display_name || selectedWebsitePublished.fallback_description !== selectedWebsite.fallback_description || selectedWebsitePublished.fallback_url !== selectedWebsite.fallback_url) {
				const draftVersion = {
					display_name: selectedWebsite.fallback_display_name,
					description: selectedWebsite.fallback_description,
					url: selectedWebsite.fallback_url,
					criteria: [
						[
							{
								"path": "user.user_metadata.country",
								"values": [
									""
								]
							},
							{
								"path": "user.user_metadata.language",
								"values": [
									""
								]
							}
						]
					]
				}
				changedVariants.push(draftVersion);
			}

			draftVariants.forEach(variant => {

				let variantFound = false;

				selectedWebsitePublished?.variants?.map(pubVar => {
					if (JSON.stringify(pubVar.criteria) === JSON.stringify(variant.criteria)) {
						variantFound = true;

						if (prepareForCompare(pubVar) !== prepareForCompare(variant)) {

							changedVariants.push(variant);
						}
					}
				})

				if (!variantFound) {
					changedVariants.push(variant);
				}
			})
		}

		return changedVariants;
	}

	const handleOverviewClick = () => {
		return navigate(`/website/overview?id=${selectedWebsite.id}`);
	}

	return isLoading ? <Spinner visible={isLoading} /> : (
		<Main breadCrumbs={{ items: [{ text: `Relevant websites`, link: "/websiteoverview" }, { text: selectedWebsite.name, link: location.pathname }] }}>
			{showApplicationForm && (
				<ModalPopup paperClassName={classes.bannerPopupApplication}>
					<ApplicationPopup
						setShowApplicationForm={setShowApplicationForm}
						description={variant.description || ''}
						name={variant.display_name || ''}
						client_id={selectedWebsite.id}
						dip_app_type={selectedWebsite.app_type}
					/>
				</ModalPopup>
			)}
			<DataLoader countriesRequired={true}>
				<ImportErrorsPopup importTypeProgress={importTypeProgress} errors={importedWebsites.errors} generalError={importedWebsites.generalError} onCancelImport={cancelImport} />
				<ImportWarningsPopup importTypeProgress={importTypeProgress} warnings={importedWebsites.warnings} onCancelImport={cancelImport} onContinueImport={continueImport} importButtonTypeText="Import translations" showOtherFilesButton={false} />
				<ImportConfirmPopup importTypeProgress={importTypeProgress} importItemNames={[importedData.name]} importTypeText="app translations" importButtonTypeText="translations" onCancelImport={cancelImport} onPerformImport={continueImport} />
				<Grid container>
					<Grid container className={classes.formContainer}>
						<form onSubmit={submitForm} id="webTranslation" className={classes.formContainer}>
							<Grid container id="clickoutsideContainer">
								<Field
									id="website"
									name="website"
									label="Relevant website"
									value={selectedWebsite.name}
									defaultValue={selectedWebsite.name}
									component={LabeledDisplayText}
								/>
								<Field
									id="country"
									name="country"
									label="Country"
									value={selectedCountry}
									defaultValue={selectedCountry}
									onChange={({ target }: { target: HTMLInputElement }) => {
										handleCiteriaChange("Country", target.value);
									}}
									placeholder={"Country"}
									component={LabeledSelectbox}
									options={countries}
									className="triggerWarning"
								/>
								<Field
									id="language"
									name="language"
									label="Language"
									value={selectedLanguage}
									defaultValue={selectedLanguage}
									onChange={({ target }: { target: HTMLInputElement }) => {
										handleCiteriaChange("Language", target.value);
									}}
									placeholder={"Language"}
									component={LabeledSelectbox}
									options={languages.sort((a: any, b: any) => a.text.localeCompare(b.text))}
									className="triggerWarning"
								/>
								<Field
									id="name"
									name="name"
									label="Website name"
									value={variant.display_name || ''}
									defaultValue={variant.display_name || ''}
									onChange={({ target }: { target: HTMLInputElement }) => {
										handleChange('name', target.value);
									}}
									placeholder="Application name"
									component={LabeledTextbox}
									ismandatory={true}
									InputProps={{
										classes: {
											input: classes.blackText
										}
									}}
								/>
								<Field
									id="url"
									name="url"
									label="Website variant url"
									value={variant.url || ''}
									defaultValue={variant.url || ''}
									onChange={({ target }: { target: HTMLInputElement }) => {
										handleChange('url', target.value);
									}}
									placeholder="Website variant url"
									component={LabeledTextbox}
									ismandatory={true}
									InputProps={{
										classes: {
											input: classes.blackText
										}
									}}
								/>
								<Grid container>
									<Grid item className={classes.editorContainer}>
										<RichTextEditor
											id="websitedescription"
											type="description"
											label="Website description"
											value={variant.description || ''}
											onChange={handleChange}
											className={classes.richTextEditor}
											formatClassName={FormatClassNames.APP_POPUP}
										/>
									</Grid>
									<Grid item>
										<Button
											onClick={() => setShowApplicationForm(true)}
											type="button"
											variant="outlined"
											color="default"
											className={classes.buttonPreview}
										>
											Preview
										</Button>
									</Grid>
								</Grid>
								<Grid container id="buttonContainer" className={classes.buttonContainer}>
									{userRoles.rolesLoaded && userRoles.roles && userRoles.roles.some(userRole => [Roles.RELEVANT_WEBSITE_EDITOR, Roles.DASHBOARD_ADMIN].some(role => role === userRole)) && (
										<>
											<SpinnerButton
												type="submit"
												variant="contained"
												color="primary"
												className={classes.button}
												pathToImagesFolder={'/images/icons'}
												spinnerVisible={isSaving}
												disabled={validateForm() || !isDataChanged}
												id="formSubmitButton"
											>
												Save
											</SpinnerButton>
											<Button
												onClick={() => handleResetClick()}
												type="button"
												variant="outlined"
												color="default"
												className={classes.button}
											>
												Reset
											</Button>
											<Download getDataItems={getDataItems} disabled={false} fileName={selectedWebsite.name} />
											<label htmlFor="files" className={classes.buttonLabel}>Import texts</label>
											<input id="files" className={`${classes.buttonHidden} triggerWarning`} type="file" onChange={(e) => handleImportClick(e)} accept=".xlsx" />
										</>
									)}
								</Grid>
							</Grid>
							<Grid container spacing={5} className={classes.LinkContainer}>
								<Grid item>
									<span onClick={() => handleOverviewClick()} className={`${classes.linkText} triggerWarning`}>
										<img src={'/images/LinkArrow.svg'} className={classes.linkArrow} alt="" /> Overview
									</span>
								</Grid>
								{(getChangedVariants(selectedWebsite.variants).length > 0) && (
									<Grid item>
										<span onClick={() => handleUndoChangesClick(selectedWebsite.id)} className={`${classes.linkText} triggerWarning`}>
											<img src={'/images/LinkArrow.svg'} className={classes.linkArrow} alt="" /> View changes
										</span>
									</Grid>
								)}
								<Grid item>
									{userRoles.rolesLoaded && userRoles.roles && userRoles.roles.some(userRole => [Roles.RELEVANT_WEBSITE_PUBLISHER, Roles.DASHBOARD_ADMIN].some(role => role === userRole)) &&
										isDraft && importTypeProgress === ImportProgress.NotStarted && !selectedWebsite.published && (getChangedVariants(selectedWebsite.variants).length > 0) && (
											<span onClick={() => handlePublishClick(selectedWebsite.id)} className={`${classes.linkText} triggerWarning`}>
												<img src={'/images/LinkArrow.svg'} className={classes.linkArrow} alt="" /> Publish
											</span>
										)}
								</Grid>
							</Grid>
						</form>
					</Grid>
				</Grid>
			</DataLoader>
		</Main>
	);
}

const mapDispatchToProps = (dispatch: any) => () => ({
	resetForm: () => dispatch(reset('appTranslation'))
});

const connectedComponent = connect(null, mapDispatchToProps)(Website)

export default reduxForm({
	form: 'webTranslation',
	validate,
	asyncValidate,
	asyncBlurFields: ['name', 'url'],
	asyncChangeFields: ['url', 'name'],
	enableReinitialize: true
})(connectedComponent);