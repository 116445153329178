import { LocationActionTypes, LocationAppStateModel } from './types';
import { createReducer } from 'typesafe-actions';
import { LocationActionType } from './actions';

const initialState: LocationAppStateModel = {
	countriesLoadingStatus: {loaded: false, loading: false, failed: false},
	countries: []
};

const reducer = createReducer<LocationAppStateModel, LocationActionType>(initialState, {
	[
		LocationActionTypes.GET_COUNTRIES_SUCCEED]:
		(state, action) => {
			return { ...state, countries: action.payload };
		},	
		[LocationActionTypes.SET_COUNTRIES_LOADING_STATUS]: (state, action) => {
			return { ...state, countriesLoadingStatus: {...action.payload} };
		  },
		});

export { reducer as locationReducer };
