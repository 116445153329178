import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    emailAccordion: {
        padding: theme.spacing(0, 2),
        '& .MuiAccordionSummary-expandIcon': {
            margin: 0,
        }
    },
    icon: {
        width: 16,
    },
    duration: {
        opacity: 0.5,
        fontSize: 16,
    }
}));