import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  sidebar: {
    backgroundColor: colors.webexuiGray
  },
  container: {
    margin: '0 4px',
    [theme.breakpoints.between('sm', 'md')]: {
      margin: '0 40px'
    },
    [theme.breakpoints.only('xs')]: {
      margin: 0
    },
    maxWidth: '100%'
  },
  containerFullWidth: {
    margin: '0 auto',
    maxWidth: '1280px'    
  },
  marginLeft: {
    marginLeft: '5px !important'
  },
  popupContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    position: 'absolute',
    top: '-50px',
  },
  contentContainer: {
    position: 'relative',
    height: 'calc(100vh - 147px)',
    [theme.breakpoints.only('md')]: {
      height: 'calc(100vh - 126px)'
    },
    [theme.breakpoints.only('sm')]: {
      height: 'calc(100vh - 114px)'
    },
    overflowY: 'scroll',
    maxWidth: '100%',
    padding: '0px 151px 73px'
  },
  contentInnerContainer: {
    marginBottom: '100px',
    maxWidth: '1280px'
  },
  popup: {
    top: '233px',
    maxWidth: '650px',
    display: 'table',
    marginTop: 'auto !important',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '-27px'
    }
  },
  overlay: {
    [theme.breakpoints.only('xs')]: {
      marginTop: '10px',
      height: '100%'
    }
  },
  close: {
    cursor: 'pointer',
    width: '16px',
    [theme.breakpoints.only('xs')]: {
      margin: '10px -16px',
    },
    [theme.breakpoints.up('sm')]: {
      right: '16px'
    },
    position: 'absolute'
  },
  maintenanceHeader: {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '20px 0 16px'
  },
  fullWidth: {
    backgroundColor: colors.webexuiWhite,
    maxWidth: '1280px',
    margin: '0 auto',
    padding: '20px 0',
    [theme.breakpoints.between('sm', 'md')]: {
      margin: '0 0 30px 0px',
    },
    [theme.breakpoints.only('xs')]: {
      margin: '0 0 30px 20px',
    },
    position: 'relative'
  },
  titleContainer: {
		margin: '-10px 0px 10px 0px'
	},
  fullHeight: {
    height: '100%'
  }
}));