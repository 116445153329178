import React from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from './Styles';
import { DashboardItemPopupProps } from './Types';
import ModalPopup from '../../Modal/ModalPopup';
import parse from 'html-react-parser';

const DashboardItemPopup: React.FC<DashboardItemPopupProps> = ({ headerText, bodyText, togglePopup }) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.popupContainer}>
            <ModalPopup paperClassName={classes.popup} overlayClassName={classes.overlay}>
                <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
                    <div onClick={() => togglePopup()}>
                        <img src={'/images/icons/Close.svg'} className={classes.close} alt="" />
                    </div>
                </Grid>
                {headerText && (
                    <Grid item xs={12} className={classes.popupHeader} >
                        {parse(headerText)}
                    </Grid>)
                }
                <Grid item xs={12} >
                    <span className={classes.popupContent}>
                        {bodyText}
                    </span>
                </Grid>
            </ModalPopup>
        </Grid>
    );
};

export default DashboardItemPopup;
