import React from 'react';
import { useStyles } from './styles';

interface Props {
  overlayClassName?: any,
  paperClassName?: any,
  children: React.ReactNode
}
const ModalPopup: React.FC<Props> = ({overlayClassName, paperClassName, children}) => {

  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={`${classes.overlay} ${overlayClassName || ''}`} id="OVERLAY" />
      <div className={`${classes.paper} ${paperClassName || ''}`}>
        {children}
      </div>
    </React.Fragment>
  );
}

export default ModalPopup;