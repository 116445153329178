import { ApplicationsActionTypes, ApplicationsAppStateModel } from './types';
import { createReducer } from 'typesafe-actions';
import { ApplicationsActionType } from './actions';

const initialState: ApplicationsAppStateModel = {
	applicationsLoadingStatus: { loaded: false, loading: false, failed: false },
	applicationTranslationsLoadingStatus: { loaded: false, loading: false, failed: false },
	applications: [],
	applicationTranslations: []
};


const reducer = createReducer<ApplicationsAppStateModel, ApplicationsActionType>(initialState, {
	[ApplicationsActionTypes.GET_APPLICATIONS_SUCCEED]:
		(state, action) => {
			return { ...state, applications: action.payload };
		},
	[ApplicationsActionTypes.GET_APPLICATIONTRANSLATIONS_SUCCEED]:
		(state, action) => {
			return { ...state, applicationTranslations: action.payload };
		},
	[ApplicationsActionTypes.SET_APPLICATIONS_LOADING_STATUS]: (state, action) => {
		return { ...state, applicationsLoadingStatus: { ...action.payload } };
	},
	[ApplicationsActionTypes.SET_APPLICATIONTRANSLATIONS_LOADING_STATUS]: (state, action) => {
		return { ...state, applicationTranslationsLoadingStatus: { ...action.payload } };
	},
});

export { reducer as applicationsReducer };
