import React, { useState, useEffect } from 'react';
import uniq from 'lodash/uniq';
import Main from '../../Main';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './styles';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import { getApplicationTranslations, LanguageTranslation, ApplicationWithTranslations, getApplicationsForUserWithRoles, Application, publishApplicationTranslations } from '../../../API/application';
import { CheckboxWithName } from '../../RenderFields/RenderFields';
import { languages } from '../../../Constants';
import TranslationRow from './TranslationRow';
import { SpinnerButton } from '@danfoss/webex-ui/dist/mui';
import { Spinner } from '@danfoss/webex-ui';
import { PublishLanguage } from './types';
import { useAuth0 } from '@auth0/auth0-react';
import { Roles } from '../../../Store/Roles/types';
import settings from '../../../Config/settings';
import { useSelector } from 'react-redux';
import { AppState } from '../../../Store';
import DataLoader from '../../DataLoader';
import queryString from 'query-string';
import Button from '@material-ui/core/Button';
import { allowPublishApplicationTexts, getDanfossIdentityId, getUserRoles } from '../../../Utils';
import { getContacts } from '../utils';

const AppTranslationPublish: React.FC = () => {
	const classes = useStyles();
	const location = useLocation();
	const [applicationsWithTranslations, setApplicationsWithTranslations] = useState<ApplicationWithTranslations[]>([]);
	const [selectedApplications, setSelectedApplications] = useState<string[]>([]);
	const [selections, setSelections] = useState<PublishLanguage[]>([]);
	const [isPublishing, setIsPublishing] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [appVariantsVisible, setAppVariantsVisible] = useState<string[]>([]);
	const { getAccessTokenSilently } = useAuth0();
	const applications = useSelector((state: AppState) => state.applications?.applications);
	const applicationsLoaded = useSelector((state: AppState) => state.applications.applicationsLoadingStatus.loaded);
	const currentUser = useSelector((state: AppState) => state.user);
	const userRoles = useSelector((state: AppState) => getUserRoles(state.userRoles));
	const contacts = useSelector((state: AppState) => state.contacts.contactInfo);

	//Get querystring parameters
	const parsed = queryString.parse(window.location.search);
	const client_id = parsed?.client_id as string | null;
	let useGrayBackground = true;

	useEffect(() => {
		const getUserApps = async () => {
			if (currentUser.userLoaded && currentUser.user && applicationsLoaded && !isPublishing) {
				setIsLoading(true);

				const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
				const accessTokenMyDanfossAccountApi = await getAccessTokenSilently(settings.myDanfossAccountApi.accessTokenOptions);

				const getAppsForUser = async () => {
					const isAdmin = userRoles.includes(Roles.DASHBOARD_ADMIN);
					const clientIdsForUser: string[] = isAdmin
						? applications?.map(app => app.client_id) || []
						: await getApplicationsForUserWithRoles(
							accessTokenMyDanfossApi,
							accessTokenMyDanfossAccountApi,
							getDanfossIdentityId(currentUser),
							[Roles.APPLICATION_OWNER, Roles.APPLICATION_PUBLISHER]
						);

					return applications?.filter(app => clientIdsForUser.includes(app.client_id)) || [];
				}

				const appsForUser = await getAppsForUser();

				const getAppsWithTranslationsTasks = appsForUser.map(async app => {
					const appTranslations = await getApplicationTranslations(app.client_id);

					return { ...app, ...appTranslations } as ApplicationWithTranslations;
				});

				const appsWithTranslations = await Promise.all(getAppsWithTranslationsTasks);

				const selectionsToAdd = appsWithTranslations.reduce<PublishLanguage[]>((acc, app) => {
					const languages = app.application_texts
						.filter(appText => !appText.published)
						.map(trans => ({ appLanguage: `${app.client_id}#${trans.language}` }));

					return [...acc, ...languages];
				}, []);

				setApplicationsWithTranslations(appsWithTranslations);
				setSelections(selectionsToAdd);

				setIsLoading(false);
			}
		};

		getUserApps();
	}, [currentUser, isPublishing, applications, applicationsLoaded, getAccessTokenSilently, userRoles]);

	useEffect(() => {
		const applicationClientIds = uniq(selections.map(l => l.appLanguage.substring(0, l.appLanguage.indexOf("#"))));

		setSelectedApplications(applicationClientIds);
	}, [selections]);

	const handleCheckboxClick = (target: any) => {
		if (!allowPublish()) {
			return;
		}

		if (target.checked) {
			if (!selections.find((item: PublishLanguage) => item.appLanguage === target.id)) {
				setSelections([...selections, { appLanguage: target.id }]);
			}
		} else {
			setSelections(selections.filter(item => item.appLanguage !== target.id));
		}
	}

	const handlePublishButtonClick = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		setIsPublishing(true);

		const updateTranslationsTasks = selectedApplications.map(app_client_id => {
			const languages: string[] = [];

			const updateTranslations = async () => {
				const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);

				selections.filter(sel => sel.appLanguage.indexOf(app_client_id) > -1).forEach((selection: PublishLanguage) => {
					languages.push(selection.appLanguage.substring(selection.appLanguage.lastIndexOf("#") + 1));
				});

				if (languages.length > 0) {
					await publishApplicationTranslations(app_client_id, { languages: languages }, accessTokenMyDanfossApi);
				}
			}

			return updateTranslations();
		});

		await Promise.all(updateTranslationsTasks);

		setIsPublishing(false);
	}

	const toggleVariants = (id: string) => {
		if (!appVariantsVisible.includes(id)) {
			setAppVariantsVisible([...appVariantsVisible, id]);
		} else {
			setAppVariantsVisible(appVariantsVisible.filter((variant: string) => variant !== id));
		}
	}

	const onChangeSelectApp = (clientId: string, checked: boolean) => {
		if (!allowPublish()) {
			return;
		}

		onChangeSelectAllLanguages(clientId, checked);
	}

	const onChangeSelectAllLanguages = (clientId: string, checked: boolean) => {
		if (!allowPublish()) {
			return;
		}

		if (checked) {
			const translations = applicationsWithTranslations.find(app => app.client_id === clientId)?.application_texts || [];
			let selectionsToAdd = selections;

			translations.filter(trans => !trans.published).forEach(trans => {
				const selection = { appLanguage: `${clientId}#${trans.language}` };

				if (!selections.find((item: PublishLanguage) => item.appLanguage === selection.appLanguage)) {
					selectionsToAdd.push(selection)
					setSelections([...selectionsToAdd]);
				}
			});
		} else {
			setSelections(selections.filter(selection => !selection.appLanguage.startsWith(`${clientId}#`)));
		}
	}

	const allowPublish = () => {
		return allowPublishApplicationTexts(userRoles);
	}

	const unpublishedApplicationsWithTranslations = applicationsWithTranslations.filter(awt => awt.application_texts.filter(appText => !appText.published).length > 0);

	const onChangeSelectAll = (checked: boolean) => {
		if (!allowPublish()) {
			return;
		}

		if (checked) {
			unpublishedApplicationsWithTranslations.forEach(app => {
				onChangeSelectAllLanguages(app.client_id, checked);
			});
		} else {
			setSelections([]);
		}
	}

	return (
		<Main breadCrumbs={{ items: [(client_id ? { text: 'Application translation', link: `/application/translation?client_id=${client_id}` } : { text: 'Publish overview', link: '/publish' }), { text: `Publish application translations`, link: location.pathname }] }}>
			<DataLoader applicationsRequired={true} contactsRequired={true} >
				{isLoading ? (<Spinner visible={isLoading} />) : (
					<Grid container className={classes.formContainer}>
						{!allowPublish() &&
							<Grid container className={classes.notAllowed}>
								{`You are not allowed to publish yourself - please ask ${getContacts(contacts)} to publish the application translations`}
							</Grid>
						}
						<form id="appTranslationPublish" className={classes.translationForm}>
							<Grid container>
								{applicationsWithTranslations.filter(awt => awt.application_texts.filter(appText => appText.published).length > 0).length > 0 && (
									<Grid container className={`${classes.toggleButtonContainer}`}>
										<Button
											type="button"
											variant="outlined"
											color="default"
											component={RouterLink}
											to={client_id ? `/publish/applicationtranslation/unpublish?client_id=${client_id}` : "/publish/applicationtranslation/unpublish"}
										>
											{allowPublish() ? 'Remove existing translations' : 'View existing translations'}
										</Button>
									</Grid>
								)}
								<Grid container className={classes.listGridHeading}>
									<Grid item className={`${classes.listGridItem} ${classes.listGridSelect}`} >
										<Field
											name="selectAll"
											id="selectAll"
											value={"Application name"}
											defaultValue={"Application name"}
											checked={allowPublish() && (selectedApplications.length > 0 &&
												unpublishedApplicationsWithTranslations.every(web => selectedApplications.some(clientId => clientId === web.client_id)))}
											component={CheckboxWithName}
											onClick={(event: any) => { onChangeSelectAll(event.target.checked); }}
											label=""
										/>
									</Grid>
									<Grid item className={`${classes.listGridItem} ${classes.listGridStatus}`} >
										Status
									</Grid>
								</Grid>
								{unpublishedApplicationsWithTranslations.map((app, idx) => {
									useGrayBackground = true;

									return (
										<Grid key={app.client_id} container>
											<Grid container className={`${classes.listGrid} ${idx % 2 === 0 ? classes.listGridEven : classes.listGridOdd}`} >
												<Grid item className={`${classes.listGridItem} ${classes.listGridSelect}`} >
													<Field
														name={`publish${idx}`}
														id={`publish${idx}`}
														value={app.name}
														defaultValue={app.name}
														checked={allowPublish() && selectedApplications.some(selApp => selApp === app.client_id)}
														component={CheckboxWithName}
														onClick={(event: any) => { onChangeSelectApp(app.client_id, event.target.checked); }}
														label=""
														className={classes.checkboxLabel}
													/>
												</Grid>
												<Grid item className={`${classes.listGridItem} ${classes.listGridStatus}`} onClick={() => toggleVariants(app.client_id)}>
													Under change
												</Grid>
												<Grid item className={`${classes.listGridItem} ${classes.listGridIcon}`} onClick={() => toggleVariants(app.client_id)}>
													<img src={'/images/icons/ChevronDown.svg'} className={appVariantsVisible.includes(app.client_id) ? classes.iconStyleRotate : classes.iconStyle} alt="" />
												</Grid>
											</Grid>
											<Grid container className={appVariantsVisible.includes(app.client_id) ? classes.show : classes.hide}>
												<Grid container className={classes.subListGridHeading}>
													<Grid item className={`${classes.listGridItem} ${classes.listGridSelectLanguage}`} >
														<Field
															name="selectAll"
															id="selectAll"
															value={"Language"}
															defaultValue={"Language"}
															checked={allowPublish() && (app.application_texts?.length > 0 &&
																app.application_texts.filter(apptext => !apptext.published).every(trans => selections.some(sel => sel.appLanguage === `${app.client_id}#${trans.language}`)))}
															component={CheckboxWithName}
															onClick={(event: any) => { onChangeSelectAllLanguages(app.client_id, event.target.checked); }}
															label=""
														/>
													</Grid>
													<Grid item className={`${classes.listGridItem} ${classes.listGridName}`} >
														Name
													</Grid>
												</Grid>
												{languages.map((language) => {
													const languageTranslation = (app.application_texts || []).find((appTranslation: LanguageTranslation) => appTranslation.language === language.id && !appTranslation.published);
													const isChecked = allowPublish() ? selections.some(appLan => appLan.appLanguage === `${app.client_id}#${language.id}`) : false;

													if (languageTranslation) {
														useGrayBackground = !useGrayBackground;
													}

													return languageTranslation && (
														<TranslationRow clientId={app.client_id} useGrayBackground={useGrayBackground} onChange={handleCheckboxClick} languageTranslation={languageTranslation || {} as LanguageTranslation} language={language.text} key={language.text} checked={isChecked} rowContainerClass={classes.rowContainer} showCheckbox={true} />
													)
												})}
											</Grid>
										</Grid>
									)
								})}

								{selectedApplications.length > 0 && selections.length > 0 && (
									<Grid container className={classes.buttonContainer}>
										{allowPublish() &&
											<SpinnerButton
												type="submit"
												variant="contained"
												color="primary"
												className={classes.button}
												pathToImagesFolder={'/images/icons'}
												onClick={(e: any) => handlePublishButtonClick(e)}
												spinnerVisible={isPublishing}
											>
												Publish
											</SpinnerButton>
										}
									</Grid>
								)}
								{!isLoading && selections.length === 0 &&
									<Grid container >
										<Grid item className={`${classes.listGridItem}`} >
											No changes to publish
										</Grid>
									</Grid>
								}
							</Grid>
						</form>
					</Grid>
				)}
			</DataLoader>
		</Main>
	);
}

export default reduxForm({
	form: 'appTranslationPublish'
})(AppTranslationPublish);
