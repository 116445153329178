import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	banner: {
		boxShadow: '0px -4px 0px #E2000F',
		borderRadius: '0px 0px 2px 2px',
		"&:hover": {
			boxShadow: '0px -4px 0px #E2000F, 0px 12px 17px 2px rgba(66, 78, 84, 0.14)'
		}
	},
	bannerText: {
		fontSize: '20px',
		fontWeight: 600,
		lineHeight: '24px'
	},
	bannerPopup: {
		position: 'absolute',
    	margin: '0 auto',
		maxWidth: '546px',
		[theme.breakpoints.up('sm')]: {
		  margin: '0px 15px 0'
		},
		textAlign: 'left',
		paddingBottom: '23px',
		padding: '16px 35px 50px 35px !important'
	  },
	  bannerPopupContainer: {
		  maxWidth: '1280px',
		  display: 'flex',
		  justifyContent: 'center',
		  position: 'absolute',
		  top: '250px'
	  }
}));