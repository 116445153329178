import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	bannerContainer: {
		position: 'relative',
		margin: '37px 0'
	},
	blackText: {
		color: colors.webexuiBlack,
	},
	button: {
		marginRight: '20px'
	},
	buttonPreview: {
		margin: '32px 0px 0 20px',
		width: '89px'
	},
	buttonDefault: {
		margin: '20px 0px 0 20px',
		width: '89px'
	},
	buttonHidden: {
		display: 'none'
	},
	buttonLabel: {
		border: '1px solid #d8d8d9',
		padding: '8px 16px 13px',
		backgroundColor: colors.webexuiWhite,
		color: colors.webexuiBlack,
		fontSize: '16px',
		minWidth: '64px',
		boxSizing: 'border-box',
		transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		fontFamily: 'myriad-pro, Myriad Pro',
		fontWeight: 600,
		lineHeight: '1.75',
		borderRadius: '4px',
		textTransform: 'none',
		cursor: 'pointer'
	},
	buttonLabelGray: {
		border: '1px solid #000000',
		padding: '6px 16px 7px',
		backgroundColor: colors.webexuiWhite,
		color: colors.webexuiBlack,
		opacity: '0.2',
		fontSize: '16px',
		minWidth: '64px',
		boxSizing: 'border-box',
		transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		fontFamily: 'myriad-pro, Myriad Pro',
		fontWeight: 600,
		lineHeight: '1.75',
		borderRadius: '4px',
		textTransform: 'none'
	},
	formContainer: {
		position: 'relative',
		minWidth: '692px',
		width: '810px'
	},
	richTextEditor: {
		width: '100%',
		maxWidth: '692px'
	},
	buttonContainer: {
		marginTop: '20px',
		justifyContent: 'start'
	},
	translationForm: {
		minWidth: '684px'
	},

	outerContainer: {
		backgroundImage: 'radial-gradient(#e2000f, #b6000f)'
	},
	container: {
		paddingLeft: '0px',
		paddingRight: '0px',
	},
	gridContainerLeftPadding: {
		[theme.breakpoints.up('sm')]: {
			paddingLeft: '13px'
		},
		[theme.breakpoints.only('xs')]: {
			paddingLeft: '22px'
		}
	},
	paneContainer: {
		color: colors.webexuiGrayText,
		paddingBottom: '5px',
		marginBottom: '0px',
		fontSize: '16px',
		zIndex: 10
	},
	pane: {
		minWidth: '80px'
	},
	paneunderline: {
		width: 'calc(100% + 72px)',
		borderBottom: `1px solid ${colors.webexuiGray}`,
		marginTop: '-20px',
		marginLeft: '-36px',
		marginRight: '-36px',
		marginBottom: '24px'
	},
	gridContainerRightBorder: {
		[theme.breakpoints.up('sm')]: {
			padding: '0px 46px 0 13px',
		},
		[theme.breakpoints.only('xs')]: {
			padding: '0px 22px',
		},
		marginTop: '8px',
		borderRight: `1px solid ${colors.webexuiGray}`
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(0.5),
	},
	TextboxContainer: {
		paddingBottom: '12px',
		fontSize: '16px'
	},
	marginTop: {
		marginTop: '-1px'
	},
	gridButtonContainer: {
		margin: '49px 0px 16px 0px;'
	},
	gridButtonContainerSignup: {
		margin: '28px 0px 1px 0px'
	},
	gridContainer: {
		[theme.breakpoints.up('sm')]: {
			padding: '12px 0px 0px 0px'
		},
		[theme.breakpoints.only('xs')]: {
			padding: '12px 22px'
		},

	},
	messageArea: {
		color: colors.webexuiBlack,
		fontSize: '18px',
		margin: '8px 0px 0px',
		fontWeight: 600,
		display: 'inline',
    	[theme.breakpoints.only('xs')]: {
			marginBottom: '33px'
		}
	},
	bannerPopupLogin: {
		position: 'fixed',
		width: '862px',
		minHeight: '490px',
		textAlign: 'left',
		paddingBottom: '23px',
		padding: '16px 35px 50px 35px !important',
		left: 0,
		top: 100,
		right: 0,
		margin: 'auto'
	},
	bannerPopupSignup: {
		position: 'fixed',
		width: '862px',
		minHeight: '748px',
		textAlign: 'left',
		paddingBottom: '23px',
		padding: '16px 35px 50px 35px !important',
		left: 0,
		top: 100,
		right: 0,
		margin: 'auto'
	},
	bannerPopupApplication: {
		position: 'fixed',
		width: '372px',
		minHeight: '250px',
		textAlign: 'left',
		paddingBottom: '23px',
		padding: '16px 20px 20px 35px !important',
		left: 0,
		top: 100,
		right: 0,
		margin: 'auto'
	},
	loginHeader: {
		fontSize: '24px',
		fontWeight: 'bold',
		paddingLeft: '12px',
		margin: '24px 0'
	},
	PopupText: {
		color: '#616161',
		padding: '13px 17px 13px 47px',
		fontSize: '16px'
	},
	input: {
		width: '100%',
		border: 0,
		height: '24px',
		margin: 0,
		display: 'block',
		padding: '6px 0 7px',
		minWidth: 0,
		background: 'none',
		boxSizing: 'content-box',
		letterSpacing: 'inherit',
		backgroundColor: '#f4f4f4'
	},
	redDot: {
		color: colors.webexuiRed
	},
	redBottomBorder: {
		borderBottom: '3px solid #e2000f',
		paddingBottom: '10px'
	},
	linkArrow: {
		top: '2px',
		width: '16px',
		height: '16px',
		position: 'relative',
		marginRight: '10px',
	},
	informationIcon:{
		width: '16px',
		height: '16px'
	},
	informationIconContainer: {
		display: 'inline-table',
		lineHeight: '16px',
		marginLeft: '6px',
		verticalAlign: 'middle'
	},
	close: {
		cursor: 'pointer',
		width: '16px',
		[theme.breakpoints.only('xs')]: {
		  margin: '10px -16px',
		},
		[theme.breakpoints.up('sm')]: {
		  right: '25px',
		  top: '25px'
		},
		position: 'absolute'
	  },
	  checkboxContainer:{
		  marginTop: '30px'
	  },
	  checkboxLink: {
		color: '#e2000f',
		fontSize: '16px',
		letterSpacing: '0.4px',
		textDecoration: 'underline'
	  },
	  editorContainer: {
		  width: '698px'
	  },
	banner: {
		boxShadow: '0px -4px 0px #E2000F',
		borderRadius: '0px 0px 2px 2px',
		"&:hover": {
			boxShadow: '0px -4px 0px #E2000F, 0px 12px 17px 2px rgba(66, 78, 84, 0.14)'
		},
		border: '1px solid #E2000F'
	},
	bannerText: {
		fontSize: '20px',
		fontWeight: 600,
		lineHeight: '24px'
	},
	bannerIconBorder: {
		borderRight: `1px solid ${colors.webexuiGraySoft}`,
	},
	bannerPopup: {
		position: 'absolute',
    	margin: '0 auto',
		maxWidth: '650px',
		minWidth: '650px',
		[theme.breakpoints.up('sm')]: {
		  margin: '-243px 15px 0'
		},
		textAlign: 'left',
		paddingBottom: '23px',
		padding: '16px 35px 50px 35px !important'
	  },
	  bannerPopupContainer: {
		  maxWidth: '1280px',
		  display: 'flex',
		  justifyContent: 'center',
		  position: 'absolute',
		  top: '250px'
	  },
	  textAreaButtonContainer: {
		width: '100px'
	  },
	  linkText: {
		fontSize: '18px',
		fontWeight: 600,
		letterSpacing: '0.18px',
		lineHeight: '28px',
		textDecoration: 'none',
		color: colors.webexuiDarkGrayish,
		cursor: 'pointer'
	  },
	  LinkContainer: {
		  marginTop: '10px'
	  }
})
);