import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { UserInfo } from '../../../../API/users';
import { Spinner } from '@danfoss/webex-ui';
import AuthLogsTable from './AuthLogsTable';
import { useStyles } from './styles';
import useUserAuthInfoPagination from './useUserAuthInfoPagination';


export interface UserAuthInfoProps {
    user: UserInfo;
}

const UserAuthInfo: React.FC<UserAuthInfoProps> = ({ user }) => {
    const classes = useStyles();

    const {
        data,
        currentPage,
        onPageChange,
        totalPages,
        isLoading,
    } = useUserAuthInfoPagination(user);

    return (
        <Grid className={classes.tableContainer} container item xs={12}>
            {isLoading && <Spinner className={classes.spinner} visible />}
            {data.length !== 0 && (
                <>
                    <Typography variant='h6'>
                        Logs
                    </Typography>
                    <AuthLogsTable
                        data={data}
                        totalPages={totalPages}
                        currentPage={currentPage}
                        onPageChange={onPageChange}
                    />
                </>
            )}
            {!isLoading && data.length === 0 && (
                <Typography className={classes.noData} variant='h6'>
                    No data
                </Typography>
            )}
        </Grid>
    );
}

export default UserAuthInfo;
