import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ModalPopup from '../../Components/Modal/ModalPopup';
import { SpinnerButton } from '@danfoss/webex-ui/dist/mui';
import { FormLeaveConfirmProps } from './types';
import { useStyles } from './styles';
import { isIE } from 'mobile-device-detect';

const FormLeaveConfirm: React.FC<FormLeaveConfirmProps> = ({ setShowLeave, onSave, onLeave }) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.popupContainer}>
            <ModalPopup paperClassName={classes.popup}>
                <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
                    <div onClick={() => setShowLeave()}>
                        <img src={'/images/icons/Close.svg'} className={classes.close} alt="" />
                    </div>
                </Grid>
                <Grid container className={classes.root}>
                    <Typography className={classes.header}>
                        Data change detected
                    </Typography>
                    <Typography>
                        You have unsaved changes, sure you want reset your changes?
                    </Typography>
                    <Grid container alignItems="flex-start" justifyContent="flex-start" direction="row" className={classes.buttonContainer}>
                        <SpinnerButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={() => onSave()}
                            pathToImagesFolder={'/images'}
                            spinnerVisible={false}
                        >
                            Save changes
                        </SpinnerButton>
                        {!isIE && (
                            <Button
                                onClick={(e) => onLeave(e)}
                                type="button"
                                variant="outlined"
                                color="default"
                                className={classes.button}
                            >
                                Leave without saving
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </ModalPopup>
        </Grid>
    );
};

export default FormLeaveConfirm;
