import { action } from 'typesafe-actions';
import { LoadingStatus } from '../types';
import { ContactInfoActionTypes, ContactInfo } from './types';

export interface GetContactInfo {
    type: ContactInfoActionTypes.GET_CONTACT_INFO;
    payload: {
        contact_ids: string[],
        accessTokenMyDanfossApi: string;
    };
}

export interface GetContactInfoSucceed {
    type: ContactInfoActionTypes.SET_CONTACT_INFO;
    payload: ContactInfo[];
}

export interface ContactInfoLoadingStatus {
    type: ContactInfoActionTypes.SET_CONTACT_LOADING_STATUS;
    payload: LoadingStatus;
}


export const GetContactInfoAction = (contact_ids: string[], accessTokenMyDanfossApi: string): GetContactInfo =>
    action(ContactInfoActionTypes.GET_CONTACT_INFO, { contact_ids, accessTokenMyDanfossApi });

export const SetContactInfo = (contactInfos: ContactInfo[]): GetContactInfoSucceed =>
    action(ContactInfoActionTypes.SET_CONTACT_INFO, contactInfos);

export const SetContactLoadingStatus = (loadingStatus: LoadingStatus): ContactInfoLoadingStatus =>
    action(ContactInfoActionTypes.SET_CONTACT_LOADING_STATUS, loadingStatus);


export type ContactInfoActionType = GetContactInfoSucceed | ContactInfoLoadingStatus;