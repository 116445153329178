import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	root: {
		marginLeft: '0px',
		position: 'relative'
	},

	fullWidth: {
		backgroundColor: colors.webexuiWhite,
		width: '100%',
    	margin: '30px 0 30px 10px'
	},
	fullHeight: {
		height: '100%'
	},
  mainTitle: {
    paddingLeft: '0px !important',
    textAlign: 'left',
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '24px'
  },
textContainer: {
    color: '#686868',
    textAlign: 'left'
},

}));