import { action } from 'typesafe-actions';
import { UserInfo } from '../../API/users';
import { UserActionTypes, CurrentUser } from './types';

export interface GetCurrentUser {
	type: UserActionTypes.GET_CURRENT_USER;
    payload: {
        user_id: string, 
        accessTokenMyDanfossAccountApi: string;
    };
}

export interface SetCurrentUser {
    type: UserActionTypes.SET_CURRENT_USER;
    payload: CurrentUser;
}

export interface SetImpersonatedUser {
    type: UserActionTypes.SET_IMPERSONATED_USER;
    payload: UserInfo;
}

export interface ClearImpersonatedUser {
    type: UserActionTypes.CLEAR_IMPERSONATED_USER
}

export const getCurrentUser = (user_id: string, accessTokenMyDanfossAccountApi: string): GetCurrentUser =>
    action(UserActionTypes.GET_CURRENT_USER, {user_id, accessTokenMyDanfossAccountApi});
    
export const setCurrentUser = (currentUser: CurrentUser): SetCurrentUser =>
    action(UserActionTypes.SET_CURRENT_USER, currentUser);
    
export const setImpersonatedUser = (impersonatedUser: UserInfo): SetImpersonatedUser =>
    action(UserActionTypes.SET_IMPERSONATED_USER, impersonatedUser);

export const clearImpersonatedUser = (): ClearImpersonatedUser =>
    action(UserActionTypes.CLEAR_IMPERSONATED_USER);

export type UserActionType = SetCurrentUser | SetImpersonatedUser | ClearImpersonatedUser;