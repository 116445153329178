import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	errorText: {
		color: colors.webexuiRed,
		width: '100%'
	},
	errorHeading: {
		fontWeight: 'bold',
		width: '100%',
		marginBottom: '15px'
	},
	button: {
		marginRight: '20px',
		marginTop: '20px'
	},

})
);