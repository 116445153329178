import { Auth0ClientOptions, GetTokenSilentlyOptions } from '@auth0/auth0-spa-js/dist/typings/global';

interface Auth0ClientExtensions {
    custom_domain: string
}

interface Auth0ClientOptionsWithExtensions extends Auth0ClientOptions, Auth0ClientExtensions {

}

interface ApiSettings {
    endpoint: string;
    audience: string;
    scopes: string;
}

interface AppSettings {
    auth0: Auth0ClientOptionsWithExtensions,
    generalInformation: {
        endpoint: string;
    },
    myDanfossAccountApi: {
        endpoint: string;
        audience: string;
        scopes: string;
        accessTokenOptions : GetTokenSilentlyOptions; 
    },
    myDanfossAccount: {
        endpoint: string;
    },
    myDanfossApi: {
        endpoint: string;
        audience: string;
        scopes: string;
        accessTokenOptions : GetTokenSilentlyOptions; 
    },
    appInsights: {
        key: string;
    },
    terms: {
        endpoint: string;
    },
    delayForEndpoints: {
        read_users: string
        per_user_request: string
    },
    specialPermissionSettings: {
        allow_publish: boolean
    },
    contacts: {
        ids: string
    }
}

const myDanfossApiSettings : ApiSettings = JSON.parse(process.env.REACT_APP_MYDANFOSS_API || "{}");
const myDanfossAccountApiSettings : ApiSettings = JSON.parse(process.env.REACT_APP_MYDANFOSSACCOUNTBACKEND_API || "{}");
const settings: AppSettings = {
    auth0: JSON.parse(process.env.REACT_APP_AUTH0 || "{}"),
    myDanfossAccountApi: {...myDanfossAccountApiSettings, accessTokenOptions: { audience: myDanfossAccountApiSettings.audience, scope: myDanfossAccountApiSettings.scopes, analytics: false}},
    myDanfossAccount: JSON.parse(process.env.REACT_APP_MYDANFOSSACCOUNT || "{}"),
    myDanfossApi: {...myDanfossApiSettings, accessTokenOptions: { audience: myDanfossApiSettings.audience, scope: myDanfossApiSettings.scopes, analytics: false}},
    appInsights: JSON.parse(process.env.REACT_APP_APPINSIGHTS || "{}"),
    generalInformation: JSON.parse(process.env.REACT_APP_GENERALINFORMATION || "{}"),
    terms: JSON.parse(process.env.REACT_APP_TERMS || "{}"),
    delayForEndpoints: JSON.parse(process.env.REACT_APP_DELAY_FOR_ENDPOINTS || "{\"user_reads\": \"2000\",\"per_user_request\": \"100\"}"),
    specialPermissionSettings: JSON.parse(process.env.REACT_APP_SPECIAL_PERMISSION_SETTINGS || "{\"allow_publish\": true}"),
    contacts: JSON.parse(process.env.REACT_APP_CONTACTS || "{}")
};

export default settings;
