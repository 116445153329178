import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	gridMargin: {
        paddingTop: "5px",
        paddingBottom: "5px"
      },
      label: {
        fontWeight: 600,
        whiteSpace: 'nowrap'
      },
      text: {
        fontSize: '16px',
        lineHeight: '22px',
        color: colors.webexuiDarkGrayish,
        whiteSpace: "normal"
      },
      editText: {
        fontSize: '16px',
        lineHeight: '24px',
        color: colors.webexuiGrayText
      },
      selectMenu: {
        "& .MuiSelect-selectMenu": {
          whiteSpace: 'no-wrap',
        }
      },
      element: {
        width: '100%',
        marginBottom: '5px',
        color: colors.webexuiBlack
      },
      checkboxElement: {
        color: colors.webexuiBlack
      },
      labelError: {
        fontFamily: 'myriad-pro, Myriad Pro',
        color: 'red',
        fontSize: '16px',
        lineHeight: '24px',
        whiteSpace: 'normal',
        [theme.breakpoints.up('md')]: {
          float: 'right'
        }
      },
      labelErrorLeft: {
        fontFamily: 'myriad-pro, Myriad Pro',
        color: 'red',
        fontSize: '16px',
        lineHeight: '24px',
        [theme.breakpoints.up('md')]: {
          float: 'left'
        }
      },
      ErrorShadow: {
        boxShadow: `0 0 5px 0 ${colors.webexuiRed}`
      },
      iconStyle: {
        position: 'absolute',
        top: '0',
        right: '0',
        display: 'inline-block',
        color: '#c0c0c0',
        width: '13px',
        height: '13px',
        margin: '12px 10px',
        pointerEvents: 'none'
      },
      grayTextRoot: {
        '& $disabled': {
          color: '#686868'
        }
      },
      disabled: {},
      marginTop: {
        marginTop: '10px'
      },
      richTextEditor: {
        width: '100%',
        maxWidth: '575px'
      },
      richTextEditorContainer: {
        marginTop: '9px'
      },
      isMandatory: {
        color: colors.webexuiRed
      },
      hide: {
        display: 'none'
      }
}));