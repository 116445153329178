import React from 'react';
import { Table as MuiTable, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useStyles } from './styles';



export interface ColumnConfig<T> {
    label: string;
    render: (row: T) => React.ReactNode;
    className?: string;
}

export interface TableProps<T> {
    data: T[];
    columns: ColumnConfig<T>[];
    entityIdSelector: (entity: T) => string | number;
}

function Table<T>({ data, columns, entityIdSelector }: TableProps<T>) {
    const classes = useStyles();

    return (
        <MuiTable>
            <TableHead>
                <TableRow className={classes.header}>
                    {columns.map(col => (
                        <TableCell
                            key={col.label}
                            className={`${classes.headerCell} ${col.className}`}
                        >
                            {col.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((row, rowIndex) => (
                    <TableRow key={entityIdSelector(row)} className={rowIndex % 2 === 0 ? '' : classes.rowOdd}>
                        {columns.map(col => (
                            <TableCell
                                key={col.label}
                                className={`${classes.cell} ${col.className}`}
                            >
                                {col.render(row)}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </MuiTable>
    );
}

export default Table;
