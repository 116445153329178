import React from 'react';
import Main from '../Main/Main';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';

const NoAccess: React.FC = (props) => {

    //Get querystring parameters
    const classes = useStyles();

    return (
        <Main>
            <Grid container >
                <Grid item xs={12}>
                    <Typography className={`${classes.mainTitle}`}>
                        No Access
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.textContainer}>
                    You do not have access to the selected function
                </Grid>
            </Grid>
        </Main>
    );
}

export default NoAccess