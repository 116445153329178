import React, { useEffect, useState } from 'react';
import Main from '../../Main/Main';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './styles';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../../../Store';
import settings from '../../../Config/settings';
import { useAuth0 } from '@auth0/auth0-react';
import { RecommendedApplication, getRecommendedApplications, updateRecommendedApplicationDraft, deleteRecommendedApplicationDraft, getAllRecommendedApplications } from '../../../API/recommendedApplication';
import { Application, getApplicationsForUserWithRoles } from '../../../API/application';
import { Roles } from '../../../Store/Roles/types';
import Button from '@material-ui/core/Button';
import ApplicationRow from './ApplicationRow';
import ImportErrorsPopup from '../../ImportPopup/ImportErrorsPopup';
import ImportWarningsPopup from '../../ImportPopup/ImportWarningsPopup';
import ImportConfirmPopup from '../../ImportPopup/ImportConfirmPopup';
import DataLoader from '../../DataLoader';
import { Spinner } from '@danfoss/webex-ui';
import { CopyProgress, ExportProgress, ImportedCriteria } from './types';
import { ImportProgress, ApplicationErrors, ApplicationWarnings } from '../../ImportPopup/types';
import { Field } from 'redux-form';
import dateFormat, { masks } from "dateformat";
import { CheckboxWithName } from '../../RenderFields/RenderFields';
import { validateImportedCriteria } from './validation';
import { getDanfossIdentityId, getUserRoles } from '../../../Utils';
import './animate.scss';
const AppCriteria: React.FC = () => {

	const classes = useStyles();
	const location = useLocation();
	const { getAccessTokenSilently } = useAuth0();
	const [recommendedApplications, setRecommendedApplications] = useState([] as RecommendedApplication[]);
	const [applicationsForUser, setApplicationsForUser] = useState([] as Application[]);
	const [selectedApplication, setSelectedApplication] = useState(undefined as string | undefined);
	const [showAlApplications, setShowAllApplications] = useState(false);
	const [copyCriteriaFrom, setCopyCriteriaFrom] = useState('');
	const [copyCriteriaTo, setCopyCriteriaTo] = useState([] as string[]);
	const [copyCriteriaProgress, setCopyCriteriaProgress] = useState(CopyProgress.NotStarted);
	const [exportCriteriaProgress, setExportCriteriaProgress] = useState(ExportProgress.NotStarted);
	const [importCriteriaProgress, setImportCriteriaProgress] = useState(ImportProgress.NotStarted);
	const [exportCriteriaFrom, setExportCriteriaFrom] = useState([] as string[]);
	const [importedCriteria, setImportedCriteria] = useState({ criteria: [] as ImportedCriteria[], errors: [] as ApplicationErrors[], warnings: [] as ApplicationWarnings[] });
	const [loading, setLoading] = useState(false);
	const currentUser = useSelector((state: AppState) => state.user);
	const userRoles = useSelector((state: AppState) => getUserRoles(state.userRoles));
	const applications = useSelector((state: AppState) => state.applications);
	const applicationsLoaded = useSelector((state: AppState) => state.applications.applicationsLoadingStatus.loaded);
	const appState = useSelector((state: AppState) => state);

	const handleEditClick = (client_id: string) => {
		setSelectedApplication(applicationsForUser.find(app => app.client_id === client_id)?.client_id);
	}

	const updateAppCriteria = (editedAppCriteria: RecommendedApplication) => {

		const updateCriteria = async () => {
			const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);

			await updateRecommendedApplicationDraft(accessTokenMyDanfossApi, editedAppCriteria);

			const editedRecommendedApplications = recommendedApplications.filter(recommApp => !(recommApp.client_id === editedAppCriteria.client_id && !recommApp.published));
			editedRecommendedApplications.push(editedAppCriteria);
			setRecommendedApplications(editedRecommendedApplications);
			setSelectedApplication(undefined);
		}

		updateCriteria();
	}

	const deleteDraftAppCriteria = (client_id: string) => {

		const updateCriteria = async () => {
			const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);

			await deleteRecommendedApplicationDraft(accessTokenMyDanfossApi, client_id);

			const editedRecommendedApplications = recommendedApplications.filter(recommApp => !(recommApp.client_id === client_id && !recommApp.published));
			setRecommendedApplications(editedRecommendedApplications);
		}

		updateCriteria();
	}

	const cancelUpdate = () => {
		setSelectedApplication(undefined);
	}

	const onSelectCopyFrom = (client_id: string, checked: boolean) => {
		if (checked) {
			setCopyCriteriaFrom(client_id);
		}
		else {
			setCopyCriteriaFrom('');
		}
	}

	const onSelectCopyTo = (client_id: string, checked: boolean) => {
		if (copyCriteriaTo.indexOf(client_id) > -1) {
			if (!checked) {
				setCopyCriteriaTo(copyCriteriaTo.filter(row => row !== client_id));
			}
		}
		else {
			if (checked) {
				setCopyCriteriaTo([...copyCriteriaTo, client_id]);
			}
		}

	}

	const onSelectExportFrom = (client_id: string, checked: boolean) => {
		if (exportCriteriaFrom.indexOf(client_id) > -1) {
			if (!checked) {
				setExportCriteriaFrom(exportCriteriaFrom.filter(row => row !== client_id));
			}
		}
		else {
			if (checked) {
				setExportCriteriaFrom([...exportCriteriaFrom, client_id]);
			}
		}

	}

	const onChangeSelectExportAll = (checked: boolean) => {
		if (checked) {
			setExportCriteriaFrom(recommendedApplications.map(recommApp => recommApp.client_id));
		}
		else {
			setExportCriteriaFrom([]);
		}
	}


	const pasteCriteriaOnSelectedApplications = () => {
		const pasteCrit = async () => {
			const sourceApp = recommendedApplications.find(rApp => rApp.client_id === copyCriteriaFrom && !rApp.published) ||
				recommendedApplications.find(rApp => rApp.client_id === copyCriteriaFrom && rApp.published) ||
				{ all_users: false, criteria: [], client_id: copyCriteriaFrom, published: false }

			const targetApps = copyCriteriaTo.map(target_client_id => {
				return { client_id: target_client_id, published: false, all_users: sourceApp.all_users, criteria: [...sourceApp.criteria] }
			})

			const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
			targetApps.forEach(async targetApp => {
				await updateRecommendedApplicationDraft(accessTokenMyDanfossApi, targetApp);
			});

			const editedRecommendedApplications = recommendedApplications.filter(recommApp => !(targetApps.some(target => recommApp.client_id === target.client_id) && !recommApp.published));
			editedRecommendedApplications.push(...targetApps);

			setRecommendedApplications(editedRecommendedApplications);
			setCopyCriteriaFrom('');
			setCopyCriteriaTo([]);
			setCopyCriteriaProgress(CopyProgress.NotStarted);
		}

		pasteCrit();
	}

	const cancelPaste = () => {
		setCopyCriteriaFrom('');
		setCopyCriteriaTo([]);
		setCopyCriteriaProgress(CopyProgress.NotStarted);
	}

	const exportSelectedApplications = () => {
		const sourceApps = recommendedApplications
			.filter(recommApp => exportCriteriaFrom.includes(recommApp.client_id) &&
				(!recommApp.published || !recommendedApplications.some(recommApp2 => !recommApp2.published && recommApp2.client_id === recommApp.client_id))
			)
			.map(recommApp => {
				const app = applications.applications?.find(app => app.client_id === recommApp.client_id);
				return {
					client_name: app && app.name || recommApp.client_id,
					all_users: recommApp.all_users,
					criteria: recommApp.criteria
				}
			});
		const contentType = "application/json;charset=utf-8;";
		var a = document.createElement('a');
		a.download = `RecommendedApps-${dateFormat(new Date, 'yyyymmdd-HHMMss')}`;
		a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(sourceApps, null, 4));
		a.target = '_blank';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);

		setExportCriteriaFrom([]);
		setExportCriteriaProgress(ExportProgress.NotStarted);
	}

	const cancelExport = () => {
		setExportCriteriaFrom([]);
		setExportCriteriaProgress(ExportProgress.NotStarted);
	}

	const handleImportClick = (e: any) => {
		e.preventDefault();

		var files = e.target.files, f = files[0];
		var fileReader = new FileReader();

		fileReader.readAsText(e.target.files[0], "UTF-8");
		fileReader.onload = (e: any) => {
			try {
				const importedCriteria: ImportedCriteria[] = JSON.parse(e.target.result);
				importedCriteria.forEach(crit => {
					crit.client_id = applications.applications?.find(app => app.name === crit.client_name)?.client_id || '';
					crit.published = false;
				})

				const { errors, warnings } = validateImportedCriteria(importedCriteria, recommendedApplications,
					applicationsForUser, appState);
				setImportCriteriaProgress((errors.length > 0 && ImportProgress.HandleErrors) || (warnings.length > 0 && ImportProgress.HandleWarnings) || ImportProgress.ConfirmImport)
				setImportedCriteria({ criteria: importedCriteria, errors, warnings })
			}
			catch (error) {
				setImportCriteriaProgress(ImportProgress.ImportFailed);
				setImportedCriteria({ criteria: [], errors: [], warnings: [] })
			}
		};
		e.target.value = '';
	}

	const cancelImport = () => {
		setImportedCriteria({ criteria: [], errors: [], warnings: [] });
		setImportCriteriaProgress(ImportProgress.NotStarted);
	}

	const criteriaWithoutWarning = () => {
		return importedCriteria.criteria.filter(crit => {
			return !(crit.client_name && importedCriteria.warnings.some(warning => warning.client_name === crit.client_name));
		})
	}

	const continueImport = (skipAppsWithUnpublishedChanges: boolean) => {
		const appsToImport = skipAppsWithUnpublishedChanges && criteriaWithoutWarning() || importedCriteria.criteria;
		setImportedCriteria({ ...importedCriteria, criteria: appsToImport });
		setImportCriteriaProgress(ImportProgress.ConfirmImport);
	}

	const performImport = () => {
		const importAsDrafts = async () => {
			const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
			importedCriteria.criteria.forEach(async importedApp => {
				await updateRecommendedApplicationDraft(accessTokenMyDanfossApi, importedApp);
			});

			const importedRecommendedApplications = recommendedApplications.filter(recommApp => !(importedCriteria.criteria.some(importedApp => recommApp.client_id === importedApp.client_id) && !recommApp.published));
			importedRecommendedApplications.push(...importedCriteria.criteria);

			setRecommendedApplications(importedRecommendedApplications);
			setImportedCriteria({ criteria: [], errors: [], warnings: [] });
			setImportCriteriaProgress(ImportProgress.NotStarted);
		}

		importAsDrafts();
	}

	useEffect(() => {
		const loadRecommendedApps = async () => {
			setLoading(true);
			if (currentUser.userLoaded && currentUser.user && applicationsLoaded) {
				const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
				const accessTokenMyDanfossAccountApi = await getAccessTokenSilently(settings.myDanfossAccountApi.accessTokenOptions);
				let recommendedApps: RecommendedApplication[] = []
				if (userRoles.some(role => role === Roles.DASHBOARD_ADMIN)) {
					setApplicationsForUser(applications.applications?.filter(app => app.app_id) || []);
					recommendedApps = await getAllRecommendedApplications(accessTokenMyDanfossApi);
				}
				else {
					const clientIdsForUser = await getApplicationsForUserWithRoles(accessTokenMyDanfossApi, accessTokenMyDanfossAccountApi, getDanfossIdentityId(currentUser), [Roles.APPLICATION_OWNER, Roles.RECOMMENDED_APPLICATION_EDITOR]);
					const appsForUser = applications.applications?.filter(app => clientIdsForUser.includes(app.client_id)) || [];
					setApplicationsForUser(appsForUser.filter(app => app.app_id));
					recommendedApps = await getRecommendedApplications(accessTokenMyDanfossApi, clientIdsForUser);
				}
				setRecommendedApplications(recommendedApps);
				setShowAllApplications(recommendedApps && recommendedApps.length === 0)
				setLoading(false);
			}
		}
		loadRecommendedApps();

	}, [applications.applications, applicationsLoaded, currentUser]);

	return (
		<Main breadCrumbs={{ items: [{ text: `Recommended application criteria`, link: location.pathname }] }}>
			<DataLoader referencesRequired={true} applicationsRequired={true} countriesRequired={true}>
				<Spinner visible={loading} ></Spinner>
				<ImportErrorsPopup
					importTypeProgress={importCriteriaProgress}
					errors={importedCriteria.errors} onCancelImport={cancelImport}
				/>
				<ImportWarningsPopup
					importTypeProgress={importCriteriaProgress}
					warnings={importedCriteria.warnings}
					onCancelImport={cancelImport}
					onContinueImport={continueImport}
					importButtonTypeText='Import all apps despite warnings'
					importOtherButtonTypeText='Import apps without warnings'
					showOtherFilesButton={criteriaWithoutWarning().length > 0}
				/>
				<ImportConfirmPopup
					importTypeProgress={importCriteriaProgress}
					importItemNames={importedCriteria.criteria.map(crit => crit.client_name)}
					importTypeText='apps with criteria'
					importButtonTypeText='criteria'
					onCancelImport={cancelImport}
					onPerformImport={performImport}
				/>

				{!loading && applicationsForUser.length > recommendedApplications.length &&
					<Grid container className={`${classes.toggleButtonContainer}`}>
						<Button
							type="button"
							variant="outlined"
							color="default"
							disabled={!!selectedApplication}
							onClick={(e) => { e.preventDefault(); setShowAllApplications(!showAlApplications) }}
						>
							{showAlApplications && 'Only show recommended applications' || 'Show all applications'}
						</Button>
					</Grid>
				}
				<Grid container className={classes.applicationListGridHeading}>
					<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridEdit}`} >
						{copyCriteriaProgress === CopyProgress.SelectSource && 'Copy from' || ''}
						{copyCriteriaProgress === CopyProgress.SelectTargets && 'Copy to' || ''}
						{exportCriteriaProgress === ExportProgress.SelectSources && <CheckboxWithName
							name="selectAll"
							id="selectAll"
							value=""
							defaultValue=""
							checked={recommendedApplications.every(recommApp => exportCriteriaFrom.includes(recommApp.client_id))}
							onClick={(event: any) => { onChangeSelectExportAll(event.target.checked); }}
							label=""
						/>
						}
					</Grid>
					<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridName}`} >
						Application name
					</Grid>
					<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridCriteria}`} >
						Criteria
					</Grid>
					<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridStatus}`} >
						Status
					</Grid>
				</Grid>
				{applicationsForUser.filter(app => showAlApplications || recommendedApplications.some(recommApp => recommApp.client_id === app.client_id))
					.sort((app1, app2) => app1.name.localeCompare(app2.name))
					.map((app, idx, allRecommAppsForUser) => {
						const noOfRecommAppsForUser = allRecommAppsForUser.length;
						const recommApp = recommendedApplications.find(rApp => rApp.client_id === app.client_id && !rApp.published) ||
							recommendedApplications.find(rApp => rApp.client_id === app.client_id && rApp.published);
						const recommAppPublished = recommendedApplications.find(rApp => rApp.client_id === app.client_id && rApp.published);
						return (
							<ApplicationRow
								application={app}
								recommendedApplication={recommApp}
								recommendedApplicationPublished={recommAppPublished}
								copyCriteriaProgress={copyCriteriaProgress}
								copyCriteriaFrom={copyCriteriaFrom}
								copyCriteriaTo={copyCriteriaTo}
								exportCriteriaProgress={exportCriteriaProgress}
								exportCriteriaFrom={exportCriteriaFrom}
								updateAppCriteria={updateAppCriteria}
								deleteDraftAppCriteria={deleteDraftAppCriteria}
								cancelUpdate={cancelUpdate}
								onSelectCopyFrom={onSelectCopyFrom}
								onSelectCopyTo={onSelectCopyTo}
								onSelectExportFrom={onSelectExportFrom}
								handleEditClick={handleEditClick}
								rowNo={idx}
								editMode={selectedApplication === app.client_id}
								disabled={!!selectedApplication && selectedApplication !== app.client_id}
								key={idx}
								noOfRecommAppsForUser={noOfRecommAppsForUser}
							/>
						)
					})}
				<Grid container >
					<Grid item className={`${classes.applicationListGridItem} `} >
						{copyCriteriaProgress === CopyProgress.SelectTargets && copyCriteriaFrom && copyCriteriaTo.length > 0 &&
							<Button
								type="button"
								variant="contained"
								color="primary"
								onClick={() => pasteCriteriaOnSelectedApplications()}
								disabled={!!selectedApplication}
								className={classes.button}
							>
								Paste criteria on selected applications
							</Button>
						}
						{copyCriteriaProgress === CopyProgress.SelectSource && copyCriteriaFrom &&
							<Button
								type="button"
								variant="contained"
								color="primary"
								onClick={() => setCopyCriteriaProgress(CopyProgress.SelectTargets)}
								disabled={!!selectedApplication}
								className={classes.button}
							>
								Continue
							</Button>
						}
						{copyCriteriaProgress !== CopyProgress.NotStarted &&
							<Button
								type="button"
								variant="outlined"
								color="default"
								onClick={() => cancelPaste()}
								disabled={!!selectedApplication}
								className={classes.button}
							>
								Cancel
							</Button>
						}
						{exportCriteriaProgress === ExportProgress.SelectSources && exportCriteriaFrom && exportCriteriaFrom.length > 0 &&
							<Button
								type="button"
								variant="contained"
								color="primary"
								onClick={() => exportSelectedApplications()}
								disabled={!!selectedApplication}
								className={classes.button}
							>
								Export selected applications
							</Button>
						}
						{exportCriteriaProgress !== ExportProgress.NotStarted &&
							<Button
								type="button"
								variant="outlined"
								color="default"
								onClick={() => cancelExport()}
								disabled={!!selectedApplication}
								className={classes.button}
							>
								Cancel
							</Button>
						}
						{!loading && copyCriteriaProgress === CopyProgress.NotStarted && exportCriteriaProgress === ExportProgress.NotStarted &&
							<Button
								type="button"
								variant="outlined"
								color="default"
								onClick={() => setCopyCriteriaProgress(CopyProgress.SelectSource)}
								disabled={!!selectedApplication}
								className={classes.button}
							>
								Copy criteria
							</Button>
						}
						{!loading && exportCriteriaProgress === ExportProgress.NotStarted && copyCriteriaProgress === CopyProgress.NotStarted &&
							<Button
								type="button"
								variant="outlined"
								color="default"
								onClick={() => setExportCriteriaProgress(ExportProgress.SelectSources)}
								disabled={!!selectedApplication}
								className={classes.button}
							>
								Export criteria
							</Button>
						}
						{!loading && exportCriteriaProgress === ExportProgress.NotStarted && copyCriteriaProgress === CopyProgress.NotStarted &&
							<React.Fragment>
								<label htmlFor="files" className={`${classes.buttonLabel} ${!!selectedApplication && classes.buttonLabelDisabled || ''}`}>Import criteria</label>
								<input disabled={!!selectedApplication} id="files" className={classes.buttonHidden} type="file" accept=".json" onChange={(e) => handleImportClick(e)} />
							</React.Fragment>
						}
						<Button
							type="button"
							variant="outlined"
							color="default"
							className={classes.button}
							component={RouterLink}
							to="/preview/applicationcriteria"
							disabled={!!selectedApplication}
						>
							Preview
						</Button>
						{userRoles.some(userRole => [Roles.RECOMMENDED_APPLICATION_PUBLISHER, Roles.APPLICATION_OWNER, Roles.DASHBOARD_ADMIN].some(role => role === userRole)) &&
							recommendedApplications.some(recommApp => !recommApp.published) &&
							exportCriteriaProgress === ExportProgress.NotStarted && copyCriteriaProgress === CopyProgress.NotStarted &&
							<Button
								type="button"
								variant="outlined"
								color="default"
								className={classes.button}
								component={RouterLink}
								disabled={!!selectedApplication}
								to="/publish/applicationcriteria"
							>
								Publish
							</Button>
						}
					</Grid>
				</Grid>

			</DataLoader>
		</Main>
	);
}

export default AppCriteria;
