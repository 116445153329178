import { makeStyles } from '@material-ui/core';
import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';

export const useStyles = makeStyles((theme) => ({
    field: {
        fontWeight: 600,
    },
    id: {
        borderRadius: 4,
        padding: '2px 6px',
        backgroundColor: colors.webexuiGrayBg,
        fontWeight: 400,
    },
}));