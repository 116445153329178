import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	root: {
		marginLeft: '0px',
		marginTop: '10px',
		position: 'relative',
		flexDirection: 'column'
	},
	toggleMobileContainer: {
		marginTop: '30px',
	},
	toggleModeContainer: {
		marginTop: '0px',
	},
	labelGrid: {
		fontSize: '1rem',
		width: '15%',
		paddingTop: '10px'
	},
	label: {
		fontSize: '1rem'
	},
	radioButton: {
		minWidth: '10%'
	},
	radioButtonsGrid: {
		width: '85%'
	},
	applicationContainer: {
		backgroundColor: colors.webexuiGraySoft,
		flexGrow: 1
	},
	innerContentMargin: {
		margin: '0 8px 0 5px',
		alignItems: 'center',
		position: 'relative',
		alignContent: 'flex-start',
		[theme.breakpoints.between('sm', 'md')]: {
			margin: '0 40px'
		},
		[theme.breakpoints.only('xs')]: {
			margin: '0 16px'
		}
	},
	applicationContainerMaxWidth: {
		position: 'relative',
		margin: 'auto',
		maxWidth: '1280px',
		marginBottom: '15px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: '10px'
		},
		[theme.breakpoints.only('xs')]: {
			marginBottom: '10px'
		}
	},
	applicationIconContainer: {
		margin: '18px 0px',
		[theme.breakpoints.only('xs')]: {
			width: '100%'
		},
		flexGrow: 1,
	},
	applicationTypeHeader: {
		margin: '25px 0 0 0',
		fontSize: '16px',
		fontWeight: 600
	},
	popup: {
		top: '233px',
		maxWidth: '650px',
		display: 'table',
		[theme.breakpoints.up('sm')]: {
			top: '300px'
		}
	},
	overlay: {
		[theme.breakpoints.only('xs')]: {
			marginTop: '10px',
			height: '100%'
		}
	},
	popupHeader: {
		color: colors.webexuiDarkGrayish,
		fontSize: '24px',
		fontWeight: 'bold',
		lineHeight: '25px',
		textAlign: 'left',
		margin: '26px 26px 26px 0'
	},
	popupContent: {
		fontSize: '16px',
		color: '#686868',
		marginBottom: '12px',
		"& a": {
			color: '#e2000f'
		}
	},
	popupContainer: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		position: 'absolute',
		top: '-50px'
	},
	popupContainerMobile: {
		visibility: 'hidden'
	},
	close: {
		cursor: 'pointer',
		width: '16px',
		[theme.breakpoints.only('xs')]: {
			margin: '10px -16px',
		},
		[theme.breakpoints.up('sm')]: {
			right: '16px'
		},
		position: 'absolute'
	},
	banner: {
		backgroundColor: colors.webexuiWhite,
		boxShadow: '0px -4px 0px #E2000F',
		borderRadius: '0px 0px 2px 2px',
		"&:hover": {
			boxShadow: '0px -4px 0px #E2000F, 0px 12px 17px 2px rgba(66, 78, 84, 0.14)'
		}
	},
	bannerInactive: {
		backgroundColor: colors.webexuiWhite,
		boxShadow: '0px -4px 0px #B0B0B1',
		borderRadius: '0px 0px 2px 2px',
		"&:hover": {
			boxShadow: '0px -4px 0px #B0B0B1, 0px 12px 17px 2px rgba(66, 78, 84, 0.14)'
		}
	},
	bannerText: {
		fontSize: '20px',
		fontWeight: 600,
		lineHeight: '24px'
	},
	bannerTextSmallFont: {
		fontSize: '16px',
		fontWeight: 600,
		lineHeight: '24px'
	},
	bannerIconBorder: {
		borderRight: `1px solid ${colors.webexuiGraySoft}`,
	},
	itemContainer: {
		[theme.breakpoints.only('xs')]: {
			paddingTop: '30px'
		}
	},
    bannerContainer: {
        position: 'relative',
        [theme.breakpoints.only('xs')]: {
            width: '100%'
        },
    },
	bannerSubContainer: {
		margin: '10px 0',
		[theme.breakpoints.up('xs')]: {
			margin: '10px 24px 14px 0',
			width: '302px'
		},
		[theme.breakpoints.only('xs')]: {
			margin: '10px 24px 14px 0',
			width: '100%',
			maxWidth: '100%'
		},
		padding: '36px 15px 0 0 !important',
		display: 'flex',
		maxWidth: '302px',
		minHeight: '160px',
		borderRadius: '0px 0px 2px 2px',
		position: 'relative'
	},
	addCursor: {
		cursor: 'pointer',
	},
	bannerIconContainer: {
		maxWidth: '86px',
		marginRight: '26px',
		height: '90px',
		textAlign: 'center'
	},
	bannerHelper: {
		height: '100%',
		display: 'inline-block',
		verticalAlign: 'middle'
	},
	bannerArrowSubContainer: {
		display: 'inline-block',
		verticalAlign: 'middle',
		lineHeight: '8px'
	},
	bannerImage: {
		width: '36px'
	},
	bannerTextContainer: {
		fontSize: '20px',
		letterSpacing: '0.1px',
		lineHeight: '24px',
		fontWeight: 600,
		color: colors.webexuiDarkGrayish,
		maxWidth: '166px',
		height: '90px'
	},
	bannerTextSubContainer: {
		verticalAlign: 'middle',
		display: 'inline-block'
	},
	informationIcon: {
		cursor: 'pointer',
		width: '24px'
	},
	iconContainer: {
		position: 'absolute',
		top: '20px',
		right: '35px',
		[theme.breakpoints.only('xs')]: {
			top: '18px',
			right: '8px'
		},
		zIndex: 1
	},
	informationIconContainer: {
		display: 'table-cell',
		paddingLeft: '12px'
	},
	})
);