import React, { useState } from 'react';
import { useStyles } from './styles';
import { Button } from '@material-ui/core';
import { UserInfo } from '../../../../../API/users';
import { useAuth0 } from '@auth0/auth0-react';
import settings from '../../../../../Config/settings';
import { deleteUserBounce } from '../../../../../API/activityHistory';


export interface DeleteBounceProps {
    user: UserInfo;
}

const DeleteBounce: React.FC<DeleteBounceProps> = ({ user }) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);

    const { getAccessTokenSilently } = useAuth0();

    const onDelete = async () => {
        setIsLoading(true);

        const accessToken = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
        await deleteUserBounce(accessToken, user.auth0_user_id);

        setIsLoading(false);
    };

    return (
        <Button
            className={classes.button}
            variant='outlined'
            disabled={isLoading}
            onClick={onDelete}
        >
            Delete bounces
        </Button>
    )
};

export default DeleteBounce;
