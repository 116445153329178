import React from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './Styles';
import { BannerProps } from './Types';
import parse from 'html-react-parser';

const Banner: React.FC<BannerProps> = ({ bannerClick, bannerClass, bannerIconBorderClass, bannerTextClass, bannerIcon, bannerIconHover, bannerText, bannerInformationIconUrl, bannerInformationClick, handleItemDisplay, id = "", index, itemType, client_id, showAsActive }) => {
    const classes = useStyles();
    const [iconSrc, setIconSrc] = React.useState(bannerIcon);
    
    const dragRef = React.useRef(null)
    const dropRef = React.useRef(null)
    
    const handleBannerClick = () => {
        bannerClick && bannerClick();
    }

    return (
        <div ref={dropRef} className={classes.bannerContainer}>
            <Grid ref={dragRef}>
                
                <Grid className={classes.iconContainer}>
                    {bannerInformationIconUrl && (
                        <Grid onClick={(e) => { e.preventDefault(); bannerInformationClick && bannerInformationClick(e) }} className={classes.informationIconContainer}>
                            <img src={bannerInformationIconUrl} className={classes.informationIcon} title={'Show more information'} alt="" />
                        </Grid>                        
                    )}
                </Grid>
                <Grid item xs={12} id={id} className={`${classes.bannerSubContainer} ${bannerClass} ${bannerClick && classes.addCursor}`} onClick={handleBannerClick} onMouseEnter={() => setIconSrc(bannerIconHover)} onMouseLeave={() => setIconSrc(bannerIcon)}>

                    <Grid item xs={12} className={`${classes.bannerIconContainer} ${bannerIconBorderClass}`}>
                        <span className={classes.bannerHelper} />
                        <span className={classes.bannerArrowSubContainer}>
                            <img src={iconSrc} alt="" className={classes.bannerImage} />
                        </span>
                    </Grid>
                    <Grid item xs={12} className={classes.bannerTextContainer}>
                        <span className={classes.bannerHelper} />
                        <span className={classes.bannerTextSubContainer}>
                            <Typography className={bannerTextClass}>
                                <span translate="no">{parse(bannerText)}</span>
                            </Typography>
                        </span>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default Banner;
