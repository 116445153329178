import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from './styles';
import { EditOwnerProps, FormValues } from './types'
import { asyncValidate, validateOnSubmit } from './validation'
import { validateEmail, isValidEmail } from '../validation'
import { reduxForm, Field, InjectedFormProps, WrappedFieldArrayProps, FieldArray, getFormValues, SubmissionError, change, stopAsyncValidation, FormErrors, clearSubmitErrors, reset } from 'redux-form';
import { LabeledTextboxUncontrolled } from '../../RenderFields/RenderFields';
import { SpinnerButton } from '@danfoss/webex-ui/dist/mui';
import Button from '@material-ui/core/Button';
import { AppState } from '../../../Store';
import { connect, useDispatch } from 'react-redux';
import { ApplicationOwner } from '../../../API/application';
import { searchUserByEmail } from '../../../API/users';
import { useState } from 'react';

type TimeoutIdType = ReturnType<typeof setTimeout>;

const FORMID = 'appOwners';
const RenderOwners: React.FC<InjectedFormProps<{}> & WrappedFieldArrayProps & FormValues> = ({ values, fields, resetForm, setLeaveConfirmValues, meta: { error, submitFailed }, ...rest }) => {
    const classes = useStyles();
    const [onChangeTimeout, setOnChangeTimeout] = useState<TimeoutIdType | undefined>(undefined);
    const dispatch = useDispatch();

    const clearReadOnlyFields = (idx: number) => {
        updateReadOnlyFields(idx, '', '', '');
    }

    const updateReadOnlyFields = (idx: number, danfoss_identity_id: string, name: string, phone_number: string) => {
        dispatch(change(FORMID, `owners[${idx}].danfoss_identity_id`, danfoss_identity_id));
        dispatch(change(FORMID, `owners[${idx}].name`, name));
        dispatch(change(FORMID, `owners[${idx}].phonenumber`, phone_number));
    }

    const validateDanfossEmail = async (email: string, idx: number) => {
        try {
            const user = await searchUserByEmail((rest as any).accessToken, email);
            updateReadOnlyFields(idx, user.danfoss_identity_id, user.name, user.phone_number);
            dispatch(clearSubmitErrors(FORMID));
        }
        catch {
            const errors: FormErrors<any, any> = { owners: [] };
            for (let i = 0; i < idx; i++) {
                errors.owners.push({});
            }
            errors.owners.push({ email: 'Email not found' })
            clearReadOnlyFields(idx);
            dispatch(stopAsyncValidation(FORMID, errors));
        }
    }

    const onChangeEmail = async (event: any, ownerIdx: number) => {
        if (onChangeTimeout) {
            // Cancel pending validation of Danfoss email from previous onChange
            clearTimeout(onChangeTimeout);
        }

        let timeoutId: TimeoutIdType | undefined = undefined;
        if (isValidEmail(event.target.value, values, rest)) {
            // Delay testing of Danfoss email to avoid too many calls to the API during typíng of the email
            timeoutId = setTimeout(async () => await validateDanfossEmail(event.target.value, ownerIdx), 2000);
        }

        clearReadOnlyFields(ownerIdx);

        setFormChanged();

        setOnChangeTimeout(timeoutId);
    }

    const onBlurEmail = async (event: any, ownerIdx: number) => {
        if (onChangeTimeout) {
            clearTimeout(onChangeTimeout);
            setOnChangeTimeout(undefined);
        }

        if (isValidEmail(event.target.value, values, rest)) {
            await validateDanfossEmail(event.target.value, ownerIdx);
        }
        else {
            clearReadOnlyFields(ownerIdx);
        }
    }

    useEffect(() => {
        if ((values as Partial<ApplicationOwner>).owners?.length === 0) {
            fields.push({ name: '', phonenumber: '', email: '' });
        }
    }, [])

    const setFormChanged = () => {
        // we need to tell the clickoutsidewrapper that a change have happened. No need to send 
        // actual values of the changed events, just send two string that doesn't match 
        setLeaveConfirmValues && setLeaveConfirmValues("1", "2", true, resetForm, document.getElementById('formSubmitButton'))
    }

    return (
        <React.Fragment>
            {(values as Partial<ApplicationOwner>).owners?.map((owner, idx) =>
                <Grid container key={idx} className={`${classes.ownerListGrid}`}>
                    <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridOwnerEmail}`} >
                        <Field
                            name={`owners[${idx}].email`}
                            component={LabeledTextboxUncontrolled}
                            validate={[validateEmail]}
                            onBlur={(event: any) => { onBlurEmail(event, idx) }}
                            onChange={(event: any) => { onChangeEmail(event, idx) }}
                            InputProps={{
                                classes: {
                                    input: classes.blackText
                                }
                            }}
                        />
                    </Grid>
                    <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridOwnerName}`}  >
                        <Field
                            name={`owners[${idx}].name`}
                            component={LabeledTextboxUncontrolled}
                            disabled={true}
                            InputProps={{
                                classes: {
                                    input: classes.blackText
                                }
                            }}
                        />
                    </Grid>
                    <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridOwnerPhone}`} >
                        <Field
                            name={`owners[${idx}].phonenumber`}
                            component={LabeledTextboxUncontrolled}
                            disabled={true}
                            InputProps={{
                                classes: {
                                    input: classes.blackText
                                }
                            }}
                        />
                    </Grid>
                    <Grid item className={`${classes.applicationListGridItem} ${classes.ownerListGridEdit}`} >
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            className={classes.editOwnerGridButton}
                            onClick={() => { fields.remove(idx); setFormChanged(); }}
                        >
                            Delete
                        </Button>
                    </Grid>
                </Grid>
            )}
            <Grid container >
                <Grid item className={`${classes.applicationListGridItem} ${classes.ownerListGridAddOwner}`} >
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        className={classes.editGridButton}
                        onClick={() => { fields.push({ name: '', phonenumber: '', email: '' }); setFormChanged(); }}
                    >
                        Add owner
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

const Owners = connect((state: AppState) => ({
    values: getFormValues(FORMID)(state)
}))(RenderOwners);

const EditOwner: React.FC<EditOwnerProps & InjectedFormProps<{}>> = (props) => {
    const classes = useStyles();
    const { application, updateApplication, cancel, handleSubmit, submitting, containerClassName, setLeaveConfirmValues, resetForm } = props;
    const submitForm = (values: any) => {
        const { errors, hasErrors } = validateOnSubmit(values, props);
        if (hasErrors) {
            throw new SubmissionError(errors);
        }

        const newApplication = { ...application, owners: values.owners }

        updateApplication(newApplication);

        setLeaveConfirmValues && setLeaveConfirmValues(newApplication, newApplication, false, resetForm, document.getElementById('formSubmitButton'))
    }
    const cancelAndReset = () => {
        // we need to tell the clickoutsidewrapper that changes have been canceled. No need to send 
        // actual values of the changed events, just send two matching strings
        setLeaveConfirmValues && setLeaveConfirmValues('', '', false, resetForm, document.getElementById('formSubmitButton'))
        cancel();
    }

    return (
        <Grid item id="clickoutsideContainer" className={classes.clickOutSideContainer}>
            <form onSubmit={handleSubmit(submitForm)} id={FORMID}>
                <Grid container className={`${classes.applicationListGrid} ${containerClassName}`} >
                    <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridEdit}`} >
                        <SpinnerButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.editGridButton}
                            pathToImagesFolder={'/images/icons'}
                            spinnerVisible={submitting}
                            id="formSubmitButton"
                        >
                            Save
                        </SpinnerButton>
                        <br />
                        <Button
                            type="button"
                            variant="outlined"
                            color="default"
                            className={classes.editGridButton}
                            onClick={(e) => { e.preventDefault(); cancelAndReset() }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridName}`} >
                        {application?.name}
                    </Grid>
                    <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridOwners}`} >
                        <FieldArray name="owners" component={Owners} {...props} />
                    </Grid>
                </Grid>
            </form>
        </Grid>
    );
};

function mapStateToProps(state: AppState, ownProps: EditOwnerProps) {
    const { application } = ownProps;
    return {
        initialValues: {
            applicationname: application?.name,
            owners: application?.owners.map(owner => ({ danfoss_identity_id: owner.danfoss_identity_id, name: owner.name, email: owner.email, phonenumber: owner.phonenumber }))
        }
    }
}

const mapDispatchToProps = (dispatch: any) => () => ({
    resetForm: () => dispatch(reset(FORMID))
});

const EditOwnerReduxForm = reduxForm({
    form: FORMID,
    asyncValidate,
    asyncBlurFields: ['owners[].email'],
    asyncChangeFields: [],
    shouldAsyncValidate: () => false
})(EditOwner)

export default connect(mapStateToProps, mapDispatchToProps)(EditOwnerReduxForm);

