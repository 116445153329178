import React, { useState } from 'react';
import Main from '../Main/Main';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './styles';
import Banner from '../Banner'
import BannerPopup from '../Banner/BannerPopup';
import ModalPopup from '../Modal/ModalPopup';
import { Roles } from '../../Store/Roles/types';
import DataLoader from '../DataLoader';
import { useSelector } from 'react-redux';
import { AppState } from '../../Store';
import { useLocation } from 'react-router-dom';
import { Table, TableHead, TableContainer, TableRow, TableCell, TableBody } from '@material-ui/core/';

const Home: React.FC = () => {
	const classes = useStyles();
	const contacts = useSelector((state: AppState) => state.contacts.contactInfo);
	const location = useLocation();

	return (
		<Main breadCrumbs={{ items: [{ text: `Contact information`, link: location.pathname }] }}>
			<DataLoader contactsRequired={true}>

				<Grid container>
					<h1>Contact information</h1>
					<Grid item xs={12} className={classes.textContainer}>
						If you need help for
						<ul>
							<li>
								How to use the application
							</li>
							<li>
								How to get access to editing or publishing content
							</li>
							<li>
								Help to publish content because you don not have authorization for that
							</li>
						</ul>
					</Grid>
					<Grid item xs={12} className={classes.textContainer}>
						please contact
					</Grid>
					<TableContainer className={classes.contactTable} >
						<Table >
							<TableHead>
								<TableRow  className={classes.contactTableHead}>
									<TableCell className={classes.contactTableHead}>Contact name</TableCell>
									<TableCell className={classes.contactTableHead}>Email</TableCell>
									<TableCell className={classes.contactTableHead}>Phone</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{contacts.map((contact, idx) => (
									<TableRow
										key={idx}
									>
										<TableCell component="th" scope="row" className={classes.contactTableRow}>
											{contact.name}
										</TableCell>
										<TableCell scope="row" className={classes.contactTableRow}>
											{contact.email}
										</TableCell>
										<TableCell scope="row" className={classes.contactTableRow}>
											{contact.phone_number}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

				</Grid>
			</DataLoader>
		</Main>
	);
}

export default Home;
