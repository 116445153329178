import React from 'react';
import { ImportProgress, ImportWarningsPopupProps } from './types';
import { useStyles } from './styles';
import { Button, Grid } from '@material-ui/core';
import ModalPopup from '../Modal/ModalPopup';

const scrollToTop = () => {
    (document.getElementById("mainContent") || {} as HTMLElement).scrollTop = 0;
    document.documentElement.scrollTop = 0;
}

const ImportWarningsPopup: React.FC<ImportWarningsPopupProps> = ({ importTypeProgress, warnings, onCancelImport, onContinueImport, importButtonTypeText, importOtherButtonTypeText, showOtherFilesButton }) => {
    
    const classes = useStyles();
    
    if (importTypeProgress === ImportProgress.HandleWarnings) {
        scrollToTop()
        return (
            <Grid container className={classes.confirmPopupContainer}>
                <ModalPopup paperClassName={classes.confirmPopup}>
                    <div className={classes.bannerContainer}>
                        <Grid container alignItems="flex-start" direction="row">
                            <Grid className={classes.confirmPopupHeader} >
                                Warnings in imported file
                            </Grid>
                        </Grid>
                        <Grid container alignItems="flex-start" direction="row">
                            <Grid item className={classes.errorList}>
                                The imported file contains the following warnings:
                                <ul>
                                    {warnings.map((appWarnings, idx) => {
                                        return appWarnings.warnings.map((warning, warningIdx) => (<li key={(idx*1000) + warningIdx}>{warning}</li>))
                                    })}
                                </ul>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="flex-start" direction="row" className={classes.confirmButtons}>
                            <Grid item>
                                {
                                    showOtherFilesButton && (
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            onClick={() => onContinueImport(true)}
                                        >
                                            {importOtherButtonTypeText || `Import other apps`}
                                        </Button>
                                    )
                                }
                                {
                                    !warnings.some(warn => warn.warnings.some(w => w.indexOf("no changes to import") > -1)) && (
                                    <Button
                                        type="button"
                                        variant={!showOtherFilesButton ? "contained" :"outlined"}
                                        color={!showOtherFilesButton ? "primary" :"default"}
                                        className={classes.button}
                                        onClick={() => onContinueImport(false)}
                                    >
                                        {`${importButtonTypeText}`}
                                    </Button>
                                    )
                                }
                                <Button
                                    type="button"
                                    variant="outlined"
                                    color="default"
                                    onClick={() => { onCancelImport(); }}
                                    className={classes.button}
                                >
                                    Cancel import
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </ModalPopup>
            </Grid>
        )
    }
    else {
        return null;
    }
}

export default ImportWarningsPopup;
