import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	paper: {
        backgroundColor: colors.webexuiWhite,
        [theme.breakpoints.up('sm')]: {
        padding: '16px 68px 50px 33px',
        borderRadius: '6px',
        marginBottom: '15px'
        },
        [theme.breakpoints.only('xs')]: {
        padding: '20px'
        },
        zIndex:1201,
        marginTop: '-100px',
        boxShadow: '0 1px 4px 0 rgba(0,0,0,0.5)',
        position: 'relative'
    },
    overlay: {
        position:'fixed', 
        backgroundColor: colors.webexuiWhite, 
        top:'0', 
        left:'0', 
        bottom:'0', 
        right:'0', 
        overflow:'auto', 
        zIndex:1200,
        opacity: '0.85',
        marginRight: '18px'
    }
}));