import { Application, ImportedApplicationTranslations } from '../../API/application';
import { LoadingStatus } from '../types';


export enum ApplicationsActionTypes {
	GET_APPLICATIONS = '@@applications/GET_APPLICATIONS',
	GET_APPLICATIONS_SUCCEED = '@@applications/GET_APPLICATIONS_SUCCEED',
	SET_APPLICATIONS_LOADING_STATUS = '@@applications/SET_APPLICATIONS_LOADING_STATUS',
	GET_APPLICATIONTRANSLATIONS = '@@applications/GET_APPLICATIONTRANSLATIONS',
	GET_APPLICATIONTRANSLATIONS_SUCCEED = '@@applications/GET_APPLICATIONTRANSLATIONS_SUCCEED',
	SET_APPLICATIONTRANSLATIONS_LOADING_STATUS = '@@applications/SET_APPLICATIONTRANSLATIONS_LOADING_STATUS',
}

export interface ApplicationsAppStateModel {
	applications?: Application[];
	applicationTranslations?: ImportedApplicationTranslations[];
	applicationsLoadingStatus: LoadingStatus,
	applicationTranslationsLoadingStatus: LoadingStatus,
}

