import { getRoles } from "@testing-library/dom";

export interface UserRoles {
    roles: Roles[],
    rolesLoaded: boolean;
    impersonatedUserRoles?: Roles[]
}

export enum RolesActionTypes {
    SET_USER_ROLES = '@@roles/ADD_USER_ROLES',
    GET_USER_ROLES = '@@roles/GET_USER_ROLES',
    SET_IMPERSONATED_USER_ROLES = '@@roles/SET_IMPERSONATED_USER_ROLES',
    CLEAR_IMPERSONATED_USER_ROLES = '@@roles/CLEAR_IMPERSONATED_USER_ROLES',
}

export enum Roles {
    APPLICATION_EDITOR = 'App.Editor',
    APPLICATION_PUBLISHER = 'App.Publisher',
    RECOMMENDED_APPLICATION_EDITOR = 'Recom.App.Editor',
    RECOMMENDED_APPLICATION_PUBLISHER = 'Recom.App.Publisher',
    RELEVANT_WEBSITE_EDITOR = 'Rel.Website.Editor',
    RELEVANT_WEBSITE_PUBLISHER = 'Rel.Website.Publisher',
    APPLICATION_SETUP_VIEWER = 'App.Setup.Viewer',
    ALLOW_PUBLISH_OVERRIDE = 'Allow.Publish.Override',

    DASHBOARD_ADMIN = 'DashboardAdmin',
    APPLICATION_OWNER = 'AppOwner',
    NONE = 'None'
}

export const ApplicationRoles = [
    Roles.APPLICATION_EDITOR,
    Roles.APPLICATION_PUBLISHER,
    Roles.RECOMMENDED_APPLICATION_EDITOR,
    Roles.RECOMMENDED_APPLICATION_PUBLISHER,
    Roles.APPLICATION_SETUP_VIEWER,
]

export const ApplicationExtensionRoles = [
    Roles.ALLOW_PUBLISH_OVERRIDE
]

export const WebsitesRoles = [
    Roles.RELEVANT_WEBSITE_EDITOR,
    Roles.RELEVANT_WEBSITE_PUBLISHER
]

export const RolesDisplayName = {
    [Roles.DASHBOARD_ADMIN]: 'Admin',
    [Roles.APPLICATION_OWNER]: 'App. owner',
    [Roles.APPLICATION_EDITOR]: 'App. editor',
    [Roles.APPLICATION_PUBLISHER]: 'App. publisher',
    [Roles.RECOMMENDED_APPLICATION_EDITOR]: 'Recom. app. editor',
    [Roles.RECOMMENDED_APPLICATION_PUBLISHER]: 'Recom. app. publisher',
    [Roles.RELEVANT_WEBSITE_EDITOR]: 'Rel. website editor',
    [Roles.RELEVANT_WEBSITE_PUBLISHER]: 'Rel. website publisher',
    [Roles.APPLICATION_SETUP_VIEWER]: 'App. setup viewer',
    [Roles.ALLOW_PUBLISH_OVERRIDE]: 'Allow publish',
    [Roles.NONE]: 'No Role',
}

function getKeyName(value: string) {
    return Object.entries(Roles).find(([key, val]) => val === value)?.[0];
}

export const getRolesEnum = (roleName: string): Roles => {
    const enumRole: Roles | undefined = (<any>Roles)[getKeyName(roleName) || 'NONE'];
    return enumRole || Roles.NONE;
}

export const getRolesValue = (role: Roles): string => {
    return Object.entries(Roles).find(([key, val]) => key === getKeyName(role))?.[1] || '';
}
