import React, { useEffect, useState } from 'react';
import Main from '../../Main/Main';
import EditOwner from './EditOwner';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './styles';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react'
import { getApplicationOwners, ApplicationOwner, updateApplicationOwners } from '../../../API/application';
import { getUsersByIdentityId } from '../../../API/users';
import { getIdpDomainConnections } from '../../../API/connection';
import settings from '../../../Config/settings';
import DataLoader from '../../DataLoader';
import Button from '@material-ui/core/Button';
import { AppState } from '../../../Store';
import { useSelector } from 'react-redux';
import { Spinner } from '@danfoss/webex-ui';
import { getUserRolesForApplication, updateApplicationUserRoles } from '../../../API/roles';
import ClickOutsideWrapper from '../../ClickOutsideWrapper';
import usePrevious from '../../../Utils/usePrevious';
import { getUserRoles } from '../../../Utils';
import { Roles } from '../../../Store/Roles/types';

const AppOwners: React.FC = () => {
	const classes = useStyles();
	const location = useLocation();
	const { getAccessTokenSilently } = useAuth0();

	const [applicationOwners, setApplicationOwners] = useState([] as ApplicationOwner[])
	const [selectedApplicationOwner, setSelectedApplicationOwner] = useState(undefined as ApplicationOwner | undefined)
	const [ipdDomains, setIdpDomains] = useState([] as string[]);
	const [accessTokenForDanfossApi, setAccessTokenForDanfossApi] = useState('');
	const [loading, setLoading] = useState(true);
	const impersonating = useSelector((state: AppState) => !!state.user.impersonatedUser);
	const applications = useSelector((state: AppState) => state.applications);
    const prevAppsLoaded = usePrevious(applications.applicationsLoadingStatus.loaded);
	const userRoles = useSelector((state: AppState) => getUserRoles(state.userRoles));

	const handleEditClick = (client_id: string) => {
		setSelectedApplicationOwner(applicationOwners.find(appOwner => appOwner.client_id === client_id));
	}

	const updateApplication = (editedApplication: ApplicationOwner) => {

		const updateOwners = async () => {
			const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);

			await updateApplicationOwners(accessTokenMyDanfossApi,
				{
					client_id: editedApplication.client_id,
					users: editedApplication.owners.map(owner => ({ danfoss_identity_id: owner.danfoss_identity_id, email: owner.email }))
				});

			// Delete any roles for the users on the application, because as owner you implicitly have all roles	
			const appUserRoles = await (await getUserRolesForApplication(accessTokenMyDanfossApi, editedApplication.client_id)).find(role => role.client_id === editedApplication.client_id);
			if (editedApplication.owners.length > 0 && appUserRoles && appUserRoles.users.some(user => editedApplication.owners.some(owner => owner.danfoss_identity_id === user.danfoss_identity_id))) {
				await updateApplicationUserRoles(accessTokenMyDanfossApi,
					{
						client_id: editedApplication.client_id,
						users: appUserRoles.users.filter(user => !editedApplication.owners.some(owner => owner.danfoss_identity_id === user.danfoss_identity_id))
					}
				)
			}
			const editedApplicationOwners = applicationOwners.filter(app => app.client_id !== editedApplication.client_id);
			editedApplicationOwners.push(editedApplication);
			setApplicationOwners(editedApplicationOwners);
			setSelectedApplicationOwner(undefined);
		}

		updateOwners();
	}

	const cancelUpdate = () => {
		setSelectedApplicationOwner(undefined);
	}
	useEffect(() => {
		const loadIdpDomains = async () => {
			const idpConnections = await getIdpDomainConnections();
			const idpDomains = idpConnections.filter(conn => conn.connection_name === settings.auth0.connection).map(conn => (conn.domains))
			setIdpDomains(idpDomains && idpDomains.length > 0 && idpDomains[0] || []);
		};

		loadIdpDomains();

	}, []);

	useEffect(() => {
		const loadApplicationOwners = async () => {
			const accessTokenMyDanfossAccountApi = await getAccessTokenSilently(settings.myDanfossAccountApi.accessTokenOptions);
			const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
			setAccessTokenForDanfossApi(accessTokenMyDanfossApi);
			const appOwnersApi = await getApplicationOwners(accessTokenMyDanfossAccountApi);
			const danfossIdentityIds: string[] = [];
			appOwnersApi.owners.forEach(owner => owner.users.forEach(user => danfossIdentityIds.push(user.danfoss_identity_id)));
			const users = await getUsersByIdentityId(accessTokenMyDanfossApi, Array.from(new Set(danfossIdentityIds)));
			const appOwners: ApplicationOwner[] = [];
			applications.applications && applications.applications.forEach(app => {
				const appOwner = appOwnersApi.owners.find(appOwner => appOwner.client_id === app.client_id) || { client_id: app.client_id, users: [] };
				appOwners.push({
					client_id: app.client_id,
					name: app.name,
					owners: appOwner.users.map(user => {
						const userInfo = users.find(u => u.danfoss_identity_id === user.danfoss_identity_id);
						return {
							danfoss_identity_id: user.danfoss_identity_id,
							email: userInfo?.email || 'N/A',
							name: userInfo?.name || 'Unknow user',
							phonenumber: userInfo?.phone_number || 'N/A'
						}
					})
				})
			});
			setApplicationOwners(appOwners);
			setLoading(false);
		};

		applications.applicationsLoadingStatus.loaded && applications.applicationsLoadingStatus.loaded !== prevAppsLoaded && loadApplicationOwners();
	}, [applications]);

	return (
		<Main breadCrumbs={location.pathname !== '/listappowners' && 
				{ items: [{ text: `User roles`, link: '/userroles' }, { text: 'Maintain application owners', link: location.pathname }] } ||
				{ items: [{ text: 'List application owners', link: location.pathname}]} } >
			<DataLoader applicationsRequired={true} >
				<Spinner visible={loading} ></Spinner>
				<Grid container className={classes.applicationListGridHeading}>
					<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridEdit}`} >
					</Grid>
					<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridName}`} >
						Application name
					</Grid>
					<Grid item className={`${classes.applicationListGridOwners}`} >
						<Grid container>
							<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridOwnerEmail}`} >
								Owner email
							</Grid>
							<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridOwnerName}`} >
								Owner name
							</Grid>
							<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridOwnerPhone}`} >
								Owner phone
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{applicationOwners
					.filter(appowner => appowner.owners.length > 0 || userRoles.includes(Roles.DASHBOARD_ADMIN))
					.sort((app1, app2) => app1.name.localeCompare(app2.name))
					.map((appOwner, idx) => {
						if (selectedApplicationOwner && selectedApplicationOwner.client_id === appOwner.client_id) {
							return (
								<ClickOutsideWrapper>
									<EditOwner key={idx} containerClassName={`${idx % 2 === 0 ? classes.applicationListGridEven : classes.applicationListGridOdd}`} application={selectedApplicationOwner} cancel={cancelUpdate} updateApplication={updateApplication} idpDomains={ipdDomains} accessToken={accessTokenForDanfossApi} />
								</ClickOutsideWrapper>
							)
						}
						else {
							return (
								<Grid container key={idx} className={`${classes.applicationListGrid} ${idx % 2 === 0 ? classes.applicationListGridEven : classes.applicationListGridOdd}`}>
									<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridEdit}`} >
										{userRoles.includes(Roles.DASHBOARD_ADMIN) &&
											<Button
												type="button"
												variant="outlined"
												color="default"
												className={classes.gridButton}
												onClick={(e) => { e.preventDefault(); handleEditClick(appOwner.client_id) }}
												disabled={selectedApplicationOwner && selectedApplicationOwner.client_id !== appOwner.client_id}
											>
												Edit
											</Button>
										}
									</Grid>
									<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridName}`} >
										{appOwner.name}
									</Grid>
									<Grid item className={`${classes.applicationListGridOwners}`}>
										{appOwner.owners.map((owner, oidx) => {
											return (
												<Grid container key={oidx}>
													<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridOwnerEmail}`} >
														{owner.email}
													</Grid>
													<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridOwnerName}`}  >
														{owner.name}
													</Grid>
													<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridOwnerPhone}`} >
														{owner.phonenumber}
													</Grid>
													{!impersonating && userRoles.includes(Roles.DASHBOARD_ADMIN) &&
														<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridImpersonate}`} >
															<RouterLink to={`/impersonate?email=${owner.email}`}>Impersonate</RouterLink>
														</Grid>
													}
												</Grid>
											)
										})}
									</Grid>
								</Grid>
							)
						}
					})}
			</DataLoader>
		</Main>
	);
}

export default AppOwners;
