import { action } from 'typesafe-actions';
import { LocationActionTypes } from './types';
import {
	LocationApiCountry
} from '../../API/location';
import { LoadingStatus } from '../types';

export interface GetCountries {
	type: LocationActionTypes.GET_COUNTRIES;
	payload: {
		accessToken: string;
	}
}

export interface GetCountriesSucceed {
	type: LocationActionTypes.GET_COUNTRIES_SUCCEED;
	payload: LocationApiCountry[];
}

export interface CountrieLoadingStatus {
    type: LocationActionTypes.SET_COUNTRIES_LOADING_STATUS;
    payload: LoadingStatus;
}

export const GetCountriesAction = (accessToken: string): GetCountries =>
	action(LocationActionTypes.GET_COUNTRIES, { accessToken });

export const SetCountriesAction = (
	countries: LocationApiCountry[]
): GetCountriesSucceed =>
	action(LocationActionTypes.GET_COUNTRIES_SUCCEED, countries);

export const SetCountriesLoadingStatus = (loadingStatus: LoadingStatus): CountrieLoadingStatus =>
    action(LocationActionTypes.SET_COUNTRIES_LOADING_STATUS, loadingStatus);

export type LocationActionType =
	| GetCountriesSucceed
	| GetCountries
	| CountrieLoadingStatus;
