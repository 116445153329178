import { Application } from "../../../API/application";
import { RecommendedApplication } from "../../../API/recommendedApplication";
import { AppState } from "../../../Store";
import { CriteriaFormValues, ImportedCriteria } from "./types";
import { ApplicationErrors, ApplicationWarnings } from '../../ImportPopup/types';
import { pathToDisplayName, validateValue } from "./utils";
import _ from 'lodash';

export const asyncValidate = (values: any, dispatch: any, props: any) => {
    return Promise.resolve(true);
}

export const validateOnSubmit = (values: CriteriaFormValues, props: any) => {

    let errors: any = {};
    let hasErrors = false;

    if (!values.all_users && ((values as any).criteria.length === 0)) {
        return ({errors: {all_users: 'Either select all users or set at least one criteria'}, hasErrors: true});
    }

    if (!values.all_users) {
        errors.criteria = values.criteria.map((crit, idx) => {
            const criteriaErrors: any = {};

            if (!crit.andor && idx > 0) {
                criteriaErrors.andor = 'Please select and/or';
                hasErrors = true;
            }

            if (!crit.attribute) {
                criteriaErrors.attribute = 'Please select attribute';
                hasErrors = true;
            }

            if (!(crit.values && crit.values.length > 0)) {
                criteriaErrors.values = 'Please select at least one value';
                hasErrors = true;
            }

            return criteriaErrors;
        })
    }
    return { errors, hasErrors };
};

export const validateImportedCriteria = (importedCriteria: ImportedCriteria[], recommendedApps: RecommendedApplication[], applicationsForUser: Application[], state: AppState): { errors: ApplicationErrors[], warnings: ApplicationWarnings[] } => {
    const errors: ApplicationErrors[] = [];
    const warnings: ApplicationWarnings[] = [];

    importedCriteria.sort((app1, app2) => app1.client_name.localeCompare(app2.client_name)).forEach(crit => {
        const errorsForApplication: string[] = [];
        if (!crit.client_id) {
            errorsForApplication.push(`Application ${crit.client_name} does not exist`);
        }
        else if (!applicationsForUser.some(app => app.client_id === crit.client_id)) {
                errorsForApplication.push(`You are not allowed to import criteria for application ${crit.client_name}`);
        }
        else {

            if (crit.all_users && crit.criteria.length > 0) {
                errorsForApplication.push('  Criteria not allowed when recommended to all users is true');
            }
            crit.criteria.forEach(orCrit => {
                orCrit.forEach(andCrit => {
                    const pathName = pathToDisplayName(andCrit.path)
                    if (pathName === 'Unknown') {
                        errorsForApplication.push(`  Path ${andCrit.path} does not exist`);
                    }
                    else {
                        andCrit.values.forEach(value => {
                            if (!validateValue(value, andCrit.path, state)) {
                                errorsForApplication.push(`  Value ${value} does not exist for ${pathName}`);
                            }
                        })
                    }
                })
            })
        }
        if (errorsForApplication.length > 0) {
            errors.push({client_name: crit.client_name, errors: errorsForApplication});
        }

        const warningsForApplication: string[] = [];
        if (crit.client_id && recommendedApps.some(recommApp => recommApp.client_id === crit.client_id && !recommApp.published)) {
            warningsForApplication.push(`Unpublished changes for ${crit.client_name} will be overwritten by import`);
        } 

        const existingCrit = recommendedApps.find(recommApp => recommApp.client_id === crit.client_id && !recommApp.published) ||
                             recommendedApps.find(recommApp => recommApp.client_id === crit.client_id && recommApp.published);
        if (existingCrit && existingCrit.all_users === crit.all_users && _.isEqual(existingCrit.criteria, crit.criteria)) {
            warningsForApplication.push(`${crit.client_name} no changes to import`);
        }

        if (warningsForApplication.length > 0) {
            warnings.push({client_name: crit.client_name, warnings: warningsForApplication});
        }
    })

    return { errors, warnings };
}