import React, { useEffect, useState } from 'react';
import Main from '../../Main/Main';
import Grid from '@material-ui/core/Grid';
import { reduxForm, Field } from 'redux-form';
import { useStyles } from './styles';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../../../Store';
import settings from '../../../Config/settings';
import { useAuth0 } from '@auth0/auth0-react';
import { RecommendedApplication, getRecommendedApplicationsPublished, getAllRecommendedApplicationsPublished, deleteRecommendedApplicationPublished } from '../../../API/recommendedApplication';
import { Application, getApplicationsForUserWithRoles} from '../../../API/application';
import { Roles } from '../../../Store/Roles/types';
import Button from '@material-ui/core/Button';
import { SpinnerButton } from '@danfoss/webex-ui/dist/mui';
import DataLoader from '../../DataLoader';
import { Spinner } from '@danfoss/webex-ui';
import DisplayCriteria from '../../Application/Criteria//DisplayCriteria'
import { CheckboxWithName } from '../../RenderFields/RenderFields';
import ModalPopup from '../../Modal/ModalPopup';
import { allowPublishRecommendedApps, getDanfossIdentityId, getUserRoles } from '../../../Utils';
import { getContacts } from '../utils';

const FORMID = 'appCriteriaPublish'

const AppCriteriaUnpublish: React.FC = () => {
	const classes = useStyles();
	const location = useLocation();
	const { getAccessTokenSilently } = useAuth0();
	const [recommendedApplications, setRecommendedApplications] = useState([] as RecommendedApplication[]);
	const [applicationsForUser, setApplicationsForUser] = useState([] as Application[]);
	const [selectedApplications, setSelectedApplications] = useState([] as string[]);
	const [showConfirmUnpublish, setShowConfirmUnpublish] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const userRoles = useSelector((state: AppState) => getUserRoles(state.userRoles));
	const applications = useSelector((state: AppState) => state.applications);
	const applicationsLoaded = useSelector((state: AppState) => state.applications.applicationsLoadingStatus.loaded);
	const currentUser = useSelector((state: AppState) => state.user);
    const contacts = useSelector((state: AppState) => state.contacts.contactInfo);

	const unpublishSelectedApplications = () => {
		setIsSaving(true);
		const unpublish = async () => {
			const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);

			Promise.allSettled(
				selectedApplications.map(selApp => {
					return deleteRecommendedApplicationPublished(accessTokenMyDanfossApi, selApp)
				})
			)
				.then((results: PromiseSettledResult<Response>[]) => {
					results.forEach((result, idx) => {
						if (result.status !== "fulfilled") {
							console.log(`Publish of ${applicationsForUser.find(app => app.client_id === selectedApplications[idx])?.name} failed`);
						}
					})

					getRecommendedApplicationsPublished(accessTokenMyDanfossApi, applicationsForUser.map(app => app.client_id))
						.then((result: RecommendedApplication[]) => {
							setRecommendedApplications(result);
							setSelectedApplications([]);
							setShowConfirmUnpublish(false);
							setIsSaving(false);
						})
						.catch(() => {
							setSelectedApplications([]);
							setShowConfirmUnpublish(false);
							setIsSaving(false);
						})
				})

		}

		unpublish();
	}

	const onChangeSelectApplication = (client_id: string, checked: boolean) => {
		if (selectedApplications.indexOf(client_id) > -1) {
			if (!checked) {
				setSelectedApplications(selectedApplications.filter(row => row !== client_id));
			}
		}
		else {
			if (checked) {
				setSelectedApplications([...selectedApplications, client_id]);
			}
		}

	}

	const allowPublish = () => {
		return allowPublishRecommendedApps(userRoles);
	}

	useEffect(() => {
		const loadRecommendedApps = async () => {
			if (currentUser.userLoaded && currentUser.user && applicationsLoaded) {
				setLoading(true);
				const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
				const accessTokenMyDanfossAccountApi = await getAccessTokenSilently(settings.myDanfossAccountApi.accessTokenOptions);
				let recommendedApps: RecommendedApplication[] = []
				if (userRoles.some(role => role === Roles.DASHBOARD_ADMIN)) {
					setApplicationsForUser(applications.applications || []);
					recommendedApps = await getAllRecommendedApplicationsPublished(accessTokenMyDanfossApi);
				}
				else {
					const clientIdsForUser = await getApplicationsForUserWithRoles(accessTokenMyDanfossApi, accessTokenMyDanfossAccountApi, getDanfossIdentityId(currentUser), [Roles.APPLICATION_OWNER, Roles.RECOMMENDED_APPLICATION_PUBLISHER]);
					const appsForUser = applications.applications?.filter(app => clientIdsForUser.includes(app.client_id)) || [];
					setApplicationsForUser(appsForUser);
					recommendedApps = await getRecommendedApplicationsPublished(accessTokenMyDanfossApi, clientIdsForUser);
				}
				setRecommendedApplications(recommendedApps);
				setSelectedApplications(recommendedApps.filter(app => !app.published).map(app => app.client_id));
				setLoading(false);
			}
		}
		loadRecommendedApps();

	}, [applications.applications, applicationsLoaded, currentUser]);

	return (
		<Main breadCrumbs={{ items: [{ text: 'Publish overview', link: '/publish' }, { text: `Recommended application criteria`, link: '/publish/applicationcriteria' }, { text: `Unpublish`, link: location.pathname }] }}>
			<DataLoader referencesRequired={true} applicationsRequired={true} countriesRequired={true} contactsRequired={true}>
				<Spinner visible={loading} ></Spinner>
				{showConfirmUnpublish &&
					<Grid container className={classes.confirmPopupContainer}>
						<ModalPopup paperClassName={classes.confirmPopup}>
							<div className={classes.bannerContainer}>
								<Grid container alignItems="flex-start" direction="row">
									<Grid className={classes.confirmPopupHeader} >
										Remove recommended applications
									</Grid>
								</Grid>
								<Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
									<Grid item>
										Do you really want to remove these applications from the users recommended applications?
									</Grid>
								</Grid>
								<Grid container alignItems="flex-start" direction="row">
									<Grid item>
										<ul>
											{selectedApplications.map((selApp, idx) => {
												return <li key={idx}>{applicationsForUser.find(app => app.client_id === selApp)?.name}</li>
											})}
										</ul>
									</Grid>
								</Grid>
								<Grid container alignItems="flex-start" direction="row" className={classes.confirmButtons}>
									<Grid item>
										<SpinnerButton
											type="button"
											variant="contained"
											color="primary"
											pathToImagesFolder={'/images/icons'}
											spinnerVisible={isSaving}
											className={classes.button}
											onClick={() => unpublishSelectedApplications()}
										>
											Yes, remove them
										</SpinnerButton>
										<Button
											type="button"
											variant="outlined"
											color="default"
											onClick={() => { setSelectedApplications([]); setShowConfirmUnpublish(false); }}
											className={classes.button}
										>
											No, leave them
										</Button>
									</Grid>
								</Grid>
							</div>
						</ModalPopup>
					</Grid>
				}
				{!allowPublish() &&
					<Grid container className={classes.notAllowed}>
						{`You are not allowed to unpublish yourself - please ask ${getContacts(contacts)} to unpublish the criteria`}
					</Grid>
				}

				<form id={FORMID} className={classes.criteriaForm}>
					<Grid container className={`${classes.toggleButtonContainer}`}>
						<Button
							type="button"
							variant="outlined"
							color="default"
							component={RouterLink}
							to="/publish/applicationcriteria"
						>
							{'Back to Publish'}
						</Button>
					</Grid>
					<Grid container className={classes.applicationListGridHeading}>
						<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridSelect}`} >
						</Grid>
						<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridName}`} >
							Application name
						</Grid>
						<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridCriteria}`} >
							Criteria
						</Grid>
						<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridStatus}`} >
							Status
						</Grid>
					</Grid>
					{applicationsForUser.filter(app => recommendedApplications.some(recommApp => recommApp.client_id === app.client_id && recommApp.published))
						.sort((app1, app2) => app1.name.localeCompare(app2.name))
						.map((app, idx, allRecommAppsForUser) => {
							const noOfRecommAppsForUser = allRecommAppsForUser.length;
							const recommApp = recommendedApplications.find(rApp => rApp.client_id === app.client_id && rApp.published);
							return (
								<Grid container key={idx} className={`${classes.applicationListGrid} ${idx % 2 === 0 ? classes.applicationListGridEven : classes.applicationListGridOdd}`}>
									<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridSelect}`} >
										{recommApp && recommApp?.published &&
											<Field
												name={`publish${idx}`}
												id={`publish${idx}`}
												checked={selectedApplications.some(selApp => selApp === app.client_id)}
												component={CheckboxWithName}
												onClick={(event: any) => { onChangeSelectApplication(app.client_id, event.target.checked); }}
												label=""
												disabled={!allowPublish()}
											/>
										}
									</Grid>
									<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridName}`} >
										{app.name}
									</Grid>
									<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridCriteria}`}  >
										<DisplayCriteria application={recommApp} noOfRecommAppsForUser={noOfRecommAppsForUser} />
									</Grid>
									<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridStatus}`} >
										Published
									</Grid>
								</Grid>
							)
						})}
					{!loading && applicationsForUser.filter(app => recommendedApplications.some(recommApp => recommApp.client_id === app.client_id && recommApp.published)).length === 0 &&
						<Grid container >
							<Grid item className={`${classes.applicationListGridItem}`} >
								No changes to publish
							</Grid>
						</Grid>
					}
					<Grid container >
						<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridPublishButton}`} >
							{!loading && allowPublish() && selectedApplications.length > 0 &&
								<Button
									type="button"
									variant="contained"
									color="primary"
									onClick={() => setShowConfirmUnpublish(true)}
								>
									Unpublish selected applications
								</Button>
							}
						</Grid>
					</Grid>

				</form>
			</DataLoader>
		</Main>
	);
}
export default reduxForm({
	form: FORMID
})(AppCriteriaUnpublish);
