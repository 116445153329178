import { useCallback, useEffect, useState } from 'react';
import { UserInfo } from '../../../../API/users';
import { UserAuth0Log, getUserAuthActivityHistory } from '../../../../API/activityHistory';
import { useAuth0 } from '@auth0/auth0-react';
import settings from '../../../../Config/settings';


const ROWS_COUNT_PER_PAGE = 10;
const MAX_RECORDS_COUNT = 1000;
const maxPage = Math.ceil(MAX_RECORDS_COUNT / ROWS_COUNT_PER_PAGE);

export interface UserAuthInfoProps {
    user: UserInfo;
}

const useUserAuthInfoPagination = (user: UserInfo) => {
    const [data, setData] = useState<UserAuth0Log[]>([]);
    const [totalPages, setTotalPages] = useState(maxPage);
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const { getAccessTokenSilently } = useAuth0();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchHistory = useCallback(async (page: number) => {
        setIsLoading(true);

        const accessToken = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
        const result = await getUserAuthActivityHistory(accessToken, user.auth0_user_id, {
            page,
            per_page: ROWS_COUNT_PER_PAGE,
        });

        setIsLoading(false);

        if (result.auth0_logs.length === 0) {
            setTotalPages(page);
            return;
        }
        if (result.auth0_logs.length < ROWS_COUNT_PER_PAGE) {
            setTotalPages(page + 1);
        }

        setCurrentPage(page);
        setData(result.auth0_logs);
    }, [getAccessTokenSilently, user.auth0_user_id]);

    useEffect(() => {
        setData([]);
        setTotalPages(maxPage);
        fetchHistory(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return {
        data,
        currentPage,
        onPageChange: fetchHistory,
        totalPages,
        isLoading,
    }
}

export default useUserAuthInfoPagination;
