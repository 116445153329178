import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { AppState } from '../../../Store';
import DataLoader from '../../DataLoader';
import settings from '../../../Config/settings';
import { useAuth0 } from '@auth0/auth0-react';
import { languages } from '../../../Constants';
import { deletePreviewProfile, getAllPreviewProfiles, getApplications, PreviewProfileApplication, PreviewProfileModel, savePreviewProfile } from '../../../API/users';
import { PreviewProfileProps } from './types'
import { Spinner } from '@danfoss/webex-ui';
import { Button } from '@material-ui/core';
import ModalPopup from '../../Modal/ModalPopup';
import EditProfile from './EditProfile';
import { ReferenceItemApiModel } from '../../../API/references';

const YOUR_PROFILE = 'Yourself';

const PreviewProfile: React.FC<PreviewProfileProps> = ({setCurrentProfile}) => {
	const classes = useStyles();
	const { getAccessTokenSilently, user } = useAuth0();
	const [profiles, setProfiles] = useState([
	] as PreviewProfileModel[]);
	const [selectedProfile, setSelectedProfile] = useState('');
	const [loadingProfile, setLoadingProfile] = useState(true);
	const [editProfile, setEditProfile] = useState(undefined as PreviewProfileModel | undefined);
	const applications = useSelector((state: AppState) => state.applications.applications);
	const references = useSelector((state: AppState) => state.references);
	const countries = useSelector((state: AppState) => state.location.countries);
	const currentUser = useSelector((state: AppState) => state.user);

	useEffect(() => {
		const loadUser = async () => {
			const accessTokenMyDanfossAccount = await getAccessTokenSilently(settings.myDanfossAccountApi.accessTokenOptions);
			const accessTokenMyDanfoss = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);

			const usedApps = await getApplications(user?.sub || '', accessTokenMyDanfossAccount);
			const previewProfiles: PreviewProfileModel[] = (await getAllPreviewProfiles (accessTokenMyDanfoss)).map(pp => ({...pp, modified: false } as PreviewProfileModel));
			const yourSavedProfileIx = previewProfiles.findIndex(pp => pp.profile_name === YOUR_PROFILE);
			const yourProfile: PreviewProfileModel = {
				danfoss_identity_id: yourSavedProfileIx > -1 && previewProfiles[yourSavedProfileIx].danfoss_identity_id || '',
				profile_name: YOUR_PROFILE,
				language: currentUser.user?.language,
				country: currentUser.user?.country,
				areas_of_interest: currentUser.user?.areas_of_interest,
				company_type: currentUser.user?.company_type,
				department: currentUser.user?.department,
				identification: {is_workforce: currentUser.user?.identification.is_workforce || false},
				used_applications: usedApps.user_applications.map(app => ({app_id: app.app_id, name: app.name}))
			};
			const updatedYourProfile = await savePreviewProfile(accessTokenMyDanfoss, yourProfile)

			if (yourSavedProfileIx > -1) {
				previewProfiles.splice(yourSavedProfileIx, 1);
			}
			previewProfiles.splice(0, 0, updatedYourProfile);

			setProfiles(previewProfiles);
			switchProfile(updatedYourProfile);
			setLoadingProfile(false);
		}

		loadUser();
	}, [])

	const enrichReferenceItemWithText = (attribute: ReferenceItemApiModel | undefined, data: any) : ReferenceItemApiModel | undefined => {
		return attribute && {id: attribute.id, text: data?.find((item: ReferenceItemApiModel) => item.id === attribute?.id)?.text || ''} || undefined;	
	} 

	const enrichApplicationWithText = (application: PreviewProfileApplication) : PreviewProfileApplication => {
		return {app_id: application.app_id, name: applications?.find(item => item.app_id === application?.app_id)?.name || ''};	
	} 

	const updateUserProfile = async (profile: PreviewProfileModel) => {
		const accessTokenMyDanfoss = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
		const updatedProfiles = profiles.slice();

		const enrichedProfile: PreviewProfileModel = {
			...profile,
			area_of_interest: undefined,
			areas_of_interest: profile.areas_of_interest?.map(aoi => enrichReferenceItemWithText(aoi, references.areasOfInterest) || {id: aoi.id, text: ''}),
			department: enrichReferenceItemWithText(profile.department, references.departments),
			company_type: enrichReferenceItemWithText(profile.company_type, references.companyTypes),
			language: enrichReferenceItemWithText(profile.language, languages),
			country: enrichReferenceItemWithText(profile.country, countries),
			used_applications: profile.used_applications?.map(usedApp => enrichApplicationWithText(usedApp))
		}
		const updatedProfile = await savePreviewProfile(accessTokenMyDanfoss, enrichedProfile)

		if (profile.danfoss_identity_id) {
			updatedProfiles.splice(updatedProfiles.findIndex(up => up.danfoss_identity_id === profile.danfoss_identity_id), 1);
		}
		updatedProfiles.push(updatedProfile);
		setProfiles(updatedProfiles);
		if (profile.profile_name === selectedProfile) {
			setCurrentProfile({...updatedProfile, modified: false});
		}
		setEditProfile(undefined);
	}

	const deleteProfile = async (profileId: string) => {
		const accessTokenMyDanfoss = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
		await deletePreviewProfile(accessTokenMyDanfoss, profileId);
		const updatedProfiles = profiles.slice();
		updatedProfiles.splice(updatedProfiles.findIndex(up => up.danfoss_identity_id === profileId), 1);
		setProfiles(updatedProfiles);
	}

	const cancelEditProfile = () => {
		setEditProfile(undefined);
	}

	const switchProfile = (profile: PreviewProfileModel) => {
		setSelectedProfile(profile.profile_name);
		setCurrentProfile(profile);
	}

	const capitalizeBoolean = (value?: boolean)  => {
		return value !== undefined && value.toString().charAt(0).toUpperCase()
  			+ value.toString().slice(1);
	}

	const currentProfile = profiles.find(prf => prf.profile_name === selectedProfile);

	return (
		<DataLoader referencesRequired={true} applicationsRequired={true} countriesRequired={true}>
			<Spinner visible={loadingProfile} />
			{editProfile &&
				<Grid container className={classes.editProfilePopupContainer}>
					<ModalPopup paperClassName={classes.editProfilePopup}>
						<EditProfile profile={editProfile} allProfiles={profiles} updateUserProfile={updateUserProfile} cancel={cancelEditProfile} />
					</ModalPopup>
				</Grid>
			}
			{!loadingProfile &&
				<Grid container className={`${classes.previewProfiles}`}>
					<Grid item className={`${classes.currentProfile}`}>
						<Grid container className={classes.listGridOdd} >
							<Grid item  >
								<span className={`${classes.heading} `}>Current profile</span><span> ({selectedProfile})</span>
							</Grid>
						</Grid>
						<Grid container className={classes.listGridEven} >
							<Grid item className={classes.profileAttribute} >
								Used applications
							</Grid>
							<Grid item className={classes.profileValues} >
								{currentProfile?.used_applications?.map((usedApp, idx) => (
									<div key={idx}>{applications?.find(app => app.app_id == usedApp.app_id)?.name || usedApp.app_id}</div>
								))}
							</Grid>
						</Grid>
						<Grid container className={classes.listGridOdd} >
							<Grid item className={classes.profileAttribute} >
								Areas of interest
							</Grid>
							<Grid item className={classes.profileValues} >
								{currentProfile?.areas_of_interest?.map((aoi, idx) => (
									references?.areasOfInterest?.find(area => area.id === aoi.id)?.text || aoi.id
								)).join(', ')}
							</Grid>
						</Grid>
						<Grid container className={classes.listGridEven} >
							<Grid item className={classes.profileAttribute} >
								Company type
							</Grid>
							<Grid item className={classes.profileValues} >
								{references?.companyTypes?.find(ct => ct.id === currentProfile?.company_type?.id)?.text || currentProfile?.company_type?.id}
							</Grid>
						</Grid>
						<Grid container className={classes.listGridOdd} >
							<Grid item className={classes.profileAttribute} >
								Department
							</Grid>
							<Grid item className={classes.profileValues} >
								{references?.departments?.find(dep => dep.id === currentProfile?.department?.id)?.text || currentProfile?.department?.id}
							</Grid>
						</Grid>
						<Grid container className={classes.listGridEven} >
							<Grid item className={classes.profileAttribute} >
								Country
							</Grid>
							<Grid item className={classes.profileValues} >
								{countries?.find(cty => cty.alpha2 === currentProfile?.country?.id)?.name || currentProfile?.country?.id}
							</Grid>
						</Grid>
						<Grid container className={classes.listGridOdd} >
							<Grid item className={classes.profileAttribute} >
								Language
							</Grid>
							<Grid item className={classes.profileValues} >
								{languages.find(lng => lng.id === currentProfile?.language?.id)?.text || currentProfile?.language?.id}
							</Grid>
						</Grid>
						<Grid container className={classes.listGridEven} >
							<Grid item className={classes.profileAttribute} >
								Is workforce
							</Grid>
							<Grid item className={classes.profileValues} >
								{capitalizeBoolean(currentProfile?.identification?.is_workforce) || 'Not set' }
							</Grid>
						</Grid>
					</Grid>
					<Grid item className={`${classes.space}`}>
					</Grid>
					<Grid item className={`${classes.otherProfiles}`}>
						<Grid container className={classes.listGridOdd} >
							<Grid item className={`${classes.heading} `} >
								Other profiles
							</Grid>
						</Grid>
						{profiles.sort((prf1, prf2) => prf1.profile_name === YOUR_PROFILE || prf2.profile_name === YOUR_PROFILE ? 0 : prf1.profile_name.localeCompare(prf2.profile_name)).map((prf, idx) => (
							<Grid container key={idx} className={`${classes.profileList} ${idx % 2 === 0 ? classes.listGridEven : classes.listGridOdd}`}>
								<Grid item className={`${classes.profileName} ${prf.profile_name === selectedProfile && classes.selectedProfile}`} >
									<span onClick={() => switchProfile(prf)}>{prf.profile_name}</span>
								</Grid>
								<Grid item className={`${classes.profileEdit} `} >
									{prf.profile_name !== YOUR_PROFILE &&
										<Button
											type="button"
											variant="outlined"
											color="default"
											className={classes.editGridButton}
											onClick={(e) => { e.preventDefault(); deleteProfile(prf.danfoss_identity_id) }}
										>
											Delete
										</Button>
									}
									{prf.profile_name !== YOUR_PROFILE &&
										<Button
											type="button"
											variant="outlined"
											color="default"
											className={classes.editGridButton}
											onClick={(e) => { e.preventDefault(); setEditProfile(prf) }}
										>
											Edit
										</Button>
									}
								</Grid>
							</Grid>
						))}
						<Grid container  >
							<Grid item  >
								<Button
									type="button"
									variant="outlined"
									color="default"
									className={classes.addButton}
									onClick={(e) => { e.preventDefault(); setEditProfile({danfoss_identity_id: '', profile_name: 'New profile'}) }}
								>
									Add profile
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			}
		</DataLoader>
	);
}
export default PreviewProfile;
