import { ReferenceItemApiModel } from '../../API/references';
import { LoadingStatus } from '../types';

export enum ReferencesActionTypes {
	GET_DEPARTMENTS = '@@references/GET_DEPARTMENTS',
	GET_DEPARTMENTS_SUCCEED = '@@references/GET_DEPARTMENTS_SUCCEED',
    SET_DEPARTMENTS_LOADING_STATUS = '@@references/SET_DEPARTMENTS_LOADING_STATUS',

	GET_COMPANY_TYPES = '@@references/GET_COMPANY_TYPES',
	GET_COMPANY_TYPES_SUCCEED = '@@references/GET_COMPANY_TYPES_SUCCEED',
    SET_COMPANY_TYPES_LOADING_STATUS = '@@references/SET_COMPANY_TYPES_LOADING_STATUS',

	GET_AREAS_OF_INTEREST = '@@references/GET_AREAS_OF_INTEREST',
	GET_AREAS_OF_INTEREST_SUCCEED = '@@references/GET_AREAS_OF_INTEREST_SUCCEED',
    SET_AREAS_OF_INTEREST_LOADING_STATUS = '@@references/SET_AREAS_OF_INTEREST_LOADING_STATUS',
}

export interface ReferencesAppStateModel
	extends DepartmentsAppStateModel,
		AreasOfInterestAppStateModel,
		CompanyTypesAppStateModel {
	departmentsLoadingStatus: LoadingStatus,
	areasOfInterestLoadingStatus: LoadingStatus,
	companyTypesLoadingStatus: LoadingStatus	
}

export interface DepartmentsAppStateModel {
	departments?: ReferenceItemApiModel[];
}

export interface AreasOfInterestAppStateModel {
	areasOfInterest?: ReferenceItemApiModel[];
}

export interface CompanyTypesAppStateModel {
	companyTypes?: ReferenceItemApiModel[];
}

