export const getAppType = (app_type: string) => {
    switch (app_type) {
        case 'regular_web':
            return 'Regular Web Application';
        case 'spa':
            return 'Single Page Application';
        case 'native':
            return 'Native';

    }
}
