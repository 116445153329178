import React from 'react';
import { FormControlLabel, InputLabel, InputAdornment, MenuItem, Radio, FormLabel } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Select, { SelectProps } from '@material-ui/core/Select';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import RadioGroup, { RadioGroupProps } from '@material-ui/core/RadioGroup';
import { TextFieldProps, BaseTextFieldProps } from "@material-ui/core/TextField/TextField";
import Grid from '@material-ui/core/Grid';
import parse from 'html-react-parser';
import { useStyles } from './styles';
import './RenderFields.scss';
import { RenderProps, SelectBoxRenderProps, CheckBoxProps, RadioButtonGroupProps } from './types';

const ArrowDownIcon = () => {
  const classes = useStyles();

  return <img src={'/images/icons/ChevronDown.svg'} className={classes.iconStyle} alt="" />
}

const LockIcon = () => {
  const classes = useStyles();

  return <img src={'/images/icons/lock.svg'} className={classes.iconStyle} alt="" />
}

export const LabeledTextboxUncontrolled: React.FC<RenderProps & TextFieldProps & BaseTextFieldProps> = ({
  className, meta: { error, active, invalid }, required, inputProps,
  input, label, placeholder, type, autocomplete, defaultValue, showsubmitfailed, disabled, ...rest
}) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row" className={classes.marginTop}>
      <Grid item xs={12}>
        {label && (
          <InputLabel shrink htmlFor={input.name} required={required} className={classes.editText}>
            {label}
          </InputLabel>
        )}
        {error && (
          <div className={classes.labelError}>{(invalid && error)}</div>
        )}
        {active && inputProps && inputProps.maxLength && (inputProps.maxLength === (defaultValue && defaultValue.length)) && (
          <div className={classes.labelError}>{`The length of the field value can not be longer than ${inputProps.maxLength} characters.`}</div>
        )}
      </Grid>


      <Grid item xs={12}>
        <TextField  {...input} {...rest}
          className={`${className} ${classes.element} ${error ? classes.ErrorShadow : ''}`}
          type={type}
          autoComplete={autocomplete}
          inputProps={{ ...inputProps }}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
};

export const LabeledSelectboxUncontrolled: React.FC<SelectBoxRenderProps & SelectProps> = ({
  className, meta: { error }, required, inputProps, input, label, type,
  value, defaultValue, options, placeholder, disabled, ...rest
}) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row" className={classes.marginTop}>
      <Grid item xs={12}>
        {label && (
          <InputLabel shrink htmlFor={input.name} required={required} className={classes.editText}>
            {label}
          </InputLabel>
        )}
        {error && (
          <div className={classes.labelError}>{error}</div>
        )}
      </Grid>
      <Grid item xs={12}>
        <Select
          native
          value={defaultValue}
          inputProps={{
            name: input.name,
            id: input.name,
            translate: "no"
          }}
          onChange={input.onChange}
          displayEmpty
          className={`${className} ${classes.element} select`}
          IconComponent={disabled ? LockIcon : ArrowDownIcon}
          disabled={disabled}
          {...rest}
        >
          <option value=""></option>
          {(options || []).map((option => {
            return <option key={option.id} value={option.id}>{parse(option.text)}</option>;
          }))}
        </Select>
      </Grid>
    </Grid>
  )
};
export const LabeledMultiSelectbox: React.FC<SelectBoxRenderProps & SelectProps> = ({
  className, meta: { error }, required, inputProps, input, label, type,
  value, defaultValue, options, placeholder, disabled, ...rest
}) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row" className={classes.marginTop}>
      <Grid item xs={12}>
        {label && (
          <InputLabel shrink htmlFor={input.name} required={required} className={classes.editText}>
            {label}
          </InputLabel>
        )}
        {error && (
          <div className={classes.labelError}>{error}</div>
        )}
      </Grid>
      <Grid item xs={12}>
        <Select
          native={false}
          inputProps={{
            name: input.name,
            id: input.name,
            translate: "no"
          }}
          value={defaultValue}
          onChange={input.onChange}
          displayEmpty
          className={`${className} ${classes.element} ${classes.selectMenu} select`}
          IconComponent={disabled ? LockIcon : ArrowDownIcon}
          disabled={disabled}
          {...rest}
        >
          {(options || []).map((option => {
            return <MenuItem key={option.id} value={option.id} >{option.text}</MenuItem>;
          }))}
        </Select>
      </Grid>
    </Grid>
  )
};


export const LabeledTextbox: React.FC<RenderProps & TextFieldProps & BaseTextFieldProps> = ({
  className, meta: { error, active, invalid }, required, inputProps, ismandatory,
  input, label, placeholder, type, autocomplete, defaultValue, showsubmitfailed, ...rest
}) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row" className={classes.marginTop}>
      <Grid item xs={12}>
        {label && (
          <InputLabel shrink htmlFor={input.name} required={required} className={classes.editText}>
            {label}<span className={ismandatory ? classes.isMandatory : classes.hide } > *</span>
          </InputLabel>
        )}
        {error && (
          <div className={classes.labelError}>{(invalid && Array.isArray(error) ? error : "Name already in use for that language")}</div>
        )}
        {active && inputProps && inputProps.maxLength && (inputProps.maxLength === (defaultValue && defaultValue.length)) && (
          <div className={classes.labelError}>{`The length of the field value can not be longer than ${inputProps.maxLength} characters.`}</div>
        )}
      </Grid>


      <Grid item xs={12}>
        <TextField  {...input} {...rest}
          className={`${className} ${classes.element} ${error ? classes.ErrorShadow : ''}`}
          type={type}
          autoComplete={autocomplete}
          value={defaultValue}
          inputProps={{ ...inputProps?.filter((key:string) => key !== 'isMandatory') }}
        />
      </Grid>
    </Grid>
  )
};

export const LabeledSelectbox: React.FC<SelectBoxRenderProps & SelectProps> = ({
  className, meta: { error }, required, inputProps, input, label, type,
  value, defaultValue, options, emptyOption, placeholder, disabled, ...rest
}) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row" className={classes.marginTop}>
      <Grid item xs={12}>
        {label && (
          <InputLabel shrink htmlFor={input.name} required={required} className={classes.editText}>
            {label}
          </InputLabel>
        )}
        {error && (
          <div className={classes.labelError}>Please choose a value</div>
        )}
      </Grid>
      <Grid item xs={12}>
        <Select
          native
          value={defaultValue}
          inputProps={{
            name: input.name,
            id: input.name,
            translate: "no"
          }}
          onChange={input.onChange}
          displayEmpty
          className={`${className} ${classes.element} select`}
          IconComponent={ArrowDownIcon}
          disabled={disabled}
          {...rest}
        >
          {emptyOption !== false && (
            <option value=""></option>)
          }
          {(options || []).map((option => {
            return <option key={option.id} value={option.id}>{parse(option.text)}</option>;
          }))}
        </Select>
      </Grid>
    </Grid>
  )
};

export const CheckboxWithName: React.FC<CheckBoxProps & CheckboxProps> = ({
  meta: { error, invalid } = {error: '', invalid: false}, className, required, inputProps, value, defaultValue, placeholder, disabled, label, onClick, onChange, id, ...rest
}) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
      {error && (
        <Grid item xs={12}>
          <div className={classes.labelErrorLeft}>{error}</div>
        </Grid>
      )}
      <Grid item xs={12}>
          <FormControlLabel className={className} control={
            <Checkbox
              id={id}
              // value={defaultValue}
              onClick={(event) => onClick && onClick(event)}
              onChange={(event) => onChange && onChange(event, event.target.checked)}
              className={`${className}`}
              disabled={disabled}
              {...rest}
            />
          } label={label || defaultValue} />
      </Grid>
    </Grid>
  )
};

export const RadioButtonGroup: React.FC<RadioButtonGroupProps & RadioGroupProps> = ({
  className, value, defaultValue, placeholder, label, onClick, onChange, id, radioButtons, row, ...rest
}) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
      <Grid item xs={12}>
        <RadioGroup
          id={id}
          row={row}
          value={value}
          onClick={(event) => onClick && onClick(event)}
          onChange={(event) => onChange && onChange(event, event.target.value)}
          className={`${className}`}
          {...rest}
        >
          {radioButtons.map((button, idx) => (
            <FormControlLabel key={idx} className={button.className} value={button.value} control={<Radio />} label={button.label} />
          ))}
        </RadioGroup>
      </Grid>
    </Grid>
  )
};

export const LabeledDisplayText: React.FC<RenderProps> = ({
  className, label, value, defaultValue, ...rest
}) => {

  const classes = useStyles();

  return (
    <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row" className={classes.gridMargin}>
      <Grid item xs={12} sm={12} md={4}>
        {label && (
          <InputLabel shrink className={`${classes.label} ${classes.text}`}>
            {label}:
          </InputLabel>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={8}>
        {defaultValue && (
          <span className={classes.text} >
            {parse(defaultValue)}
          </span>
        )}
      </Grid>
    </Grid>
  )
};
