import React, { useEffect, useState } from 'react';
import Main from '../../Main/Main';
import EditRoles from './EditRoles';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './styles';
import { ApplicationUserRoles } from './types';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react'
import { Application, getApplicationsForOwner } from '../../../API/application';
import { getUserRolesForApplications, updateApplicationUserRoles, getAllUserRoles, ApplicationUserRolesAPI } from '../../../API/roles';
import { searchUserByEmail, getUsersByIdentityId } from '../../../API/users';
import { getRolesEnum, getRolesValue, Roles, RolesDisplayName } from '../../../Store/Roles/types';
import { getIdpDomainConnections } from '../../../API/connection';
import settings from '../../../Config/settings';
import DataLoader from '../../DataLoader';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { AppState } from '../../../Store';
import { Spinner } from '@danfoss/webex-ui';
import { getDanfossIdentityId, getUserRoles } from '../../../Utils';
import ClickOutsideWrapper from '../../ClickOutsideWrapper';
import usePrevious from '../../../Utils/usePrevious';

const AppRoles: React.FC = () => {
	const classes = useStyles();
	const location = useLocation();
	const { getAccessTokenSilently } = useAuth0();

	const [applicationUserRoles, setApplicationUserRoles] = useState<ApplicationUserRoles[]>([]);
	const [selectedApplicationUserRoles, setSelectedApplicationUserRoles] = useState<ApplicationUserRoles | undefined>(undefined);
	const [ipdDomains, setIdpDomains] = useState<string[]>([]);
	const [accessTokenForDanfossApi, setAccessTokenForDanfossApi] = useState('');
	const [accessTokenForMyDanfossAccountApi, setAccessTokenForMyDanfossAccountAPI] = useState('');
	const [loading, setLoading] = useState(true);
	const applications = useSelector((state: AppState) => state.applications);
	const loggedInUserRoles = useSelector((state: AppState) => getUserRoles(state.userRoles));
	const loggedInUser = useSelector((state: AppState) => state.user);
	const impersonating = useSelector((state: AppState) => !!state.user.impersonatedUser);
	const prevAppsLoaded = usePrevious(applications.applicationsLoadingStatus.loaded);

	const handleEditClick = (client_id: string) => {
		setSelectedApplicationUserRoles(applicationUserRoles.find(app => app.client_id === client_id));
	}

	const updateUserRoles = (editedUserRoles: ApplicationUserRoles) => {

		const updateRoles = async () => {
			const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
			await updateApplicationUserRoles(accessTokenMyDanfossApi,
				{
					client_id: editedUserRoles.client_id,
					users: editedUserRoles.user_roles.map(userRole => ({
						danfoss_identity_id: userRole.danfoss_identity_id,
						user_roles: userRole.roles.map(role => getRolesValue(role))
					}))
				}
			);

			const editedApplicationuserRoles = applicationUserRoles.filter(app => app.client_id !== editedUserRoles.client_id);
			editedApplicationuserRoles.push(editedUserRoles);
			setApplicationUserRoles(editedApplicationuserRoles);
			setSelectedApplicationUserRoles(undefined);
		}

		updateRoles();
	}

	const cancelUpdate = () => {
		setSelectedApplicationUserRoles(undefined);
	}

	useEffect(() => {
		const loadIdpDomains = async () => {
			const idpConnections = await getIdpDomainConnections();
			const idpDomains = idpConnections.filter(conn => conn.connection_name === settings.auth0.connection).map(conn => (conn.domains))
			setIdpDomains(idpDomains && idpDomains.length > 0 && idpDomains[0] || []);
		};

		loadIdpDomains();

	}, []);

	useEffect(() => {
		const loadUserRoles = async () => {
			const accessTokenMyDanfossAccountApi = await getAccessTokenSilently(settings.myDanfossAccountApi.accessTokenOptions);
			const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
			setAccessTokenForDanfossApi(accessTokenMyDanfossApi);
			setAccessTokenForMyDanfossAccountAPI(accessTokenMyDanfossAccountApi);
			let applicationsForOwner: Application[] = [];
			let userRolesAPI: ApplicationUserRolesAPI[];
			if (loggedInUserRoles.some(role => role === Roles.DASHBOARD_ADMIN)) {
				applicationsForOwner = applications.applications || [];
				userRolesAPI = await getAllUserRoles(accessTokenMyDanfossApi);
			}
			else {
				const ownerClientIds = await getApplicationsForOwner(getDanfossIdentityId(loggedInUser), accessTokenMyDanfossAccountApi);
				applicationsForOwner = applications.applications && applications.applications.filter(app => ownerClientIds.includes(app.client_id)) || [];
				userRolesAPI = await getUserRolesForApplications(accessTokenMyDanfossApi, applicationsForOwner.map(owner => (owner.client_id)));
			}
			const danfossIdentityIds: string[] = [];
			userRolesAPI.forEach(app => app.users.forEach(u => danfossIdentityIds.push(u.danfoss_identity_id)));
			const users = await getUsersByIdentityId(accessTokenMyDanfossApi, Array.from(new Set(danfossIdentityIds)));
			const userRoles = applicationsForOwner.map(owner => {
				const roles = userRolesAPI.find(role => role.client_id === owner.client_id);
				return {
					client_id: owner.client_id,
					client_name: applications.applications && applications.applications.find(app => app.client_id == owner.client_id)?.name || owner.client_id,
					user_roles: roles && roles.users
						.filter(userWithRoles => userWithRoles.user_roles && userWithRoles.user_roles.length > 0)
						.map(userWithRoles => {
							const userInfo = users.find(u => u.danfoss_identity_id === userWithRoles.danfoss_identity_id);
							return {
								danfoss_identity_id: userWithRoles.danfoss_identity_id,
								user_email: userInfo?.email || 'N/A',
								user_name: userInfo?.name || 'Unknown user',
								user_phonenumber: userInfo?.phone_number || 'N/A',
								roles: userWithRoles.user_roles.map(role => getRolesEnum(role))
							}
						}) || []
				};
			});

			setApplicationUserRoles(userRoles);
			setLoading(false);
		};
		applications.applicationsLoadingStatus.loaded && applications.applicationsLoadingStatus.loaded !== prevAppsLoaded && loadUserRoles();

	}, [applications]);

	return (
		<Main breadCrumbs={loggedInUserRoles.includes(Roles.DASHBOARD_ADMIN) &&
			{ items: [{ text: `User roles`, link: '/userroles' }, { text: 'Maintain application user roles', link: location.pathname }] } ||
			{ items: [{ text: 'Maintain application user roles', link: location.pathname }] }} >
			<DataLoader applicationsRequired={true} >
				<Spinner visible={loading} ></Spinner>
				<Grid container className={classes.applicationListGridHeading}>
					<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridEdit}`} >
					</Grid>
					<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridName}`} >
						Application name
					</Grid>
					<Grid item className={`${classes.applicationListGridRoleUsers}`} >
						<Grid container>
							<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridRoleName}`} >
								Role
							</Grid>
							<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridRoleUserEmail}`} >
								User email
							</Grid>
							<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridRoleUserName}`} >
								User name
							</Grid>
							<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridRoleUserPhone}`} >
								User phone
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{applicationUserRoles
					.sort((app1, app2) => app1.client_name.localeCompare(app2.client_name))
					.map((appUserRoles, idx) => {
						if (selectedApplicationUserRoles && selectedApplicationUserRoles.client_id === appUserRoles.client_id) {
							return (
								<ClickOutsideWrapper>
									<EditRoles key={idx} containerClassName={`${idx % 2 === 0 ? classes.applicationListGridEven : classes.applicationListGridOdd}`} application={selectedApplicationUserRoles} cancel={cancelUpdate} updateUserRoles={updateUserRoles} idpDomains={ipdDomains} accessTokenMyDanfossAPI={accessTokenForDanfossApi} accessTokenMyDanfossAccountAPI={accessTokenForMyDanfossAccountApi} />
								</ClickOutsideWrapper>
							)
						}
						else {
							return (
								<Grid container key={idx} className={`${classes.applicationListGrid} ${idx % 2 === 0 ? classes.applicationListGridEven : classes.applicationListGridOdd}`}>
									<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridEdit}`} >
										<Button
											type="button"
											variant="outlined"
											color="default"
											className={classes.gridButton}
											onClick={(e) => { e.preventDefault(); handleEditClick(appUserRoles.client_id) }}
											disabled={selectedApplicationUserRoles && selectedApplicationUserRoles.client_id !== appUserRoles.client_id}
										>
											Edit
										</Button>
									</Grid>
									<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridName}`} >
										{appUserRoles.client_name}
									</Grid>
									<Grid item className={`${classes.applicationListGridRoleUsers}`} >
										{appUserRoles.user_roles.map((user_role, uridx) => {
											return (
												<Grid container key={uridx}>
													<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridRoleName}`}  >
														{user_role.roles.map((role, roleIdx) => (<div key={roleIdx}>{RolesDisplayName[role]}</div>))}
													</Grid>
													<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridRoleUserEmail}`} >
														{user_role.user_email}
													</Grid>
													<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridRoleUserName}`}  >
														{user_role.user_name}
													</Grid>
													<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridRoleUserPhone}`} >
														{user_role.user_phonenumber}
													</Grid>
													{loggedInUserRoles.includes(Roles.DASHBOARD_ADMIN) && !impersonating &&
														<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridRoleImpersonate}`} >
															<RouterLink to={`/impersonate?email=${user_role.user_email}`}>Impersonate</RouterLink>
														</Grid>
													}
												</Grid>
											)
										})}
									</Grid>
								</Grid>
							)
						}
					})}
			</DataLoader>
		</Main>
	);
}

export default AppRoles;
