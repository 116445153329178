import { RolesActionTypes, UserRoles  } from "./types";
import { createReducer } from 'typesafe-actions';
import { RolesActionType } from './actions';

export const initialState: UserRoles = {
  roles: [],
  rolesLoaded : false
};

const reducer = createReducer<UserRoles, RolesActionType>(initialState, {
  [RolesActionTypes.SET_USER_ROLES]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [RolesActionTypes.SET_IMPERSONATED_USER_ROLES]: (state, action) => {
    return { ...state, impersonatedUserRoles: action.payload };
  },
  [RolesActionTypes.CLEAR_IMPERSONATED_USER_ROLES]: (state) => {
    return { ...state, impersonatedUserRoles: undefined };
  }
});

export { reducer as rolesReducer };

