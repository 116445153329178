import React, { useEffect, useState } from 'react';
import { Navigate } from "react-router-dom";
import { useAuth0, GetTokenSilentlyOptions, withAuthenticationRequired } from '@auth0/auth0-react'
import settings from '../../Config/settings';
import { Roles } from '../../Store/Roles/types';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../Store';
import { getRolesForUser } from '../../Store/Roles/actions';
import { Spinner } from '@danfoss/webex-ui';
import { getCurrentUser } from '../../Store/User/actions';

type ProtectedRouteProps = {
  allowedRoles?: Roles[],
  children: React.ReactElement
}

const getAccessToken = (options: GetTokenSilentlyOptions, getAccessTokenSilently: any, getAccessTokenWithPopup: any): Promise<string> => {
  return new Promise((resolve, reject) => {
    getAccessTokenSilently(options)
      .then((token: string) => resolve(token))
      .catch((err: any) => {
        if (err.error === 'consent_required') {
          console.log('consent', err.error);
          getAccessTokenWithPopup(options)
            .then((token: string) => resolve(token))
            .catch((popupErr: any) => {
              reject(popupErr);
            });
        }
        else {
          reject(err);
        }
      });

    return '';
  });
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const {
    allowedRoles, children
  } = props;

  const { isLoading, isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0();
  const [error, setError] = useState({} as any);
  const userRoles = useSelector((state: AppState) => state.userRoles);
  const currentUser = useSelector((state: AppState) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const automaticLogin = async () => {
      getAccessToken(settings.myDanfossApi.accessTokenOptions, getAccessTokenSilently, getAccessTokenWithPopup)
        .then(() => {
          getAccessToken(settings.myDanfossAccountApi.accessTokenOptions, getAccessTokenSilently, getAccessTokenWithPopup)
            .catch((err) => {
              setError(err);
            })
        })
        .catch((err) => {
          setError(err);
        })
    };
    automaticLogin();
  }, []);

  useEffect(() => {
    const loadCurrentUser = async () => {
      if (!isLoading && isAuthenticated && user && user.sub) {
        if (!currentUser.userLoaded) {
          const accessTokenMyDanfossAccountApi = await getAccessTokenSilently(settings.myDanfossAccountApi.accessTokenOptions);
          dispatch(getCurrentUser(user.sub, accessTokenMyDanfossAccountApi));
        }

      }
    };
    loadCurrentUser();
  }, [currentUser, isLoading, isAuthenticated, user]);

  useEffect(() => {
    const loadRoles = async () => {
      if (!isLoading && isAuthenticated && currentUser.userLoaded && currentUser.user) {
        if (!userRoles.rolesLoaded) {
          const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
          const accessTokenMyDanfossAccountApi = await getAccessTokenSilently(settings.myDanfossAccountApi.accessTokenOptions);
          dispatch(getRolesForUser(currentUser.user?.identification.danfoss_identity_id, accessTokenMyDanfossApi, accessTokenMyDanfossAccountApi));
        }

      }
    };
    loadRoles();
  }, [currentUser, userRoles, isLoading, isAuthenticated]);

  // Handle login error
  if (error && error.error) {
    return <Navigate to={`/loginerror?error_code=${error.name}&error_description=${error.message}` || "/"} />;
  }

  // Must be authenticated and user info read
  if (!isAuthenticated || !currentUser.userLoaded)
    return <Spinner visible={true} />;

  // If allowedRoles is speciefied wait for user roles to load.
  if (allowedRoles && allowedRoles.length > 0) {
    if (!userRoles.rolesLoaded) {
      return <Spinner visible={true} />;
    }
    // Redirect to NoAccess if the users roles don't allow access to the selected route
    if (!allowedRoles.some(role => userRoles.roles.some(userRole => userRole === role))) {
      return <Navigate to="/noacces" />;
    }
  }

  return children


}

export default withAuthenticationRequired(ProtectedRoute, {
  onRedirecting: () => <Spinner visible={true} />
});

