import {validEmail, isValidEmailDomain} from '../validation'

export const asyncValidate = (values: any, dispatch: any, props: any) => {
    return Promise.resolve(true);
}

export const validateOnSubmit = (values: any, props: any) => {

    let errors: any = {};
    let hasErrors = false;

    errors.owners = values.owners.map((owner: any) => {
        const ownerErrors: any = {};
        if (!owner.email) {
            ownerErrors.email = 'Please fill in owner email';
            hasErrors = true;
        }
        else {
            if (!validEmail(owner.email)) {
                ownerErrors.email = 'Please enter a valid email';
                hasErrors = true;
            }
    
            if (!isValidEmailDomain(owner.email, props.idpDomains)) {
                ownerErrors.email = 'Please enter a Danfoss email';
                hasErrors = true;
            }

            if(!owner.danfoss_identity_id) {
                ownerErrors.email = 'Reading user data...';
                hasErrors = true;
            }
        }

        return ownerErrors;
    })

    return { errors, hasErrors};
};

