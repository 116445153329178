import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';
import { BannerProps } from './types';
import parse from 'html-react-parser';
import urljoin from 'url-join';
import { useNavigate } from 'react-router-dom'
import settings from '../../Config/settings';
import { AppState } from '../../Store';
import { getUserRoles } from '../../Utils';
import { rolesReducer } from '../../Store/Roles/reducers';

const Banner: React.FC<BannerProps> = ({ bannerURL, bannerClass, bannerTextClass, bannerText, bannerInformationClick, allowedRoles, denyRoles }) => {
    const classes = useStyles();
    const navigate = useNavigate();
	const userRoles = useSelector((state: AppState) => getUserRoles(state.userRoles));
	const userRolesLoaded = useSelector((state: AppState) =>state.userRoles.rolesLoaded);

    const handleBannerClick = () => {
        return navigate(bannerURL);
    }

    if (!userRolesLoaded) {
        return null;
    }

    if (denyRoles && denyRoles.length > 0 && denyRoles.some(role => userRoles.some(userRole => userRole === role) )) {
        return null;
    }
    if (allowedRoles && allowedRoles.length > 0 && !allowedRoles.some(role => userRoles.some(userRole => userRole === role) )) {
        return null;
    }
    
    return (
        <div className={classes.bannerContainer}>
            <Grid className={classes.iconContainer}>
                <Grid onClick={(e) => { e.preventDefault(); bannerInformationClick && bannerInformationClick(e) }} className={classes.informationIconContainer}>
                    <img src={'/images/icons/Information_gray.svg'} className={classes.informationIcon} title={bannerText} alt="" />
                </Grid>
            </Grid>
            <Grid item xs={12} className={`${classes.bannerSubContainer} ${bannerClass}`} onClick={handleBannerClick}>
                <Grid item xs={12} className={classes.bannerTextContainer}>
                    <span className={classes.bannerHelper} />
                    <span className={classes.bannerTextSubContainer}>
                        <Typography className={bannerTextClass}>
                            <span translate="no">{parse(bannerText)}</span>
                        </Typography>
                    </span>
                </Grid>
            </Grid>
        </div>
    );
};

export default Banner;
