import React, { useEffect } from 'react';
import { Menu, MenuItem, Modal } from '@material-ui/core';
import settings from '../../Config/settings';
import { useAuth0 } from '@auth0/auth0-react'
import { NavigateFunction, useNavigate } from 'react-router-dom';

// Graphics
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { AppState } from '../../Store';
import { Roles } from '../../Store/Roles/types';
import { UserInfo } from '../../API/users';

function isVisible(e: HTMLSpanElement) {
    return !!(e.offsetWidth || e.offsetHeight || e.getClientRects().length);
}

const setMenuStyles = () => {
    const menu = document.getElementById("DIPMenuItems")?.children[2] as HTMLDivElement;

    if (menu && isVisible(menu)) {
        const header = document.getElementById("DIPHeader");
        const marginTop = header && (header?.offsetHeight - document.documentElement.scrollTop);

        const myModal = document.getElementById("DIPUserModal")?.firstChild as HTMLDivElement || null;
        const topValue = `${marginTop}px`;
        myModal && myModal.style.setProperty("inset", `${topValue} 0px 0px`, 'important');
    }
}

const openProfile = () => {
    window.open(settings.myDanfossAccount.endpoint)
}

const getMenuContent = (classes: any, anchorEl: HTMLSpanElement | null, handleClose: Function, logoutClick: Function, navigate: NavigateFunction, user: any, roles: Roles[], impersonatedUser?: UserInfo) => {
    return (<Modal
        className={`${classes.modal} triggerWarning`}
        open={Boolean(anchorEl)}
        id="DIPUserModal"
    >
        <Menu
            classes={{ paper: classes.paper }}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            open={Boolean(anchorEl)}
            marginThreshold={11}
            onClose={() => handleClose()}
            onClick={() => handleClose()}
            MenuListProps={{ "translate": "no" }}
            autoFocus={false}
            onEnter={setMenuStyles}
            id="DIPMenuItems"
        >
            <MenuItem
                className={classes.menuItem}
                onClick={() => openProfile()}
            >
                <span className={classes.menuItemLink}>Your profile</span>
                <span>
                    <div className={classes.menuItemName}>
                        {user.given_name || ''} {user.family_name || ''}
                    </div>
                    <div className={classes.menuItemEmail}>
                        {user.email}
                    </div>
                </span>
            </MenuItem>

            {roles.includes(Roles.DASHBOARD_ADMIN) &&
                <MenuItem
                    className={`${classes.menuItem} ${classes.menuItemLast}`}
                    onClick={() => {navigate('/impersonate')}}
                >
                    <span className={classes.menuItemLink} >{impersonatedUser && 'Stop/change impersonate user' || 'Impersonate user'}</span>
                    {impersonatedUser &&
                        <span>
                            <div className={classes.menuItemName}>
                                {impersonatedUser.name}
                            </div>
                            <div className={classes.menuItemEmail}>
                                {impersonatedUser.email}
                            </div>
                        </span>
                    }
                </MenuItem>
            }
            <MenuItem
                className={`${classes.menuItem} ${classes.menuItemLast}`}
                onClick={() => logoutClick()}
            >
                <span className={classes.menuItemLink}>Log out</span>
            </MenuItem>
        </Menu>
    </Modal>
    )
}

const getUserInitials = (setInitials: Function, user: any) => {
    const userInitials = (user && ((user.given_name?.substring(0, 1) || '') + (user.family_name?.substring(0, 1) || ''))) || '';

    setInitials(userInitials.toUpperCase());
}

const UserMenuSelector: React.FC = () => {
    const { isLoading, isAuthenticated, loginWithRedirect, logout } = useAuth0();
    const [anchorEl, setAnchorEl] = React.useState<HTMLSpanElement | null>(null);
    const [initials, setInitials] = React.useState('');
    const classes = useStyles();
    const { user } = useAuth0();
    const userRoles = useSelector((state: AppState) => state.userRoles);
    const impersonatedUser = useSelector((state: AppState) => state.user.impersonatedUser);
	const navigate = useNavigate();

    useEffect(() => {
        getUserInitials(setInitials, user);

        window.addEventListener('resize', setMenuStyles, true);

        return () => {
            // When unmounting make sure to remove the eventListener again
            document.removeEventListener('resize', setMenuStyles, true);
        }

    }, [isLoading]);


    function handleClick() {
        if (anchorEl === null) {
            var elements = document.getElementsByClassName("DIPUserMenu");

            Array.from(elements).forEach((element) => {
                if (isVisible(element as HTMLSpanElement)) {
                    setAnchorEl(element as HTMLSpanElement);
                }
            });

        } else {
            setAnchorEl(null);
        }
    }

    function handleClose() {
        setAnchorEl(null);
    }

    const logoutClick = () => {
        logout({ returnTo: window.location.origin + '/logout' });
    };

    const getUserIconAndText = () => {

        return !isAuthenticated ? (
            <span className={`${classes.languageContainer} triggerWarning`} onClick={loginClick}>
                <img src={'/images/icons/Header_User_Unknown.svg'} className={`${classes.menuIcon} triggerWarning`} alt="" />Log in
            </span>
        ) : (
            <>
                <span className={`${classes.languageContainer} ${classes.cultureNameBigScreen} triggerWarning DIPUserMenu`} onClick={handleClick}>
                    <img src={'/images/icons/Header_User.svg'} className={`${classes.menuIcon} triggerWarning`} alt="" />{`${initials}${impersonatedUser && ' !!!' || ''}`}
                </span>
                <span className={`${classes.languageContainer} ${classes.cultureNameSmallScreen} triggerWarning DIPUserMenu`} onClick={handleClick}>
                    <img src={'/images/icons/Header_User.svg'} className={`${classes.menuIcon} triggerWarning`} alt="" />{`${initials}${impersonatedUser && ' !!!' || ''}`}
                </span>
            </>
        )
    };

    const loginClick = () => {
        loginWithRedirect({ ui_locales: 'en', appState: { redirectUri: '/' } });
    };

    return isLoading ? null : (
        <React.Fragment>
            {getUserIconAndText()}
            {isAuthenticated && getMenuContent(classes, anchorEl, handleClose, logoutClick, navigate, user, userRoles.roles, impersonatedUser)}
        </React.Fragment>
    );
};

export default UserMenuSelector;
