import React, { useEffect, useState } from 'react';
import Main from '../../Main/Main';
import Grid from '@material-ui/core/Grid';
import { reduxForm, Field } from 'redux-form';
import { useStyles } from './styles';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../../../Store';
import settings from '../../../Config/settings';
import { useAuth0 } from '@auth0/auth0-react';
import { RecommendedApplication, getRecommendedApplications, publishRecommendedApplication, getAllRecommendedApplications } from '../../../API/recommendedApplication';
import { Application, getApplicationsForUserWithRoles } from '../../../API/application';
import { Roles } from '../../../Store/Roles/types';
import Button from '@material-ui/core/Button';
import { SpinnerButton } from '@danfoss/webex-ui/dist/mui';
import DataLoader from '../../DataLoader';
import { Spinner } from '@danfoss/webex-ui';
import DisplayCriteria from '../../Application/Criteria//DisplayCriteria'
import { CheckboxWithName } from '../../RenderFields/RenderFields';
import { allowPublishRecommendedApps, getDanfossIdentityId, getUserRoles } from '../../../Utils';
import { getContacts } from '../utils';

const FORMID = 'appCriteriaPublish'

const AppCriteriaPublish: React.FC = () => {
	const classes = useStyles();
	const location = useLocation();
	const { getAccessTokenSilently } = useAuth0();
	const [recommendedApplications, setRecommendedApplications] = useState([] as RecommendedApplication[]);
	const [applicationsForUser, setApplicationsForUser] = useState([] as Application[]);
	const [selectedApplications, setSelectedApplications] = useState([] as string[]);
	const [showChanges, setShowChanges] = useState(true);
	const [loading, setLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [expandedRows, setExpandedRows] = useState([] as string[]);
	const userRoles = useSelector((state: AppState) => getUserRoles(state.userRoles));
	const applications = useSelector((state: AppState) => state.applications);
	const currentUser = useSelector((state: AppState) => state.user);
    const contacts = useSelector((state: AppState) => state.contacts.contactInfo);

	const getUsersRecommendedApplications = async (accessTokenMyDanfossApi: string, clientIdsForUser: string[] = []): Promise<RecommendedApplication[]> => {
		if (userRoles.some(role => role === Roles.DASHBOARD_ADMIN)) {
			return await getAllRecommendedApplications(accessTokenMyDanfossApi);
		}
		else {
			return await getRecommendedApplications(accessTokenMyDanfossApi, clientIdsForUser);
		}
	}

	const publishSelectedApplications = () => {
		setIsSaving(true);
		const publish = async () => {
			const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);

			Promise.allSettled(
				selectedApplications.map(selApp => {
					return publishRecommendedApplication(accessTokenMyDanfossApi, recommendedApplications.find(recommApp => recommApp.client_id === selApp && !recommApp.published)?.client_id || '')
				})
			)
				.then((results: PromiseSettledResult<Response>[]) => {
					results.forEach((result, idx) => {
						if (result.status !== "fulfilled") {
							console.log(`Publish of ${applicationsForUser.find(app => app.client_id === selectedApplications[idx])?.name} failed`);
						}
					})

					getUsersRecommendedApplications(accessTokenMyDanfossApi, applicationsForUser.map(app => app.client_id))
						.then((result: RecommendedApplication[]) => {
							setRecommendedApplications(result);
							setSelectedApplications(result.map(app => app.client_id));
							setIsSaving(false);
						})
						.catch(() => {
							setIsSaving(false);
						})
				})

		}

		publish();
	}

	const toggleShowPublished = (client_id: string) => {
		if (expandedRows.indexOf(client_id) > -1) {

			setExpandedRows(expandedRows.filter(row => row !== client_id));
		}
		else {
			setExpandedRows([...expandedRows, client_id]);
		}
	}

	const onChangeSelectApplication = (client_id: string, checked: boolean) => {
		if (selectedApplications.indexOf(client_id) > -1) {
			if (!checked) {
				setSelectedApplications(selectedApplications.filter(row => row !== client_id));
			}
		}
		else {
			if (checked) {
				setSelectedApplications([...selectedApplications, client_id]);
			}
		}

	}

	const onChangeSelectAll = (checked: boolean) => {
		if (checked) {
			setSelectedApplications(recommendedApplications.filter(app => !app.published).map(app => app.client_id));
		}
		else {
			setSelectedApplications([]);
		}
	}

	const allowPublish = () => {
		return allowPublishRecommendedApps(userRoles);
	}

	useEffect(() => {
		const loadRecommendedApps = async () => {
			if (currentUser.userLoaded && currentUser.user) {
				setLoading(true);
				const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
				const accessTokenMyDanfossAccountApi = await getAccessTokenSilently(settings.myDanfossAccountApi.accessTokenOptions);
				let recommendedApps: RecommendedApplication[] = []
				if (userRoles.some(role => role === Roles.DASHBOARD_ADMIN)) {
					setApplicationsForUser(applications.applications || []);
					recommendedApps = await getUsersRecommendedApplications(accessTokenMyDanfossApi);
				}
				else {
					const clientIdsForUser = await getApplicationsForUserWithRoles(accessTokenMyDanfossApi, accessTokenMyDanfossAccountApi, getDanfossIdentityId(currentUser), [Roles.APPLICATION_OWNER, Roles.RECOMMENDED_APPLICATION_PUBLISHER]);
					const appsForUser = applications.applications?.filter(app => clientIdsForUser.includes(app.client_id)) || [];
					setApplicationsForUser(appsForUser);
					recommendedApps = await getUsersRecommendedApplications(accessTokenMyDanfossApi, clientIdsForUser);
				}
				setRecommendedApplications(recommendedApps);
				setSelectedApplications(recommendedApps.filter(app => !app.published).map(app => app.client_id));
				setLoading(false);
			}
		}
		loadRecommendedApps();

	}, [applications.applications, currentUser]);

	const status = (recommApp: RecommendedApplication | undefined, recommAppPublished: RecommendedApplication | undefined) => { return (recommApp && (recommApp.published && 'Published')) || (recommAppPublished && 'Under change') || 'New' };

	return (
		<Main breadCrumbs={{ items: [{ text: 'Publish overview', link: '/publish' }, { text: `Publish recommended criteria`, link: location.pathname }] }}>
			<DataLoader referencesRequired={true} applicationsRequired={true} countriesRequired={true} contactsRequired={true}>
				<Spinner visible={loading} ></Spinner>
				{!allowPublish() &&
					<Grid container className={classes.notAllowed}>
						{`You are not allowed to publish yourself - please ask ${getContacts(contacts)} to publish the criteria`}
					</Grid>
				}
				<form id={FORMID} className={classes.criteriaForm}>
					<Grid container className={`${classes.toggleButtonContainer}`}>
						<Button
							type="button"
							variant="outlined"
							color="default"
							component={RouterLink}
							className={classes.navigationButton}
							to="/publish/applicationcriteria/unpublish"
						>
							{allowPublish() && 'Remove existing recommendations' || 'View existing recommendations'}
						</Button>
						<Button
							type="button"
							variant="outlined"
							color="default"
							className={classes.navigationButton}
							onClick={(e) => { e.preventDefault(); setShowChanges(!showChanges) }}
						>
							{showChanges && 'Show all recommended applications' || 'Only show recommended applications with changes'}
						</Button>
					</Grid>
					<Grid container className={classes.applicationListGridHeading}>
						<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridSelect}`} >
							<Field
								name="selectAll"
								id="selectAll"
								checked={allowPublish() && recommendedApplications.some(recommApp => !recommApp.published) &&
									recommendedApplications.filter(recommApp => !recommApp.published).every(recommApp => selectedApplications.some(selApp => selApp === recommApp.client_id))}
								component={CheckboxWithName}
								onClick={(event: any) => { onChangeSelectAll(event.target.checked); }}
								label=""
								disabled={!allowPublish()}
							/>
						</Grid>
						<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridName}`} >
							Application name
						</Grid>
						<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridCriteria}`} >
							Criteria
						</Grid>
						<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridStatus}`} >
							Status
						</Grid>
					</Grid>
					{applicationsForUser.filter(app => recommendedApplications.some(recommApp => recommApp.client_id === app.client_id && (!showChanges || !recommApp.published)))
						.sort((app1, app2) => app1.name.localeCompare(app2.name))
						.map((app, idx, allRecommAppsForUser) => {
							const noOfRecommAppsForUser = allRecommAppsForUser.length;
							const recommApp = recommendedApplications.find(rApp => rApp.client_id === app.client_id && !rApp.published) ||
								recommendedApplications.find(rApp => rApp.client_id === app.client_id && rApp.published);
							const recommAppPublished = recommendedApplications.find(rApp => rApp.client_id === app.client_id && rApp.published);
							return (
								<Grid container key={idx} className={`${classes.applicationListGrid} ${idx % 2 === 0 ? classes.applicationListGridEven : classes.applicationListGridOdd}`}>
									<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridSelect}`} >
										{['Under change', 'New'].includes(status(recommApp, recommAppPublished)) &&
											<Field
												name={`publish${idx}`}
												id={`publish${idx}`}
												checked={allowPublish() && selectedApplications.some(selApp => selApp === app.client_id)}
												component={CheckboxWithName}
												onClick={(event: any) => { onChangeSelectApplication(app.client_id, event.target.checked); }}
												label=""
												disabled={!allowPublish()}
											/>
										}
									</Grid>
									<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridName}`} >
										{app.name}
									</Grid>
									<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridCriteria}`}  >
										<DisplayCriteria application={recommApp} existingApplication={recommAppPublished} isNew={!recommAppPublished} noOfRecommAppsForUser={noOfRecommAppsForUser} />
										{['Under change'].includes(status(recommApp, recommAppPublished)) &&
											<Grid item className={classes.criteriaIcon} onClick={() => { toggleShowPublished(app.client_id) }} >
												<img src={'/images/icons/ChevronDown.svg'} className={expandedRows.some(exp => exp === app.client_id) && `${classes.iconStyleRotate} ${classes.iconStyle} ` || classes.iconStyle} alt="" />
												<span className={classes.expandText}>{expandedRows.some(exp => exp === app.client_id) && 'Hide' || 'Show'} existing criteria</span>
											</Grid>}
										{recommAppPublished && expandedRows.some(exp => exp === app.client_id) &&
											<DisplayCriteria application={recommAppPublished} existingApplication={recommApp} />
										}
									</Grid>
									<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridStatus}`} >
										{status(recommApp, recommAppPublished)}
									</Grid>
								</Grid>
							)
						})}
					{!loading && applicationsForUser.filter(app => recommendedApplications.some(recommApp => recommApp.client_id === app.client_id && (!showChanges || !recommApp.published))).length === 0 &&
						<Grid container >
							<Grid item className={`${classes.applicationListGridItem}`} >
								No changes to publish
							</Grid>
						</Grid>
					}
					<Grid container >
						<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridPublishButton}`} >
							{!loading && allowPublish() && selectedApplications.length > 0 && applicationsForUser.filter(app => recommendedApplications.some(recommApp => recommApp.client_id === app.client_id && (!showChanges || !recommApp.published))).length > 0 &&
								<SpinnerButton
									type="button"
									variant="contained"
									color="primary"
									pathToImagesFolder={'/images/icons'}
									spinnerVisible={isSaving}
									className={classes.button}
									onClick={() => publishSelectedApplications()}
								>
									Publish selected applications
								</SpinnerButton>
							}
							<Button
								type="button"
								variant="outlined"
								color="default"
								className={classes.button}
								component={RouterLink}
								to="/preview/applicationcriteria"
							>
								Preview
							</Button>
							{userRoles.some(userRole => [Roles.RECOMMENDED_APPLICATION_EDITOR, Roles.APPLICATION_OWNER, Roles.DASHBOARD_ADMIN].some(role => role === userRole)) &&
								<Button
									type="button"
									variant="outlined"
									color="default"
									className={classes.button}
									component={RouterLink}
									to="/application/criteria"
								>
									Edit Criteria
								</Button>
							}
						</Grid>
					</Grid>

				</form>
			</DataLoader>
		</Main>
	);
}
export default reduxForm({
	form: FORMID
})(AppCriteriaPublish);
