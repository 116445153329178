import React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import { Container, Grid } from '@material-ui/core';
import { useStyles } from './styles';
import urljoin from 'url-join';
import settings from '../../Config/settings';
import UserMenuSelector from '../UserMenuSelector/UserMenuSelector';

const Header: React.FC = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar position="static" elevation={0} className={classes.appBar} id="DIPHeader">
                <Container className={`myAccount ${classes.headerFullWidth}`} >
                    <Grid item xs={12} className={classes.headerMargin}>
                        <Toolbar className={classes.toolbar} disableGutters={true}>
                            <div className={classes.leftContainer}>
                                <div className={classes.leftSubContainer}>
                                    <Link to="/" className={`${classes.headerItemContainer} triggerWarning`}>
                                        <img src={'/images/logo-eng-tomorrow.png'} className={classes.logo} alt="Danfoss logo" />
                                    </Link>
                                </div>
                            </div>
                            <div className={classes.rightContainer}>
                                <div className={classes.rightSubContainer}>
                                    <div className="triggerWarning">
                                        <UserMenuSelector />
                                    </div>
                                </div>
                            </div>
                        </Toolbar>
                    </Grid>
                </Container>
            </AppBar>
        </React.Fragment>
    );
}

export default Header;
