import { takeLatest, all, put, fork, select } from 'redux-saga/effects';
import {
	SetDepartments,
	SetCompanyTypes,
	SetAreasOfInterest,
	SetDepartmentsLoadingStatus,
	SetCompanyTypesLoadingStatus,
	SetAreasOfInterestLoadingStatus
} from './actions';

import {
	ReferencesActionTypes,
	ReferencesAppStateModel,
} from './types';
import { AppState } from '../';
import {
	ReferenceApiModel,
	getDepartments,
	getCompanyTypes,
	getAreasOfInterest,
} from '../../API/references';

function* loadDepartments() {
	const { references } = (yield select((state: AppState) => {
		return { references: state.references };
	})) as { references: ReferencesAppStateModel; };

	if (references.departmentsLoadingStatus.loading || references.departmentsLoadingStatus.loaded) {
		return;
	}
	try {
		yield put(SetDepartmentsLoadingStatus({ loaded: false, loading: true, failed: false }));
		const departmentsApi: ReferenceApiModel = yield getDepartments();
		yield put(SetDepartments(departmentsApi.items || []));
		yield put(SetDepartmentsLoadingStatus({ loaded: true, loading: false, failed: false }));
	}
	catch (error) {
		yield put(SetDepartmentsLoadingStatus({ loading: false, loaded: false, failed: true }));
	}
}

function* loadCompanyTypes() {
	const { references } = (yield select((state: AppState) => {
		return { references: state.references };
	})) as { references: ReferencesAppStateModel; };

	if (references.companyTypesLoadingStatus.loading || references.companyTypesLoadingStatus.loaded) {
		return;
	}
	try {
		yield put(SetCompanyTypesLoadingStatus({ loaded: false, loading: true, failed: false }));
		const companyTypesApi: ReferenceApiModel = yield getCompanyTypes();
		yield put(SetCompanyTypes(companyTypesApi.items || []));
		yield put(SetCompanyTypesLoadingStatus({ loaded: true, loading: false, failed: false }));
	}
	catch (error) {
		yield put(SetCompanyTypesLoadingStatus({ loading: false, loaded: false, failed: true }));
	}
}

function* loadAreasOfInterest() {
	const { references } = (yield select((state: AppState) => {
		return { references: state.references };
		
	})) as { references: ReferencesAppStateModel };

	if ((references.areasOfInterestLoadingStatus.loading || references.areasOfInterestLoadingStatus.loaded) && !references.areasOfInterestLoadingStatus.failed) {
		return;
	}
	try {
		yield put(SetAreasOfInterestLoadingStatus({ loaded: false, loading: true, failed: false }));
		const areasOfInterestApi: ReferenceApiModel = yield getAreasOfInterest();
		yield put(SetAreasOfInterest(areasOfInterestApi.items || []));
		yield put(SetAreasOfInterestLoadingStatus({ loaded: true, loading: false, failed: false }));
	}
	catch (error) {
		yield put(SetAreasOfInterestLoadingStatus({ loading: false, loaded: false, failed: true }));
	}
}

function* watchLoadDepartments() {
	yield takeLatest(ReferencesActionTypes.GET_DEPARTMENTS, loadDepartments);
}

function* watchLoadAreasOfInterest() {
	yield takeLatest(ReferencesActionTypes.GET_AREAS_OF_INTEREST, loadAreasOfInterest);
}

function* watchLoadCompanyTypes() {
	yield takeLatest(ReferencesActionTypes.GET_COMPANY_TYPES, loadCompanyTypes);
}

export default function* referencesSagas() {
	yield all([
		fork(watchLoadDepartments),
		fork(watchLoadAreasOfInterest),
		fork(watchLoadCompanyTypes),
	]);
}
