import { Contacts, ContactInfo, ContactInfoActionTypes } from "./types";
import { createReducer } from 'typesafe-actions';
import { ContactInfoActionType } from './actions';

const initialState: Contacts = {
  loadingStatus: {loaded: false, loading: false, failed: false},
  contactInfo: [],
};

const reducer = createReducer<Contacts, ContactInfoActionType>(initialState, {
  [ContactInfoActionTypes.SET_CONTACT_INFO]: (state, action) => {
    return { ...state, contactInfo: action.payload };
  },
  [ContactInfoActionTypes.SET_CONTACT_LOADING_STATUS]: (state, action) => {
    return { ...state, loadingStatus: {...action.payload} };
  },
});

export { reducer as contactsReducer };

