import { ReferencesActionTypes, ReferencesAppStateModel } from './types';
import { createReducer } from 'typesafe-actions';
import { ReferencesActionType } from './actions';

const initialState: ReferencesAppStateModel = {
	areasOfInterestLoadingStatus: { loaded: false, loading: false, failed: false },
	departmentsLoadingStatus: { loaded: false, loading: false, failed: false },
	companyTypesLoadingStatus: { loaded: false, loading: false, failed: false },
	companyTypes: [],
	departments: [],
	areasOfInterest: []
};

const reducer = createReducer<ReferencesAppStateModel, ReferencesActionType>(initialState, {
	[ReferencesActionTypes.GET_DEPARTMENTS_SUCCEED]:
		(state, action) => {
			return { ...state, departments: action.payload };
		},
	[ReferencesActionTypes.GET_AREAS_OF_INTEREST_SUCCEED]:
		(state, action) => {
			return { ...state, areasOfInterest: action.payload };
		},
	[ReferencesActionTypes.GET_COMPANY_TYPES_SUCCEED]:
		(state, action) => {
			return { ...state, companyTypes: action.payload };
		},
		[ReferencesActionTypes.SET_AREAS_OF_INTEREST_LOADING_STATUS]: 
		(state, action) => {
			return { ...state, areasOfInterestLoadingStatus: { ...action.payload } };
		},
		[ReferencesActionTypes.SET_COMPANY_TYPES_LOADING_STATUS]: 
		(state, action) => {
			return { ...state, companyTypesLoadingStatus: { ...action.payload } };
		},
		[ReferencesActionTypes.SET_DEPARTMENTS_LOADING_STATUS]: 
		(state, action) => {
			return { ...state, departmentsLoadingStatus: { ...action.payload } };
		},
});

export { reducer as referencesReducer };
