import { PreviewProfileModel } from "../../../API/users";

export const asyncValidate = (values: any, dispatch: any, props: any) => {
    return new Promise((resolve, reject) => {
      
        const { profile, allProfiles } : {profile: PreviewProfileModel, allProfiles: PreviewProfileModel[] } = props;

        let errors: any = {};

        if (!values.profile_name) {
            errors.profile_name = 'Please enter profile name';
        }
        else if (allProfiles.some( prf => prf.profile_name === values.profile_name && prf.danfoss_identity_id !== profile.danfoss_identity_id)) {
            errors.profile_name = 'Profile name already used'
        }

        if (Object.keys(errors).length === 0 || !Object.keys(errors).some(key => !!errors[key])) {
            resolve(true);
        }
        throw (errors);
    })
  }


