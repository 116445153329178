export const validEmail = (email: string) => {
    // eslint-disable-next-line
    return (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email));
}

export const isValidEmailDomain = (email: string, idpDomains: string[]) => {
    if (email.indexOf("@") > 0) {
        // Check if the IdP domains collection contains the email domain
        return idpDomains.some(domain => email.toLowerCase().endsWith(domain.toLowerCase()));
    } else {
        return false;
    }
}

export const isValidEmail = (email: string, values: any, props: any): boolean => {
    return validateEmail(email, values, props, '') === undefined;
}

export const validateEmail = (email: string, allValues: any, props: any, name: string) => {
    const { idpDomains } = props;
    if (email) {
        if (!validEmail(email)) {
            return 'Please enter a valid email';
        }

        if (!isValidEmailDomain(email, idpDomains)) {
            return 'Please enter a Danfoss email';
        }
    }
    return undefined;
}

