import {
	LocationApiCountry
} from '../../API/location';
import { LoadingStatus } from '../types';

export enum LocationActionTypes {
	GET_COUNTRIES = '@@location/GET_COUNTRIES',
	GET_COUNTRIES_SUCCEED = '@@location/GET_COUNTRIES_SUCCEED',
	SET_COUNTRIES_LOADING_STATUS = '@@location/SET_COUNTRIES_LOADING_STATUS',
}

export interface LocationAppStateModel
	extends CountriesAppStateModel {
		countriesLoadingStatus: LoadingStatus;
	}

interface CountriesAppStateModel {
	countries?: LocationApiCountry[];
}

