import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import settings from '../../Config/settings';
import { GetCountriesAction } from '../../Store/Location/actions';
import { GetApplicationsAction, GetApplicationTranslationsAction } from '../../Store/Applications/actions';
import { Spinner } from '@danfoss/webex-ui';
import { GetAreasOfInterestAction, GetCompanyTypesAction, GetDepartmentsAction } from '../../Store/References/actions';
import { GetContactInfoAction } from '../../Store/Contacts/actions';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { AppState } from '../../Store';
import { useStyles } from './styles';
import { applicationTranslations } from '../../Test/appDescription';

interface DataLoaderProps {
	children?: any,
	applicationsLoaded?: boolean,
	countriesLoaded?: boolean,
	contactsLoaded?: boolean,
	referencesLoaded?: boolean,
	applicationsFailed?: boolean,
	applicationTranslationsFailed?: boolean,
	countriesFailed?: boolean,
	contactsFailed?: boolean,
	departmentsFailed?: boolean,
	areasOfInterrestFailed?: boolean,
	companyTypesFailed?: boolean,
	countriesRequired?: boolean,
	applicationsRequired?: boolean,
	referencesRequired?: boolean,
	contactsRequired?: boolean
}

const DataLoader: React.FC<DataLoaderProps> = ({ applicationsLoaded, countriesLoaded, contactsLoaded, referencesLoaded,
	applicationsFailed, applicationTranslationsFailed, countriesFailed, contactsFailed, departmentsFailed, companyTypesFailed, areasOfInterrestFailed,
	referencesRequired, countriesRequired, applicationsRequired, contactsRequired, children }) => {
	const dispatch = useDispatch();
	const { getAccessTokenSilently } = useAuth0();
	const [tryNumber, setTryNumber] = useState(0);
	const classes = useStyles();

	const reloadDataOnError = () => {
		setTryNumber(tryNumber + 1);
	}

	useEffect(() => {
		const loadCountries = async () => {
			const accessTokenMyDanfossAccountApi = await getAccessTokenSilently(settings.myDanfossAccountApi.accessTokenOptions);
			dispatch(GetCountriesAction(accessTokenMyDanfossAccountApi));
		};
		if (countriesRequired)
			loadCountries();

		const loadApplications = async () => {
			const accessTokenMyDanfossAccountApi = await getAccessTokenSilently(settings.myDanfossAccountApi.accessTokenOptions);
			dispatch(GetApplicationsAction(accessTokenMyDanfossAccountApi));
		};
		if (applicationsRequired)
			loadApplications();

		const loadApplicationTranslations = async () => {
			dispatch(GetApplicationTranslationsAction());
		};

		if (applicationsRequired)
			loadApplicationTranslations();

		const loadDepartments = async () => {
			dispatch(GetDepartmentsAction());
		};
		if (referencesRequired)
			loadDepartments();

		const loadAreasOfInterest = async () => {
			dispatch(GetAreasOfInterestAction());
		};
		if (referencesRequired)
			loadAreasOfInterest();

		const loadCompanyTypes = async () => {
			dispatch(GetCompanyTypesAction());
		};
		if (referencesRequired)
			loadCompanyTypes();

		const loadContacts = async () => {
			const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
			dispatch(GetContactInfoAction(settings.contacts.ids.split(','), accessTokenMyDanfossApi));
		};
		if (contactsRequired)
			loadContacts();
		// setForceReload(false);
	}, [tryNumber]);

	if ((countriesRequired && countriesFailed) ||
		(applicationsRequired && (applicationsFailed || applicationTranslationsFailed)) ||
		(referencesRequired && (departmentsFailed || companyTypesFailed || areasOfInterrestFailed)) ||
		(contactsRequired && contactsFailed)) {
		return (
			<React.Fragment>
				<Grid container>
					<Grid item className={classes.errorHeading}>
						We have an issue loading data needed for this function. Please try to reload. 
						<br/>If the issue continues please send this information to one of the contacts mentioned in the Contact Information in the footer
					</Grid>

				</Grid>
				<Grid container>
					{applicationsRequired && applicationsFailed &&
						<Grid item className={classes.errorText}>
							Failed to load applications
						</Grid>
					}
					{applicationsRequired && applicationTranslationsFailed &&
						<Grid item className={classes.errorText}>
							Failed to load application translations
						</Grid>
					}
					{countriesRequired && countriesFailed &&
						<Grid item className={classes.errorText}>
							Failed to load countries from location API
						</Grid>
					}
					{referencesRequired && departmentsFailed &&
						<Grid item className={classes.errorText}>
							Failed to load departments
						</Grid>
					}
					{referencesRequired && companyTypesFailed &&
						<Grid item className={classes.errorText}>
							Failed to load company types
						</Grid>
					}
					{referencesRequired && areasOfInterrestFailed &&
						<Grid item className={classes.errorText}>
							Failed to load areas of interest
						</Grid>
					}
					{contactsRequired && contactsFailed &&
						<Grid item className={classes.errorText}>
							Failed to load contact info
						</Grid>
					}
				</Grid>
				<Grid container>
					<Grid item>
						<Button
							type="button"
							variant="contained"
							color="primary"
							onClick={() => reloadDataOnError()}
							className={classes.button}
						>
							Reload data
						</Button>

					</Grid>
				</Grid>
			</React.Fragment>
		)
	}
	if ((countriesRequired && !countriesLoaded) ||
		(applicationsRequired && !applicationsLoaded) ||
		(referencesRequired && !referencesLoaded) ||
		(contactsRequired && !contactsLoaded)) {
		return <Spinner visible={true}></Spinner>
	}
	else {
		return (
			children
		)
	}
}

const mapStateToProps = (state: AppState) => ({
	applicationsLoaded: state.applications.applicationsLoadingStatus.loaded && state.applications.applicationTranslationsLoadingStatus.loaded,
	countriesLoaded: state.location.countriesLoadingStatus.loaded,
	contactsLoaded: state.contacts.loadingStatus.loaded,
	referencesLoaded: state.references.areasOfInterestLoadingStatus.loaded && state.references.companyTypesLoadingStatus.loaded && state.references.departmentsLoadingStatus.loaded,
	applicationsFailed: state.applications.applicationsLoadingStatus.failed,
	applicationTranslationsFailed: state.applications.applicationTranslationsLoadingStatus.failed,
	countriesFailed: state.location.countriesLoadingStatus.failed,
	contactsFailed: state.contacts.loadingStatus.failed,
	areasOfInterrestFailed: state.references.areasOfInterestLoadingStatus.failed,
	companyTypesFailed: state.references.companyTypesLoadingStatus.failed,
	departmentsFailed: state.references.departmentsLoadingStatus.failed
});

export default connect(mapStateToProps)(DataLoader);


