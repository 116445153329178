import React, { useState } from 'react';
import Main from '../Main';
import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router-dom';
import { useStyles } from './styles';
import Banner from '../Banner'
import ModalPopup from '../Modal/ModalPopup';
import BannerPopup from '../Banner/BannerPopup';
import { Roles } from '../../Store/Roles/types';
import settings from '../../Config/settings';
import { useSelector } from 'react-redux';
import { AppState } from '../../Store';
import { allowPublishApplicationTexts, allowPublishRecommendedApps, getUserRoles, replaceLast } from '../../Utils';
import DataLoader from '../DataLoader';
import { getContacts } from './utils';

const PublishOverview: React.FC = () => {
    const location = useLocation();
    const classes = useStyles();
    const [showBannerPopup, setShowBannerPopup] = useState(false);
    const [bannerHeader, setBannerHeader] = useState('');
    const [bannerBody, setBannerBody] = useState('');
    const userRoles = useSelector((state: AppState) => getUserRoles(state.userRoles));
    const contacts = useSelector((state: AppState) => state.contacts.contactInfo);

    const handleBannerInformationClick = (bannerHeader: string, bannerBody: string) => {
        setBannerBody(bannerBody);
        setBannerHeader(bannerHeader);
        setShowBannerPopup(true);
    }

    return (
        <Main breadCrumbs={{ items: [{ text: `Publish overview`, link: location.pathname }] }}>
            <DataLoader contactsRequired={true}>
                <Grid container>
                    {showBannerPopup && (
                        <Grid container className={classes.bannerPopupContainer}>
                            <ModalPopup paperClassName={classes.bannerPopup}>
                                <BannerPopup bannerHeader={bannerHeader} bannerBody={bannerBody} toggleBannerPopup={setShowBannerPopup} />
                            </ModalPopup>
                        </Grid>
                    )
                    }
                    <Banner
                        bannerText={allowPublishApplicationTexts(userRoles) && "Publish application texts" || "View application texts ready for publish"}
                        bannerURL={"/publish/applicationtranslation"}
                        bannerInformationClick={() => handleBannerInformationClick(
                            allowPublishApplicationTexts(userRoles) && "Publish application texts" || "View application texts ready for publish",
                            allowPublishApplicationTexts(userRoles) &&
                            "Publish application texts like header, description, login and sign-up texts" ||
                            `View application texts like header, description, login and sign-up texts ready for publish.<br/>You are not allowed to publish the texts yourself - please ask ${getContacts(contacts)} to publish the texts`)}
                        bannerClass={classes.banner}
                        bannerTextClass={classes.bannerText}
                        allowedRoles={[Roles.APPLICATION_OWNER, Roles.APPLICATION_PUBLISHER, Roles.DASHBOARD_ADMIN]}
                    />
                    <Banner
                        bannerText={allowPublishRecommendedApps(userRoles) && "Publish recommended criteria" || "View recommended criteria ready for publish"}
                        bannerURL={"/publish/applicationcriteria"}
                        bannerInformationClick={() => handleBannerInformationClick(
                            allowPublishRecommendedApps(userRoles) && "Publish recommended criteria" || "View recommended criteria ready for publish",
                            allowPublishRecommendedApps(userRoles) &&
                            "Publish criteria for recommended applications" ||
                            `View criteria for recommended apps ready for publish.<br/>You are not allowed to publish the criteria yourself - please ask ${getContacts(contacts)} to publish the criteria`)}
                        bannerClass={classes.banner}
                        bannerTextClass={classes.bannerText}
                        allowedRoles={[Roles.APPLICATION_OWNER, Roles.RECOMMENDED_APPLICATION_PUBLISHER, Roles.DASHBOARD_ADMIN]}
                    />
                    <Banner
                        bannerText="Publish relevant website"
                        bannerURL={"/publish/website"}
                        bannerInformationClick={() => handleBannerInformationClick("Publish relevant website", "Publish changes like name, description, url based on language and country criteria")}
                        bannerClass={classes.banner}
                        bannerTextClass={classes.bannerText}
                        allowedRoles={[Roles.RELEVANT_WEBSITE_PUBLISHER, Roles.DASHBOARD_ADMIN]}
                    />
                </Grid>
            </DataLoader>
        </Main>
    );
}

export default PublishOverview;