import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	bannerContainer: {
		position: 'relative'
	},
	root: {
		marginLeft: '0px',
		position: 'relative'
	},
	toggleButtonContainer: {
		marginBottom: '20px',
		justifyContent: 'end'
	},
	applicationListGridHeading: {
		backgroundColor: colors.webexuiGraySoft,
		minHeight: '40px',
		fontWeight: 600
	},
	applicationListGridHeadingUnpublish: {
		backgroundColor: colors.webexuiGraySoft,
		minHeight: '40px',
		paddingBottom: '10px',
		fontWeight: 600
	},
	applicationListGridItem: {
		padding: '5px 8px 5px 8px',
		marginTop: '10px'
	},
	applicationListGridSelect: {
		width: '5%',
		marginTop: '0px'
	},
	applicationListGridEdit: {
		width: '15%',
		[theme.breakpoints.down('md')]: {
			minWidth: '140px'
		}
	},
	applicationListGridEdit2: {
		width: '10%',
		[theme.breakpoints.down('md')]: {
			minWidth: '80px'
		}
	},
	applicationListGridName: {
		width: '20%'
	},
	applicationListGridCriteria: {
		width: '45%',
		marginBottom: '12px'
	},
	applicationListGridAttribute: {
		width: '30%',
		[theme.breakpoints.down('md')]: {
			width: '35%',
		}
	},
	applicationListGridAndOr: {
		width: '10%',
		minWidth: '85px'
	},
	applicationListGridValues: {
		width: '30%',
		[theme.breakpoints.down('md')]: {
			width: '35%',
		}
	},
	applicationListGridCritEdit: {
		width: '65%',
		[theme.breakpoints.down('md')]: {
			width: '60%',
		}
	},
	applicationListGridStatus: {
		width: '15%',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
	applicationListGridPublishButton: {
		paddingLeft: '0px'
	},
	applicationListGrid: {
		minHeight: '40px'
	},
	critListGrid: {
		marginTop: '-5px',
		marginLeft: '-10px',
		minHeight: '30px'
	},
	applicationListGridOdd: {
		backgroundColor: colors.webexuiGraySoft
	},
	applicationListGridEven: {
		backgroundColor: colors.webexuiWhite
	},
	editGridButton: {
		marginBottom: '12px',
		height: '30px',
		minWidth: '160px',
		[theme.breakpoints.down('md')]: {
			minWidth: '130px',
			padding: '7px 7px 7px 7px'
		}
	},
	editOwnerGridButton: {
		marginTop: '12px',
		height: '30px'
	},
	button: {
		marginRight: '20px'
	},
	navigationButton: {
		marginLeft: '20px'
	},
	editPopup: {
		position: 'absolute',
		margin: '0 auto',
		maxWidth: '1200px',
		[theme.breakpoints.up('sm')]: {
			margin: '0px 15px 0'
		},
		textAlign: 'left',
		paddingBottom: '23px',
		padding: '16px 35px 50px 35px !important'
	},
	editPopupContainer: {
		maxWidth: '1280px',
		display: 'flex',
		justifyContent: 'center',
		position: 'absolute',
		top: '250px'
	},
	editContainer: {
		position: 'relative'
	},
	popupClose: {
		cursor: 'pointer',
		width: '18px',
		[theme.breakpoints.only('xs')]: {
			margin: '10px -16px',
		},
		[theme.breakpoints.up('sm')]: {
			right: '-20px'
		},
		position: 'absolute'
	},
	formContainer: {
		position: 'relative',
		height: '500px'
	},
	blackText: {
		color: colors.webexuiBlack,
	},
	iconStyle: {
		top: '0',
		right: '0',
		display: 'inline-block',
		color: '#c0c0c0',
		width: '13px',
		height: '13px',
		margin: '12px 10px 12px 0px',
		verticalAlign: 'middle',
		pointerEvents: 'none'
	},
	expandText: {
		verticalAlign: 'middle'
	},
	iconStyleRotate: {
		transform: 'rotate(180deg)'
	},
	criteriaIcon: {
		cursor: 'pointer'
	},
	criteriaForm: {
		width: '100%'
	},
	confirm: {
		boxShadow: '0px -4px 0px #E2000F',
		borderRadius: '0px 0px 2px 2px',
		"&:hover": {
			boxShadow: '0px -4px 0px #E2000F, 0px 12px 17px 2px rgba(66, 78, 84, 0.14)'
		}
	},
	confirmText: {
		fontSize: '20px',
		fontWeight: 600,
		lineHeight: '24px'
	},
	confirmButtons: {
		marginTop: '20px'
	},
	confirmPopup: {
		position: 'absolute',
		margin: '0 auto',
		maxWidth: '546px',
		[theme.breakpoints.up('sm')]: {
			margin: '0px 15px 0'
		},
		textAlign: 'left',
		paddingBottom: '23px',
		padding: '16px 35px 50px 35px !important'
	},
	confirmPopupContainer: {
		maxWidth: '1280px',
		display: 'flex',
		justifyContent: 'center',
		position: 'absolute',
		top: '250px'
	},
	confirmPopupHeader: {
		fontSize: '24px',
		fontWeight: 'bold',
		margin: '20px 0 16px'
	}

}));