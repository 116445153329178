import React, { useEffect, useState } from 'react';
import Main from '../../Main/Main';
import { useLocation, Link as RouterLink,  } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../../../Store';
import settings from '../../../Config/settings';
import { useAuth0 } from '@auth0/auth0-react';
import DataLoader from '../../DataLoader';
import { Spinner } from '@danfoss/webex-ui';
import PreviewProfile from '../PreviewProfile';
import { getPrevievRecommendedApplications, PreviewProfileModel } from '../../../API/users';
import DashboardWrapper from '../UserDashboard/DashboardWrapper';
import { getAllRecommendedApplicationsDraft } from '../../../API/recommendedApplication';
import { DashBoardItem } from '../UserDashboard/Types';
import { LanguageTranslationWithClientId } from './types';
import { ApplicationTranslations, getApplicationTranslations } from '../../../API/application';
import _ from 'lodash';
import { Button, Grid } from '@material-ui/core';
import { useStyles } from './styles';
import { Roles } from '../../../Store/Roles/types';

const AppCriteriaPreview: React.FC = () => {
	const classes = useStyles();
	const location = useLocation();
	const { getAccessTokenSilently } = useAuth0();
	const [dashboardItems, setDashboardItems] = useState([] as DashBoardItem[] | undefined);
	const [recommendedApplicationsInDraftMode, setRecommendedApplicationsInDraftMode] = useState([] as string[] | undefined);
	const [applicationTranslations, setApplicationsTranslations] = useState([] as LanguageTranslationWithClientId[]);
	const [loading, setLoading] = useState(true);
	const [currentUserProfile, setCurrentUserProfile] = useState(undefined as PreviewProfileModel | undefined)
	const [reloading, setReloading] = useState(true);
	const applications = useSelector((state: AppState) => state.applications.applications);
	const userRoles = useSelector((state: AppState) => state.userRoles);

	const getApplicationTranslationsForPreview = async (accessTokenMyDanfossAccountBackend: string, client_ids: string[]): Promise<LanguageTranslationWithClientId[]> => {
		// Get client ids where application translatione hasn't already been read
		const missingClients = _.uniq(client_ids).filter(client_id => !applicationTranslations.some(trans => trans.client_id === client_id)).map(client_id => client_id);
		const newTranslations: LanguageTranslationWithClientId[] = [];
		// Read new translations in parallel
		const translationsRead = await Promise.allSettled(missingClients.map(client_id => getApplicationTranslations(client_id)))
			.then(results => {
				results.forEach((result, idx) => {
					if (result.status === "fulfilled") {
						const value: ApplicationTranslations = result.value;
						newTranslations.push({ client_id: missingClients[idx], translations: value.application_texts });
					}
					else {
						// An empty translation object is added in order to avoid repeated read for non-existing trasnlations
						newTranslations.push({ client_id: missingClients[idx], translations: [] })
					}
				})
			});

		// Add the new translations to state	
		const allTranslations = [...applicationTranslations.slice(), ...newTranslations];
		setApplicationsTranslations(allTranslations);

		return new Promise((resolve, reject) => {
			return resolve(allTranslations.filter(trans => client_ids.includes(trans.client_id) && trans.translations && trans.translations.length > 0));
		})
	}

	useEffect(() => {
		const loadRecommendedAppsInDraftMode = async () => {
			const accessToken = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
			const recommApps = await getAllRecommendedApplicationsDraft(accessToken);
			setRecommendedApplicationsInDraftMode(recommApps.map(app => app.client_id));
			setLoading(false);
		}

		loadRecommendedAppsInDraftMode();
	}, [])

	useEffect(() => {
		const loadRecommendedAppsForPreviewProfile = async () => {
			if (currentUserProfile) {
				setReloading(true);
				const accessToken = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
				const accessTokenMyDanfossAccount = await getAccessTokenSilently(settings.myDanfossAccountApi.accessTokenOptions);
				// Get recommended applications according to the current profile (both draft and published)
				const recommApps = await getPrevievRecommendedApplications(accessToken, currentUserProfile.danfoss_identity_id);
				// Get client_id for used apps from app_id
				const usedApps = applications?.filter(app => currentUserProfile.used_applications?.some(ua => ua.app_id === app.app_id ));
				// Remove apps specified as uses apps in currentUserProfile
				const recommAppsCleaned = recommApps.filter(ra => !usedApps?.some(ua => ua.client_id === ra.client_id ));
				// Get application translations for the recommended application (both draft and published)
				const appTranslations = await getApplicationTranslationsForPreview(accessTokenMyDanfossAccount, recommAppsCleaned.map(recommApp => recommApp.client_id));
				setDashboardItems(recommAppsCleaned
					.filter(ra => applications?.some(app => ra.client_id === app.client_id))
					.map(ra => {
						// Get translations for client in the selected language (both draft and published)
						const translations = appTranslations
							.find(appTrans => appTrans.client_id == ra.client_id)?.translations
							.filter(trans => trans.language === (currentUserProfile.language?.id || 'en') &&
								(ra.published === trans.published || trans.published)
							);

						// Return recommended app with application info and translations in same mode as recommende app or defaulted to published 
						return {
							...(applications?.find(app => app.client_id === ra.client_id) || {}),
							...(translations?.find(trans => trans.published === ra.published) || translations?.find(trans => trans.published)),
							...{...ra, draftExists: recommendedApplicationsInDraftMode?.some(draft => draft === ra.client_id) || false} 
						}
					}));
				setReloading(false);
			}
		}

		loadRecommendedAppsForPreviewProfile();
	}, [currentUserProfile])

	return (
		<Main breadCrumbs={{ items: [{ text: 'Preview overview', link: '/preview' }, { text: `Preview recommended applications`, link: location.pathname }] }}>
			<DataLoader referencesRequired={true} applicationsRequired={true} countriesRequired={true}>
				<Spinner visible={loading} ></Spinner>
				{!loading &&
					<PreviewProfile
						setCurrentProfile={(profile: PreviewProfileModel) => setCurrentUserProfile(profile)}
					// usedApplications={usedApplications}
					/>
				}
				{!(loading || reloading) &&
					<DashboardWrapper
						areaSubject="Preview recommended applications"
						dashboardItems={dashboardItems?.sort((item1, item2) => (item1.name || '').localeCompare(item2.name || '')) || []}
					/>
				}
				
				{!(loading || reloading) &&
					<Grid container >
						<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridPublishButton}`} >
							{userRoles.roles.some(userRole => [Roles.RECOMMENDED_APPLICATION_EDITOR, Roles.APPLICATION_OWNER, Roles.DASHBOARD_ADMIN].some(role => role === userRole)) &&
								<Button
									type="button"
									variant="outlined"
									color="default"
									className={classes.button}
									component={RouterLink}
									to="/application/criteria"
								>
									Edit recommended applications
								</Button>
							}
							{userRoles.roles.some(userRole => [Roles.RECOMMENDED_APPLICATION_PUBLISHER, Roles.APPLICATION_OWNER, Roles.DASHBOARD_ADMIN].some(role => role === userRole)) &&
								<Button
									type="button"
									variant="outlined"
									color="default"
									className={classes.button}
									component={RouterLink}
									to="/publish/applicationcriteria"
								>
									Publish recommended applications
								</Button>
							}
						</Grid>
					</Grid>
				}
			</DataLoader>
		</Main>
	);
}
export default AppCriteriaPreview;
