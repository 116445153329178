import React from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from './styles';
import { BannerPopupProps } from './types';
import parse from 'html-react-parser';


const BannerPopup: React.FC<BannerPopupProps> = ({ bannerHeader, bannerBody, toggleBannerPopup }) => {
    const classes = useStyles();

    return (
        <div className={classes.bannerContainer}>
            <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
					<div onClick={() => toggleBannerPopup()}>
						<img src={'/images/icons/Close.svg'} className={classes.bannerPopupClose} alt="" />
					</div>
            </Grid>
            <Grid item xs={12} className={classes.bannerHeader} >
                {parse(bannerBody) as string}
            </Grid>
            <Grid item xs={12} >
                <span className={classes.bannerBody}>
                    {parse(bannerBody) as string}
                </span>
            </Grid>
        </div>
    );
};

export default BannerPopup;
