import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../Store';
import { ImportConfirmPopupProps, ImportProgress } from './types';
import { useStyles } from './styles';
import { Button, Grid } from '@material-ui/core';
import ModalPopup from '../Modal/ModalPopup';

const scrollToTop = () => {
    (document.getElementById("mainContent") || {} as HTMLElement).scrollTop = 0;
    document.documentElement.scrollTop = 0;
}

const ImportConfirmPopup: React.FC<ImportConfirmPopupProps> = ({ importTypeProgress, importItemNames, importTypeText, importButtonTypeText, onCancelImport, onPerformImport }) => {
    const appState = useSelector((state: AppState) => state);
    const classes = useStyles();

    if (importTypeProgress === ImportProgress.ConfirmImport) {
        scrollToTop()
        return (
            <Grid container className={classes.confirmPopupContainer}>
                <ModalPopup paperClassName={classes.confirmPopup}>
                    <div className={classes.bannerContainer}>
                        <Grid container alignItems="flex-start" direction="row">
                            <Grid className={classes.confirmPopupHeader} >
                                Confirm import
                            </Grid>
                        </Grid>
                        <Grid container alignItems="flex-start" direction="row">
                            {importItemNames.length > 0 &&
                                <Grid item className={classes.errorList}>
                                    {`The following ${importTypeText} will be imported:`}
                                    <ul>
                                        {importItemNames.map((itemName, idx) => (
                                            <li key={idx}>{itemName}</li>
                                        ))}
                                    </ul>
                                </Grid>
                            }
                            {importItemNames.length === 0 &&
                                <Grid item className={classes.errorList}>
                                    Nothing to import
                                </Grid>
                            }
                        </Grid>
                        <Grid container alignItems="flex-start" direction="row" className={classes.confirmButtons}>
                            <Grid item>
                                {importItemNames.length > 0 &&
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        onClick={() => onPerformImport()}
                                    >
                                        {`Yes, import ${importButtonTypeText}`}
                                    </Button>
                                }
                                <Button
                                    type="button"
                                    variant="outlined"
                                    color="default"
                                    onClick={() => { onCancelImport(); }}
                                    className={classes.button}
                                >
                                    Cancel import
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </ModalPopup>
            </Grid>
        )
    }
    else {
        return null;
    }
}

export default ImportConfirmPopup;
