import { takeLatest, all, put, fork, select, call } from 'redux-saga/effects';
import {
	SetCountriesAction, GetCountries, SetCountriesLoadingStatus
} from './actions';
import {
	getCountries, LocationApiCountries
} from '../../API/location';
import { LocationActionTypes, LocationAppStateModel } from './types';
import { AppState } from '../';

function* loadCountries(action: GetCountries) {
	const { payload: { accessToken } } = action;
	const { location } = (yield select((state: AppState) => {
		return { location: state.location };
	})) as { location: LocationAppStateModel; };

	if (location.countriesLoadingStatus.loading || location.countriesLoadingStatus.loaded) {
		return;
	}
	try {
		yield put(SetCountriesLoadingStatus({ loaded: false, loading: true, failed: false }));
		let countries: LocationApiCountries = yield getCountries(accessToken);

		yield put(SetCountriesAction(countries.value));
		yield put(SetCountriesLoadingStatus({ loaded: true, loading: false, failed: false }));
	}
	catch (error) {
		yield put(SetCountriesLoadingStatus({ loading: false, loaded: false, failed: true }));
	}

}


function* watchLoadCountries() {
	yield takeLatest(LocationActionTypes.GET_COUNTRIES, loadCountries);
}

export default function* culturesSagas() {

	yield all([
		fork(watchLoadCountries),
	]);
}
