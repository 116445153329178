import React, { useState, useEffect } from 'react';
import Main from '../../Main';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './styles';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import { CheckboxWithName } from '../../RenderFields/RenderFields';
import { SpinnerButton } from '@danfoss/webex-ui/dist/mui';
import { Spinner } from '@danfoss/webex-ui';
import { useAuth0 } from '@auth0/auth0-react';
import settings from '../../../Config/settings';
import { getRelevantWebsites, RelevantWebsite, updateRelevantWebsite, RelevantWebsiteCriteria, RelevantWebsites, RelevantWebsiteVariant } from '../../../API/relevantWebsite';
import { useSelector } from 'react-redux';
import { AppState } from '../../../Store';
import { languages } from '../../../Constants';
import { prepareForCompare } from '../../../Utils';
import Button from '@material-ui/core/Button';
import queryString from 'query-string';
import { BreadCrumbs } from '../../BreadCrumbs/types';
import parse from 'html-react-parser';

const WebsitePublish: React.FC = () => {
	const classes = useStyles();
	const location = useLocation();
	const locationAPICountries = useSelector((state: AppState) => state.location.countries);
	const { getAccessTokenSilently } = useAuth0();
	const [relevantWebsites, setRelevantWebsites] = useState({} as RelevantWebsites);
	const [isLoading, setIsLoading] = useState(true);
	const [isSaving, setIsSaving] = useState(false);
	const [selectedWebsites, setSelectedWebsites] = useState([] as string[]);
	const [selectedVariants, setSelectedVariants] = useState([] as string[]);
	const [websiteVariantsVisible, setWebsiteVariantsVisible] = useState([] as string[]);
	const [breadCrumbs, setBreadCrumbs] = useState({} as BreadCrumbs)
	//Get querystring parameters
	const parsed = queryString.parse(window.location.search);
	const id = (parsed && parsed.id) as string

	useEffect(() => {

		const getRelWebsites = async () => {
			const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
			const relevantWeb = await getRelevantWebsites(accessTokenMyDanfossApi);

			if (id) {
				setRelevantWebsites({ relevant_websites: relevantWeb.relevant_websites.filter(relWeb => relWeb.id === id) });
			} else {
				setRelevantWebsites(relevantWeb);
			}

			if (id) {
				setSelectedWebsites(relevantWeb.relevant_websites.filter(relWeb => !relWeb.published && relWeb.id === id).map(web => web.id));
			} else {
				setSelectedWebsites(relevantWeb.relevant_websites.filter(relWeb => !relWeb.published).map(web => web.id));
			}

			setIsLoading(false);
		}

		getRelWebsites();

	}, [])

	useEffect(() => {

		const websiteName = relevantWebsites.relevant_websites?.find(relWeb => relWeb.id === id)?.name || '';
		setBreadCrumbs({ items: [(id ? { text: websiteName, link: `/website?id=${id}` } : { text: 'publish overview', link: '/publish' }), { text: `Publish relevant website translations`, link: location.pathname }] });

		let variants = [] as string[];

		relevantWebsites.relevant_websites?.filter(relWeb => !relWeb.published).map(web => {

			const changedVariants = getChangedVariants(web)

			changedVariants.forEach(variant => {

				const variantCriteria_country = (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.country")?.values[0] || '';
				const variantCriteria_language = (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.language")?.values[0] || '';
				variants.push(`${web.id}_${variantCriteria_country}_${variantCriteria_language}`);
			})
		});

		setSelectedVariants(variants);

	}, [relevantWebsites])

	const publishSelectedWebsites = async () => {

		setIsSaving(true);

		const PublishWebsite = async () => {

			const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);

			// Loop all draft websites
			relevantWebsites.relevant_websites.filter(relWeb => !relWeb.published).forEach((website: RelevantWebsite) => {

				// Only publish selected checked
				if (selectedWebsites.includes(website.id)) {

					let changedVariants = getChangedVariants(website);
					let draftVariantsToPublish = [] as RelevantWebsiteVariant[];
					let draftVariantsNotToPublish = [] as RelevantWebsiteVariant[];

					// Loop variants and sort efter checked or not
					changedVariants.map((variant, idx) => {

						const variantCriteria_country = (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.country")?.values[0] || '';
						const variantCriteria_language = (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.language")?.values[0] || '';
						const variantKey = `${website.id}_${variantCriteria_country}_${variantCriteria_language}`;

						if (selectedVariants.includes(variantKey)) {
							if (variantCriteria_country !== '' || variantCriteria_language !== '') {
								draftVariantsToPublish.push(variant);
							}
						} else {
							draftVariantsNotToPublish.push(variant);
						}

					})

					// get variants from the published version of the website and merge with draft variants to publish
					let vaiantsToPublish = relevantWebsites.relevant_websites.filter(publishedWebsite => publishedWebsite.published && publishedWebsite.id === website.id)[0].variants.map(websiteVariant => {
						const variantCriteria_country = (websiteVariant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.country")?.values[0] || '';
						const variantCriteria_language = (websiteVariant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.language")?.values[0] || '';
						const variantKey = `${website.id}_${variantCriteria_country}_${variantCriteria_language}`;

						if (selectedVariants.includes(variantKey)) {

							const draftVariant = draftVariantsToPublish.find(variant => JSON.stringify(variant.criteria) === JSON.stringify(websiteVariant.criteria)) || {} as RelevantWebsiteVariant;
							draftVariantsToPublish = draftVariantsToPublish.filter(variant => JSON.stringify(variant.criteria) !== JSON.stringify(draftVariant.criteria))
							return draftVariant;
						}

						return websiteVariant;

					});

					// Add any new variants added
					if (draftVariantsToPublish.length > 0) {
						draftVariantsToPublish.forEach(variant => {

							const variantCriteria_country = (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.country")?.values[0] || '';
							const variantCriteria_language = (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.language")?.values[0] || '';
							const variantKey = `${website.id}_${variantCriteria_country}_${variantCriteria_language}`;

							if (selectedVariants.includes(variantKey)) {
								vaiantsToPublish.push(variant);
							}
						})
					}

					let websiteWithVariantsToPublish = { ...website, variants: vaiantsToPublish };

					updateRelevantWebsite(website.id, websiteWithVariantsToPublish, accessTokenMyDanfossApi, false).then(resp => {

						const reloadRelevantWebsites = async () => {
							// Some variants was not published, so make sure to save a draft 
							// version with the existing changed variants
							if (draftVariantsNotToPublish.length > 0) {
								await updateRelevantWebsite(website.id, website, accessTokenMyDanfossApi, true);
							}

							await getRelevantWebsites(accessTokenMyDanfossApi).then(resp => {
								setRelevantWebsites(resp);
							});
						}

						reloadRelevantWebsites().then(resp => {
							setIsSaving(false);
						});
					});
				}

			});
		}

		PublishWebsite();

	}

	const onChangeSelectAll = (checked: boolean) => {

		if (checked) {

			const draftRelevantWebsites = relevantWebsites.relevant_websites.filter(relWeb => !relWeb.published);
			let variantIds = [] as string[];

			setSelectedWebsites(draftRelevantWebsites.map(web => web.id));

			draftRelevantWebsites.map(web => {

				const changedVariants = getChangedVariants(web)

				changedVariants.forEach(variant => {
					const variantCriteria_country = (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.country")?.values[0] || '';
					const variantCriteria_language = (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.language")?.values[0] || '';
					variantIds.push(`${web.id}_${variantCriteria_country}_${variantCriteria_language}`);
				})
			});

			setSelectedVariants(variantIds);
		}
		else {
			setSelectedWebsites([]);
			setSelectedVariants([]);
		}
	}

	const onChangeSelectAllVariants = (website: RelevantWebsite, checked: boolean) => {
		if (checked) {

			const changedVariants = getChangedVariants(website)

			let variantIds = [] as string[];

			changedVariants.forEach(variant => {
				const variantCriteria_country = (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.country")?.values[0] || '';
				const variantCriteria_language = (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.language")?.values[0] || '';

				if (selectedVariants.indexOf(`${website.id}_${variantCriteria_country}_${variantCriteria_language}`) === -1) {
					variantIds.push(`${website.id}_${variantCriteria_country}_${variantCriteria_language}`);
				}
			})

			setSelectedVariants(selectedVariants.concat(variantIds));

			if (selectedWebsites.indexOf(website.id) === -1) {
				setSelectedWebsites([...selectedWebsites, website.id]);
			};
		}
		else {
			setSelectedVariants(selectedVariants.filter(sv => !sv.startsWith(website.id)));
		}
	}

	const onChangeSelectWebsite = (websiteId: string, checked: boolean) => {

		if (selectedWebsites.indexOf(websiteId) > -1) {
			if (!checked) {
				setSelectedWebsites(selectedWebsites.filter(row => row !== websiteId));
				setSelectedVariants(selectedVariants.filter(selvar => !selvar.startsWith(websiteId)));
			}
		}
		else {
			if (checked) {
				setSelectedWebsites([...selectedWebsites, websiteId]);
				let variants = [] as string[];
				const website = relevantWebsites.relevant_websites.find(web => web.id === websiteId);

				const changedVariants = getChangedVariants(website || {} as RelevantWebsite)

				changedVariants.forEach(variant => {

					const variantCriteria_country = (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.country")?.values[0] || '';
					const variantCriteria_language = (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.language")?.values[0] || '';
					variants.push(`${websiteId}_${variantCriteria_country}_${variantCriteria_language}`);
				})

				setSelectedVariants(selectedVariants.concat(variants));

			}
		}
	}

	const onChangeSelectVariant = (websiteId: string, variantId: string, checked: boolean) => {

		if (selectedVariants.indexOf(variantId) > -1) {
			if (!checked) {
				setSelectedVariants(selectedVariants.filter(row => row !== variantId));
			}
		}
		else {
			if (checked) {
				setSelectedVariants([...selectedVariants, variantId]);

				if (selectedWebsites.indexOf(websiteId) === -1) {
					setSelectedWebsites([...selectedWebsites, websiteId]);
				};
			}
		}
	}

	const isAllVariantsSelected = (website: RelevantWebsite) => {
		const changedVariantsLength = getChangedVariants(website).length;
		let count = 0;

		selectedVariants.forEach(variant => {
			if (variant.startsWith(`${website.id}_`)) {
				count++;
			}
		})

		if (count === changedVariantsLength) {
			return true;
		}

		return false;
	}

	const toggleWebsite = (id: string) => {

		if (!websiteVariantsVisible.includes(id)) {
			setWebsiteVariantsVisible([...websiteVariantsVisible, id]);
		} else {
			setWebsiteVariantsVisible(websiteVariantsVisible.filter((variant: string) => variant !== id));
		}

		return;
	}

	const toggleVariants = (id: string) => {

		if (!websiteVariantsVisible.includes(id)) {
			setWebsiteVariantsVisible([...websiteVariantsVisible, id]);
		} else {
			setWebsiteVariantsVisible(websiteVariantsVisible.filter((variant: string) => variant !== id));
		}

		return;
	}

	const getChangedVariants = (draftWebsite: RelevantWebsite) => {
		const publishedWebsite = relevantWebsites.relevant_websites.find(web => web.published && web.id === draftWebsite.id);

		const changedVariants = [] as RelevantWebsiteVariant[];

		if (publishedWebsite) {

			// Add Fallback to variants if changed
			if (publishedWebsite.fallback_display_name !== draftWebsite.fallback_display_name || publishedWebsite.fallback_description !== draftWebsite.fallback_description || publishedWebsite.fallback_url !== draftWebsite.fallback_url) {
				const draftVersion = {
					display_name: draftWebsite.fallback_display_name,
					description: draftWebsite.fallback_description,
					url: draftWebsite.fallback_url,
					criteria: [
						[
							{
								"path": "user.user_metadata.country",
								"values": [
									""
								]
							},
							{
								"path": "user.user_metadata.language",
								"values": [
									""
								]
							}
						]
					]
				}
				changedVariants.push(draftVersion);
			}

			draftWebsite.variants.forEach(variant => {

				let variantFound = false;

				publishedWebsite?.variants?.map(pubVar => {
					if (JSON.stringify(pubVar.criteria) === JSON.stringify(variant.criteria)) {
						variantFound = true;

						if (prepareForCompare(pubVar) !== prepareForCompare(variant)) {

							changedVariants.push(variant);
						}
					}
				})

				if (!variantFound) {
					changedVariants.push(variant);
				}
			})
		}

		return changedVariants;
	}

	return (isLoading || isSaving) ? (<Spinner visible={isLoading || isSaving} />) : (
		<Main breadCrumbs={breadCrumbs}>
			<form id="publishWebsite" className={classes.websiteForm}>
				{relevantWebsites.relevant_websites.filter(web => web.published).length > 0 && (
					<Grid container className={`${classes.toggleButtonContainer}`}>
						<Button
							type="button"
							variant="outlined"
							color="default"
							component={RouterLink}
							to={id ? `/publish/website/unpublish?id=${id}` : "/publish/website/unpublish"}
						>
							Remove existing translations
						</Button>
					</Grid>
				)}
				<Grid container className={classes.listGridHeading}>
					<Grid item className={`${classes.listGridItem} ${classes.listGridSelect}`} >
						<Field
							name="selectAll"
							id="selectAll"
							checked={(relevantWebsites.relevant_websites.filter(relWeb => !relWeb.published).length > 0 &&
								relevantWebsites.relevant_websites.filter(relWeb => !relWeb.published).every(web => selectedWebsites.some(selWeb => selWeb === web.id)))}
							component={CheckboxWithName}
							onClick={(event: any) => { onChangeSelectAll(event.target.checked); }}
							label=""
						/>
					</Grid>
					<Grid item className={`${classes.listGridItem} ${classes.listGridName}`} >
						Website name
					</Grid>
					<Grid item className={`${classes.listGridItem} ${classes.listGridStatus}`} >
						Status
					</Grid>
				</Grid>
				{relevantWebsites.relevant_websites.filter(relWeb => !relWeb.published).map((web, idx) => {

					const isWebsiteChecked = selectedWebsites.some(selWeb => selWeb === web.id)
					const changedVariants = getChangedVariants(web);

					return changedVariants.length > 0 && (
						<Grid key={idx} container>
							<Grid key={idx} container className={`${classes.listGrid} ${idx % 2 === 0 ? classes.listGridEven : classes.listGridOdd}`} >
								<Grid item className={`${classes.listGridItem} ${classes.listGridSelect}`} >
									<Field
										name={`publish${idx}`}
										id={`publish${idx}`}
										checked={isWebsiteChecked}
										component={CheckboxWithName}
										onClick={(event: any) => { onChangeSelectWebsite(web.id, event.target.checked); }}
										label=""
									/>
								</Grid>
								<Grid item className={`${classes.listGridItem} ${classes.listGridName}`} onClick={() => toggleWebsite(web.id)}>
									{web.fallback_display_name}
								</Grid>
								<Grid item className={`${classes.listGridItem} ${classes.listGridStatus}`} onClick={() => toggleWebsite(web.id)}>
									Under change
								</Grid>
								<Grid item className={`${classes.listGridItem} ${classes.listGridIconPublish}`} onClick={() => toggleWebsite(web.id)}>
									<img src={'/images/icons/ChevronDown.svg'} className={websiteVariantsVisible.includes(web.id) ? classes.iconStyleRotate : classes.iconStyle} alt="" />
								</Grid>
							</Grid>
							<Grid container className={websiteVariantsVisible.includes(web.id) ? classes.show : classes.hide}>
								<Grid container className={classes.listGridHeadingVariant}>
									<Grid item className={`${classes.listGridItem} ${classes.listGridSelect}`} >
										<Field
											name="selectAllvariants"
											id="selectAllvariants"
											checked={(isWebsiteChecked && isAllVariantsSelected(web))}
											component={CheckboxWithName}
											onClick={(event: any) => { onChangeSelectAllVariants(web, event.target.checked); }}
											label=""
										/>
									</Grid>
									<Grid item className={`${classes.listGridItem} ${classes.listGridCountryVariant}`} >
										Country
									</Grid>
									<Grid item className={`${classes.listGridItem} ${classes.listGridLanguageVariant}`} >
										Language
									</Grid>
									<Grid item className={`${classes.listGridItem} ${classes.listGridNameVariant}`} >
										Name
									</Grid>
									<Grid item className={`${classes.listGridItem} ${classes.listGridNameVariant}`} >
										URL
									</Grid>
								</Grid>
								{getChangedVariants(web).map((variant, idx) => {

									const variantCriteria_country = (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.country")?.values[0] || '';
									const variantCriteria_language = (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.language")?.values[0] || '';
									const variantKey = `${web.id}_${variantCriteria_country || ''}_${variantCriteria_language}`;
									const countryName = locationAPICountries?.find(country => country.alpha2 === variantCriteria_country)?.name || ''
									const languageName = languages.find(lan => lan.id === variantCriteria_language)?.text || '';

									return (
										<Grid container key={idx} className={`${idx % 2 === 0 ? classes.listGridEven : classes.subListGridOdd}`}>
											<Grid item className={`${classes.listGridItem} ${classes.listGridSelect}`} >
												<Field
													name={`publish${idx}`}
													id={`publish${idx}`}
													checked={(isWebsiteChecked && selectedVariants.some(selVar => selVar === variantKey))}
													component={CheckboxWithName}
													onClick={(event: any) => { onChangeSelectVariant(web.id, variantKey, event.target.checked); }}
													label=""
												/>
											</Grid>
											<Grid item className={`${classes.listGridItem} ${classes.listGridCountryVariant}`} >
												{countryName}
											</Grid>
											<Grid item className={`${classes.listGridItem} ${classes.listGridLanguageVariant}`} >
												{languageName}
											</Grid>
											<Grid item className={`${classes.listGridItem} ${classes.listGridNameVariant}`} onClick={() => toggleVariants(`${web.id}_${countryName}_${languageName}`)}>
												{variant.display_name}
											</Grid>
											<Grid item className={`${classes.listGridItem} ${classes.listGridURLVariant}`} onClick={() => toggleVariants(`${web.id}_${countryName}_${languageName}`)}>
												{variant.url}
											</Grid>
											<Grid item className={`${classes.listGridItem} ${classes.listGridIconVariantsPublish}`} onClick={() => toggleVariants(`${web.id}_${countryName}_${languageName}`)}>
												<img src={'/images/icons/ChevronDown.svg'} className={websiteVariantsVisible.includes(`${web.id}_${countryName}_${languageName}`) ? classes.iconStyleRotate : classes.iconStyle} alt="" />
											</Grid>
											<Grid item className={`${classes.listGridItem} ${classes.listGridDescriptionVariant} ${websiteVariantsVisible.includes(`${web.id}_${countryName}_${languageName}`) ? classes.show : classes.hide}`} >
												{parse(variant.description || '')}
											</Grid>
										</Grid>
									)

								})}
							</Grid>
						</Grid>
					)
				})}
				{!isLoading && selectedVariants.length === 0 &&
					<Grid container >
						<Grid item className={`${classes.listGridItem}`} >
							No changes to publish
						</Grid>
					</Grid>
				}
				<Grid container >
					<Grid item className={`${classes.listGridItem} ${classes.listGridPublishButton}`} >
						{!isLoading && selectedVariants.length > 0 &&
							<SpinnerButton
								type="button"
								variant="contained"
								color="primary"
								pathToImagesFolder={'/images/icons'}
								spinnerVisible={isSaving}
								onClick={() => publishSelectedWebsites()}
							>
								Publish selected websites
							</SpinnerButton>
						}
					</Grid>
				</Grid>

			</form>
		</Main>
	);
}

export default reduxForm({
	form: 'publishWebsite'
})(WebsitePublish);
