import { action } from 'typesafe-actions';
import { AllApplicationTranslations, Application, ImportedApplicationTranslations } from '../../API/application';
import { LoadingStatus } from '../types';
import { ApplicationsActionTypes } from './types';

export interface GetApplications {
	type: ApplicationsActionTypes.GET_APPLICATIONS;
	payload: {
		accessToken: string;
	}
}

export interface GetApplicationsSucceed {
	type: ApplicationsActionTypes.GET_APPLICATIONS_SUCCEED;
	payload: Application[];
}

export interface GetApplicationTranslations {
	type: ApplicationsActionTypes.GET_APPLICATIONTRANSLATIONS;
}

export interface GetApplicationTranslationsSucceed {
	type: ApplicationsActionTypes.GET_APPLICATIONTRANSLATIONS_SUCCEED;
	payload: ImportedApplicationTranslations[];
}

export interface ApplicationsLoadingStatus {
	type: ApplicationsActionTypes.SET_APPLICATIONS_LOADING_STATUS;
	payload: LoadingStatus;
}

export interface ApplicationTranslationssLoadingStatus {
	type: ApplicationsActionTypes.SET_APPLICATIONTRANSLATIONS_LOADING_STATUS;
	payload: LoadingStatus;
}

export const GetApplicationsAction = (accessToken: string): GetApplications =>
	action(ApplicationsActionTypes.GET_APPLICATIONS, { accessToken });

export const SetApplicationsAction = (
	countries: Application[]
): GetApplicationsSucceed =>
	action(ApplicationsActionTypes.GET_APPLICATIONS_SUCCEED, countries);

export const GetApplicationTranslationsAction = (): GetApplicationTranslations =>
	action(ApplicationsActionTypes.GET_APPLICATIONTRANSLATIONS);

export const SetApplicationTranslationsAction = (
	translations: ImportedApplicationTranslations[]
): GetApplicationTranslationsSucceed =>
	action(ApplicationsActionTypes.GET_APPLICATIONTRANSLATIONS_SUCCEED, translations);

export const SetApplicationsLoadingStatus = (loadingStatus: LoadingStatus): ApplicationsLoadingStatus =>
	action(ApplicationsActionTypes.SET_APPLICATIONS_LOADING_STATUS, loadingStatus);

export const SetApplicationTranslationsLoadingStatus = (loadingStatus: LoadingStatus): ApplicationTranslationssLoadingStatus =>
	action(ApplicationsActionTypes.SET_APPLICATIONTRANSLATIONS_LOADING_STATUS, loadingStatus);


export type ApplicationsActionType =
	| GetApplicationTranslationsSucceed
	| GetApplicationTranslations
	| GetApplicationsSucceed
	| GetApplications
	| ApplicationsLoadingStatus
	| ApplicationTranslationssLoadingStatus;
