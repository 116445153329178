import React from 'react';
import { Grid } from '@material-ui/core';
import settings from '../../Config/settings';
import { useStyles } from './styles';
import { FooterProps } from './types'
import { Link as RouterLink, useLocation } from 'react-router-dom';


const Footer: React.FC<FooterProps> = ({contentClass}) => {
    const classes = useStyles();
   
    return (
        <Grid container className={classes.black}>
            <Grid container className={classes.root}>
                <Grid container className={(contentClass || classes.content) && `${classes.padding}`}>
                    <div className={classes.item}>
                        <a href={`${settings.terms.endpoint}/PrivacyPolicy?culture=en`} target="_blank" rel="noopener noreferrer" className={classes.link} data-testid="privacy_policy">
                            Privacy policy
                        </a>
                    </div>
                    <div className={classes.item}>
                        <a href={`${settings.terms.endpoint}/TermsAndConditions?culture=en&appId=my-danfoss-maintenance-dashboard`} target="_blank" rel="noopener noreferrer" className={classes.link} data-testid="terms_of_use">
                            Terms of use
                        </a>
                    </div>
                    <div className={classes.item}>
                        <a href={settings.generalInformation.endpoint} target="_blank" rel="noopener noreferrer" className={classes.link}>
                            General information
                        </a>
                    </div>
                    <div className={classes.item}>
                        <RouterLink to="/contactinfo" className={classes.link}>Contact information</RouterLink>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Footer;

