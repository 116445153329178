import fetchApiWrapper from './fetchAPIWrapper';
import settings from '../Config/settings';

interface Response {
    ok: boolean
}


export interface UserRolesAPI {
    roles: string[];
}

export interface UserRolesInfoAPI {
    user_roles: string[];
    danfoss_identity_id: string,
    user_name?: string;
    user_email?: string;
    user_phonenumber?: string;
}

export interface ApplicationUserRolesAPI {
    client_id: string;
    users: UserRolesInfoAPI[]
}

export interface ApplicationsUserRoles {
    user_roles: ApplicationUserRolesAPI[];
}

export interface ApplicationUserRoles {
    client_id: string;
    users: UserRolesInfoAPI[]
}

export const getAdminRole = (accessToken: string): Promise<string> => {

    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper<{ is_success: boolean }>(settings.myDanfossApi.endpoint + `/admin-actions/is-user-admin`, {
        headers: requestHeaders,
        method: 'GET',
    })
    .then((result) => {
        return result.is_success && 'DashboardAdmin' || '';
    });

}

export const getUsersRolesPerApplication = (identity_id: string, accessToken: string): Promise<ApplicationsUserRoles> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper<ApplicationsUserRoles>(settings.myDanfossApi.endpoint + `/admin-actions/user-roles?danfoss_identity_id=${identity_id}`, {
        headers: requestHeaders,
        method: 'GET',
    });
}

export const getRolesForUser = (identity_id: string, accessToken: string): Promise<UserRolesAPI> => {
    return Promise.allSettled([
        getUsersRolesPerApplication(identity_id, accessToken),
        getAdminRole(accessToken)])
        .then((results) => {
            const allRoles: string[] = [];
            if (results[0].status === "fulfilled" && results[0].value) {
                const rolesPerAppl = results[0].value;
                rolesPerAppl.user_roles.forEach(app => {
                    app.users
                        .filter(user => user.danfoss_identity_id === identity_id)
                        .forEach(user => {
                            user.user_roles
                                .forEach(user_role => {
                                    if (!allRoles.includes(user_role)) {
                                        allRoles.push(user_role);
                                    }
                                })
                        })
                });
            }
            if (results[1].status === "fulfilled" && results[1].value) {
                const adminRole = results[1].value;
                if (adminRole) {
                    allRoles.push(adminRole);
                }
            }
            return { roles: allRoles};
        });
}

export const getUserRolesForApplication = (accessToken: string, client_id: string): Promise<ApplicationUserRolesAPI[]> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper<ApplicationsUserRoles>(settings.myDanfossApi.endpoint + `/admin-actions/user-roles?clientId=${client_id}&client_id=${client_id}`, {
        headers: requestHeaders,
        method: 'GET',
    })
        .then((result) => {
            return result.user_roles.filter(app => app.client_id === client_id)
        });

}

export const getUserRolesForApplications = (accessToken: string, client_ids: string[]): Promise<ApplicationUserRolesAPI[]> => {
    const user_roles: ApplicationUserRoles[] = [];
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return Promise.allSettled(client_ids.map(async (client_id: string) => {
        return getUserRolesForApplication(accessToken, client_id);
    })).then((results) => {
        results.forEach(result => {
            if (result.status === "fulfilled" && result.value) {
                user_roles.push(...result.value);
            }
        })
        return Promise.resolve(user_roles);
    })

}

export const getAllUserRoles = (accessToken: string): Promise<ApplicationUserRolesAPI[]> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper<ApplicationsUserRoles>(settings.myDanfossApi.endpoint + `/admin-actions/user-roles`, {
        headers: requestHeaders,
        method: 'GET',
    })
        .then((result) => {
            return result.user_roles
        });

}

export const updateApplicationUserRoles = (accessToken: string, applicationUserRoles: ApplicationUserRoles): Promise<Response> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper(`${settings.myDanfossApi.endpoint}/admin-actions/user-roles`, {
        body: JSON.stringify(applicationUserRoles),
        headers: requestHeaders,
        method: 'PUT',
    });
}

