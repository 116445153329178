import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../Store';
import { ApplicationErrors, ImportErrorsPopupProps, ImportProgress } from './types';
import { useStyles } from './styles';
import { Button, Grid } from '@material-ui/core';
import ModalPopup from '../Modal/ModalPopup';
import parse from 'html-react-parser';

const scrollToTop = () => {
    (document.getElementById("mainContent") || {} as HTMLElement).scrollTop = 0;
    document.documentElement.scrollTop = 0;
}

const ImportErrorsPopup: React.FC<ImportErrorsPopupProps> = ({ importTypeProgress, errors, generalError, onCancelImport }) => {
    const appState = useSelector((state: AppState) => state);
    const classes = useStyles();
    if (importTypeProgress === ImportProgress.HandleErrors) {
        scrollToTop()
        return (
            <Grid container className={classes.confirmPopupContainer}>
                <ModalPopup paperClassName={classes.confirmPopup}>
                    <div className={classes.bannerContainer}>
                        <Grid container alignItems="flex-start" direction="row">
                            <Grid className={classes.confirmPopupHeader} >
                                Errors in imported file
                            </Grid>
                        </Grid>
                        <Grid container alignItems="flex-start" direction="row">
                            {generalError &&
                                <Grid item className={classes.errorList}>
                                    <span>{parse(generalError)}</span>
                                </Grid>
                            }
                            {!generalError &&
                                <Grid item className={classes.errorList}>
                                    The imported file contains the following errors:
                                    <ul>
                                        {errors.map((appError, idx) => (
                                            <li key={idx}>
                                                {`Errors in application ${appError.client_name}`}
                                                <ul>
                                                    {appError.errors.map((error, errorIdx) => (
                                                        <li key={errorIdx}>{error}</li>
                                                    ))}
                                                </ul>
                                            </li>
                                        ))}
                                    </ul>
                                </Grid>
                            }
                        </Grid>
                        <Grid container alignItems="flex-start" direction="row" className={classes.confirmButtons}>
                            <Grid item>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    color="default"
                                    onClick={() => { onCancelImport(); }}
                                    className={classes.button}
                                >
                                    Cancel import
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </ModalPopup>
            </Grid>
        )
    }
    else {
        return null;
    }
}

export default ImportErrorsPopup;
