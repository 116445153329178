import React from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from './styles';
import { EditProfileProps, FormValues, RenderFormFieldsProps } from './types'
import { asyncValidate } from './validation'
import { reduxForm, Field, InjectedFormProps, getFormValues } from 'redux-form';
import { LabeledMultiSelectbox, LabeledTextboxUncontrolled, LabeledSelectbox } from '../../RenderFields/RenderFields';
import { SpinnerButton } from '@danfoss/webex-ui/dist/mui';
import Button from '@material-ui/core/Button';
import { AppState } from '../../../Store';
import { connect, useSelector } from 'react-redux';
import { getApplicationsValues, getBooleanValues, getCountryValues, getLanguageValues, getReferenceValues } from '../../Application/Criteria/utils';
import { PreviewProfileModel } from '../../../API/users';

const FORMID = 'previewProfile';

const RenderFormFields: React.FC<EditProfileProps & InjectedFormProps<{}> & RenderFormFieldsProps> = (props) => {
	const classes = useStyles();
	const { values } = props;
	const references = useSelector((state: AppState) => state.references);
	const location = useSelector((state: AppState) => state.location);
	const applications = useSelector((state: AppState) => state.applications);

	return (
		<div className={classes.formFields} >
			<Grid container className={`${classes.editProfileGrid}`} >
				<Grid item className={`${classes.editProfileGridItem} `} >
					<Field
						id="profile_name"
						name="profile_name"
						label="Profile name"
						component={LabeledTextboxUncontrolled}
					/>
				</Grid>
			</Grid>
			<Grid container className={`${classes.editProfileGrid}`} >
				<Grid item className={`${classes.editProfileGridItem} `} >
					<Field
						id="used_applications"
						name="used_applications"
						label="Used applications"
						component={LabeledMultiSelectbox}
						defaultValue={values.used_applications || []}
						multiple={true}
						options={getApplicationsValues(applications)}
					/>
				</Grid>
			</Grid>
			<Grid container className={`${classes.editProfileGrid}`} >
				<Grid item className={`${classes.editProfileGridItem} `} >
					<Field
						id="areas_of_interest"
						name="areas_of_interest"
						label="Areas of interest"
						component={LabeledMultiSelectbox}
						defaultValue={values.areas_of_interest || []}
						multiple={true}
						options={getReferenceValues(references, 'areasOfInterest')}
					/>
				</Grid>
			</Grid>
			<Grid container className={`${classes.editProfileGrid}`} >
				<Grid item className={`${classes.editProfileGridItem} `} >
					<Field
						id="company_type"
						name="company_type"
						label="Company type"
						component={LabeledSelectbox}
						defaultValue={values.company_type}
						options={getReferenceValues(references, 'companyTypes')}
					/>
				</Grid>
			</Grid>
			<Grid container className={`${classes.editProfileGrid}`} >
				<Grid item className={`${classes.editProfileGridItem} `} >
					<Field
						id="department"
						name="department"
						label="Department"
						component={LabeledSelectbox}
						defaultValue={values.department}
						options={getReferenceValues(references, 'departments')}
					/>
				</Grid>
			</Grid>
			<Grid container className={`${classes.editProfileGrid}`} >
				<Grid item className={`${classes.editProfileGridItem} `} >
					<Field
						id="country"
						name="country"
						label="Country"
						component={LabeledSelectbox}
						defaultValue={values.country}
						options={getCountryValues(location)}
					/>
				</Grid>
			</Grid>
			<Grid container className={`${classes.editProfileGrid}`} >
				<Grid item className={`${classes.editProfileGridItem} `} >
					<Field
						id="language"
						name="language"
						label="Language"
						component={LabeledSelectbox}
						defaultValue={values.language}
						options={getLanguageValues()}
					/>
				</Grid>
			</Grid>
			<Grid container className={`${classes.editProfileGrid}`} >
				<Grid item className={`${classes.editProfileGridItem} `} >
					<Field
						id="is_workforce"
						name="is_workforce"
						label="Is Workforce"
						component={LabeledSelectbox}
						defaultValue={values.is_workforce}
						options={getBooleanValues()}
						emptyOption={false}
					/>
				</Grid>
			</Grid>
		</div>
	)
}

const FormFields = connect((state: AppState) => ({
	values: getFormValues(FORMID)(state) as FormValues
}))(RenderFormFields);


const EditProfile: React.FC<EditProfileProps & InjectedFormProps<{}>> = (props) => {
	const classes = useStyles();
	const { profile, cancel, updateUserProfile, handleSubmit, submitting } = props;

	const submitForm = (values: any) => {
		const updatedProfile: PreviewProfileModel = {
			danfoss_identity_id: profile.danfoss_identity_id,
			profile_name: values.profile_name,
			area_of_interest: values.areas_of_interest && values.areas_of_interest.length > 0 && {id: values.areas_of_interest[0], text: ''},
			areas_of_interest: values.areas_of_interest && values.areas_of_interest.map((aoi: string) => ({id: aoi, text: ''})) || [],
			department: values.department && {id: values.department, text: ''} || undefined,
			company_type: values.company_type && {id: values.company_type, text: ''}  || undefined,
			language: values.language && {id: values.language, text: ''} || undefined,
			country: values.country && {id: values.country, text: ''} || undefined,
			identification: {is_workforce: values.is_workforce === 'True' && true || false},
			used_applications: values.used_applications && values.used_applications.map((ua: string) => ({app_id: ua, name: ''})) || []
		}
		updateUserProfile(updatedProfile);
	}

	return (
		<form onSubmit={handleSubmit(submitForm)} id={FORMID}>
			<Grid item>
				<Grid container className={`${classes.editProfileGrid}`} >
					<FormFields {...props} />
					<Grid item className={`${classes.editProfileGridItem} `} >
						<SpinnerButton
							type="submit"
							variant="contained"
							color="primary"
							className={classes.button}
							pathToImagesFolder={'/images/icons'}
							spinnerVisible={submitting}
						>
							Save
						</SpinnerButton>
						<Button
							type="button"
							variant="outlined"
							color="default"
							className={classes.button}
							onClick={(e) => { e.preventDefault(); cancel() }}
						>
							Cancel
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</form>
	);
};

function mapStateToProps(state: AppState, ownProps: EditProfileProps) {
	const { profile } = ownProps;

	return {
		initialValues: {
			profile_name: profile.profile_name,
			language: profile.language?.id,
			country: profile.country?.id,
			areas_of_interest: profile.areas_of_interest?.length === 0 && profile.area_of_interest ? [profile.area_of_interest.id] : profile.areas_of_interest?.map(area => area.id), 
			company_type: profile.company_type?.id,
			department: profile.department?.id,
			is_workforce: profile.identification?.is_workforce && 'True' || 'False',
			used_applications: profile.used_applications?.map(usedApp => usedApp.app_id)
		}
	}
}

const EditProfileReduxForm = reduxForm({
	form: FORMID,
	asyncValidate,
	asyncBlurFields: ['profileName'],
	asyncChangeFields: ['profileName'],
	shouldAsyncValidate: () => true
})(EditProfile)

export default connect(mapStateToProps)(EditProfileReduxForm);

