import { Store, createStore, applyMiddleware, compose, Reducer, AnyAction } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { FormStateMap } from 'redux-form';
import rootSaga from './sagas';

import { AppState, createRootReducer } from './';
import { UserRoles } from "./Roles/types";
import { ReferencesAppStateModel } from "./References/types";
import { LocationAppStateModel } from "./Location/types";
import { ApplicationsAppStateModel } from "./Applications/types";
import { CurrentUser } from './User/types';
import { Contacts } from './Contacts/types';

export default function configureStore(): Store<AppState> {
	const composeEnhancers =
		(window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const sagaMiddleware = createSagaMiddleware();

	const reducer = compose(
	)(createRootReducer()) as Reducer<{
		form: FormStateMap,
		user: CurrentUser,
		userRoles: UserRoles,
		references: ReferencesAppStateModel,
		location: LocationAppStateModel,
		applications: ApplicationsAppStateModel
		contacts: Contacts
  }, AnyAction>;

	const store = createStore(
		reducer,
		composeEnhancers(applyMiddleware(sagaMiddleware))
	);

  sagaMiddleware.run(rootSaga);


  return store;
}
