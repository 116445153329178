import React, { useState, useEffect } from 'react';
import Main from '../../Main';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './styles';
import { useLocation } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import { CheckboxWithName } from '../../RenderFields/RenderFields';
import { SpinnerButton } from '@danfoss/webex-ui/dist/mui';
import { Spinner } from '@danfoss/webex-ui';
import { useAuth0 } from '@auth0/auth0-react';
import settings from '../../../Config/settings';
import { getRelevantWebsites, RelevantWebsite, updateRelevantWebsite, RelevantWebsiteCriteria, RelevantWebsites, RelevantWebsiteVariant } from '../../../API/relevantWebsite';
import { useSelector } from 'react-redux';
import { AppState } from '../../../Store';
import { languages } from '../../../Constants';
import queryString from 'query-string';
import { BreadCrumbs } from '../../BreadCrumbs/types';
import ModalPopup from '../../Modal/ModalPopup';
import Button from '@material-ui/core/Button';
import { prepareForCompare } from '../../../Utils';

const WebsiteUnpublish: React.FC = () => {
    const classes = useStyles();
    const location = useLocation();
    const locationAPICountries = useSelector((state: AppState) => state.location.countries);
    const { getAccessTokenSilently } = useAuth0();
    const [relevantWebsites, setRelevantWebsites] = useState({} as RelevantWebsites);
    const [selectedRelevantWebsites, setSelectedRelevantWebsites] = useState({} as RelevantWebsites);
    const [isLoading, setIsLoading] = useState(true);
    const [isUnpublishing, setIsUnpublishing] = useState(false);
    const [selectedWebsites, setSelectedWebsites] = useState([] as string[]);
    const [selectedVariants, setSelectedVariants] = useState([] as string[]);
    const [websiteVariantsVisible, setWebsiteVariantsVisible] = useState([] as string[]);
    const [websitesVisible, setWebsitesVisible] = useState([] as string[]);
    const [showCheckboxes, setShowCheckboxes] = useState(true);
    const [breadCrumbs, setBreadCrumbs] = useState({} as BreadCrumbs);
    const [showConfirmUnpublish, setShowConfirmUnpublish] = useState(false);

    //Get querystring parameters
    const parsed = queryString.parse(window.location.search);
    const id = (parsed && parsed.id) as string

    useEffect(() => {

        let relevantWeb = {} as RelevantWebsites;

        if (!isUnpublishing) {
            const getRelWebsites = async () => {
                const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
                relevantWeb = await getRelevantWebsites(accessTokenMyDanfossApi);

                setRelevantWebsites(relevantWeb);

                if (id) {
                    if (location.pathname === "/website/overview") {
                        if (relevantWeb.relevant_websites.filter(relWeb => relWeb.id === id).length > 1) {
                            setSelectedRelevantWebsites({relevant_websites : relevantWeb.relevant_websites.filter(relWeb => relWeb.id === id && !relWeb.published)})
                        } else {
                            setSelectedRelevantWebsites({relevant_websites : relevantWeb.relevant_websites.filter(relWeb => relWeb.id === id)})
                        }
                        
                    } else {
                        setSelectedRelevantWebsites({relevant_websites : relevantWeb.relevant_websites.filter(relWeb => relWeb.id === id && relWeb.published)})
                    }
                    
                } else {
                    setSelectedRelevantWebsites({relevant_websites: relevantWeb.relevant_websites.filter(relWeb => relWeb.published)})
                }
                
                setIsLoading(false);
            }

            getRelWebsites();
        }

        

    }, [isUnpublishing])

    useEffect(() => {
        let websiteName = "";

        if (id && relevantWebsites.relevant_websites) {
            websiteName = relevantWebsites.relevant_websites.find(relWeb => relWeb.id === id)?.name || '';
        }

        if (location.pathname === "/website/overview") {
            setShowCheckboxes(false);
            setWebsitesVisible([id]);
            setBreadCrumbs({ items: [{ text: websiteName, link: `/website?id=${id}` }, { text: 'Relevant website translation overview', link: location.pathname }] })
        } else {
            setBreadCrumbs({ items: [(id ? { text: websiteName, link: `/website?id=${id}` } : { text: 'publish overview', link: '/publish' }), { text: `Unpublish relevant website translations`, link: location.pathname }] });
        }

    }, [relevantWebsites])

    const getWebsiteDraftVariants = (publishedWebsite: RelevantWebsite) => {
        const draftRelevantWebsite = relevantWebsites.relevant_websites.find(relWeb => relWeb.id === publishedWebsite.id && !relWeb.published);

        if (draftRelevantWebsite) {
            const changedVariants = [] as RelevantWebsiteVariant[];
            
            draftRelevantWebsite.variants.forEach(variant => {

                let variantFound = false;

                publishedWebsite.variants.map(pubVar => {
                    if (JSON.stringify(pubVar.criteria) === JSON.stringify(variant.criteria)) {
                        variantFound = true;

                        if (prepareForCompare(pubVar) !== prepareForCompare(variant)) {
                            changedVariants.push(variant);
                        }
                    }
                })

                if (!variantFound) {
                    changedVariants.push(variant);
                }
            })

            return changedVariants;            
        }

        return [] as RelevantWebsiteVariant[];
    }

    const unpublishSelectedTranslations = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsUnpublishing(true);


        const unPublishWebsite = async () => {

            const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);

            // Loop websites
            selectedRelevantWebsites.relevant_websites.forEach((website: RelevantWebsite) => {

                // Only unpublish selected checked
                if (selectedWebsites.includes(website.id)) {

                    let changedVariants = getVariants(website);

                    
                    let variantsToUnPublish = [] as RelevantWebsiteVariant[];
                    let variantsNotToUnPublish = [] as RelevantWebsiteVariant[];

                    // Loop variants and sort efter checked or not
                    changedVariants.map((variant, idx) => {

                        const variantCriteria_country = (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.country")?.values[0] || '';
                        const variantCriteria_language = (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.language")?.values[0] || '';
                        const variantKey = `${website.id}_${variantCriteria_country}_${variantCriteria_language}`;

                        if (selectedVariants.includes(variantKey)) {
                            if (variantCriteria_country !== '' || variantCriteria_language !== '') {
                                variantsToUnPublish.push(variant);
                            }
                        } else {
                            variantsNotToUnPublish.push(variant);
                        }

                    })

                    let websiteWithVariantsNotToUnPublish = { ...website, variants: variantsNotToUnPublish };

                    const draftVariants = getWebsiteDraftVariants(website); 

                    updateRelevantWebsite(website.id, websiteWithVariantsNotToUnPublish, accessTokenMyDanfossApi, false).then(resp => {

                        // Since saving a published website will delete any draft previous saved, we need to add that one again
                        const saveDraftRelevantWebsites = async () => {
                            await updateRelevantWebsite(website.id, { ...website, variants: [...websiteWithVariantsNotToUnPublish.variants, ...draftVariants] }, accessTokenMyDanfossApi, true);
						}

						saveDraftRelevantWebsites().then(resp => {
							setIsUnpublishing(false);
                            setShowConfirmUnpublish(false);
                            setSelectedWebsites([]);
                            setSelectedVariants([]);
						});
                    });
                }

            });
        }

        unPublishWebsite();

    }

    const onChangeSelectAll = (checked: boolean) => {

        if (checked) {
            let variantIds = [] as string[];

            selectedRelevantWebsites.relevant_websites.map(web => {

                const changedVariants = getVariants(web)

                changedVariants.forEach(variant => {
                    const variantCriteria_country = (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.country")?.values[0] || '';
                    const variantCriteria_language = (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.language")?.values[0] || '';
                    variantIds.push(`${web.id}_${variantCriteria_country}_${variantCriteria_language}`);
                })

                setSelectedWebsites([...selectedWebsites, web.id]);
            });
    
            setSelectedVariants(variantIds);
        }
        else {
            setSelectedWebsites([]);
            setSelectedVariants([]);
        }
    }

    const onChangeSelectAllVariants = (website: RelevantWebsite, checked: boolean) => {
        if (checked) {

            const changedVariants = getVariants(website)

            let variantIds = [] as string[];

            changedVariants.forEach(variant => {
                const variantCriteria_country = (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.country")?.values[0] || '';
                const variantCriteria_language = (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.language")?.values[0] || '';

                if (selectedVariants.indexOf(`${website.id}_${variantCriteria_country}_${variantCriteria_language}`) === -1) {
                    variantIds.push(`${website.id}_${variantCriteria_country}_${variantCriteria_language}`);
                }
            })

            setSelectedVariants(selectedVariants.concat(variantIds));

            if (selectedWebsites.indexOf(website.id) === -1) {
                setSelectedWebsites([...selectedWebsites, website.id]);
            };
        }
        else {
            setSelectedVariants(selectedVariants.filter(sv => !sv.startsWith(website.id)));
        }
    }

    const onChangeSelectWebsite = (websiteId: string, checked: boolean) => {

        if (selectedWebsites.indexOf(websiteId) > -1) {
            if (!checked) {
                setSelectedWebsites(selectedWebsites.filter(row => row !== websiteId));
                setSelectedVariants(selectedVariants.filter(selvar => !selvar.startsWith(websiteId)));
            }
        }
        else {
            if (checked) {
                setSelectedWebsites([...selectedWebsites, websiteId]);
                let variants = [] as string[];
                const website = relevantWebsites.relevant_websites.find(web => web.id === websiteId);

                const changedVariants = getVariants(website || {} as RelevantWebsite)

                changedVariants.forEach(variant => {

                    const variantCriteria_country = (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.country")?.values[0] || '';
                    const variantCriteria_language = (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.language")?.values[0] || '';
                    variants.push(`${websiteId}_${variantCriteria_country}_${variantCriteria_language}`);
                })

                setSelectedVariants(selectedVariants.concat(variants));

            }
        }
    }

    const onChangeSelectVariant = (websiteId: string, variantId: string, checked: boolean) => {

        if (selectedVariants.indexOf(variantId) > -1) {
            if (!checked) {
                setSelectedVariants(selectedVariants.filter(row => row !== variantId));
            }
        }
        else {
            if (checked) {
                setSelectedVariants([...selectedVariants, variantId]);

                if (selectedWebsites.indexOf(websiteId) === -1) {
                    setSelectedWebsites([...selectedWebsites, websiteId]);
                };
            }
        }
    }

    const isAllVariantsSelected = (website: RelevantWebsite) => {
        const changedVariantsLength = getVariants(website).length;
        let count = 0;

        selectedVariants.forEach(variant => {
            if (variant.startsWith(`${website.id}_`)) {
                count++;
            }
        })

        if (count === changedVariantsLength) {
            return true;
        }

        return false;
    }

    const toggleWebsite = (id: string) => {

        if (!websitesVisible.includes(id)) {
            setWebsitesVisible([...websitesVisible, id]);
        } else {
            setWebsitesVisible(websitesVisible.filter((variant: string) => variant !== id));
        }

        return;
    }

    const toggleVariants = (id: string) => {

        if (!websiteVariantsVisible.includes(id)) {
            setWebsiteVariantsVisible([...websiteVariantsVisible, id]);
        } else {
            setWebsiteVariantsVisible(websiteVariantsVisible.filter((variant: string) => variant !== id));
        }

        return;
    }

    const getVariants = (website: RelevantWebsite) => {
        
        let variants = [] as RelevantWebsiteVariant[];

        website.variants.forEach(variant => {
            variants.push(variant);
        })

        return variants;
    }

    const getVariantStatus = (relevantSite: RelevantWebsite, country: string, language: string) => {

        const draftRelWeb = relevantWebsites.relevant_websites.find(relWeb => relWeb.id === relevantSite.id && !relWeb.published)
        const publishedRelWeb = relevantWebsites.relevant_websites.find(relWeb => relWeb.id === relevantSite.id && relWeb.published)

        let publishedVersion:RelevantWebsiteVariant | undefined = undefined;
        let draftVersion: RelevantWebsiteVariant | undefined = undefined;

        if (language !== "" && country !== "") {
            publishedVersion = publishedRelWeb?.variants.filter(variant => ((variant.criteria[0] as RelevantWebsiteCriteria[]).length === 2)).find(variant => ((variant.criteria[0] as RelevantWebsiteCriteria[])[0].path === "user.user_metadata.country" && (variant.criteria[0] as RelevantWebsiteCriteria[])[0].values.includes(country)) && ((variant.criteria[0] as RelevantWebsiteCriteria[])[1].path === "user.user_metadata.language" && (variant.criteria[0] as RelevantWebsiteCriteria[])[1].values.includes(language)));
            //draftVersion = draftRelWeb?.variants.find(variant => (variant.criteria[0] as RelevantWebsiteCriteria[]).filter((wvc: RelevantWebsiteCriteria) => (wvc.path === "user.user_metadata.country" && wvc.values.includes(country)) || (wvc.path === "user.user_metadata.language" && wvc.values.includes(language))).length > 0);
            draftVersion = draftRelWeb?.variants.filter(variant => ((variant.criteria[0] as RelevantWebsiteCriteria[]).length === 2)).find(variant => ((variant.criteria[0] as RelevantWebsiteCriteria[])[0].path === "user.user_metadata.country" && (variant.criteria[0] as RelevantWebsiteCriteria[])[0].values.includes(country)) && ((variant.criteria[0] as RelevantWebsiteCriteria[])[1].path === "user.user_metadata.language" && (variant.criteria[0] as RelevantWebsiteCriteria[])[1].values.includes(language)));
        } else if (language === "" && country !== "") {
            publishedVersion = publishedRelWeb?.variants.filter(variant => ((variant.criteria[0] as RelevantWebsiteCriteria[]).length === 1)).find(variant => (variant.criteria[0] as RelevantWebsiteCriteria[]).filter((wvc: RelevantWebsiteCriteria) => (wvc.path === "user.user_metadata.country" && wvc.values.includes(country))).length > 0);
            draftVersion = draftRelWeb?.variants.filter(variant => ((variant.criteria[0] as RelevantWebsiteCriteria[]).length === 1)).find(variant => (variant.criteria[0] as RelevantWebsiteCriteria[]).filter((wvc: RelevantWebsiteCriteria) => (wvc.path === "user.user_metadata.country" && wvc.values.includes(country))).length > 0);
        } else if (language !== "" && country === "") {
            publishedVersion = publishedRelWeb?.variants.filter(variant => ((variant.criteria[0] as RelevantWebsiteCriteria[]).length === 1)).find(variant => (variant.criteria[0] as RelevantWebsiteCriteria[]).filter((wvc: RelevantWebsiteCriteria) => (wvc.path === "user.user_metadata.language" && wvc.values.includes(language))).length > 0);
            draftVersion = draftRelWeb?.variants.filter(variant => ((variant.criteria[0] as RelevantWebsiteCriteria[]).length === 1)).find(variant => (variant.criteria[0] as RelevantWebsiteCriteria[]).filter((wvc: RelevantWebsiteCriteria) => (wvc.path === "user.user_metadata.language" && wvc.values.includes(language))).length > 0);
        }

        if (publishedVersion && draftVersion) {
            if (prepareForCompare(publishedVersion) !== prepareForCompare(draftVersion)) {
                return "Under change";
            } else {
                return "Published";
            }
        } else if (draftVersion && !publishedVersion) {
            return "New";
        } else {
            return "Published"
        }
    } 

    if (showConfirmUnpublish) {
		(document.getElementById("mainContent") || {} as HTMLElement).scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

    return isLoading ? (<Spinner visible={isLoading} />) : (
        <Main breadCrumbs={breadCrumbs}>
            {showConfirmUnpublish &&
                <Grid container className={classes.confirmPopupContainer}>
                    <ModalPopup paperClassName={classes.confirmPopup}>
                        <div className={classes.bannerContainer}>
                            <Grid container alignItems="flex-start" direction="row">
                                <Grid className={classes.confirmPopupHeader} >
                                    Remove website translations
                                </Grid>
                            </Grid>
                            <Grid container alignItems="flex-start" justifyContent="flex-start" direction="row">
                                Do you really want to remove website translations for these country/language combinations?
                            </Grid>
                            <Grid container alignItems="flex-start" direction="row" className={classes.websContainer}>
                                {selectedRelevantWebsites.relevant_websites.map((selectedWeb, idx) => {
                                    return (
                                        <Grid container key={`warning_${selectedWeb}`}>
                                            <Grid container>
                                                {selectedWeb.name}
                                            </Grid>
                                            <Grid container>
                                                <ul>
                                                    {selectedVariants.filter(sel => sel.substring(0, sel.indexOf("_")) === selectedWeb.id).map((selection, idx) => {

                                                        const countrylanguage = selection.replace(`${selectedWeb.id}_`, '');
                                                        const countryCode = countrylanguage.substring(0,countrylanguage.indexOf("_"));
                                                        const countryName = locationAPICountries?.find(country => country.alpha2 === countryCode)?.name || ''
                                                        const languageCode = countrylanguage.replace(`${countryCode}_`, '');
                                                        const language = languages.find(lan => lan.id === languageCode)?.text || '';

                                                        return <li key={idx}>{`${countryName && (countryName+' ')} ${language && language}`}</li>
                                                    })}
                                                </ul>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                            <Grid container alignItems="flex-start" direction="row" className={classes.confirmButtons}>
                                <Grid item>
                                    <SpinnerButton
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        pathToImagesFolder={'/images/icons'}
                                        spinnerVisible={isUnpublishing}
                                        className={classes.buttonLeft}
                                        onClick={(e: any) => unpublishSelectedTranslations(e)}
                                    >
                                        Yes, remove them
                                    </SpinnerButton>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        color="default"
                                        onClick={() => { setSelectedVariants([]); setSelectedWebsites([]); setShowConfirmUnpublish(false); }}
                                        className={classes.button}
                                    >
                                        No, leave them
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </ModalPopup>
                </Grid>
            }
            <form id="publishWebsite" className={classes.websiteForm}>
                <Grid container className={classes.listGridHeading}>
                    <Grid item className={`${classes.listGridItem} ${classes.listGridSelect} ${!showCheckboxes && classes.hide}`} >
                        <Field
                            name="selectAll"
                            id="selectAll"
                            checked={selectedRelevantWebsites.relevant_websites.every(web => selectedWebsites.some(selWeb => selWeb === web.id))}
                            component={CheckboxWithName}
                            onClick={(event: any) => { onChangeSelectAll(event.target.checked); }}
                            label=""
                        />
                    </Grid>
                    <Grid item className={`${classes.listGridItem} ${classes.listGridName}`} >
                        Website name
                    </Grid>
                    <Grid item className={`${classes.listGridItem} ${classes.listGridStatus}`} >
                        
                    </Grid>
                </Grid>
                {selectedRelevantWebsites.relevant_websites.map((web, idx) => {

                    const isWebsiteChecked = selectedWebsites.some(selWeb => selWeb === web.id)
                    
                    return (
                        <Grid key={idx} container>
                            <Grid key={idx} container className={`${classes.listGrid} ${idx % 2 === 0 ? classes.listGridEven : classes.listGridOdd}`} >
                                <Grid item className={`${classes.listGridItem} ${classes.listGridSelect} ${!showCheckboxes && classes.hide}`} >
                                    <Field
                                        name={`publish${idx}`}
                                        id={`publish${idx}`}
                                        checked={isWebsiteChecked}
                                        component={CheckboxWithName}
                                        onClick={(event: any) => { onChangeSelectWebsite(web.id, event.target.checked); }}
                                        label=""
                                    />
                                </Grid>
                                <Grid item className={`${classes.listGridItem} ${classes.listGridName}`} onClick={() => toggleWebsite(web.id)}>
                                    {web.fallback_display_name}
                                </Grid>
                                <Grid item className={`${classes.listGridItem} ${classes.listGridStatus}`} onClick={() => toggleWebsite(web.id)}>
                                </Grid>
                                <Grid item className={`${classes.listGridItem} ${!showCheckboxes ? classes.listGridIcon : classes.listGridIconPublish}`} onClick={() => toggleWebsite(web.id)}>
                                    <img src={'/images/icons/ChevronDown.svg'} className={websitesVisible.includes(web.id) ? classes.iconStyleRotate : classes.iconStyle} alt="" />
                                </Grid>
                            </Grid>
                            <Grid container className={websitesVisible.includes(web.id) ? classes.show : classes.hide}>
                                <Grid container className={classes.listGridHeadingVariant}>
                                    <Grid item className={`${classes.listGridItem} ${classes.listGridSelect} ${!showCheckboxes && classes.hide}`} >
                                        <Field
                                            name="selectAllvariants"
                                            id="selectAllvariants"
                                            checked={(isWebsiteChecked && isAllVariantsSelected(web))}
                                            component={CheckboxWithName}
                                            onClick={(event: any) => { onChangeSelectAllVariants(web, event.target.checked); }}
                                            label=""
                                        />
                                    </Grid>
                                    <Grid item className={`${classes.listGridItem} ${classes.listGridCountryVariant}`} >
                                        Country
                                    </Grid>
                                    <Grid item className={`${classes.listGridItem} ${classes.listGridLanguageVariant}`} >
                                        Language
                                    </Grid>
                                    <Grid item className={`${classes.listGridItem} ${classes.listGridNameVariant}`} >
                                        Name
                                    </Grid>
                                    <Grid item className={`${classes.listGridItem} ${classes.listGridNameVariant}`} >
                                        Status
                                    </Grid>
                                </Grid>
                                {web.variants.map((variant, idx) => {

                                    const variantCriteria_country = (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.country")?.values[0] || '';
                                    const variantCriteria_language = (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.language")?.values[0] || '';
                                    const variantKey = `${web.id}_${variantCriteria_country || ''}_${variantCriteria_language}`;
                                    const countryName = locationAPICountries?.find(country => country.alpha2 === variantCriteria_country)?.name || ''
                                    const languageName = languages.find(lan => lan.id === variantCriteria_language)?.text || '';
                                    
                                    const variantStatus = getVariantStatus(web, variantCriteria_country, variantCriteria_language);

                                    return (
                                        <Grid container key={idx} className={`${idx % 2 === 0 ? classes.listGridEven : classes.subListGridOdd}`}>
                                            <Grid item className={`${classes.listGridItem} ${classes.listGridSelect} ${!showCheckboxes && classes.hide}`} >
                                                <Field
                                                    name={`publish${idx}`}
                                                    id={`publish${idx}`}
                                                    checked={(isWebsiteChecked && selectedVariants.some(selVar => selVar === variantKey))}
                                                    component={CheckboxWithName}
                                                    onClick={(event: any) => { onChangeSelectVariant(web.id, variantKey, event.target.checked); }}
                                                    label=""
                                                />
                                            </Grid>
                                            <Grid item className={`${classes.listGridItem} ${classes.listGridCountryVariant}`} >
                                                {countryName}
                                            </Grid>
                                            <Grid item className={`${classes.listGridItem} ${classes.listGridLanguageVariant}`} >
                                                {languageName}
                                            </Grid>
                                            <Grid item className={`${classes.listGridItem} ${classes.listGridNameVariant}`}  onClick={() => toggleVariants(`${web.id}_${countryName}_${languageName}`)}>
                                                {variant.display_name}
                                            </Grid>
                                            <Grid item className={`${classes.listGridItem} ${classes.listGridURLVariant}`}  onClick={() => toggleVariants(`${web.id}_${countryName}_${languageName}`)}>
                                                {!showCheckboxes ? variantStatus : "Published"}
                                            </Grid>
                                            <Grid item className={`${classes.listGridItem} ${showCheckboxes ? classes.listGridIconVariantsPublish : classes.listGridIconVariants}`} onClick={() => toggleVariants(`${web.id}_${countryName}_${languageName}`)}>
                                                <img src={'/images/icons/ChevronDown.svg'} className={websiteVariantsVisible.includes(`${web.id}_${countryName}_${languageName}`) ? classes.iconStyleRotate : classes.iconStyle} alt="" />
                                            </Grid>
                                            <Grid item className={`${classes.listGridItem} ${classes.listGridDescriptionVariant} ${websiteVariantsVisible.includes(`${web.id}_${countryName}_${languageName}`) ? classes.show : classes.hide}`} >
                                                <Grid container> 
                                                    <Grid xs={2} className={classes.variantLabel}>
                                                        URL: 
                                                    </Grid>
                                                    <Grid  xs={10}>
                                                        {variant.url}
                                                    </Grid>
                                                </Grid>
                                                <Grid container> 
                                                    <Grid xs={2} className={classes.variantLabel}>
                                                        Description: 
                                                    </Grid>
                                                    <Grid xs={10}>
                                                        {variant.description}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )

                                })}
                            </Grid>
                        </Grid>
                    )
                })}
                <Grid container >
                    <Grid item className={`${classes.listGridItem} ${classes.listGridPublishButton}`} >
                        {!isLoading && selectedVariants.length > 0 &&
                            <SpinnerButton
                                type="button"
                                variant="contained"
                                color="primary"
                                pathToImagesFolder={'/images/icons'}
                                spinnerVisible={isUnpublishing}
                                onClick={() => setShowConfirmUnpublish(true)}
                            >
                                Unpublish selected websites
                            </SpinnerButton>
                        }
                    </Grid>
                </Grid>

            </form>
        </Main>
    );
}

export default reduxForm({
    form: 'publishWebsite'
})(WebsiteUnpublish);
