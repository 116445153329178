import React, { useState } from 'react';
import Dashboard from './Dashboard';
import { Spinner } from '@danfoss/webex-ui';
import DashboardItemPopup from './DashboardItemPopup';
import parse from 'html-react-parser';
import { DashBoardItem } from './Types';
import { Grid } from '@material-ui/core';
import { RadioButtonGroup } from '../../RenderFields/RenderFields';
import { useStyles } from './Styles';
import DashBoardItems from './DashBoardItems';

export interface DashboardWrapperProps {
	dashboardItems: DashBoardItem[],
	areaSubject: string,
	showMobileFlag?: boolean
}

const DashboardWrapper: React.FC<DashboardWrapperProps> = ({ dashboardItems, areaSubject, showMobileFlag = true }) => {
	const classes = useStyles();
	const [showDashboardItemDescription, setShowDashboardItemDescription] = useState(false);
	const [dashboardItem, setDashboardItem] = useState({} as DashBoardItem);
	const [emulateMobile, setEmulateMobile] = useState(false);
	const [mode, setMode] = useState('draft');

	const handleInformationIconClick = (item: DashBoardItem) => {
		setDashboardItem(item)
		setShowDashboardItemDescription(!showDashboardItemDescription);
	}

	const toggleApplicationDescription = () => {
		setShowDashboardItemDescription(!showDashboardItemDescription);
	}

	if (showDashboardItemDescription) {
		(document.getElementById("mainContent") || {} as HTMLElement).scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}
	const filteredDashboardItems = () => {

		const dashBoardItemsWithDraft = dashboardItems.filter(dbitem => !dbitem.published || dbitem.draftExists);

		if (mode === 'published') {
			return dashboardItems.filter(dbitem => dbitem.published);
		}
		else {
			return dashboardItems.filter(dbitem => !dbitem.published || !dashBoardItemsWithDraft.some(draft => draft.client_id === dbitem.client_id));
		}
	}

	return !dashboardItems ? (<Spinner visible={!dashboardItems} />) : (
		<React.Fragment>
			{ showMobileFlag && (
			<Grid container className={classes.toggleMobileContainer}>
				<Grid item className={classes.labelGrid}>
					<label className={classes.label}>Select view</label>
				</Grid>	
				<Grid item className={classes.radioButtonsGrid}>
					<RadioButtonGroup 
						id="mode"
						value={emulateMobile && 'mobile' || 'desktop'}
						label='Select mode: '
						row={true}
						onClick={(e: any) => { setEmulateMobile(e.target.value === 'mobile') }}
						radioButtons={[
							{label:'Desktop', value: 'desktop', className: classes.radioButton },
							{label:'Mobile', value: 'mobile', className: classes.radioButton },
						]}
					/>
				</Grid>	
			</Grid>
			)}
			<Grid container className={classes.toggleModeContainer}>
				<Grid item className={classes.labelGrid}>
					<label className={classes.label}>Select mode</label>
				</Grid>	
				<Grid item className={classes.radioButtonsGrid}>
					<RadioButtonGroup 
						id="mode"
						value={mode}
						label='Select mode: '
						row={true}
						onClick={(e: any) => { setMode(e.target.value) }}
						radioButtons={[
							{label:'Preview', value: 'draft', className: classes.radioButton },
							{label:'Published', value: 'published', className: classes.radioButton },
						]}
					/>
				</Grid>	
			</Grid>
			{showDashboardItemDescription && (
				<DashboardItemPopup
					headerText={dashboardItem.name}
					bodyText={parse(dashboardItem.description || '')}
					togglePopup={toggleApplicationDescription}
				/>
			)}
				<Dashboard
					dashboardItems={filteredDashboardItems()}
					areaSubject={areaSubject}
					emulateMobile={emulateMobile}
					handleInformationIconClick={handleInformationIconClick}
				/>
		</React.Fragment>
	);
};

export default DashboardWrapper;
