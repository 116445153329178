import { RecommendedApplication, RecommendedApplicationCriteria } from "../../../API/recommendedApplication"
import { LocationAppStateModel } from "../../../Store/Location/types";
import { ReferencesAppStateModel } from "../../../Store/References/types";
import { Criteria, CriteriaFormValues, criterionAttributes, CriterionOptions, UserCounts } from "./types";
import { booleanValues, languages } from '../../../Constants';
import { ApplicationsAppStateModel } from "../../../Store/Applications/types";
import { AppState } from "../../../Store";
import { noOfUsersWithCriteria } from "../../../API/users";
import { replaceLast } from "../../../Utils";

export const showNoOfUsers = (count: number) => {
    let countAsText = '';
    if (count === -2)
        countAsText = '<span class="blink">...</span>';
    else if (count === -1)
        countAsText = '?';
    else if (count < 1000)
        countAsText = count.toString();
    else
        countAsText = '999+';
    return `<span style="font-weight: bold">${countAsText}</span>`
}

export const generateCriteriaAsText = (application: RecommendedApplication | undefined, existingApplication: RecommendedApplication | undefined, state: AppState, userCounts: UserCounts): string => {
    if (application?.all_users)
        return `<span ${existingApplication && !existingApplication.all_users && 'style="font-weight: bold"' || ''}>Recommend to all users</span>`;

    if (!application?.criteria) {
        return '';
    }

    let criteriaText: string = '';
    const noOfOrs = application?.criteria.length;
    application?.criteria.forEach((orCrit, orCritIdx) => {
        const noOfAnds = orCrit.length;
        orCrit.forEach((andCrit, andCritIdx) => {
            const existingCrit = existingApplication &&
                existingApplication.criteria &&
                existingApplication.criteria.length > orCritIdx &&
                existingApplication.criteria[orCritIdx].length > andCritIdx &&
                existingApplication.criteria[orCritIdx][andCritIdx];
            const attributeChanged = (existingCrit && existingCrit.path !== andCrit.path) || !existingCrit;
            const valuesChanged = (existingCrit && !arrayEquals(existingCrit.values, andCrit.values)) || !existingCrit;

            criteriaText += orCritIdx === 0 && andCritIdx === 0 && `<div${existingApplication && existingApplication.criteria?.length === 0 && ' style="font-weight: bold">' || '>'} Recommend to ${showNoOfUsers(userCounts.critGroups.length > orCritIdx ? userCounts.critGroups[orCritIdx] : -2)} users who</div>` || '';
            const styleAttribute = `style="color: mediumblue ${attributeChanged && '; font-weight: bold' || ''}"`
            const styleValues = `style="color: mediumblue; font-style: italic ${valuesChanged || !existingCrit && '; font-weight: bold' || ''}"`
            criteriaText += `<div style="padding-left: 30px; text-indent: -20px;${attributeChanged && valuesChanged && ' font-weight: bold' || ''}">`;

            if (attributeIsMultivalue(andCrit.path) && andCrit.path === 'user_applications.app_id') {
                criteriaText += andCrit.values.length > 1 && 'have used at least one of these ' || 'have used the ';
                criteriaText += `<span ${styleAttribute}>${pathToTextDisplayName(andCrit.path, andCrit.values.length > 1)}</span>${andCrit.values.length > 1 && ':' || ''} `;
                criteriaText += `<span ${styleValues}>'${replaceLast(andCrit.values.map(value => valueToDisplayName(value, andCrit.path, state)).join("', '"), ',', '</span><span> or</span><span ' + styleValues + '>')}'</span>`
            }
            else if (attributeIsMultivalue(andCrit.path)  && andCrit.path !== 'user_applications.app_id' && andCrit.values.length > 1) {
                criteriaText += 'have at least one of these '
                criteriaText += `<span ${styleAttribute}>${pathToTextDisplayName(andCrit.path, andCrit.values.length > 1)}</span>${andCrit.values.length > 1 && ':' || ''} `;
                criteriaText += `<span ${styleValues}>'${replaceLast(andCrit.values.map(value => valueToDisplayName(value, andCrit.path, state)).join("', '"), ',', '</span><span> or</span><span ' + styleValues + '>')}'</span>`
            }
            else if (!isBooleanValue(andCrit.values)) {
                criteriaText += 'have ';
                criteriaText += `<span ${styleValues}>'${replaceLast(andCrit.values.map(value => valueToDisplayName(value, andCrit.path, state)).join("', '"), ',', '</span><span> or</span><span ' + styleValues + '>')}'</span>`
                criteriaText += ` as <span ${styleAttribute}>${pathToTextDisplayName(andCrit.path)}</span>`;
            }
            else {
                criteriaText += 'have ';
                criteriaText += `<span ${styleAttribute}>${pathToTextDisplayName(andCrit.path)}</span>`;
                criteriaText += ` = <span ${styleValues}>${replaceLast(andCrit.values.map(value => valueToDisplayName(value, andCrit.path, state)).join("', '"), ',', '</span><span> or</span><span ' + styleValues + '>')}</span>`
            }
            if (andCritIdx < noOfAnds - 1) {
                criteriaText += ' <span style="font-weight: normal">and</span></div>';
            }
        })
        criteriaText += '</div>'

        if (orCritIdx < noOfOrs - 1) {
            criteriaText += `<div${existingApplication && existingApplication.criteria && existingApplication.criteria.length <= orCritIdx + 1 && ' style="font-weight: bold">' || '>'}And also to ${showNoOfUsers(userCounts.critGroups.length > orCritIdx + 1 ? userCounts.critGroups[orCritIdx + 1] : -2)} users who</div>`;
        }
    });
    if (application.criteria.length > 1) {
        criteriaText += `<div>Recommend to ${showNoOfUsers(userCounts.total)} users in total</div>`;
    }

    criteriaText += '</div>'
    return criteriaText;
}


const arrayEquals = (a: string[], b: string[] | undefined) => {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
}

export const generateCriteriaAsTextFromFormValues = (values: CriteriaFormValues, state: AppState, userCounts: UserCounts): string => {
    if (values.all_users)
        return 'Recommend to all users';

    if (!(values.criteria && values.criteria.length > 0)) {
        return '';
    }
    let criteriaText: string = '';
    let orCritIdx = 0;
    values.criteria.forEach((crit, idx) => {
        criteriaText += idx === 0 && `<div>Recommend to ${showNoOfUsers(userCounts.critGroups.length > orCritIdx ? userCounts.critGroups[orCritIdx] : -2)} users who</div><div>` || '';
        criteriaText += crit.andor === 'and' && ' and </div>' || '</div>';
        if (crit.andor === 'or') {
            orCritIdx++;
            criteriaText += `<div>And also to ${showNoOfUsers(userCounts.critGroups.length > orCritIdx ? userCounts.critGroups[orCritIdx] : -2)} users who</div>` || '';
        }
        const styleAttribute = `style="color: mediumblue"`
        const styleValues = `style="color: mediumblue; font-style: italic"`
        criteriaText += '<div style="padding-left: 10px">';

        if (crit.attribute) {
            const critValues = Array.isArray(crit.values) && crit.values || [crit.values];
            if (attributeIsMultivalue(crit.attribute) && crit.attribute === 'user_applications.app_id') {
                criteriaText += critValues.length > 1 && 'have used at least one of these ' || 'have used the ';
                criteriaText += `<span ${styleAttribute}>${pathToTextDisplayName(crit.attribute, critValues.length > 1)}</span>${critValues.length > 1 && ':' || ''} `;
                criteriaText += `<span ${styleValues}>'${replaceLast(critValues.map(value => valueToDisplayName(value, crit.attribute, state)).join("', '"), ',', '</span><span> or</span><span ' + styleValues + '>')}'</span>`
            }
            else if (attributeIsMultivalue(crit.attribute) && crit.attribute !== 'user_applications.app_id' && critValues.length > 1) {
                criteriaText += 'have at least one of these ';
                criteriaText += `<span ${styleAttribute}>${pathToTextDisplayName(crit.attribute, critValues.length > 1)}</span>${critValues.length > 1 && ':' || ''} `;
                criteriaText += `<span ${styleValues}>'${replaceLast(critValues.map(value => valueToDisplayName(value, crit.attribute, state)).join("', '"), ',', '</span><span> or</span><span ' + styleValues + '>')}'</span>`
            }
            else if (!isBooleanValue(critValues)) {
                criteriaText += 'have ';
                criteriaText += `<span ${styleValues}>'${replaceLast(critValues.map(value => valueToDisplayName(value, crit.attribute, state)).join("', '"), ',', '</span><span> or</span><span ' + styleValues + '>')}'</span>`
                criteriaText += ` as <span ${styleAttribute}>${pathToTextDisplayName(crit.attribute)}</span>`;
            }
            else {
                criteriaText += 'have ';
                criteriaText += ` <span ${styleAttribute}>${pathToTextDisplayName(crit.attribute)}</span>`;
                criteriaText += ` = <span ${styleValues}>${replaceLast(critValues.map(value => valueToDisplayName(value, crit.attribute, state)).join("', '"), ',', '</span><span> or</span><span ' + styleValues + '>')}</span>`
            }
        }
    });
    criteriaText += '</div>';
    if (values.criteria.length > 1) {
        criteriaText += `<div>Recommend to ${showNoOfUsers(userCounts.total)} users in total</div>`;
    }

    return criteriaText && '<hr/>' + criteriaText || criteriaText;
}

export const pathToDisplayName = (path: string): string => {
    return criterionAttributes.find(attr => attr.id === path)?.text || 'Unknown';
}

export const pathToTextDisplayName = (path: string, multivalue = false): string => {
    const critAttr = criterionAttributes.find(attr => attr.id === path);
    if (!critAttr)
        return 'Unknown';

    return multivalue && critAttr.generatedTextPlural || critAttr.generatedText || critAttr.text;
}

export const pathToQueryField = (path: string): string => {
    const critAttr = criterionAttributes.find(attr => attr.id === path);
    return critAttr?.queryField || '';
}

export const pathToExtraQueryField = (path: string): string => {
    const critAttr = criterionAttributes.find(attr => attr.id === path);
    return critAttr?.extraQueryField || '';
}

export const attributeIsMultivalue = (path: string): boolean => {
    return criterionAttributes.find(attr => attr.id === path)?.multivalue || false;
}

export const getReferenceValues = (references: ReferencesAppStateModel, stateProperty?: string): CriterionOptions[] | undefined => {

    switch (stateProperty) {
        case 'areasOfInterest':
            return references.areasOfInterest;
        case 'companyTypes':
            return references.companyTypes;
        case 'departments':
            return references.departments;
    }
}

export const getCountryValues = (location: LocationAppStateModel): CriterionOptions[] | undefined => {

    return location.countries?.map(country => ({ id: country.alpha2, text: country.name }));
}

export const getLanguageValues = (): CriterionOptions[] | undefined => {

    return languages;
}

export const getBooleanValues = (): CriterionOptions[] | undefined => {

    return booleanValues;
}

export const getApplicationsValues = (applications: ApplicationsAppStateModel): CriterionOptions[] | undefined => {

    return applications.applications
        ?.filter(app => app.app_id)
        .sort((app1, app2) => app1.name.localeCompare(app2.name))
        .map(app => ({ id: app.app_id, text: app.name }));
}

export const valueToDisplayName = (value: string, path: string, state: AppState) => {
    const criterionAttribute = criterionAttributes.find(attr => attr.id === path);

    if (!criterionAttribute)
        return value;

    switch (criterionAttribute.state) {
        case 'references':
            switch (criterionAttribute.stateProperty) {
                case 'areasOfInterest':
                    return state.references.areasOfInterest?.find(item => item.id === value)?.text || value;
                case 'companyTypes':
                    return state.references.companyTypes?.find(item => item.id === value)?.text || value;
                case 'departments':
                    return state.references.departments?.find(item => item.id === value)?.text || value;
            }
            break;
        case 'location':
            if (criterionAttribute.stateProperty === 'countries')
                return state.location.countries?.find(item => item.alpha2 === value)?.name || value;
            break;
        case 'applications':
            return state.applications.applications?.find(item => item.app_id === value)?.name || value;
        case 'language':
            return languages.find(item => item.id === value)?.text || value;

    }

    return value;

}

export const validateValue = (value: string, path: string, state: AppState): boolean => {
    const criterionAttribute = criterionAttributes.find(attr => attr.id === path);

    if (!criterionAttribute)
        return false;

    switch (criterionAttribute.state) {
        case 'references':
            switch (criterionAttribute.stateProperty) {
                case 'areasOfInterest':
                    return state.references.areasOfInterest?.some(item => item.id === value) || false;
                case 'companyTypes':
                    return state.references.companyTypes?.some(item => item.id === value) || false;
                case 'departments':
                    return state.references.departments?.some(item => item.id === value) || false;
            }
            break;
        case 'location':
            if (criterionAttribute.stateProperty === 'countries')
                return state.location.countries?.some(item => item.alpha2 === value) || false;
            break;
        case 'applications':
            return state.applications.applications?.some(item => item.app_id === value) || false;
        case '':
            switch(criterionAttribute.type) {
                case 'language':
                    return languages.some(item => item.id === value) || false;
                case 'is_workforce':
                    return booleanValues.some(item => item.id === value) || false;
            }
            break;            
    }

    return false;

}

/**
* Returns the index of the last element in the array where predicate is true, and -1
* otherwise.
* @param array The source array to search in
* @param predicate find calls predicate once for each element of the array, in descending
* order, until it finds one where predicate returns true. If such an element is found,
* findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
*/
export function findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
    let l = array.length;
    while (l--) {
        if (predicate(array[l], l, array))
            return l;
    }
    return -1;
}

// Get list of attibutes not used in other criterion in current or-block 
export const getCriterionAttibutes = (currentCritIdx: number, allCriteria: Criteria[]) => {
    // Find start of the current or-block
    const orGroupStartIndex = findLastIndex(allCriteria, (crit, ix) => ['', 'or'].includes(crit.andor) && ix <= currentCritIdx);
    // Find start of the next or-block
    let nextOrGroupStartIndex = allCriteria.findIndex((crit, ix) => crit.andor === 'or' && ix > currentCritIdx);
    nextOrGroupStartIndex = nextOrGroupStartIndex > -1 && nextOrGroupStartIndex || 99999;
    // Get id's of the other criteria attributes used in current or-block      
    const andGroupAttributeIds = allCriteria.filter((_crit, ix) => ix >= orGroupStartIndex && ix !== currentCritIdx && ix < nextOrGroupStartIndex).map(crit => crit.attribute);

    // Return list of attibutes not used in other criterion in current or-block
    return criterionAttributes.filter(attr => !andGroupAttributeIds.some(attrId => attr.id === attrId));
}

const isBooleanValue = (values: string[]) => {
    return values.every(value => ['True', 'False'].includes(value));
}

export const getNoOfUsers = async (accessToken: string, clientId: string, criteria: RecommendedApplicationCriteria, state: AppState, noOfRecommAppsForUser?: number) => {
    const { applications } = state.applications;
    if (!(applications && applications.length > 0))
        return 0;
    if (!(criteria && criteria.length > 0))
        return 0;

    let query = '(';

    criteria.forEach((orCrit, orCritIdx) => {
        query += '(';
        orCrit.forEach((andCrit, andCritIdx) => {
            const pathToExtraQF = pathToExtraQueryField(andCrit.path);
            if (pathToExtraQF) {
                query += '(';
            }
            query += pathToQueryField(andCrit.path) + ':(';

            if (andCrit.path !== 'user_applications.app_id') {
                if (isBooleanValue(andCrit.values)) {
                    andCrit.values.forEach(value => {
                        query += value.toLocaleLowerCase()
                    })
                }
                else {
                    andCrit.values.forEach(value => {
                        query += '"' + value + '" ';
                    })
                }
            }
            else {
                andCrit.values.forEach(value => {
                    const client = applications.find(app => app.app_id === value);
                    query += '"' + client?.client_id + '" ';
                })
            }

            if (pathToExtraQF) {
                query += ') OR ' +pathToExtraQF + ':(';
                andCrit.values.forEach(value => {
                    query += '"' + value + '" ';
                });
                query += ')';                
            }
            
            query += andCritIdx < orCrit.length - 1 && ') AND ' || ')';
        });
        query += orCritIdx < criteria.length - 1 && ') OR ' || ')';
    });

    query += ') AND NOT app_metadata.apps.clientID:"' + clientId + '"';

    return noOfUsersWithCriteria(accessToken, query, noOfRecommAppsForUser);
}

export const getNoOfUsersFromFormValues = async (accessToken: string, clientId: string, criteria: Criteria[], state: AppState) => {
    const { applications } = state.applications;
    if (!(applications && applications.length > 0))
        return 0;
    if (!(criteria && criteria.length > 0))
        return 0;
    if (criteria.some(crit => !(crit.attribute && crit.values && crit.values.length > 0)))
        return -2;
    let query = '(';

    criteria.forEach((crit, idx) => {
        query += idx === 0 && '(' || '';
        query += idx > 0 && crit.andor === 'or' && ')) OR (' || '';
        query += idx > 0 && crit.andor === 'and' && ') AND ' || '';
        const critValues = crit.values && (Array.isArray(crit.values) && crit.values || [crit.values]) || [] ;
        if (crit.attribute && critValues && critValues.length > 0) {
            const pathToExtraQF = pathToExtraQueryField(crit.attribute);
            if (pathToExtraQF) {
                query += '(';
            }
            query += pathToQueryField(crit.attribute) + ':(';

            if (crit.attribute !== 'user_applications.app_id') {
                if (isBooleanValue(critValues)) {
                    critValues.forEach(value => {
                        query += value.toLocaleLowerCase()
                    })
                }
                else {
                    critValues.forEach(value => {
                        query += '"' + value + '" ';
                    })
                }
            }
            else {
                critValues.forEach(value => {
                    const client = applications.find(app => app.app_id === value);
                    query += '"' + client?.client_id + '" ';
                })
            }

            if (pathToExtraQF) {
                query += ') OR ' + pathToExtraQF + ':(';
                critValues.forEach(value => {
                    query += '"' + value + '" ';
                });
                query += ')';                
            }
            

        }
        query += idx === criteria.length - 1 && '))' || '';
    });

    query += ') AND NOT app_metadata.apps.clientID:"' + clientId + '"';

    return noOfUsersWithCriteria(accessToken, query);
}

