import React from 'react';
import queryString from 'query-string';
import Main from '../Main/Main';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';

const LoginError: React.FC = (props) => {

    //Get querystring parameters
    const parsed = queryString.parse(window.location.search);

    const classes = useStyles();

    return (
        <Main>
            <Grid container >
                <Grid item xs={12}>
                    <Typography className={`${classes.mainTitle}`}>
                        Login Failed
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.textContainer}>
                    {parsed && parsed.error_description}
                </Grid>
            </Grid>
        </Main>
    );
}

export default LoginError