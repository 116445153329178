import React, { useState } from 'react';
import Main from '../Main/Main';
import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router-dom';
import { useStyles } from './styles';
import Banner from '../Banner'
import ModalPopup from '../Modal/ModalPopup';
import BannerPopup from '../Banner/BannerPopup';
import { Roles } from '../../Store/Roles/types';

const UserRoles: React.FC = () => {
	const location = useLocation();
	const classes = useStyles();
	const [showBannerPopup, setShowBannerPopup] = useState(false);
	const [bannerHeader, setBannerHeader] = useState('');
	const [bannerBody, setBannerBody] = useState('');

	const handleBannerInformationClick = (header: string, body: string) => {
		setBannerBody(body);
		setBannerHeader(header);
		setShowBannerPopup(true);
	}

	return (
		<Main breadCrumbs={{ items: [{ text: `User roles`, link: location.pathname }] }} >
			<Grid container>
				{showBannerPopup && (
					<Grid container className={classes.bannerPopupContainer}>
						<ModalPopup paperClassName={classes.bannerPopup}>
							<BannerPopup bannerHeader={bannerHeader} bannerBody={bannerBody} toggleBannerPopup={setShowBannerPopup} />
						</ModalPopup>
					</Grid>
				)
				}
				<Banner
					bannerText="Maintain application owners"
					bannerURL={"/userroles/appowners"}
					bannerInformationClick={() => handleBannerInformationClick("Maintain application owners", "Add and delete owners on applications")}
					bannerClass={classes.banner}
					bannerTextClass={classes.bannerText}
					allowedRoles={[Roles.DASHBOARD_ADMIN]}
				/>
				<Banner
					bannerText="Maintain application user roles"
					bannerURL={"/userroles/approles"}
					bannerInformationClick={() => handleBannerInformationClick("Maintain application roles", "Add and delete user roles on your applications")}
					bannerClass={classes.banner}
					bannerTextClass={classes.bannerText}
					allowedRoles={[Roles.APPLICATION_OWNER, Roles.DASHBOARD_ADMIN]}
				/>
			</Grid>
		</Main>
	);
}

export default UserRoles;
