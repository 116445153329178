import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { TextField } from '@material-ui/core';
import { useStyles } from './styles';
import { useAuth0 } from '@auth0/auth0-react';
import { SpinnerButton } from '@danfoss/webex-ui/dist/mui';
import settings from '../../../Config/settings';
import { searchUserByEmail, UserInfo } from '../../../API/users';
import { validEmail } from '../../../Utils';

export interface UserSearchProps {
    onUserChange: (user: UserInfo) => void;
}

const UserSearch: React.FC<UserSearchProps> = ({ onUserChange }) => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const { getAccessTokenSilently } = useAuth0();

    const onEmailSearch = async () => {
        if (!validEmail(email)) {
            setEmailError('Please enter a valid email');
            return;
        }

        setIsLoading(true);
        const accessToken = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
        try {
            const user = await searchUserByEmail(accessToken, email);

            onUserChange(user);
        } catch (error: any) {
            setEmailError(error.detail ?? 'Something went wrong');
        }

        setIsLoading(false);
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        if (emailError) {
            setEmailError(null);
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <TextField
                    label='User email'
                    value={email}
                    onChange={handleEmailChange}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            onEmailSearch();
                        }
                    }}
                    error={!!emailError}
                    helperText={emailError}
                    InputLabelProps={{ shrink: true }}
                    disabled={isLoading}
                    fullWidth
                />
            </Grid>
            <Grid item xs={1}>
                <SpinnerButton
                    className={classes.searchButton}
                    onClick={onEmailSearch}
                    variant='contained'
                    color='primary'
                    pathToImagesFolder={'/images/icons'}
                    spinnerVisible={isLoading}
                    disabled={!!emailError}
                >
                    Search
                </SpinnerButton>
            </Grid>
        </Grid>
    );
}

export default UserSearch;
