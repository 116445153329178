import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	root: {
		marginLeft: '0px',
		position: 'relative'
	},
	applicationListGridHeading: {
		backgroundColor: colors.webexuiGraySoft,
		minHeight: '40px',
		fontWeight: 600
	},
	applicationListGridItem: {
		padding: '5px 8px 5px 8px'
	},
	applicationListGridEdit: {
		width: '8%',
		[theme.breakpoints.down('md')]: {
			width: '80px'
		  }
	},
	ownerListGridEdit: {
		paddingLeft: '0px',
		paddingBottom: '13px',
		width: '8%',
		[theme.breakpoints.down('md')]: {
			width: '80px'
		},
		alignSelf: 'flex-end'
	},
	ownerListGridAddOwner: {
		paddingLeft: '0px'
	},
	applicationListGridName: {
		width: '20%'
	},
	applicationListGridOwners: {
		width: '72%',
		[theme.breakpoints.down('md')]: {
			width: 'calc(80% - 80px)'
		  }
	},
	applicationListGridOwnerName: {
		width: '25%',
		alignSelf: 'flex-end'
	},
	applicationListGridOwnerEmail: {
		width: '30%',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		alignSelf: 'flex-end'
	},
	applicationListGridOwnerPhone: {
		width: '20%',
		alignSelf: 'flex-end'
	},
	applicationListGridImpersonate: {
		width: '15%',
		alignSelf: 'flex-end'
	},
	applicationListGrid: {
		minHeight: '40px'
	},
	ownerListGrid: {
		marginTop:'-5px',
		marginLeft:'-10px',
		minHeight: '30px'
	},
	applicationListGridOdd: {
		backgroundColor: colors.webexuiGraySoft
	},
	applicationListGridEven: {
		backgroundColor: colors.webexuiWhite
	},
	gridButton: {
		marginRight: '20px',
		height: '30px',
		minWidth: '80px',
		[theme.breakpoints.down('md')]: {
			minWidth: '65px',
			padding: '7px 7px 7px 7px'
		  }

	},
	editGridButton: {
		marginBottom: '12px',
		height: '30px',
		minWidth: '80px',
		[theme.breakpoints.down('md')]: {
			minWidth: '65px',
			padding: '7px 7px 7px 7px'
		  }
	},
	editOwnerGridButton: {
		marginTop: '12px',
		height: '30px'
	},
	button: {
		marginRight: '20px'
	},
	editPopup: {
		position: 'absolute',
		margin: '0 auto',
		maxWidth: '1200px',
		[theme.breakpoints.up('sm')]: {
			margin: '0px 15px 0'
		},
		textAlign: 'left',
		paddingBottom: '23px',
		padding: '16px 35px 50px 35px !important'
	},
	editPopupContainer: {
		maxWidth: '1280px',
		display: 'flex',
		justifyContent: 'center',
		position: 'absolute',
		top: '250px'
	},
    editContainer: {
        position: 'relative'
    },
    popupClose: {
		cursor: 'pointer',
		width: '18px',
		[theme.breakpoints.only('xs')]: {
			margin: '10px -16px',
		},
		[theme.breakpoints.up('sm')]: {
			right: '-20px'
		},
		position: 'absolute'
	},
	formContainer: {
		position: 'relative',
		height: '500px'
	},
	blackText: {
		color: colors.webexuiBlack,
	},
	clickOutSideContainer: {
		width: '100%'
	}
}));