import React, { useState, useEffect } from 'react';
import Main from '../Main/Main';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './styles';
import { getRelevantWebsites, RelevantWebsite, RelevantWebsites } from '../../API/relevantWebsite';
import { useLocation, useNavigate } from 'react-router-dom';
import { Spinner } from '@danfoss/webex-ui';
import settings from '../../Config/settings';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '@material-ui/core/Button';
import parse from 'html-react-parser';

const WebsiteOverview: React.FC = () => {
	const classes = useStyles();
    const location = useLocation();
    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState(true);
	const [relevantWebsites, setRelevantWebsites] = useState({} as RelevantWebsites);

    useEffect(() => {

		const getRelWebsites = async () => {
            const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
			const relevantWebsites = await getRelevantWebsites(accessTokenMyDanfossApi);
			setRelevantWebsites(relevantWebsites);

            setIsLoading(false);
		}

		getRelWebsites();

	}, []);

    const handleRelevantWebsiteClick = (id: string, isDraft: boolean) => {
        return navigate(`/website?id=${id}&isdraft=${isDraft}`);
    }

	return isLoading ? <Spinner visible={isLoading} ></Spinner> : (
		<Main breadCrumbs={{ items: [{ text: `Relevant websites`, link: location.pathname }] }}>
			<Grid container>
                <Grid container className={classes.applicationListGridHeading}>
					<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridEdit}`} >
					</Grid>
					<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridName}`} >
						Website name
					</Grid>
					<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridCriteria}`} >
						Description
					</Grid>
					<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridStatus}`} >
						Status
					</Grid>
				</Grid>
				{ relevantWebsites.relevant_websites.filter(relWeb => relWeb.published).map((relevantWebsite: RelevantWebsite) => { 

                    const isDraft = relevantWebsites.relevant_websites.find(web => !web.published && web.id === relevantWebsite.id) !== undefined;
                    const website = !isDraft ? relevantWebsite : relevantWebsites.relevant_websites.find(web => !web.published && web.id === relevantWebsite.id);

                        return (
                            <Grid container className={classes.relevantWebsiteContainer} key={website?.id}>
                                <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridEdit}`} >
                                <Button
                                    type="button"
                                    variant="outlined"
                                    color="default"
                                    className={classes.editGridButton}
                                    onClick={(e:any) => { e.preventDefault(); handleRelevantWebsiteClick(relevantWebsite.id, isDraft) }}
                                >
                                    Edit website
                                </Button>
                                </Grid>
                                <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridName}`} >
                                    {website && website.fallback_display_name}
                                </Grid>
                                <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridCriteria}`} >
                                    {parse(website && website.fallback_description || '')}
                                </Grid>
                                <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridStatus}`} >
                                    {isDraft ? ("Under change") : ("Published")}
                                </Grid>
                            </Grid>
                        )
                    }
                )}
			</Grid>
		</Main>
	);
}

export default WebsiteOverview;
