import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    appBar: {
        color: colors.webexuiWhite,
        background: 'linear-gradient(to bottom,#c00708 0,#c70609 50%,#cf050b 100%)'
    },
    toolbar: {
        alignContent: 'flex-end',
        width: '100%'
    },
    leftSubContainer: {
        display: 'flex',
        alignItems: 'center',
        float: 'left',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '80px',
            overflow: 'hidden',
        },
    },
    rightSubContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        float: 'left',
        justifyContent: 'flex-end',
        paddingRight: '17px'
    },
    logo: {
        margin: '5px -8px 0px',
        height: '59px',
        marginTop: '16px',
        [theme.breakpoints.only('md')]: {
            height: '44px',
            margin: '10px -8px 0'
        },
        [theme.breakpoints.only('sm')]: {
            height: '33px',
            margin: '5px -5px -1px',
        },
        [theme.breakpoints.only('xs')]: {
            height: '33px',
            margin: '9px -5px 0px',
        }
    },
    leftContainer: {
        display: 'table-cell',
    },
    rightContainer: {
        display: 'table-cell',
        width: '100%',
        lineHeight: '63px',
        [theme.breakpoints.down('md')]: {
            lineHeight: '48px',
        },
        [theme.breakpoints.down('sm')]: {
            lineHeight: '37px',
            verticalAlign: 'middle',
        },
        [theme.breakpoints.only('xs')]: {
            lineHeight: '37px',
            verticalAlign: 'middle',
        }
    },
    hideLanguageSelector: {
        display: 'none'
    },
    headerIcon: {
        marginRight: '10px',
        height: '22px',
        width: '22px'
    },
    headerSpacer: {
        marginRight: '27px'
    },
    headerItemContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        marginRight: '10px',
        textDecoration: 'none',
        color: colors.webexuiWhite
    },
    headerMargin: {
        // margin: '0 80px',
        [theme.breakpoints.between('sm', 'md')]: {
            margin: '0 40px'
        },
        [theme.breakpoints.only('xs')]: {
            margin: '0 16px'
        }
    },
    headerFullWidth: {
        maxWidth: '1300px'
    },
    appsSmallScreen: {
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        marginRight: 0
    },
    appsBigScreen: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    }
}));