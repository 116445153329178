import React, { useMemo } from 'react';
import { UserAuth0Log } from '../../../../../API/activityHistory';
import Table, { ColumnConfig } from '../../../Table';
import { Button, Grid } from '@material-ui/core';
import { useStyles } from './styles';


export interface AuthLogsTableProps {
    data: UserAuth0Log[];
    totalPages: number;
    currentPage: number;
    onPageChange: (page: number) => void;
}

const AuthLogsTable: React.FC<AuthLogsTableProps> = ({
    data,
    totalPages,
    currentPage,
    onPageChange,
}) => {
    const classes = useStyles();

    const columns: ColumnConfig<UserAuth0Log>[] = useMemo(() => [
        {
            label: 'Description',
            render: l => l.description,
        },
        {
            label: 'App',
            render: l => l.client_name,
        },
        {
            label: 'From IP',
            render: l => l.ip,
        },
        {
            label: 'Date',
            render: l => new Date(l.date).toLocaleString(),
            className: classes.dateColumn,
        },
    ], [classes]);

    const handleNextPage = () => {
        if (currentPage < totalPages - 1) {
            onPageChange(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 0) {
            onPageChange(currentPage - 1);
        }
    };

    return (
        <Grid container justifyContent='center'>
            <Grid item xs={12}>
                <Table
                    data={data}
                    columns={columns}
                    entityIdSelector={l => l.id}
                />
            </Grid>
            {totalPages > 1 && (
                <Grid item>
                    <Button
                        className={classes.paginationButton}
                        onClick={handlePrevPage}
                        disabled={currentPage === 0}
                        variant='text'
                        color='default'
                    >
                        Newer
                    </Button>
                    <Button
                        className={classes.paginationButton}
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages - 1}
                        variant='text'
                        color='default'
                    >
                        Older
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

export default AuthLogsTable;
