import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './styles';
import Button from '@material-ui/core/Button';
import EditAppCriteria from './EditAppCriteria';
import DisplayCriteria from './DisplayCriteria';
import { CheckboxWithName } from '../../RenderFields/RenderFields';
import { CopyProgress, ExportProgress, ApplicationRowProps, EditButtonsProps, CopyCheckBoxesProps, ExportCheckBoxesProps, UserCounts } from './types';
import ClickOutsideWrapper from '../../ClickOutsideWrapper';

const RenderEditButtons: React.FC<EditButtonsProps> = ({ clientId, recommendedApplication, recommendedApplicationPublished, copyCriteriaProgress, exportCriteriaProgress, handleEditClick, deleteDraftAppCriteria, disabled }) => {

	const classes = useStyles();
	if (copyCriteriaProgress === CopyProgress.NotStarted && exportCriteriaProgress === ExportProgress.NotStarted) {
		return (
			<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridEdit}`} >
				<Button
					type="button"
					variant="outlined"
					color="default"
					className={classes.editGridButton}
					onClick={(e) => { e.preventDefault(); handleEditClick(clientId) }}
					disabled={disabled}
				>
					{recommendedApplication && 'Edit crit.' || 'Add crit.'}
				</Button>
				{recommendedApplication && !recommendedApplication?.published &&
					<React.Fragment>
						<br />
						<Button
							type="button"
							variant="outlined"
							color="default"
							className={classes.editGridButton}
							onClick={(e) => { e.preventDefault(); deleteDraftAppCriteria(clientId) }}
							disabled={disabled}
						>
							{recommendedApplicationPublished && 'Undo changes' || 'Remove crit.'}
						</Button>
					</React.Fragment>
				}
			</Grid>
		)
	}
	else
		return null
}

const RenderCopyCheckBoxes: React.FC<CopyCheckBoxesProps> = ({ clientId, copyCriteriaProgress, copyCriteriaFrom, copyCriteriaTo,
	onSelectCopyFrom, onSelectCopyTo, rowNo }) => {

	const classes = useStyles();
	return (
		<React.Fragment>
			{copyCriteriaProgress === CopyProgress.SelectTargets && copyCriteriaFrom !== clientId &&
				<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridEdit}`} >
					<CheckboxWithName
						name={`copyto${rowNo}`}
						id={`copyto${rowNo}`}
						value=""
						checked={copyCriteriaTo.some(to => to === clientId)}
						onClick={(event: any) => { onSelectCopyTo(clientId, event.target.checked); }}
						label=""
					/>
				</Grid>
			}
			{copyCriteriaProgress === CopyProgress.SelectSource &&
				<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridEdit}`} >
					<CheckboxWithName
						name={`copyfrom${rowNo}`}
						id={`copyfrom${rowNo}`}
						value=""
						checked={copyCriteriaFrom === clientId}
						onClick={(event: any) => { onSelectCopyFrom(clientId, event.target.checked); }}
						label=""
					/>
				</Grid>
			}
			{copyCriteriaProgress === CopyProgress.SelectTargets && copyCriteriaFrom === clientId &&
				<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridEdit}`} >
				</Grid>
			}
		</React.Fragment>
	)
}

const RenderExportCheckBoxes: React.FC<ExportCheckBoxesProps> = ({ clientId, exportCriteriaProgress, exportCriteriaFrom,
	onSelectExportFrom, rowNo }) => {

	const classes = useStyles();
	return (
		<React.Fragment>
			{exportCriteriaProgress === ExportProgress.SelectSources &&
				<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridEdit}`} >
					<CheckboxWithName
						name={`exportfrom${rowNo}`}
						id={`exportfrom${rowNo}`}
						value=""
						checked={exportCriteriaFrom.some(from => from === clientId)}
						onClick={(event: any) => { onSelectExportFrom(clientId, event.target.checked); }}
						label=""
					/>
				</Grid>
			}
		</React.Fragment>
	)
}

const ApplicationRow: React.FC<ApplicationRowProps> = ({ application, recommendedApplication, recommendedApplicationPublished,
	copyCriteriaProgress, copyCriteriaFrom, copyCriteriaTo, exportCriteriaProgress, exportCriteriaFrom,
	onSelectCopyTo, onSelectCopyFrom, onSelectExportFrom, cancelUpdate, deleteDraftAppCriteria, updateAppCriteria, handleEditClick,
	rowNo, editMode, disabled, noOfRecommAppsForUser }) => {

	const classes = useStyles();
	if (editMode) {
		return (
			<ClickOutsideWrapper>
				<EditAppCriteria
					containerClassName={`${rowNo % 2 === 0 ? classes.applicationListGridEven : classes.applicationListGridOdd}`}
					application={application}
					recommendedAppChanged={recommendedApplication}
					recommendedAppPublished={recommendedApplicationPublished}
					cancel={cancelUpdate}
					updateAppCriteria={updateAppCriteria}
				/>
			</ClickOutsideWrapper>
		)
	}
	else {
		return (
			<Grid container className={`${classes.applicationListGrid} ${rowNo % 2 === 0 && classes.applicationListGridEven || classes.applicationListGridOdd} 
										${copyCriteriaFrom === application.client_id && classes.applicationListGridCopyFrom}`}>
				<RenderEditButtons
					clientId={application.client_id}
					recommendedApplication={recommendedApplication}
					recommendedApplicationPublished={recommendedApplicationPublished}
					copyCriteriaProgress={copyCriteriaProgress}
					exportCriteriaProgress={exportCriteriaProgress}
					deleteDraftAppCriteria={deleteDraftAppCriteria}
					handleEditClick={handleEditClick}
					disabled={disabled}
				/>
				<RenderCopyCheckBoxes
					clientId={application.client_id}
					recommendedApplication={recommendedApplication}
					copyCriteriaProgress={copyCriteriaProgress}
					copyCriteriaFrom={copyCriteriaFrom}
					copyCriteriaTo={copyCriteriaTo}
					onSelectCopyFrom={onSelectCopyFrom}
					onSelectCopyTo={onSelectCopyTo}
					rowNo={rowNo}
				/>
				<RenderExportCheckBoxes
					clientId={application.client_id}
					recommendedApplication={recommendedApplication}
					exportCriteriaProgress={exportCriteriaProgress}
					exportCriteriaFrom={exportCriteriaFrom}
					onSelectExportFrom={onSelectExportFrom}
					rowNo={rowNo}
				/>
				<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridName}`} >
					{application.name}
				</Grid>
				<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridCriteria}`}  >
					<DisplayCriteria application={recommendedApplication} existingApplication={recommendedApplicationPublished} isNew={!recommendedApplicationPublished} noOfRecommAppsForUser={noOfRecommAppsForUser} />
				</Grid>
				<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridStatus}`} >
					{recommendedApplication && ((recommendedApplication.published && 'Published') || (recommendedApplicationPublished && 'Under change') || 'New')}
				</Grid>
			</Grid>
		)
	}
}

export default ApplicationRow;


