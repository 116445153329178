import React, { useState, useEffect } from 'react';
import { deleteRelevantWebsite, getRelevantWebsite, getRelevantWebsites, RelevantWebsite, RelevantWebsiteCriteria, RelevantWebsiteVariant, updateRelevantWebsite } from '../../API/relevantWebsite';
import { useLocation } from 'react-router-dom';
import { Spinner } from '@danfoss/webex-ui';
import settings from '../../Config/settings';
import { useAuth0 } from '@auth0/auth0-react';
import UndoChanges from '../UndoChanges/UndoChanges';
import queryString from 'query-string';
import { ListItem, UndoLanguage } from '../UndoChanges/types';
import { prepareForCompare } from '../../Utils';

const UndoWebsiteChanges: React.FC = () => {
    const location = useLocation();
    const { getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedWebsite, setSelectedWebsite] = useState({} as RelevantWebsite);
    const [listItem, setListItem] = useState({} as ListItem)
    const [relevantWebsitepublised, setRelevantWebsitepublised] = useState({} as RelevantWebsite)

    useEffect(() => {

        const getRelWebsiteWithCriteria = async () => {

            // Get querystring parameters
            const parsed = queryString.parse(window.location.search);

            const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);

            const relevantWebsitepublised = await getRelevantWebsite((parsed && parsed.id) as string, "false", accessTokenMyDanfossApi);
            setRelevantWebsitepublised(relevantWebsitepublised);
            
            let relevantWebsiteDraft = {} as RelevantWebsite;

            try {
                relevantWebsiteDraft = await getRelevantWebsite((parsed && parsed.id) as string, "true", accessTokenMyDanfossApi);
            } catch (ex) {
                relevantWebsiteDraft = relevantWebsitepublised
            }
            
            setSelectedWebsite(relevantWebsiteDraft);

            let DraftVariants = [] as RelevantWebsiteVariant[];
            let variants: RelevantWebsiteVariant[];
            let variant: RelevantWebsiteVariant = {} as RelevantWebsiteVariant;

            // Add Fallback to variants if changed
            if (relevantWebsitepublised.fallback_display_name !== relevantWebsiteDraft.fallback_display_name || relevantWebsitepublised.fallback_description !== relevantWebsiteDraft.fallback_description || relevantWebsitepublised.fallback_url !== relevantWebsiteDraft.fallback_url) {
                const draftVersion = {
                    display_name: relevantWebsiteDraft.fallback_display_name,
                    description: relevantWebsiteDraft.fallback_description,
                    url: relevantWebsiteDraft.fallback_url,
                    criteria: [
                        [
                          {
                            "path": "user.user_metadata.country",
                            "values": [
                              ""
                            ]
                          },
                          {
                            "path": "user.user_metadata.language",
                            "values": [
                              ""
                            ]
                          }
                        ]
                      ]
                }
                DraftVariants.push(draftVersion);
            }
            
            // Loop draft variants to see if there are changes compared to the published version
            relevantWebsiteDraft?.variants?.map((dv, idx) => {

                const draftVariantCriteria_country = (dv.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.country")?.values[0] || '';
                const draftVariantCriteria_language = (dv.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.language")?.values[0] || '';
                
                // Get variant based on new language/Country combination
                if (draftVariantCriteria_country) {

                    variants = relevantWebsitepublised.variants.reduce((websiteVariants: RelevantWebsiteVariant[], websiteVariant: RelevantWebsiteVariant) => {

                        websiteVariant.criteria.forEach(variantCriteria => {
                            
                            if (variantCriteria[0].path === "user.user_metadata.country" && variantCriteria[0].values.indexOf(draftVariantCriteria_country) > -1) {
                                websiteVariants.push(websiteVariant);
                            }
                        });

                        return websiteVariants
                    }, []);

                    if (variants.length > 1) {
                        if (draftVariantCriteria_language) {
                            // Find the variant that has the selected language as a criteria
                            variant = variants.find(variant => (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.language" && variantCriteria.values.indexOf(draftVariantCriteria_language) > -1)) || {} as RelevantWebsiteVariant;
                        } else {
                            // If a criteria has been set where only country is set.
                            variant = variants.find(variant => (variant.criteria[0] as RelevantWebsiteCriteria[]).length === 1) || {} as RelevantWebsiteVariant;
                        }

                    } else {

                        if (draftVariantCriteria_language) {
                            // Find the variant that has the selected language as a criteria
                            variant = variants.find(variant => (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.language" && variantCriteria.values.indexOf(draftVariantCriteria_language) > -1)) || {} as RelevantWebsiteVariant;
                        } else {
                            variant = variants[0];
                        }
                    }

                }
                else if (draftVariantCriteria_language) {
                    variants = relevantWebsitepublised.variants.reduce((websiteVariants: RelevantWebsiteVariant[], websiteVariant: RelevantWebsiteVariant) => {

                        websiteVariant.criteria.forEach(variantCriteria => {
                            if (variantCriteria[0].path === "user.user_metadata.language" && variantCriteria[0].values.indexOf(draftVariantCriteria_language) > -1) {
                                websiteVariants.push(websiteVariant);
                            }
                        });

                        return websiteVariants
                    }, []);

                    

                    // Find the variant where there is only one criteria, which will be the language itself
                    variant = variants.find(variant => (variant.criteria[0] as RelevantWebsiteCriteria[]).length === 1) || {} as RelevantWebsiteVariant;
                }
                
                
                
                if (variant) {
                    if (prepareForCompare(dv) !== prepareForCompare(variant)) {
                        DraftVariants.push(dv);
                    }
                } else {
                    DraftVariants.push(dv);
                }

            });

            // Draft versions found, now convert to UndoChangeItems
            const data = DraftVariants.map(dv => {

                const variantCriteria_country = (dv.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.country")?.values[0] || '';
                const variantCriteria_language = (dv.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.language")?.values[0] || '';

                return { language: variantCriteria_language, country: variantCriteria_country, texts: [{ name: 'Name', text: dv.display_name }, { name: 'Url', text: dv.url }, { name: 'Description', text: dv.description }] };
            });
            
            if (data.length > 0) {
                setListItem({ id: relevantWebsiteDraft.id, name: relevantWebsiteDraft.fallback_display_name, itemHeaders: ['Language', 'Name'], data: data, type: "web" });
            } else {

            }
            setIsLoading(false)
        }

        getRelWebsiteWithCriteria();

    }, []);


    const breadCrumbs = { items: [{ text: `Relevant websites`, link: "/websiteoverview" }, { text: selectedWebsite.name, link: `/website?id=${selectedWebsite.id}` }, { text: 'view changes', link: location.pathname }] }

    const getChangedVariants = (draftVariants: RelevantWebsiteVariant[]) => {

        const changedVariants = [] as RelevantWebsiteVariant[];

        if (relevantWebsitepublised) {
            draftVariants.forEach(variant => {

                let variantFound = false;

                relevantWebsitepublised?.variants?.map(pubVar => {
                    if (JSON.stringify(pubVar.criteria) === JSON.stringify(variant.criteria)) {
                        variantFound = true;

                        if (prepareForCompare(pubVar) !== prepareForCompare(variant)) {
                            changedVariants.push(variant);
                        }
                    }
                })

                if (!variantFound) {
                    changedVariants.push(variant);
                }
            })
        }

        return changedVariants;
    }

    const undoChanges = async (selections: UndoLanguage[], setIsUndoing: Function) => {

        setIsUndoing(true);

        let keys = [] as string[]
        let websiteUpdated = selectedWebsite; 

        selections.forEach(selection => keys.push(selection.language));

        const undoWebsiteChanges = async () => {

            const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);

            let changedVariants = getChangedVariants(websiteUpdated.variants);

            let draftVariantsToUndo = [] as RelevantWebsiteVariant[];
            let draftVariantsNotToUndo = [] as RelevantWebsiteVariant[];

            // Loop variants and sort efter checked or not
            changedVariants.map((variant, idx) => {

                const variantCriteria_country = (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.country")?.values[0] || '';
                const variantCriteria_language = (variant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.language")?.values[0] || '';
                const variantKey = `${websiteUpdated.id}_${variantCriteria_language}_${variantCriteria_country || ''}`;

                if (keys.indexOf(variantKey) > -1) {
                    draftVariantsToUndo.push(variant);
                } else {
                    draftVariantsNotToUndo.push(variant);
                }

            })

            // get variants from the published version of the website and merge with draft variants to publish
            let vaiantsToPublish = [] as RelevantWebsiteVariant[]

            websiteUpdated.variants.forEach(websiteVariant => {
                const variantCriteria_country = (websiteVariant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.country")?.values[0] || '';
                const variantCriteria_language = (websiteVariant.criteria[0] as RelevantWebsiteCriteria[]).find(variantCriteria => variantCriteria.path === "user.user_metadata.language")?.values[0] || '';
                const variantKey = `${websiteUpdated.id}_${variantCriteria_language}_${variantCriteria_country || ''}`;

                if (keys.indexOf(variantKey) === -1) {
                    vaiantsToPublish.push(websiteVariant);
                }

            });

            let fallbackCanged = false;

            if (relevantWebsitepublised.fallback_display_name !== websiteUpdated.fallback_display_name || relevantWebsitepublised.fallback_description !== websiteUpdated.fallback_description || relevantWebsitepublised.fallback_url !== websiteUpdated.fallback_url) {
                fallbackCanged = true;
                if (keys.indexOf(`${selectedWebsite.id}__`) > -1) {
                    websiteUpdated.fallback_description = relevantWebsitepublised.fallback_description;
                    websiteUpdated.fallback_display_name = relevantWebsitepublised.fallback_display_name;
                    websiteUpdated.fallback_url = relevantWebsitepublised.fallback_url;
                }
            }

            const websiteWithVariantsNotToUndo = { ...websiteUpdated, variants: vaiantsToPublish };

            if (changedVariants.length > keys.length) {
                await updateRelevantWebsite(selectedWebsite.id, websiteWithVariantsNotToUndo, accessTokenMyDanfossApi, true);
            } else {
                    if (!fallbackCanged || (fallbackCanged && keys.indexOf(`${selectedWebsite.id}__`) > -1)) {
                        await deleteRelevantWebsite(listItem.id, "true", accessTokenMyDanfossApi);
                    } else {
                        await updateRelevantWebsite(selectedWebsite.id, websiteWithVariantsNotToUndo, accessTokenMyDanfossApi, true);
                    }
             }

        }

        undoWebsiteChanges();

        setIsUndoing(false);
    }

    return isLoading ? <Spinner visible={isLoading} ></Spinner> : (
        <UndoChanges
            breadCrumbs={breadCrumbs}
            typeName='Website'
            handleSubmitClick={undoChanges}
            listItem={listItem}
        />
    );
}

export default UndoWebsiteChanges;
