import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { UserInfo } from '../../../API/users';
import UserEmailsInfo from './UserEmailsInfo';


const EMAILS_HISTORY_RETENTION_DAYS = 30;

export interface UserEmailsInfoSectionProps {
    user: UserInfo;
}

const UserEmailsInfoSection: React.FC<UserEmailsInfoSectionProps> = ({ user }) => {
    const classes = useStyles();
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <Accordion
            className={classes.emailAccordion}
            expanded={isExpanded}
            onChange={() => setIsExpanded(!isExpanded)}
            TransitionProps={{ unmountOnExit: true }}
        >
            <AccordionSummary expandIcon={<img className={classes.icon} src={'/images/icons/ChevronDown.svg'} alt='expand' />}>
                <Typography variant='h6'>
                    User emails history <span className={classes.duration}>(for the last {EMAILS_HISTORY_RETENTION_DAYS} days)</span>
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <UserEmailsInfo user={user} />
            </AccordionDetails>
        </Accordion>
    );
}

export default UserEmailsInfoSection;
