import React, { useMemo } from 'react';
import { EmailDeliveryError } from '../../../../../API/activityHistory';
import Table, { ColumnConfig } from '../../../Table';
import { useStyles } from './styles';


export interface SuppressionsTableProps {
    data: EmailDeliveryError[];
}

const SuppressionsTable: React.FC<SuppressionsTableProps> = ({ data }) => {
    const classes = useStyles();

    const columns: ColumnConfig<EmailDeliveryError>[] = useMemo(() => [
        {
            label: 'Status',
            render: b => b.status,
        },
        {
            label: 'Reason',
            render: b => b.reason,
            className: classes.reasonColumn,
        },
        {
            label: 'Date',
            render: b => new Date(b.created_at).toLocaleString(),
            className: classes.dateColumn,
        },
    ], [classes]);

    return (
        <Table
            data={data}
            columns={columns}
            entityIdSelector={b => b.created_at}
        />
    );
}
export default SuppressionsTable;
