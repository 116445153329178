import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './styles';
import { CheckboxWithName } from '../RenderFields/RenderFields';
import { Field } from 'redux-form';
import { ItemText, UndoChangesRowProps } from './types';
import parse from 'html-react-parser';

const UndoChangesRow: React.FC<UndoChangesRowProps> = ({ listItem, item, language, country, useGrayBackground, onChange, checked }) => {
    const classes = useStyles();
    const [expandRow, setExpandRow] = useState(false);

    const handleRowClick = () => {
        setExpandRow(!expandRow)
    }

    const backGroundClass = useGrayBackground ? classes.graySoft : ''

    return (
        <Grid container key={`row_${item.language}`} className={`${backGroundClass} ${classes.rowContainer}`}>
            <Grid container className={classes.translationRow}>
                { item.country !== undefined ? (
                    <Grid item className={classes.listGridSelectLanguage}>
                        <Field
                            name={item.country || ''}
                            id={`${listItem.id}_${item.language}_${item.country || ''}`}
                            value={country}
                            defaultValue={country}
                            checked={checked}
                            onClick={(event:any) => onChange(event.target)}
                            component={CheckboxWithName}
                            className={classes.checkboxLabel}
                        />
                    </Grid>
                ) : <Grid item className={classes.listGridSelectLanguage}>
                <Field
                    name={item.language}
                    id={`${listItem.id}_${item.language}_${item.country || ''}`}
                    value={language}
                    defaultValue={language}
                    checked={checked}
                    onClick={(event:any) => onChange(event.target)}
                    component={CheckboxWithName}
                    className={classes.checkboxLabel}
                />
            </Grid>}
                {listItem.type === "web" && (
                   <Grid item className={`${classes.translationText} ${classes.listGridCountry}`} onClick={handleRowClick}>
                   {language}
               </Grid>
                )}
                {listItem.itemHeaders.map((itemHeader, idx) => {
                    
                    if (listItem.type === "web" && idx === 1) {
                        return null
                    }

                    const itemText: ItemText = item.texts[idx];

                    return (
                        <Grid key={idx} item className={`${classes.listGridItem} ${classes.listGridName}`} >
                            {itemText.text}
                        </Grid>
                    )
                })}
                <Grid item className={listItem.type === "web" ? classes.listGridIcon44 : classes.listGridIcon59} onClick={handleRowClick}>
                    <img src={'/images/icons/ChevronDown.svg'} className={expandRow ? classes.iconStyleRotate : classes.iconStyle} alt=""  />
                </Grid>
            </Grid>
            {expandRow && (
                <Grid container>
                    {item.texts.filter(it => it.text).map((itemText, idx) => 
                        <Grid key={idx} container className={classes.listGridItemTextContainer}> 
                            <Grid item className={classes.translationDetailsItemDescription}>
                                {itemText.name}:
                            </Grid>
                            <Grid item className={classes.translationDetailsItem}>
                                {parse(itemText.text || '')}
                            </Grid>
                        </Grid>    
                    )}
                </Grid>
            )}
        </Grid>
    );
}

export default UndoChangesRow;
