import { FormErrors } from 'redux-form';
import {validEmail, isValidEmailDomain} from '../../Utils'

export const validate = (values: any, props: any) => {
    const errors : FormErrors<any, string> = {};
    const {email, idpDomains} = values;
    
    if (email) {
        if (!validEmail(email)) {
            errors.email ='Please enter a valid email';
            return errors;
        }

        if (!isValidEmailDomain(email, idpDomains)) {
            errors.email ='Please enter a Danfoss email';
            return errors;
        }
    }
    return errors;
}

export const asyncValidate = (values: any, dispatch: any, props: any) => {
    return Promise.resolve(true);
}

export const validateOnSubmit = (values: any, props: any) => {

    let errors: any = {};
    let hasErrors = false;

    errors.owners = values.owners.map((owner: any) => {
        const ownerErrors: any = {};
        if (!owner.email) {
            ownerErrors.email = 'Please fill in owner email';
            hasErrors = true;
        }
        else {
            if (!validEmail(owner.email)) {
                ownerErrors.email = 'Please enter a valid email';
                hasErrors = true;
            }
    
            if (!isValidEmailDomain(owner.email, props.idpDomains)) {
                ownerErrors.email = 'Please enter a Danfoss email';
                hasErrors = true;
            }

            if(!owner.danfoss_identity_id) {
                ownerErrors.email = 'Reading user data...';
                hasErrors = true;
            }
        }

        return ownerErrors;
    })

    return { errors, hasErrors};
};

export const isValidEmail = (email: string, values: any, props: any): boolean => {
    return validateEmail(email, values, props, '') === undefined;
}

export const validateEmail = (email: string, allValues: any, props: any, name: string) => {
    const { idpDomains } = props;
    if (email) {
        if (!validEmail(email)) {
            return 'Please enter a valid email';
        }

        if (!isValidEmailDomain(email, idpDomains)) {
            return 'Please enter a Danfoss email';
        }
    }
    return undefined;
}
