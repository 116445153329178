import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import { AppState } from '../../../Store';
import { generateCriteriaAsText, generateCriteriaAsTextFromFormValues, getNoOfUsers, getNoOfUsersFromFormValues } from './utils';
import { Criteria, DisplayCriteriaProps, UserCounts } from './types';
import { useStyles } from './styles';
import settings from '../../../Config/settings';
import { useAuth0 } from '@auth0/auth0-react';
import usePrevious from '../../../Utils/usePrevious';
import equal from 'deep-equal';

const DisplayCriteria: React.FC<DisplayCriteriaProps> = ({ application, existingApplication, values, isNew, userCountsForValues, noOfRecommAppsForUser }) => {
    const { getAccessTokenSilently } = useAuth0();
    const appState = useSelector((state: AppState) => state);
    const classes = useStyles();
    const [userCounts, setUserCounts] = useState({ critGroups: [], total: -2 } as UserCounts);
    const prevApplication = usePrevious(application);

    useEffect(() => {
        const getUserCounts = async () => {

                const accessTokenMyDanfossAccountApi = await getAccessTokenSilently(settings.myDanfossAccountApi.accessTokenOptions);

                let critGroups: number[] = [];
                let total = 0;
                if (application && !application.all_users) {
                    await Promise.allSettled(application.criteria.map(async (crit) => getNoOfUsers(accessTokenMyDanfossAccountApi, application.client_id, [crit], appState, noOfRecommAppsForUser)))
                        .then((results) => {
                            results.forEach(result => {
                                critGroups.push(result.status === "fulfilled" && result.value || 0)
                            })
                        });
                    total = (application.criteria.length > 1 && await getNoOfUsers(accessTokenMyDanfossAccountApi, application.client_id, application.criteria, appState, noOfRecommAppsForUser) || critGroups[0]) || 0;
                }
                setUserCounts({ critGroups, total });
        }

        if (!equal(application, prevApplication) && userCounts.critGroups.length === 0) {
            getUserCounts();
        }
    }, [application]);

    useEffect(() => {
        if (userCountsForValues) {
            setUserCounts(userCountsForValues);
        }

    }, [userCountsForValues]);

    if (application)
        return (
            <div>
                <span className={`${isNew && classes.bold || classes.normal}`}>{parse(generateCriteriaAsText(application, existingApplication, appState, userCounts))}</span>
            </div>
        )
    if (values)
        return (
            <div>
                {parse(generateCriteriaAsTextFromFormValues(values, appState, userCounts))}
            </div>
        )
    return null;
}

export default DisplayCriteria;
