import { CurrentUser, UserActionTypes } from "./types";
import { createReducer } from 'typesafe-actions';
import { UserActionType } from './actions';

export const initialState: CurrentUser = {
  userLoaded: false
};

const reducer = createReducer<CurrentUser, UserActionType>(initialState, {
  [UserActionTypes.SET_CURRENT_USER]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [UserActionTypes.SET_IMPERSONATED_USER]: (state, action) => {
    return { ...state, impersonatedUser: action.payload };
  },
  [UserActionTypes.CLEAR_IMPERSONATED_USER]: (state, action) => {
    return { ...state, impersonatedUser: undefined };
  }
});

export { reducer as userReducer };

