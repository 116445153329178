import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	root: {
		marginLeft: '0px',
		position: 'relative'
	},
	textContainer: {
		backgroundColor: 'white',
	},
	contactTable: {
		width: '50%',
	},
	contactTableHead: {
		fontWeight: 600,
		fontSize: '16px'
	},
	contactTableRow: {
		fontSize: '16px'
	},
	
}));