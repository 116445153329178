import { takeLatest, all, put, fork } from 'redux-saga/effects';
import { setRolesForUser, GetUserRoles } from './actions';
import { RolesActionTypes, Roles, getRolesEnum} from './types';
import { UserRolesAPI, getRolesForUser } from '../../API/roles';
import { getApplicationsForOwner } from '../../API/application';

export function* getUserRoles(action: GetUserRoles) : any  {
    
    const { payload: {identity_id, accessTokenMyDanfossApi, accessTokenMyDanfossAccountApi} } = action;
    try {
        let [userRoles, applicationsForOwner] = yield all([
            getRolesForUser(identity_id, accessTokenMyDanfossApi),
            getApplicationsForOwner(identity_id, accessTokenMyDanfossAccountApi)
        ]);

        const roles: Roles[] = (userRoles as UserRolesAPI).roles
            .map(role => (getRolesEnum(role)))
            .filter(role => role !== Roles.NONE);
        if ((applicationsForOwner as string[]).length > 0) {
            roles.push(Roles.APPLICATION_OWNER);
        }   
        yield put(setRolesForUser({roles, rolesLoaded: true}));
    } catch (ex) {
        yield put(setRolesForUser({roles: [Roles.NONE], rolesLoaded: true}));
    }

}    

export function* watchGetUserRoles() {
    yield takeLatest(RolesActionTypes.GET_USER_ROLES, getUserRoles);
}

export function* rolesSagas() {
    yield all([
        fork(watchGetUserRoles)
    ]);
}

export default rolesSagas;