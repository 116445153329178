import { UserInfo } from "../../API/users";
import { LoadingStatus } from "../types";


export interface ContactInfo extends UserInfo {
}

export interface Contacts {
    loadingStatus: LoadingStatus,
    contactInfo: ContactInfo[];
}

export enum ContactInfoActionTypes {
    SET_CONTACT_INFO = '@@contacts/SET_CONTACT_INFO',
    SET_CONTACT_LOADING_STATUS = '@@contacts/SET_CONTACT_LOADING_STATUS',
    GET_CONTACT_INFO = '@@contacts/GET_CONTACT_INFO',
}

