import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { ClickOutsideWrapperType } from './types'
import equal from 'deep-equal';
import FormLeaveConfirm from '../FormLeaveConfirm';
import { isIE } from 'mobile-device-detect';


const ClickOutsideWrapper: React.FC<ClickOutsideWrapperType> = ({ children }) => {
    const [showLeaveConfirm, setShowLeaveConfirm] = useState(false);
	const [eventSaved, setEventSaved] = useState(false);
    const [clickEvent, setClickEvent] = useState({} as MouseEvent);
    const [isFormDirty, setIsFormDirty] = useState(true);
    const [isobjectsSet, setIsObjectSet] = useState(false);
    const [oldObject, setOldObject] = useState({});
    const [newObject, setNewObject] = useState({});
    const [resetForm, setResetForm] = useState({} as Function);
    const [submitButton, setSubmitButton] = useState({} as HTMLButtonElement);
    
    const isFormDirtyRef = React.useRef(isFormDirty);
    const oldObjectRef = React.useRef(oldObject);
    const newObjectRef = React.useRef(newObject);
	const eventRef = React.useRef(clickEvent);
    const resetFormRef = React.useRef(() => resetForm());
    const submitButtonRef = React.useRef(submitButton);
    
    const setIsFormDirtyStataAndRef = (isDirty: boolean) => {
		isFormDirtyRef.current = isDirty;
		setIsFormDirty(isDirty);
	};

    const setResetFormAndRef = (resetForm: Function) => {
		resetFormRef.current = () => resetForm();
	};

    const setSubmitButtonAndRef = (submitButton: HTMLButtonElement) => {
		submitButtonRef.current = submitButton;
	};

    const setClickEventStateAndRef = (event: MouseEvent) => {
		eventRef.current = event;
		setClickEvent(event);
	};

    const setObjectStateAndRef = (oldObject: any, newObject: any) => {
        oldObjectRef.current = oldObject;
        newObjectRef.current = newObject;
	};

    useEffect(() => {

        if (isobjectsSet) {
            document.addEventListener('click', (event: any) => handleClickOutside(event, isFormDirtyRef, oldObjectRef, newObjectRef, eventSaved), true);

            return () => {
                // When unmounting make sure to remove the eventListener again
                document.removeEventListener('click', (event: any) => handleClickOutside(event, isFormDirtyRef, oldObjectRef, newObjectRef, eventSaved), true);
            }
        }
	}, [isobjectsSet]);

    const setLeaveConfirmValues = (oldObject: any, newObject: any, isFormDirty: boolean, resetForm: Function, submitButton: HTMLButtonElement) => {
        setObjectStateAndRef(oldObject, newObject);
        setIsFormDirtyStataAndRef(isFormDirty);
        setResetFormAndRef(() => resetForm());
        setSubmitButtonAndRef(submitButton);
        setIsObjectSet(true);
    }    

    const shouldClickTriggerWarning = (event: any) => {
        return ((event.target.parentNode && event.target.parentNode.className && event.target.parentNode.className.indexOf("triggerWarning") > -1) || (event.target && event.target.className && event.target.className.indexOf("triggerWarning") > -1))
    }

    const handleClickOutside = (event: any, isFormDirtyRef: React.MutableRefObject<boolean>, oldObject: React.MutableRefObject<any>, newObject: any, eventSaved: boolean) => {
        const domNode = document.getElementById('clickoutsideContainer');

        if (isFormDirtyRef.current && (!domNode || (!domNode.contains(event.target) || event.target.parentNode.className.indexOf("triggerWarning") > -1)) && shouldClickTriggerWarning(event)) {
            if (wasDataUpdated(oldObject, newObject) && !eventSaved) {
                if (event.target.tagName.toLowerCase() === 'select') {
                    event.target.disabled = true;
                }
                event.preventDefault();
                event.stopPropagation();
                setShowLeaveConfirm(true);
                setClickEventStateAndRef(event);
                setEventSaved(true);
            } else {
                setShowLeaveConfirm(false);
                setEventSaved(false);
            }
        }
    }

    const wasDataUpdated = (oldObjectRef: React.MutableRefObject<any>, newObjectRef: React.MutableRefObject<any>) => {
        if (!equal(oldObjectRef.current, newObjectRef.current)) {
            return true;
        }
    
        return false;
    }

    const hideLeaveConfirm = () => {
        if (isIE) {
            setIsFormDirtyStataAndRef(false);
        }
        // @ts-ignore
        if (eventRef.current.target.tagName.toLowerCase() === 'select') {
            // @ts-ignore
            eventRef.current.target.disabled = false;
        }
        setEventSaved(false);
        setShowLeaveConfirm(false);
        setClickEventStateAndRef({} as MouseEvent);
        submitButtonRef && submitButtonRef?.current?.click();
    }

    const fireEvent = (event: any, eventRef: React.MutableRefObject<MouseEvent>, clickEvent: MouseEvent) => {

        if (eventRef.current && Object.keys(eventRef.current).length > 0) {
            const clickEventToFire = eventRef.current;

            event.preventDefault();
            event.stopPropagation();
            resetFormRef.current();
            setIsFormDirtyStataAndRef(false);
            setEventSaved(false);
            setShowLeaveConfirm(false);

            // @ts-ignore
            if (eventRef.current.target.tagName.toLowerCase() === 'select') {
                // @ts-ignore
                eventRef.current.target.disabled = false;
            }

            setClickEventStateAndRef({} as MouseEvent);
            
            const new_e = new MouseEvent(clickEventToFire.type, clickEventToFire);
    
            clickEvent.target && clickEvent.target.dispatchEvent(new_e)
    
        }
    }

    return (
        <Grid container>
            {showLeaveConfirm && (
            <FormLeaveConfirm
				setShowLeave={() => setShowLeaveConfirm(false)}
                onSave={() => hideLeaveConfirm()}
				onLeave={(event: any) => fireEvent(event, eventRef, clickEvent)}
			/>)}
            {React.cloneElement(children, { setLeaveConfirmValues: (oldObj: any, newObj: any, isDirty: boolean, resetForm: Function, submitButton: HTMLButtonElement) => setLeaveConfirmValues(oldObj, newObj, isDirty, resetForm, submitButton) })}
        </Grid>
    );
}

export default ClickOutsideWrapper;