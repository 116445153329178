import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { UserInfo } from '../../../../API/users';
import { getUserActivityHistory, UserActivityHistory } from '../../../../API/activityHistory';
import { useAuth0 } from '@auth0/auth0-react';
import settings from '../../../../Config/settings';
import { Spinner } from '@danfoss/webex-ui';
import EmailLogsTable from './EmailLogsTable';
import SuppressionsTable from './SuppressionsTable';
import DeleteBounce from './DeleteBounce';


const hasHistory = (history: UserActivityHistory | null): history is UserActivityHistory => !!history && (
    history.emails.length > 0 ||
    history.bounces.length > 0 ||
    history.blocks.length > 0);

export interface UserEmailsInfoProps {
    user: UserInfo;
}

const UserEmailsInfo: React.FC<UserEmailsInfoProps> = ({ user }) => {
    const classes = useStyles();
    const [history, setHistory] = useState<UserActivityHistory | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const fetchHistory = async () => {
            setIsLoading(true);

            const accessToken = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
            const activityHistory = await getUserActivityHistory(accessToken, user.auth0_user_id);

            setHistory(activityHistory);
            setIsLoading(false);
        };

        fetchHistory();
    }, [user, getAccessTokenSilently]);

    if (isLoading) {
        return <Spinner className={classes.spinner} visible />;
    }

    return hasHistory(history) ? (
        <Grid className={classes.details} container spacing={4}>
            {history.bounces.length > 0 && (
                <Grid className={classes.tableContainer} container item xs={12}>
                    <Grid container alignItems='flex-end' justifyContent='space-between' item>
                        <Grid item>
                            <Typography variant='h6'>
                                Bounces
                            </Typography>
                        </Grid>
                        <Grid item>
                            <DeleteBounce user={user} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <SuppressionsTable data={history.bounces} />
                    </Grid>
                </Grid>
            )}
            {history.blocks.length > 0 && (
                <Grid className={classes.tableContainer} container item xs={12}>
                    <Typography variant='h6'>
                        Blocks
                    </Typography>
                    <SuppressionsTable data={history.blocks} />
                </Grid>
            )}
            {history.emails.length > 0 && (
                <Grid className={classes.tableContainer} container item xs={12}>
                    <Typography variant='h6'>
                        Sent emails
                    </Typography>
                    <EmailLogsTable data={history.emails} />
                </Grid>
            )}
        </Grid>
    ) : (
        <Typography className={classes.noData} variant='h6'>
            No data
        </Typography>
    );
}

export default UserEmailsInfo;
