import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from './styles';
import { BreadCrumb, BreadCrumbs as BreadCrumsType } from './types'
import { Link as RouterLink, useLocation } from 'react-router-dom';

const BreadCrumbs: React.FC<BreadCrumsType> = ({ items }) => {
    const classes = useStyles();
    const location = useLocation();

    const [breadCrumbItems, setBreadCrumbItems] = useState([{ text: 'Home', link: "/" }] as BreadCrumb[]);

    useEffect(() => {
        setBreadCrumbItems(breadCrumbItems.concat(items))
    }, []);

    return location.pathname !== '/' ? (
        <React.Fragment>
            <Grid container className={classes.breadCrumbContainer}>
                {breadCrumbItems.map((breadCrumb, index) => {
                    return (
                        <span key={breadCrumb.text} className="triggerWarning">
                            <RouterLink to={breadCrumb.link} className={classes.breadCrumb}>{breadCrumb.text}</RouterLink>
                            {index !== (breadCrumbItems.length - 1) && <span className={classes.breadCrumbItemSpacer}>/</span>}
                        </span>
                    );
                })}
            </Grid>
        </React.Fragment>
    ) : null;
}
      
export default BreadCrumbs;