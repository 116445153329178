import fetchApiWrapper from './fetchAPIWrapper';
import settings from '../Config/settings';

interface Response {
    ok: boolean
}

export interface RelevantWebsites {
    relevant_websites: RelevantWebsite[],
}

export interface RelevantWebsite {
    app_type: string,
    fallback_description: string,
    fallback_display_name: string,
    fallback_url: string,
    name: string,
    variants: RelevantWebsiteVariant[],
    id: string,
    published: boolean
}

export interface PreviewRelevantWebsites {
    relevant_websites: PreviewRelevantWebsite[]
  }

  export interface PreviewRelevantWebsite {
    id: string,
    name: string,
    display_name: string,
    description: string,
    url: string,
    dip_app_type: string,
    app_type: string,
    published: boolean
  }

export interface RelevantWebsiteVariant {
    display_name: string,
    description: string,
    url: string,
    criteria: RelevantWebsiteCriterias[]
}

export interface RelevantWebsiteCriteria {
        path: string,
        values: string[]
}

export interface RelevantWebsiteCriterias {
    [index: number]: RelevantWebsiteCriteria
}


export const getRelevantWebsite = (id: string, isDraft: string, accessToken: string): Promise<RelevantWebsite> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)
    
    return fetchApiWrapper(`${settings.myDanfossApi.endpoint}/admin-actions/relevant-websites/${id}?is_draft=${isDraft}`, {
        headers: requestHeaders,
        method: 'GET',
    });
}

export const deleteRelevantWebsite = (id: string, isDraft: string, accessToken: string): Promise<RelevantWebsite> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)
    
    return fetchApiWrapper(`${settings.myDanfossApi.endpoint}/admin-actions/relevant-websites/${id}?is_draft=${isDraft}`, {
        headers: requestHeaders,
        method: 'DELETE',
    });
}

export const getRelevantWebsites = (accessToken: string): Promise<RelevantWebsites> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)
    
    return fetchApiWrapper(`${settings.myDanfossApi.endpoint}/admin-actions/relevant-websites`, {
        headers: requestHeaders,
        method: 'GET',
    });
}

export const updateRelevantWebsite = (id: string, relevantWebsite: RelevantWebsite, accessToken: string, draft: boolean): Promise<Response> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper(`${settings.myDanfossApi.endpoint}/admin-actions/relevant-websites/${id}?is_draft=${draft}`, {
        body: JSON.stringify(relevantWebsite),
        headers: requestHeaders,
        method: 'PUT',
    });
}

export const getPrevievRelevantWebsites = (accessToken: string, country?: string, language?: string): Promise<PreviewRelevantWebsites> => {

    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)
    
    let url = `${settings.myDanfossApi.endpoint}/previews/relevant-websites`

    if (country) {
        url = url + `?country=${country}`;
        
        if (language) {
            url = url + `&language=${language}`
        }
    } else {
        if (language) {
            url = url + `?language=${language}`
        }
    }

    return fetchApiWrapper<PreviewRelevantWebsites>(url, {
        headers: requestHeaders,
        method: 'GET',
      });
  }