
export const XlsxEncryptionError = 'The Excel file is Encrypted because of the selected Sensitivity level.<br/>Please change the Sensitivity level in the Excel file to Business unrestricted and retry the import'
export const XlsxParsingError = 'Error parsing the Excel workbook.<br/>{error-message}';

export interface ImportErrorsPopupProps {
	importTypeProgress: ImportProgress,
    errors: ApplicationErrors[],
    generalError?: string
	onCancelImport: Function,
}	

export interface ImportWarningsPopupProps {
	importTypeProgress: ImportProgress,
    warnings: ApplicationWarnings[],
	onCancelImport: Function,
    onContinueImport: Function,
    importButtonTypeText: string,
    importOtherButtonTypeText?: string,
    showOtherFilesButton: boolean
}	

export interface ImportConfirmPopupProps {
	importTypeProgress: ImportProgress,
    importItemNames: string[],
    importTypeText: string,
    importButtonTypeText: string,
	onCancelImport: Function,
    onPerformImport: Function
}	

export enum ImportProgress {
    NotStarted,
    ImportFailed,
    HandleErrors,
    HandleWarnings,
    ConfirmImport
}

export interface ApplicationErrors {
    client_name: string,
    errors: string[]
}

export interface ApplicationWarnings {
    client_name: string,
    warnings: string[]
}

