import { ContactInfo } from "../../Store/Contacts/types";
import { replaceLast } from "../../Utils";

export const getContacts = (contacts: ContactInfo[]) => {
    if (contacts && contacts.length > 0) {
        const contactsText = contacts.map(contact => contact.name).join(', ');
        return replaceLast(contactsText, ',', ' or');
    }

    return ('missing contact information')
}
