import { action } from 'typesafe-actions';
import { Roles, RolesActionTypes, UserRoles } from './types';

export interface GetUserRoles {
	type: RolesActionTypes.GET_USER_ROLES;
    payload: {
        identity_id: string,
        accessTokenMyDanfossApi: string,
        accessTokenMyDanfossAccountApi: string;
    };
}

export interface SetUserRoles {
    type: RolesActionTypes.SET_USER_ROLES;
    payload: UserRoles;
}

export interface SetImpersonatedUserRoles {
    type: RolesActionTypes.SET_IMPERSONATED_USER_ROLES;
    payload: Roles[];
}

export interface ClearImpersonatedUserRoles {
    type: RolesActionTypes.CLEAR_IMPERSONATED_USER_ROLES;
}

export const getRolesForUser = (identity_id: string, accessTokenMyDanfossApi: string, accessTokenMyDanfossAccountApi: string): GetUserRoles =>
    action(RolesActionTypes.GET_USER_ROLES, {identity_id, accessTokenMyDanfossApi, accessTokenMyDanfossAccountApi});
    
export const setRolesForUser = (userRoles: UserRoles): SetUserRoles =>
	action(RolesActionTypes.SET_USER_ROLES, userRoles);

export const setRolesForImpersonatedUser = (userRoles: Roles[]): SetImpersonatedUserRoles =>
	action(RolesActionTypes.SET_IMPERSONATED_USER_ROLES, userRoles);

export const clearRolesForImpersonatedUser = (): ClearImpersonatedUserRoles =>
	action(RolesActionTypes.CLEAR_IMPERSONATED_USER_ROLES);

export type RolesActionType = SetUserRoles | SetImpersonatedUserRoles | ClearImpersonatedUserRoles;