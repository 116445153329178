import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer';
import { Grid, Container } from '@material-ui/core';
import { Spinner } from '@danfoss/webex-ui';
import { useStyles } from './styles';
import { MainProps } from './types';
import BreadCrumbs from '../BreadCrumbs';
import { useAuth0 } from '@auth0/auth0-react'
import './styles.scss';
import Title from './Title';

const Main: React.FC<MainProps> = ({ children, breadCrumbs, showBreadCrumbs = true, showTitleOnPage = false }) => {
	const classes = useStyles();
	const { isLoading } = useAuth0();

	return isLoading ? <Spinner  visible={true} /> : (
		<React.Fragment>
			<Header/>
			<Container id="mainContent" className={`myAccount flexgrow ${classes.contentContainer}`}>
				<Grid container className={classes.container}>
					<Grid item xs={12} className={classes.fullWidth}>
						<Title showTitleOnPage={showTitleOnPage} />
						{showBreadCrumbs && <BreadCrumbs items={breadCrumbs?.items || []}/>}
						<Grid container className={classes.contentInnerContainer}>
							{children}
						</Grid>
					</Grid>
				</Grid>
			</Container>
			<Footer />
		</React.Fragment>
	);
};

export default Main;
