import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	rolesContainer: {
		marginTop: '50px'
	},
	heading: {
		fontSize: '20px',
		fontWeight: 600,
		width: '100%'
	},
}));