import { takeLatest, all, put, fork, select } from 'redux-saga/effects';
import { AppState } from '..';
import { getUsersByIdentityId } from '../../API/users';
import { GetContactInfo, SetContactInfo, SetContactLoadingStatus } from './actions';
import { ContactInfoActionTypes, ContactInfo, Contacts} from './types';

export function* getContactInfo(action: GetContactInfo) : any  {
    const { payload: {contact_ids, accessTokenMyDanfossApi} } = action;
    // No contacts to load
    if (contact_ids.length === 0) {
        yield put(SetContactLoadingStatus({loaded: true, loading: false, failed: false}));
        return;
    }

	const { contacts } = (yield select((state: AppState) => {
		return { contacts: state.contacts };
	})) as { contacts: Contacts; };

	if (contacts.loadingStatus.loading || contacts.loadingStatus.loaded) {
		return;
	}    
    try {
        yield put(SetContactLoadingStatus({loading: true, loaded: false, failed: false}));
        const contactsInfo: ContactInfo[] = yield getUsersByIdentityId(accessTokenMyDanfossApi, contact_ids); 
        yield put(SetContactInfo(contactsInfo));
        yield put(SetContactLoadingStatus({loaded: true, loading: false, failed: false}));
    } catch (ex) {
        yield put(SetContactLoadingStatus({loaded: true, loading: false, failed: true}));
    }

}    

export function* watchGetContactInfo() {
    yield takeLatest(ContactInfoActionTypes.GET_CONTACT_INFO, getContactInfo);
}

export function* contactsSagas() {
    yield all([
        fork(watchGetContactInfo)
    ]);
}

export default contactsSagas;