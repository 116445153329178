import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../Store';
import { CriteriaValuesProps, criterionAttributes, CriterionOptions } from './types';
import { getReferenceValues, getApplicationsValues, getCountryValues, getLanguageValues, getBooleanValues } from './utils'
import { LabeledMultiSelectbox, LabeledSelectbox } from '../../RenderFields/RenderFields';
import { Field } from 'redux-form';

const CriteriaValues: React.FC<CriteriaValuesProps> = ({ criterionType, defaultValue, name }) => {
    const [options, setOptions] = useState([] as CriterionOptions[] | undefined);
    const references = useSelector((state: AppState) => state.references);
    const location = useSelector((state: AppState) => state.location);
    const applications = useSelector((state: AppState) => state.applications);
    const criterionAttribute = criterionAttributes.find(attr => attr.type === criterionType);

    useEffect(() => {
        switch (criterionType) {
            case 'area_of_interest':
            case 'company_type':
            case 'department':
                setOptions(getReferenceValues(references, criterionAttribute && criterionAttribute.stateProperty));
                break;
            case 'applications':
                setOptions(getApplicationsValues(applications));
                break;
            case 'country':
                setOptions(getCountryValues(location));
                break;
            case 'language':
                setOptions(getLanguageValues());
                break;
            case 'is_workforce':
                setOptions(getBooleanValues());
                break;
                default:
                setOptions([]);
                break;    
        }
    }
    , [criterionType]);

    // const component = criterionAttribute?.multiselection && LabeledMultiSelectbox || LabeledSelectbox;
    return (
        <Field
            name={name}
            component={LabeledMultiSelectbox}
            multiple={criterionAttribute?.multiselection}
            defaultValue={defaultValue}
            options={options}
            disabled={!criterionType}
        />
    )
}

export default CriteriaValues;
