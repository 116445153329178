import fetchApiWrapper from './fetchAPIWrapper';
import settings from '../Config/settings';

export interface Auth0IdpDomainConnection {
  connection_name: string,
  display_name?: string,
  domains: string[]
}

export const getIdpDomainConnections = (): Promise<Auth0IdpDomainConnection[]> => {
  const requestHeaders = new Headers();
  requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
  
  return fetchApiWrapper(`${settings.myDanfossAccountApi.endpoint}/connections/idp-domains`, {
    headers: requestHeaders,
    method: 'GET',
  });
};

