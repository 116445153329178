import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    bannerContainer: {
        position: 'relative'
    },
    bannerSubContainer: {
        margin: '10px 0',
        [theme.breakpoints.up('xs')]: {
            margin: '10px 24px 14px 0',
            width: '302px'
        },
        [theme.breakpoints.only('xs')]: {
            margin: '10px 24px 14px 0',
            width: '100%',
            maxWidth: '100%'
        },
        padding: '36px 15px 0 0 !important',
        display: 'flex',
        maxWidth: '302px',
        minHeight: '160px',
        cursor: 'pointer',
        borderRadius: '0px 0px 2px 2px',
        position: 'relative',
        backgroundColor: colors.webexuiGraySoft
    },
    bannerIconContainer: {
        maxWidth: '86px',
        marginRight: '26px',
        height: '90px',
        textAlign: 'center'
    },
    bannerHelper: {
        height: '100%',
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    bannerArrowSubContainer: {
        display: 'inline-block',
        verticalAlign: 'middle',
        lineHeight: '8px'
    },
    bannerImage: {
        width: '36px'
    },
    bannerTextContainer: {
        fontSize: '20px',
        letterSpacing: '0.1px',
        lineHeight: '24px',
        fontWeight: 600,
        color: colors.webexuiDarkGrayish,
        maxWidth: '300px',
        height: '90px'
    },
    bannerTextSubContainer: {
        verticalAlign: 'middle',
        display: 'inline-block',
        marginLeft: '20px'
    },
    informationIcon: {
        cursor: 'pointer',
        width: '24px'
    },
    iconContainer: {
        position: 'absolute',
        top: '20px',
        right: '35px',
        [theme.breakpoints.only('xs')]: {
            top: '18px',
            right: '8px'
        },
        zIndex: 1
    },
    editIcon: {
        right: '35px',
        width: '24px',
        height: '24px',
        top: '4px',
        cursor: 'pointer'
    },
    informationIconContainer: {
        display: 'table-cell',
        paddingLeft: '12px'
    },
    displayIconContainer: {
        display: 'table-cell'
    },
    itemHidden: {
        background: '#F4F6F6'
    },
    bannerHeader: {
        fontSize: '24px',
        fontWeight: 'bold',
        margin: '20px 0 16px'
    },
    bannerBody: {
        fontSize: '16px',
        color: '#686868',
        marginBottom: '20px'
    },
    bannerPopupClose: {
		cursor: 'pointer',
		width: '18px',
		[theme.breakpoints.only('xs')]: {
			margin: '10px -16px',
		},
		[theme.breakpoints.up('sm')]: {
			right: '-20px'
		},
		position: 'absolute'
	}
})
);