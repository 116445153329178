import React, { useState } from 'react';
import Main from '../Main/Main';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './styles';
import Banner from '../Banner'
import BannerPopup from '../Banner/BannerPopup';
import ModalPopup from '../Modal/ModalPopup';
import { Roles } from '../../Store/Roles/types';
import DataLoader from '../DataLoader';
import { getUserRoles } from '../../Utils';
import { useSelector } from 'react-redux';
import { AppState } from '@auth0/auth0-react';
import { Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';
import { getContacts } from '../Publish/utils';
import AccessRights from '../AccessRights';

const Home: React.FC = () => {
	const classes = useStyles();
	const [showBannerPopup, setShowBannerPopup] = useState(false);
	const [bannerHeader, setBannerHeader] = useState('');
	const [bannerBody, setBannerBody] = useState('');
	const userRoles = useSelector((state: AppState) => getUserRoles(state.userRoles));
	const contacts = useSelector((state: AppState) => state.contacts.contactInfo);

	const handleBannerInformationClick = (bannerHeader: string, bannerBody: string) => {
		setBannerBody(bannerBody);
		setBannerHeader(bannerHeader);
		setShowBannerPopup(true);
	}

	return (
		<Main showTitleOnPage={true}>
			{showBannerPopup && (
				<Grid container className={classes.bannerPopupContainer}>
					<ModalPopup paperClassName={classes.bannerPopup}>
						<BannerPopup bannerHeader={bannerHeader} bannerBody={bannerBody} toggleBannerPopup={setShowBannerPopup} />
					</ModalPopup>
				</Grid>
			)
			}
			<DataLoader contactsRequired={true}>

				<Grid container>

					<Banner
						bannerText="Application translation"
						bannerURL={"/application/translation"}
						bannerInformationClick={() => handleBannerInformationClick("Application translation", "Edit application texts like header, description, login and sign-up texts")}
						bannerClass={classes.banner}
						bannerTextClass={classes.bannerText}
						allowedRoles={[Roles.APPLICATION_EDITOR, Roles.APPLICATION_OWNER, Roles.DASHBOARD_ADMIN]}
					/>
					<Banner
						bannerText="Recommended application criteria"
						bannerURL={"/application/criteria"}
						bannerInformationClick={() => handleBannerInformationClick("Recommended application criteria", "Setup criteria for when applications should be shown as recommended app")}
						bannerClass={classes.banner}
						bannerTextClass={classes.bannerText}
						allowedRoles={[Roles.RECOMMENDED_APPLICATION_EDITOR, Roles.APPLICATION_OWNER, Roles.DASHBOARD_ADMIN]}
					/>
					<Banner
						bannerText="Relevant websites"
						bannerURL={"/websiteoverview"}
						bannerInformationClick={() => handleBannerInformationClick("Relevant websites", "Edit relevant websites like translations and variants")}
						bannerClass={classes.banner}
						bannerTextClass={classes.bannerText}
						allowedRoles={[Roles.RELEVANT_WEBSITE_EDITOR, Roles.DASHBOARD_ADMIN]}
					/>
					<Banner
						bannerText="Preview overview"
						bannerURL={"/preview"}
						bannerInformationClick={() => handleBannerInformationClick("Preview overview", "Preview outstanding changes´ effect on the user´s experience ")}
						bannerClass={classes.banner}
						bannerTextClass={classes.bannerText}
						// allowedRoles={[Roles.APPLICATION_EDITOR, Roles.APPLICATION_OWNER, Roles.APPLICATION_PUBLISHER, Roles.DASHBOARD_ADMIN]}
						allowedRoles={[]}
					/>
					<Banner
						bannerText="Publish overview"
						bannerURL={"/publish"}
						bannerInformationClick={() => handleBannerInformationClick("Publish overview", "Publish newly added application texts like header, description, login and sign-up texts")}
						bannerClass={classes.banner}
						bannerTextClass={classes.bannerText}
						allowedRoles={[Roles.APPLICATION_OWNER, Roles.APPLICATION_PUBLISHER, Roles.RECOMMENDED_APPLICATION_PUBLISHER, Roles.RELEVANT_WEBSITE_PUBLISHER, Roles.DASHBOARD_ADMIN]}
					/>
					<Banner
						bannerText="User roles"
						bannerURL={"/userroles"}
						bannerInformationClick={() => handleBannerInformationClick("User roles", "Assign roles to users")}
						bannerClass={classes.banner}
						bannerTextClass={classes.bannerText}
						allowedRoles={[Roles.DASHBOARD_ADMIN]}
					/>
					<Banner
						bannerText="Maintain application user roles"
						bannerURL={"/userroles/approles"}
						bannerInformationClick={() => handleBannerInformationClick("Maintain application roles", "Add and delete user roles on your applications")}
						bannerClass={classes.banner}
						bannerTextClass={classes.bannerText}
						allowedRoles={[Roles.APPLICATION_OWNER]}
						denyRoles={[Roles.DASHBOARD_ADMIN]}
					/>
					<Banner
						bannerText="User support"
						bannerURL={"/usersupport"}
						bannerInformationClick={() => handleBannerInformationClick("User support", "Verify if the user exists and confirm whether they have received the email")}
						bannerClass={classes.banner}
						bannerTextClass={classes.bannerText}
						allowedRoles={[Roles.APPLICATION_OWNER, Roles.DASHBOARD_ADMIN]}
					/>
					{/* <Banner
					bannerText="List application owners"
					bannerURL={"/listappowners"}
					bannerInformationClick={() => handleBannerInformationClick("List application owners", "View list of application owners")}
					bannerClass={classes.banner}
					bannerTextClass={classes.bannerText}
					allowedRoles={[]}
					denyRoles={[Roles.DASHBOARD_ADMIN]}
				/> */}
					<Banner
						bannerText="Application setup"
						bannerURL={"/application/setup"}
						bannerInformationClick={() => handleBannerInformationClick("Application setup", "Technical setup for applications")}
						bannerClass={classes.banner}
						bannerTextClass={classes.bannerText}
						allowedRoles={[Roles.APPLICATION_OWNER, Roles.DASHBOARD_ADMIN, Roles.APPLICATION_SETUP_VIEWER]}
					/>
				</Grid>
				{userRoles.length === 0 &&
					<Grid container className={classes.textContainer}>
						<Grid item className={classes.textLine}>You only have view access to this application.</Grid>
						<Grid item className={classes.textLine}>You need to submit an access request for the app you want to manage.</Grid>
						<Grid item className={classes.textLine}>Contact the application owner to get access to a specific application. See list of applications and owners <RouterLink to="/listappowners">here</RouterLink></Grid>
						<Grid item className={classes.textLine}>Otherwise contact {getContacts(contacts)}.</Grid>
					</Grid>
				}
				{!userRoles.includes(Roles.DASHBOARD_ADMIN) &&
					<AccessRights heading='Your access rights' />
				}
			</DataLoader>
		</Main>
	);
}

export default Home;
