import { combineReducers } from 'redux';
import { userReducer } from './User/reducers';
import { rolesReducer } from './Roles/reducers';
import { ReferencesAppStateModel } from './References/types';
import { referencesReducer } from './References/reducers';
import { LocationAppStateModel } from './Location/types';
import { locationReducer } from './Location/reducers';
import { ApplicationsAppStateModel } from './Applications/types';
import { applicationsReducer } from './Applications/reducers';
import { UserRoles } from './Roles/types';
import { CurrentUser } from './User/types'
import { reducer as formReducer, FormStateMap } from 'redux-form';
import { Contacts } from './Contacts/types';
import { contactsReducer } from './Contacts/reducers';


// Top-level state object
export interface AppState {
	form?: FormStateMap;
	user: CurrentUser,
	userRoles: UserRoles;
	references: ReferencesAppStateModel;
	location: LocationAppStateModel;
	applications: ApplicationsAppStateModel;
	contacts: Contacts
}

export const createRootReducer = () =>
	combineReducers({
		form: formReducer,
		user: userReducer,
		userRoles: rolesReducer,
		references: referencesReducer,
		location: locationReducer,
		applications: applicationsReducer,
		contacts: contactsReducer
	});
