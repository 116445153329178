import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	toggleButtonContainer: {
		marginBottom: '20px',
		justifyContent: 'end'
	},
	applicationListGridHeading: {
		backgroundColor: colors.webexuiGraySoft,
		minHeight: '40px',
		fontWeight: 600
	},
	applicationListGridItem: {
		padding: '5px 8px 5px 8px',
		marginTop: '5px'
	},
	applicationListGridEdit: {
		width: '15%',
		[theme.breakpoints.down('md')]: {
			minWidth: '140px'
		}
	},
	applicationListGridEdit2: {
		width: '10%',
		[theme.breakpoints.down('md')]: {
			minWidth: '80px'
		},
		alignSelf: 'flex-end',
		paddingBottom: '14px'
	},
	applicationListGridName: {
		width: '20%'
	},
	applicationListGridCriteria: {
		width: '45%',
	},
	applicationListGridAllUsers: {
		marginLeft: '-12px'
	},
	applicationListGridAttribute: {
		width: '30%',
		[theme.breakpoints.down('md')]: {
			width: '35%',
		},
		alignSelf: 'flex-end'
	},
	applicationListGridAndOr: {
		width: '7%',
		minWidth: '40px',
		alignSelf: 'flex-start',
		paddingLeft: '5px',
		paddingBottom: '5px'
	},
	applicationListGridAndOrDiv: {
		paddingLeft: '25px',
		paddingTop: '17px',
		[theme.breakpoints.down('md')]: {
			paddingLeft: '15px',
		},
	},
	applicationListGridOneOf: {
		width: '10%',
		minWidth: '40px',
		alignSelf: 'flex-start',
		padding: '5px 0px 0px 0px',
		[theme.breakpoints.down('md')]: {
			width: '7%',
		}
	},
	applicationListGridIsDiv: {
		paddingTop: '17px',
		textAlign: 'center',
		'&::before': {
			content: '"is"',
		}
	},
	applicationListGridIncludesDiv: {
		paddingTop: '17px',
		textAlign: 'center',
		'&::before': {
			content: '"includes"',
		},
		[theme.breakpoints.down('md')]: {
			'&::before': {
				content: '"incl."',
			},
		}
	},
	applicationListGridOneOfDiv: {
		paddingTop: '17px',
		textAlign: 'center',
		'&::before': {
			content: '"is one of"',
		},
		[theme.breakpoints.down('md')]: {
			'&::before': {
				content: '"is in"',
			},
		}
	},
	applicationListGridAtLeastOneOfDiv: {
		paddingTop: '5px',
		textAlign: 'center',
		'&::before': {
			content: '"incl. at least one of"',
		},
		[theme.breakpoints.down('md')]: {
			paddingTop: '17px',
			'&::before': {
				content: '"is in"',
			},
		}
	},
	applicationListGridGroupHeading: {
		width: '100%',
		fontWeight: 'bold'
	},
	applicationListGridValues: {
		width: '30%',
		[theme.breakpoints.down('md')]: {
			width: '35%',
		},
		alignSelf: 'flex-end'
	},
	applicationListGridCritEdit: {
		width: '65%',
		[theme.breakpoints.down('md')]: {
			width: '60%',
		}
	},
	noErrorPadding: {
		paddingTop: '28px'
	},
	applicationListGridStatus: {
		width: '15%',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
	applicationListGridAddCrit: {
		paddingLeft: '0px'
	},
	applicationListGrid: {
		minHeight: '40px'
	},
	critListGrid: {
		marginTop: '-5px',
		marginLeft: '-10px',
		minHeight: '30px'
	},
	applicationListGridCopyFrom: {
		borderColor: 'red',
		borderWidth: '2px',
		borderRadius: '4px',
		borderStyle: 'dotted'
	},
	applicationListGridOdd: {
		backgroundColor: colors.webexuiGraySoft
	},
	applicationListGridEven: {
		backgroundColor: colors.webexuiWhite
	},
	editGridButton: {
		marginBottom: '12px',
		height: '30px',
		minWidth: '160px',
		[theme.breakpoints.down('md')]: {
			minWidth: '130px',
			padding: '7px 7px 7px 7px'
		}
	},
	editOwnerGridButton: {
		marginTop: '12px',
		height: '30px'
	},
	button: {
		marginRight: '20px'
	},
	bold: {
		fontWeight: 'bold',
	},
	normal: {
		fontWeight: 'normal',
	},
	buttonHidden: {
		display: 'none'
	},
	buttonLabel: {
		border: '1px solid #d8d8d9',
		padding: '10px 16px 13px',
		marginRight: '20px',
		backgroundColor: colors.webexuiWhite,
		color: colors.webexuiBlack,
		fontSize: '16px',
		minWidth: '64px',
		boxSizing: 'border-box',
		transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		fontFamily: 'myriad-pro, Myriad Pro',
		fontWeight: 600,
		lineHeight: '1.75',
		borderRadius: '4px',
		textTransform: 'none',
		cursor: 'pointer'
	},
	buttonLabelDisabled: {
		border: '1px solid rgba(0, 0, 0, 0.12)',
		color: 'rgba(0, 0, 0, 0.26)',
		cursor: 'default'
	},
	clickOutSideContainer: {
		width: '100%'
	}

}));