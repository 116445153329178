import React from 'react';
import Main from '../Main/Main';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';

const Logout: React.FC = () => {

    const classes = useStyles();

    return (
        <Main showBreadCrumbs={false}>
            <Grid container >
                <Grid item xs={12}>
                    <Typography className={`${classes.mainTitle}`}>
                        Logged out
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.textContainer}>
                    You are now logged out from the application and can close the browser
                </Grid>
            </Grid>
        </Main>
    );
}

export default Logout