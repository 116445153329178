import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    spinner: {
        position: 'initial',
        padding: theme.spacing(2),
        margin: theme.spacing(0, 'auto'),

    },
    details: {
        padding: theme.spacing(1, 0),
    },
    tableContainer: {
        gap: theme.spacing(1),
    },
    noData: {
        padding: theme.spacing(1),
        margin: theme.spacing(0, 'auto')
    }
}));