import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import { useStyles } from './styles';
import { Criteria, CriteriaFormValues, criterionAttributes, EditAppCriteriaProps, FormValues, LeaveFormProps, UserCounts } from './types'
import CriteriaValues from './CriteriaValues';
import { asyncValidate, validateOnSubmit } from './validation'
import { reduxForm, Field, InjectedFormProps, WrappedFieldArrayProps, FieldArray, getFormValues, SubmissionError, clearSubmitErrors, reset } from 'redux-form';
import { CheckboxWithName, LabeledSelectboxUncontrolled } from '../../RenderFields/RenderFields';
import { SpinnerButton } from '@danfoss/webex-ui/dist/mui';
import Button from '@material-ui/core/Button';
import { AppState } from '../../../Store';
import { connect, useSelector } from 'react-redux';
import { RecommendedApplication, RecommendedApplicationCriteria, RecommendedApplicationCriterion } from '../../../API/recommendedApplication';
import { attributeIsMultivalue, getCriterionAttibutes, getNoOfUsersFromFormValues, showNoOfUsers } from './utils';
import DisplayCriteria from './DisplayCriteria';
import equal from 'deep-equal';
import { useAuth0 } from '@auth0/auth0-react';
import settings from '../../../Config/settings';
import parse from 'html-react-parser';


const FORMID = 'appCriteria';

interface RenderAppCriteriaProps {
    userCounts?: UserCounts 
}

const RenderAppCriteria: React.FC<InjectedFormProps<{}> & WrappedFieldArrayProps & FormValues > = ({ values, fields, change, meta: { error, submitFailed }, ...rest }) => {
    const classes = useStyles();
    const criteria = (values as Partial<CriteriaFormValues>).criteria;
    const userCounts: UserCounts = (rest as any).userCounts;

    let orCritIdx = 0;

    return (
        <React.Fragment>
            {criteria?.map((crit, idx) => {
                orCritIdx += idx > 0 && crit.andor === 'or' && 1 || 0;
                const noOfValuesSelected = Array.isArray(crit.values) ? crit.values.length : (crit.values && 1 || 0);
                return (
                    <React.Fragment key={idx}>
                        {idx == 0 &&
                            <Grid container key={'heading' + idx} className={`${classes.critListGrid}`}>
                                <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridGroupHeading}`}  >
                                    {parse(`Recommend to ${showNoOfUsers(userCounts.critGroups.length > orCritIdx ? userCounts.critGroups[orCritIdx] : -2)} users where`)}
                                </Grid>
                            </Grid>
                        }
                        {idx > 0 && crit.andor === 'or' &&
                            <Grid container >
                                <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridAndOr}`}  >
                                    &nbsp;
                                </Grid>
                                <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridAddCrit}`} >
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        className={classes.editGridButton}
                                        onClick={() => fields.splice(idx, 0, { andor: 'and', attribute: '', values: [] })}
                                    >
                                        Add criteria to group
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                        {idx > 0 && crit.andor === 'or' &&
                            <Grid container key={'heading' + idx} className={`${classes.critListGrid}`}>
                                <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridGroupHeading}`}  >
                                    {parse(`And also to ${showNoOfUsers(userCounts.critGroups.length > orCritIdx ? userCounts.critGroups[orCritIdx] : -2)} users where`)}
                                </Grid>
                            </Grid>
                        }
                        <Grid container key={idx} className={`${classes.critListGrid}`}>
                            <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridAndOr}`}  >
                                {idx > 0 && crit.andor === 'and' &&
                                    <div className={`${classes.applicationListGridAndOrDiv}`}>and</div>
                                }
                            </Grid>
                            <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridAttribute}`}  >
                                <Field
                                    name={`criteria[${idx}].attribute`}
                                    component={LabeledSelectboxUncontrolled}
                                    defaultValue={crit.attribute}
                                    options={getCriterionAttibutes(idx, criteria || [])}
                                    // onClick={() => change(`criteria[${idx}].values`, [])}
                                    onChange={() => change(`criteria[${idx}].values`, [])}
                                    disabled={!crit.andor && idx > 0}
                                />
                            </Grid>
                            <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridOneOf}`}  >
                                {noOfValuesSelected === 1 &&
                                    (!attributeIsMultivalue(crit.attribute) &&
                                        <div className={`${classes.applicationListGridIsDiv}`}></div> ||
                                        <div className={`${classes.applicationListGridIncludesDiv}`}></div>)
                                    ||
                                    (!attributeIsMultivalue(crit.attribute) &&
                                        <div className={`${classes.applicationListGridOneOfDiv}`}></div> ||
                                        <div className={`${classes.applicationListGridAtLeastOneOfDiv}`}></div>
                                    )}
                            </Grid>
                            <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridValues}`}  >
                                <CriteriaValues
                                    name={`criteria[${idx}].values`}
                                    criterionType={criterionAttributes.find(attr => attr.id === crit.attribute)?.type}
                                    defaultValue={crit.values}
                                />
                            </Grid>
                            <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridEdit2}`} >
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    className={classes.editOwnerGridButton}
                                    onClick={() => fields.remove(idx)}
                                >
                                    Delete
                                </Button>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )
            }
            )}
            <Grid container>
                {criteria && criteria.length > 0 &&
                    <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridAndOr}`}  >
                        &nbsp;
                    </Grid>
                }
                {criteria && criteria.length > 0 &&
                    <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridAddCrit}`} >
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            className={`${classes.editGridButton} ${classes.button}`}
                            onClick={() => fields.push({ andor: 'and', attribute: '', values: [] })}
                        >
                            Add criteria to group
                        </Button>
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            className={`${classes.editGridButton} ${classes.button}`}
                            onClick={() => fields.push({ andor: 'or', attribute: '', values: [] })}
                        >
                            Add new criteria group
                        </Button>
                    </Grid>
                }
                {criteria && criteria.length === 0 &&
                    <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridAddCrit}`} >
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            className={`${classes.editGridButton} ${classes.button}`}
                            onClick={() => fields.push({ andor: '', attribute: '', values: [] })}
                        >
                            Add criteria group
                        </Button>
                    </Grid>
                }
            </Grid>
        </React.Fragment>
    )
}

const RenderFormFields: React.FC<EditAppCriteriaProps & InjectedFormProps<{}> & FormValues & LeaveFormProps> = (props) => {
    const classes = useStyles();
    const { values, initialValues} : {values: Partial<CriteriaFormValues>, initialValues: Partial<CriteriaFormValues>} = props;
    const { change, resetForm, setLeaveConfirmValues, handleRowChange } = props;
    const [allUsersSelected, setAllUsersSelected] = useState((initialValues as Partial<CriteriaFormValues>).all_users);
    const { getAccessTokenSilently } = useAuth0();
    const appState = useSelector((state: AppState) => state);
    const [userCounts, setUserCounts] = useState({ critGroups: [], total: -2 } as UserCounts);

    useEffect(() => {
        const getUserCounts = async () => {
            const accessTokenMyDanfossAccountApi = await getAccessTokenSilently(settings.myDanfossAccountApi.accessTokenOptions);

            let critGroups: number[] = [];
            let total = 0;
            if (values && values.criteria && !values.all_users) {
                let valuesCritGroups: Criteria[][] = [];
                let crits: Criteria[] = [];
                for (let i=0; i < values.criteria.length; i++) {
                    if (i > 0 && values.criteria[i].andor === 'or') {
                        valuesCritGroups.push(crits);
                        crits = [];
                    }
                    crits.push(values.criteria[i]);
                }; 
                valuesCritGroups.push(crits);
                await Promise.allSettled(valuesCritGroups.map(async (crit) => getNoOfUsersFromFormValues(accessTokenMyDanfossAccountApi, values && values.client_id || '', crit, appState)))
                    .then((results) => {
                        results.forEach(result => {
                            critGroups.push(result.status === "fulfilled" && result.value || 0)
                        })
                    });
                total = (values.criteria.length > 1 && await getNoOfUsersFromFormValues(accessTokenMyDanfossAccountApi, values && values.client_id || '', values.criteria, appState) || critGroups[0]) || 0;
            }
            setUserCounts({ critGroups, total });
        }

        getUserCounts();
    }, [values]);

    useEffect(() => {
        if (!equal(values?.criteria, initialValues?.criteria)) {
            setFormChanged();
        }

    }, [values]);

    const setFormChanged = () => {
        // we need to tell the clickoutsidewrapper that a change have happened. No need to send 
        // actual values of the changed events, just send two string that doesn't match 
        setLeaveConfirmValues && setLeaveConfirmValues("1", "2", true, resetForm, document.getElementById('formSubmitButton'))
        handleRowChange && handleRowChange(true);
    }

    return (
        <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridCritEdit}`} >
            <Grid container className={`${classes.critListGrid}`}>
                <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridAllUsers}`}  >
                    <Field
                        name="all_users"
                        id="all_users"
                        checked={allUsersSelected}
                        component={CheckboxWithName}
                        onClick={(event: any) => { change('all_users', event.target.checked); setAllUsersSelected(event.target.checked); setFormChanged(); }}
                        label="Recommend to all users"
                    />
                </Grid>
            </Grid>
            {!allUsersSelected &&
                <FieldArray name="criteria" component={RenderAppCriteria} props={{...props, userCounts}} />
            }
            <div>
                {/* <hr /> */}
                <DisplayCriteria values={values as CriteriaFormValues} userCountsForValues={userCounts} />
            </div>
        </Grid>
    )
}

const FormFields = connect((state: AppState) => ({
    values: getFormValues(FORMID)(state)
}))(RenderFormFields);

const EditAppCriteria: React.FC<EditAppCriteriaProps & InjectedFormProps<{}>> = (props) => {
    const classes = useStyles();
    const [rowHasChanges, setRowHasChanges] = useState(false);
    const { application, updateAppCriteria, cancel, handleSubmit, submitting, containerClassName, initialValues, submitSucceeded, submitFailed, change, setLeaveConfirmValues, resetForm } = props;

    const submitForm = (values: any) => {
        const { errors, hasErrors } = validateOnSubmit(values, props);

        if (hasErrors) {
            throw new SubmissionError(errors);
        }

        const criteria: RecommendedApplicationCriteria = [];
        if (!values.all_users) {
            let andCrit: RecommendedApplicationCriterion[] = [];
            (values as CriteriaFormValues).criteria.forEach((crit, idx) => {
                if (idx > 0 && crit.andor === 'or') {
                    criteria.push([...andCrit]);
                    andCrit = [];
                }
                const critValues = Array.isArray(crit.values) && crit.values || [crit.values];
                andCrit.push({ path: crit.attribute, values: critValues });
            })
            criteria.push([...andCrit]);
        }

        updateAppCriteria({ ...application, all_users: values.all_users, criteria });

        setLeaveConfirmValues && setLeaveConfirmValues(criteria, criteria, false, resetForm, document.getElementById('formSubmitButton'))
    }

    const cancelAndReset = () => {
        // we need to tell the clickoutsidewrapper that changes have been canceled. No need to send 
        // actual values of the changed events, just send two matching strings
        setLeaveConfirmValues && setLeaveConfirmValues('', '', false, resetForm, document.getElementById('formSubmitButton'))
        cancel();
    }
    const handleRowChange = (hasChange: boolean) => {
        setRowHasChanges(hasChange)
    }

    return (
        <Grid item id="clickoutsideContainer" className={classes.clickOutSideContainer}>
            <form onSubmit={handleSubmit(submitForm)} id={FORMID}>
                <Grid container className={`${classes.applicationListGrid} ${containerClassName}`} >
                    <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridEdit}`} >
                        <SpinnerButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.editGridButton}
                            pathToImagesFolder={'/images/icons'}
                            spinnerVisible={submitting}
                            id="formSubmitButton"
                            disabled={!rowHasChanges}
                        >
                            Save
                        </SpinnerButton>
                        <br />
                        <Button
                            type="button"
                            variant="outlined"
                            color="default"
                            className={classes.editGridButton}
                            onClick={(e) => { e.preventDefault(); cancelAndReset() }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridName}`} >
                        {application?.name}
                    </Grid>
                    <FormFields {...props} handleRowChange={handleRowChange} />
                </Grid>
            </form>
        </Grid>
    );
};

function mapStateToProps(state: AppState, ownProps: EditAppCriteriaProps) {
    const { application, recommendedAppChanged, recommendedAppPublished } = ownProps;
    const recommendedApp: RecommendedApplication | undefined = recommendedAppChanged || recommendedAppPublished || undefined;
    let criteria: any = [];
    recommendedApp?.criteria.forEach((orCrit, orCritIdx) => {
        orCrit.forEach((andCrit, andCritIdx) => {
            criteria.push({ andor: (andCritIdx > 0 ? 'and' : (orCritIdx === 0 ? '' : 'or')), attribute: andCrit.path, values: andCrit.values })
        })
    });

    return {
        initialValues: {
            client_id: application?.client_id,
            client_name: application?.name,
            all_users: recommendedApp?.all_users,
            criteria
        }
    }
}

const mapDispatchToProps = (dispatch: any) => () => ({
    resetForm: () => dispatch(reset(FORMID))
});

const EditAppCriteriaReduxForm = reduxForm({
    form: FORMID,
    asyncValidate,
    onChange: (values, dispatch, props) => {
        if (props.submitFailed) dispatch(clearSubmitErrors(FORMID));
    },
    asyncBlurFields: [],
    asyncChangeFields: [],
    shouldAsyncValidate: () => false
})(EditAppCriteria)

export default connect(mapStateToProps, mapDispatchToProps)(EditAppCriteriaReduxForm);

