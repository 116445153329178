import React, { useState } from 'react';
import Main from '../Main';
import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router-dom';
import { useStyles } from './styles';
import Banner from '../Banner'
import ModalPopup from '../Modal/ModalPopup';
import BannerPopup from '../Banner/BannerPopup';
import { Roles } from '../../Store/Roles/types';

const PublishOverview: React.FC = () => {
    const location = useLocation();
    const classes = useStyles();
    const [showBannerPopup, setShowBannerPopup] = useState(false);
    const [bannerHeader, setBannerHeader] = useState('');
    const [bannerBody, setBannerBody] = useState('');

    const handleBannerInformationClick = (bannerHeader: string, bannerBody: string) => {
        setBannerBody(bannerBody);
        setBannerHeader(bannerHeader);
        setShowBannerPopup(true);
    }

    return (
        <Main breadCrumbs={{ items: [{ text: `Preview overview`, link: location.pathname }] }}>
            <Grid container>
                {showBannerPopup && (
                    <Grid container className={classes.bannerPopupContainer}>
                        <ModalPopup paperClassName={classes.bannerPopup}>
                            <BannerPopup bannerHeader={bannerHeader} bannerBody={bannerBody} toggleBannerPopup={setShowBannerPopup} />
                        </ModalPopup>
                    </Grid>
                )
                }
                <Banner
                    bannerText="Preview recommended applications"
                    bannerURL={"/preview/applicationcriteria"}
                    bannerInformationClick={() => handleBannerInformationClick("Preview recommended applications", "Preview how recommended applications would look for different user profiles when you publish outstandaing changes")}
                    bannerClass={classes.banner}
                    bannerTextClass={classes.bannerText}
                    allowedRoles={[]}
                />
                <Banner
                    bannerText="Preview relevant websites"
                    bannerURL={"/preview/websites"}
                    bannerInformationClick={() => handleBannerInformationClick("Preview websites", "Preview how websites would look for different combinations of language and country")}
                    bannerClass={classes.banner}
                    bannerTextClass={classes.bannerText}
                    allowedRoles={[]}
                />
            </Grid>
        </Main>
    );
}

export default PublishOverview;