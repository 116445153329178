import { useRadioGroup } from "@material-ui/core";
import settings from "../Config/settings";
import { Roles, UserRoles } from "../Store/Roles/types";
import { CurrentUser } from "../Store/User/types";

export const prepareForCompare = (data: any) => {
    return JSON.stringify(data).replaceAll("/>", ">").replaceAll("\\r", "").replaceAll("\\n", "")
}

export const validEmail = (email: string) => {
    // eslint-disable-next-line
    return (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email));
}

export const isValidEmailDomain = (email: string, idpDomains: string[]) => {

    if (email.indexOf("@") > 0) {
        // Check if the IdP domains collection contains the email domain
        return idpDomains.some(domain => email.toLowerCase().endsWith(domain.toLowerCase()));
    } else {
        return false;
    }
}

export const getDanfossIdentityId = (user: CurrentUser) => {
    if (user.userLoaded) {
        if (user.impersonatedUser)
            return user.impersonatedUser.danfoss_identity_id;
        else
            return user.user?.identification.danfoss_identity_id || '';
    }
    else
        return '';
}

export const getUserRoles = (userRoles: UserRoles) => {
    if (userRoles.rolesLoaded) {
        if (userRoles.impersonatedUserRoles)
            return userRoles.impersonatedUserRoles;
        else
            return userRoles.roles
    }
    else
        return [];
}

export const allowPublishApplicationTexts = (roles: Roles[]) => {
    return roles.some(role => [Roles.DASHBOARD_ADMIN, Roles.ALLOW_PUBLISH_OVERRIDE].includes(role)) ||
        (settings.specialPermissionSettings.allow_publish &&
            roles.some(role => [Roles.APPLICATION_OWNER, Roles.APPLICATION_PUBLISHER].includes(role)));
}

export const allowPublishRecommendedApps = (roles: Roles[]) => {
    return roles.some(role => [Roles.DASHBOARD_ADMIN, Roles.ALLOW_PUBLISH_OVERRIDE].includes(role)) ||
        (settings.specialPermissionSettings.allow_publish &&
            roles.some(role => [Roles.APPLICATION_OWNER, Roles.RECOMMENDED_APPLICATION_PUBLISHER].includes(role)));
}

export const replaceLast = (text: string, what: string, replacement: string) => {
    const lastOccurence = text.lastIndexOf(what);
    return lastOccurence > -1 && text.substring(0, lastOccurence) + replacement + text.substring(lastOccurence + what.length) || text;
};

