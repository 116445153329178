import fetchApiWrapper from './fetchAPIWrapper';
import settings from '../Config/settings';

export interface ReferenceApiModel<T = ReferenceItemApiModel> {
	items?: T[];
}

export interface ReferenceItemApiModel {
	id: string;
	text: string;
}

export const getDepartments = () =>
	getReferenceData(`${settings.myDanfossAccountApi.endpoint}/references/departments`);

export const getCompanyTypes = () => 
    getReferenceData(`${settings.myDanfossAccountApi.endpoint}/references/company-types`);

export const getAreasOfInterest = () => 
    getReferenceData(`${settings.myDanfossAccountApi.endpoint}/references/interest-areas`);

export const getReferenceData = <T = ReferenceApiModel>(
	endpoint: string
): Promise<T> => {
	const requestHeaders = new Headers();
	requestHeaders.set(
		'Content-Type',
		'application/json-patch+json; charset=utf-8'
	);

	requestHeaders.append('Accept-Language', 'en');

	return fetchApiWrapper<T>(endpoint, {
		headers: requestHeaders,
		method: 'GET',
	});
};
