import fetchApiWrapper from './fetchAPIWrapper';
import settings from '../Config/settings';

export interface LocationApiCountry {
    alpha2: string,
    name: string
}

export interface LocationApiCountries {
    value: LocationApiCountry[]
}

export const getCountries = async (accessToken: string): Promise<LocationApiCountries> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper(`${settings.myDanfossAccountApi.endpoint}/location/countries?%24select=alpha2%2C%20name&%24orderby=name`, {
        headers: requestHeaders,
        method: 'GET',
      });
}

