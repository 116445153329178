import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from './styles';
import { ApplicationUserRoles, EditRolesProps, FormValues } from './types'
import { asyncValidate, validateOnSubmit } from './validation'
import { validateEmail, isValidEmail } from '../validation'
import { reduxForm, Field, InjectedFormProps, WrappedFieldArrayProps, FieldArray, getFormValues, SubmissionError, change, stopAsyncValidation, FormErrors, clearSubmitErrors, reset } from 'redux-form';
import { LabeledTextboxUncontrolled, LabeledMultiSelectbox } from '../../RenderFields/RenderFields';
import { SpinnerButton } from '@danfoss/webex-ui/dist/mui';
import Button from '@material-ui/core/Button';
import { AppState } from '../../../Store';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getRolesEnum, RolesDisplayName, ApplicationRoles, Roles, ApplicationExtensionRoles } from '../../../Store/Roles/types';
import { searchUserByEmail } from '../../../API/users';
import { getApplicationsForOwner } from '../../../API/application';
import { getUserRoles } from '../../../Utils';
import settings from '../../../Config/settings';

type TimeoutIdType = ReturnType<typeof setTimeout>;

const FORMID = 'appRoles';

const RenderUserRoles: React.FC<InjectedFormProps<{}> & WrappedFieldArrayProps & FormValues> = ({ values, fields, resetForm, setLeaveConfirmValues, meta: { error, submitFailed }, ...rest }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [onChangeTimeout, setOnChangeTimeout] = useState<TimeoutIdType | undefined>(undefined);
    const loggedInUserRoles = useSelector((state: AppState) => getUserRoles(state.userRoles));
    const { application }: { application: ApplicationUserRoles } = rest as any;
    const clearReadOnlyFields = (idx: number) => {
        updateReadOnlyFields(idx, '', '', '');
    }

    const updateReadOnlyFields = (idx: number, danfoss_identity_id: string, name: string, phone_number: string) => {
        dispatch(change(FORMID, `user_roles[${idx}].danfoss_identity_id`, danfoss_identity_id));
        dispatch(change(FORMID, `user_roles[${idx}].user_name`, name));
        dispatch(change(FORMID, `user_roles[${idx}].user_phonenumber`, phone_number));
    }

    const validateDanfossEmail = async (email: string, idx: number) => {
        try {
            const user = await searchUserByEmail((rest as any).accessTokenMyDanfossAPI, email);
            const appsForUser = await getApplicationsForOwner(user.danfoss_identity_id, (rest as any).accessTokenMyDanfossAccountAPI);
            const currentClientId = (rest as any).application.client_id;
            if (appsForUser.includes(currentClientId)) {
                const errors: FormErrors<any, any> = { user_roles: [] };
                for (let i = 0; i < idx; i++) {
                    errors.user_roles.push({});
                }
                errors.user_roles.push({ user_email: 'User is owner of the application and implicitly has all roles' });
                clearReadOnlyFields(idx);
                dispatch(stopAsyncValidation(FORMID, errors));
            }
            else {
                updateReadOnlyFields(idx, user.danfoss_identity_id, user.name, user.phone_number);
                dispatch(clearSubmitErrors(FORMID));
            }
        }
        catch {
            const errors: FormErrors<any, any> = { user_roles: [] };
            for (let i = 0; i < idx; i++) {
                errors.user_roles.push({});
            }
            errors.user_roles.push({ user_email: 'Email not found' })
            clearReadOnlyFields(idx);
            dispatch(stopAsyncValidation(FORMID, errors));
        }
    }

    const onChangeEmail = async (event: any, userRoleIdx: number) => {
        if (onChangeTimeout) {
            // Cancel pending validation of Danfoss email from previous onChange
            clearTimeout(onChangeTimeout);
        }

        let timeoutId: TimeoutIdType | undefined = undefined;
        if (isValidEmail(event.target.value, values, rest)) {
            // Delay testing of Danfoss email to avoid too many calls to the API during typíng of the email
            timeoutId = setTimeout(async () => await validateDanfossEmail(event.target.value, userRoleIdx), 2000);
        }

        clearReadOnlyFields(userRoleIdx);

        setFormChanged();

        setOnChangeTimeout(timeoutId);
    }

    const onBlurEmail = async (event: any, ownerIdx: number) => {
        if (onChangeTimeout) {
            clearTimeout(onChangeTimeout);
            setOnChangeTimeout(undefined);
        }

        if (isValidEmail(event.target.value, values, rest)) {
            await validateDanfossEmail(event.target.value, ownerIdx);
        }
        else {
            clearReadOnlyFields(ownerIdx);
        }
    }

    useEffect(() => {
        if ((values as Partial<ApplicationUserRoles>).user_roles?.length === 0) {
            fields.push({ roles: [], user_name: '', user_email: '', user_phonenumber: '' });
        }
    }, [])

    const setFormChanged = () => {
        // we need to tell the clickoutsidewrapper that a change have happened. No need to send 
        // actual values of the changed events, just send two string that doesn't match 
        setLeaveConfirmValues && setLeaveConfirmValues("1", "2", true, resetForm, document.getElementById('formSubmitButton'))
    }

    return (
        <React.Fragment>
            {(values as Partial<ApplicationUserRoles>).user_roles?.map((user_role, idx) => {
                const options = ApplicationRoles.map((role: string) => ({ id: role, text: RolesDisplayName[getRolesEnum(role)] }))
                    .concat(loggedInUserRoles.includes(Roles.DASHBOARD_ADMIN) && application.client_id === settings.auth0.client_id && ApplicationExtensionRoles.map((role: string) => ({ id: role, text: RolesDisplayName[getRolesEnum(role)] })) || []);
                return (
                    <Grid container key={idx} className={`${classes.rolesListGrid}`}>
                        <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridRoleName}`}  >
                            <Field
                                name={`user_roles[${idx}].roles`}
                                component={LabeledMultiSelectbox}
                                multiple={true}
                                defaultValue={user_role.roles}
                                className={classes.applicationSelectRoleName}
                                options={options}
                            />
                        </Grid>
                        <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridRoleUserEmail}`} >
                            <Field
                                name={`user_roles[${idx}].user_email`}
                                component={LabeledTextboxUncontrolled}
                                validate={[validateEmail]}
                                onBlur={(event: any) => { onBlurEmail(event, idx) }}
                                onChange={(event: any) => { onChangeEmail(event, idx) }}
                                InputProps={{
                                    classes: {
                                        input: classes.blackText
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridRoleUserName}`}  >
                            <Field
                                name={`user_roles[${idx}].user_name`}
                                component={LabeledTextboxUncontrolled}
                                disabled={true}
                                InputProps={{
                                    classes: {
                                        input: classes.blackText
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridRoleUserPhone}`} >
                            <Field
                                name={`user_roles[${idx}].user_phonenumber`}
                                component={LabeledTextboxUncontrolled}
                                disabled={true}
                                InputProps={{
                                    classes: {
                                        input: classes.blackText
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item className={`${classes.applicationListGridItem} ${classes.rolesListGridEdit}`} >
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                className={classes.editOwnerGridButton}
                                onClick={() => { fields.remove(idx); setFormChanged() }}
                            >
                                Delete
                            </Button>
                        </Grid>
                    </Grid>
                )
            }
            )}
            <Grid container >
                <Grid item className={`${classes.applicationListGridItem} ${classes.rolesListGridAddUserRole}`} >
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        className={classes.editGridButton}
                        onClick={() => { fields.push({ roles: [], user_name: '', user_email: '', user_phonenumber: '' }); setFormChanged() }}
                    >
                        Add user
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

const UserRoles = connect((state: AppState) => ({
    values: getFormValues(FORMID)(state)
}))(RenderUserRoles);

const EditRoles: React.FC<EditRolesProps & InjectedFormProps<{}>> = (props) => {
    const classes = useStyles();
    const { application, updateUserRoles, cancel, handleSubmit, submitting, containerClassName, setLeaveConfirmValues, resetForm } = props;
    const submitForm = (values: any) => {
        const { errors, hasErrors } = validateOnSubmit(values, props);
        if (hasErrors) {
            throw new SubmissionError(errors);
        }

        const userRoles = { ...application, user_roles: values.user_roles };

        updateUserRoles(userRoles);

        setLeaveConfirmValues && setLeaveConfirmValues(userRoles, userRoles, false, resetForm, document.getElementById('formSubmitButton'))
    }

    const cancelAndReset = () => {
        // we need to tell the clickoutsidewrapper that changes have been canceled. No need to send 
        // actual values of the changed events, just send two matching strings
        setLeaveConfirmValues && setLeaveConfirmValues('', '', false, resetForm, document.getElementById('formSubmitButton'))
        cancel();
    }

    return (
        <Grid item id="clickoutsideContainer" className={classes.clickOutSideContainer}>
            <form onSubmit={handleSubmit(submitForm)} id={FORMID}>
                <Grid container className={`${classes.applicationListGrid} ${containerClassName}`} >
                    <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridEdit}`} >
                        <SpinnerButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.editGridButton}
                            pathToImagesFolder={'/images/icons'}
                            spinnerVisible={submitting}
                            id="formSubmitButton"
                        >
                            Save
                        </SpinnerButton>
                        <br />
                        <Button
                            type="button"
                            variant="outlined"
                            color="default"
                            className={classes.editGridButton}
                            onClick={(e) => { e.preventDefault(); cancelAndReset() }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridName}`} >
                        {application?.client_name}
                    </Grid>
                    <Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridRoleUsers}`} >
                        <FieldArray name="user_roles" component={UserRoles} {...props} />
                    </Grid>
                </Grid>
            </form>
        </Grid>
    );
};

function mapStateToProps(state: AppState, ownProps: EditRolesProps) {
    const { application } = ownProps;
    return {
        initialValues: {
            client_name: application?.client_name,
            user_roles: application?.user_roles.map(user_role => (
                {
                    roles: user_role.roles,
                    user_name: user_role.user_name,
                    user_email: user_role.user_email,
                    user_phonenumber: user_role.user_phonenumber,
                    danfoss_identity_id: user_role.danfoss_identity_id
                }))
        }
    }
}

const mapDispatchToProps = (dispatch: any) => () => ({
    resetForm: () => dispatch(reset(FORMID))
});

const EditRolesReduxForm = reduxForm({
    form: FORMID,
    asyncValidate,
    asyncBlurFields: [],
    asyncChangeFields: [],
    shouldAsyncValidate: () => false
})(EditRoles)

export default connect(mapStateToProps, mapDispatchToProps)(EditRolesReduxForm);

