import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Application } from '../../../API/application';
import ModalPopup from '../../Modal/ModalPopup';
import parse from 'html-react-parser';
import { useStyles } from './Styles';
import DashboardItemPopup from './DashboardItemPopup';
import { DashBoardItem, DashboardProps } from './Types';
import DashBoardItems from './DashBoardItems';

const getDashboardItemIcon = (application: Application) => {

	if (application && application.dip_app_type === 'client_app') {
		return '/images/icons/AppDashboard_Desktop.svg';
	} else if (application && application.dip_app_type === 'web') {
		return '/images/icons/AppDashboard_Web.svg';
	} else if (application && application.dip_app_type === 'mobile_app') {
		return '/images/icons/AppDashboard_Mobile.svg';
	}

	return '';
}

const handleBannerClick = (application: Application, isActive: boolean, toggleInactiveAppPopup: Function, toggleClientAppPopup: Function, toggleMobileAppPopup: Function) => {

	if (!isActive) {
		toggleInactiveAppPopup();
		return;
	}

	if (!application) return;

	// If web application
	if (application.dip_app_type === 'web') {
		(window as any).open(application.url)
	}

	// If desktop application
	if (application.dip_app_type === 'client_app') {
		toggleClientAppPopup();
	}

	//If mobile application
	if (application.dip_app_type === 'mobile_app') {
		toggleMobileAppPopup(application)
	}
}

const getContent = (dashboardItems: DashBoardItem[], areaSubject: string, emulateMobile: boolean, classes: any, functions: any) => {

	return <Grid item xs={12} className={classes.applicationContainerMaxWidth}>
		<Grid item xs={12} className={classes.itemContainer}>
			<DashBoardItems
				areaSubject={areaSubject}
				dashBoardItems={dashboardItems}
				emulateMobile={emulateMobile}
				getDashBoardItemIcon={getDashboardItemIcon}
				shouldItemBeShownAsActive={(item: Application) => shouldItemBeShownAsActive(item, emulateMobile)}
				functions={functions}
				handleBannerClick={handleBannerClick}
				itemType="used"
			/>
		</Grid>
	</Grid>
}

const getHeader = (emulateMobile: boolean) => {
	return emulateMobile ? 'Desktop application' : 'Mobile application';
}

const getBody = (emulateMobile: boolean) => {
	return emulateMobile ? 'To be used on a desktop device.' : 'To be used on a mobile device.'
}

const getClientPopupContent = (showClientAppPopup: boolean, classes: any, toggleClientAppPopup: Function) => {
	return showClientAppPopup ? (
		<Grid container className={classes.popupContainer}>
			<ModalPopup paperClassName={classes.popup} overlayClassName={classes.overlay}>
				<Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
					<div onClick={() => toggleClientAppPopup()}>
						<img src={'/images/icons/Close.svg'} className={classes.close} alt="" />
					</div>
				</Grid>
				<Grid item xs={12} className={classes.popupHeader} >
					Desktop application
				</Grid>
				<Grid item xs={12} >
					<span className={classes.popupContent}>
						{parse('Please locate the application in your Application folder.<br/>In case you do not have it installed, please download it again.')}
					</span>
				</Grid>
			</ModalPopup>
		</Grid>
	) : null;
}

const getMobilePopupContent = (showMobileAppPopup: boolean, classes: any, toggleMobileAppPopup: Function,  PopUpApplication: Application) => {
	return showMobileAppPopup ? (
		<Grid container className={`${classes.popupContainer} ${classes.popupContainerMobile}`} id="imagePopupContainer">
			<ModalPopup paperClassName={classes.popup} overlayClassName={classes.overlay}>
				<Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
					<div onClick={() => toggleMobileAppPopup(PopUpApplication)}>
						<img src={'/images/icons/Close.svg'} className={classes.close} alt="" />
					</div>
				</Grid>
			</ModalPopup>
		</Grid>
	) : null;
}

const getInactivePopupContent = (showInactiveAppPopup: boolean, toggleInactiveAppPopup: Function, inactivePopupHeader: string, inactivePopupBody: string) => {
	return showInactiveAppPopup ? (
		<DashboardItemPopup
			headerText={inactivePopupHeader}
			bodyText={parse(inactivePopupBody)}
			togglePopup={toggleInactiveAppPopup}
		/>
	) : null;
}

const shouldItemBeShownAsActive = (application: Application, emulateMobile: boolean) => {

	if (emulateMobile) {
		return application.dip_app_type !== 'client_app';
	}
	else {
		return application.dip_app_type !== 'mobile_app';
	}
}

const Dashboard: React.FC<DashboardProps> = ({ dashboardItems, areaSubject, handleInformationIconClick, emulateMobile }) => {
	const classes = useStyles();

	const [showClientAppPopup, setShowClientAppPopup] = React.useState(false);
	const [PopUpApplication, setPopUpApplication] = React.useState({} as Application);
	const [showMobileAppPopup, setShowMobileAppPopup] = React.useState(false);
	const [showInactiveAppPopup, setShowInactiveAppPopup] = React.useState(false);
	const [inactivePopupHeader, setInactivePopupHeader] = React.useState('');
	const [inactivePopupBody, setInactivePopupBody] = React.useState('');

	useEffect(() => {
		setInactivePopupHeader(getHeader(emulateMobile));
		setInactivePopupBody(getBody(emulateMobile));
	}, []);

	const toggleClientAppPopup = () => {
		setShowClientAppPopup(!showClientAppPopup);
	}

	const toggleInactiveAppPopup = () => {
		setShowInactiveAppPopup(!showInactiveAppPopup);
	}

	const toggleMobileAppPopup = (app: Application) => {
		setPopUpApplication(app);
		setShowMobileAppPopup(!showMobileAppPopup);
	}

	if (showClientAppPopup || showMobileAppPopup || showInactiveAppPopup) {
		(document.getElementById("mainContent") || {} as HTMLElement).scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	return (
		<>
			<Grid container className={`${classes.root}`}>
				{getClientPopupContent(showClientAppPopup, classes, toggleClientAppPopup)}
				{getMobilePopupContent(showMobileAppPopup, classes, toggleMobileAppPopup, PopUpApplication)}
				{getInactivePopupContent(showInactiveAppPopup, toggleInactiveAppPopup, inactivePopupHeader, inactivePopupBody)}
				<Grid container className={classes.applicationContainer}>
					<Grid container className={classes.innerContentMargin}>
						{getContent(dashboardItems, areaSubject, emulateMobile, classes, { toggleInactiveAppPopup: toggleInactiveAppPopup, toggleClientAppPopup: toggleClientAppPopup, toggleMobileAppPopup: toggleMobileAppPopup, handleInformationIconClick: handleInformationIconClick })}
					</Grid>
				</Grid>
			</Grid>

		</>
	);
};

export default Dashboard;
