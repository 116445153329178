import { Application } from '../../../API/application';
import { RecommendedApplication, RecommendedApplicationCriteria} from '../../../API/recommendedApplication';

export interface EditAppCriteriaProps extends LeaveFormProps {
    application?: Application
    recommendedAppPublished?: RecommendedApplication
    recommendedAppChanged?: RecommendedApplication
    containerClassName: string
    updateAppCriteria: Function
    cancel: Function,
    handleRowChange?: Function
}

export interface LeaveFormProps {
    setLeaveConfirmValues?: Function,
    resetForm?: Function
}

export interface FormValues {
    values: {};
}

export interface CriteriaValuesProps {
    criterionType?: string,
    defaultValue: any,
    name: string
}

export interface DisplayCriteriaProps {
    application?: RecommendedApplication,
    existingApplication?: RecommendedApplication,
    isNew?: boolean,
    values?: CriteriaFormValues,
    userCountsForValues?: UserCounts,
    noOfRecommAppsForUser?: number
}

export interface CriterionOptions {
	id: string,
	text: string
}

export interface Criteria {
    andor: string,
    attribute: string;
    values: string[];
}

export interface CriteriaFormValues {
    client_id: string;
    client_name: string;
    all_users: boolean;
    criteria: Criteria[] 
}

export interface UserCounts {
    critGroups: number[];
    total: number;
}

export interface CriterionAttributes {
    type: string,
    id: string,
    text: string,
    generatedText?: string,
    generatedTextPlural?: string,
    state: string,
    stateProperty: string,
    multivalue: boolean,
    multiselection: boolean,
    queryField: string,
    extraQueryField?: string
}

export const criterionAttributes: CriterionAttributes[] = [
    {
        type: 'applications',
        id: 'user_applications.app_id',
        text: 'Used applications',
        generatedText: 'application',
        generatedTextPlural: 'applications',
        state: 'applications',
        stateProperty: 'applications',
        multivalue: true,
        multiselection: true,
        queryField: 'app_metadata.apps.clientID'
    },
    {
        type: 'area_of_interest',
        id: 'areas_of_interest.id',
        text: 'Areas of interest',
        generatedText: 'Area of interest',
        generatedTextPlural: 'Areas of interest',
        state: 'references',
        stateProperty: 'areasOfInterest',
        multivalue: true,
        multiselection: true,
        queryField: 'user_metadata.areas_of_interest',
        extraQueryField: 'user_metadata.area_of_interest'
    },
    {
        type: 'company_type',
        id: 'company_type.id',
        text: 'Company type',
        state: 'references',
        stateProperty: 'companyTypes',
        multivalue: false,
        multiselection: true,
        queryField: 'user_metadata.company_type'
    },
    {
        type: 'department',
        id: 'department.id',
        text: 'Department',
        state: 'references',
        stateProperty: 'departments',
        multivalue: false,
        multiselection: true,
        queryField: 'user_metadata.department'
    },
    {
        type: 'country',
        id: 'country.id',
        text: 'Country',
        state: 'location',
        stateProperty: 'countries',
        multivalue: false,
        multiselection: true,
        queryField: 'user_metadata.country'
    },
    {
        type: 'language',
        id: 'language.id',
        text: 'Language',
        state: '',
        stateProperty: '',
        multivalue: false,
        multiselection: true,
        queryField: 'user_metadata.language'
    },
    {
        type: 'is_workforce',
        id: 'identification.is_workforce',
        text: 'Is workforce',
        state: '',
        stateProperty: '',
        multivalue: false,
        multiselection: false,
        queryField: 'app_metadata.identification.is_workforce'
    }
    
]

export enum CopyProgress {
    NotStarted,
    SelectSource,
    SelectTargets
}

export enum ExportProgress {
    NotStarted,
    SelectSources
}



export interface ApplicationRowProps {
	application: Application,
	recommendedApplication?: RecommendedApplication,
	recommendedApplicationPublished?: RecommendedApplication,
	copyCriteriaProgress: CopyProgress,
	copyCriteriaFrom: string,
	copyCriteriaTo: string[],
	exportCriteriaProgress: ExportProgress,
	exportCriteriaFrom: string[],
	updateAppCriteria: Function,
	deleteDraftAppCriteria: Function,
	cancelUpdate: Function,
	onSelectCopyTo: Function,
	onSelectCopyFrom: Function,
	onSelectExportFrom: Function,
    handleEditClick: Function,
    rowNo: number,
    editMode: boolean,
    disabled: boolean,
    noOfRecommAppsForUser?: number;
}	

export interface EditButtonsProps {
	clientId: string,
	recommendedApplication?: RecommendedApplication,
	recommendedApplicationPublished?: RecommendedApplication,
	copyCriteriaProgress: CopyProgress,
	exportCriteriaProgress: ExportProgress,
    handleEditClick: Function,
	deleteDraftAppCriteria: Function,
    disabled: boolean
}	

export interface CopyCheckBoxesProps {
	clientId: string,
	recommendedApplication?: RecommendedApplication,
	copyCriteriaProgress: CopyProgress,
	copyCriteriaFrom: string,
	copyCriteriaTo: string[],
	onSelectCopyTo: Function,
	onSelectCopyFrom: Function,
    rowNo: number
}	

export interface ExportCheckBoxesProps {
	clientId: string,
	recommendedApplication?: RecommendedApplication,
	exportCriteriaProgress: ExportProgress,
	exportCriteriaFrom: string[],
	onSelectExportFrom: Function,
    rowNo: number
}	

export interface ImportedCriteria {
    client_name: string;
    client_id: string,
    all_users: boolean;
    published : boolean;
    criteria: RecommendedApplicationCriteria;
}


