import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	margin: {
		margin: '20px 0 20px',
		maxWidth: '466px'
	},
	close: {
		cursor: 'pointer',
		width: '16px',
		right: '12px',
		top: '12px',
		position: 'absolute'
	},
	root: {
		maxWidth: '468px',
		margin: '26px 36px 36px'
	},
	header: {
		fontSize: '28px',
		fontWeight: 'bold',
		letterSpacing: '0.14px',
		color: colors.webexuiDarkGrayish,
		marginBottom: '10px',
		lineHeight: '48px'
	},
	buttonContainer: {
		marginTop: '40px'
	},
	button: {
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			margin: '0px 0px 10px !important'
		},
		[theme.breakpoints.up('sm')]: {
			marginRight: '20px'
		}
	},
	popupContainer: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		position: 'absolute',
		top: '-50px'
	},
	popup: {
		top: '300px',
		maxWidth: '562px',
		position: 'relative',
		padding: '0px !important',
		zIndex: 10000
	}

}));