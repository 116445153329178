import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    profileAttribute: {
		width: '30%'
	},
    profileValues: {
		width: '70%'
	},
    listGridOdd: {
		backgroundColor: colors.webexuiGraySoft,
		padding: '5px 8px 5px 8px'
	},
	listGridEven: {
		backgroundColor: colors.webexuiWhite,
		padding: '5px 8px 5px 8px'
	},
    selectBox: {
        width: '350px'
    }
}));