import fetchApiWrapper from './fetchAPIWrapper';
import settings from '../Config/settings';

export interface RecommendedApplications {
    recommended_apps: RecommendedApplication[];
}

export interface RecommendedApplication {
    client_id: string;
    published: boolean;
    all_users: boolean;
    criteria: RecommendedApplicationCriteria;
}

export interface RecommendedApplicationCriteria extends Array<Array<RecommendedApplicationCriterion>> {};    
export interface RecommendedApplicationCriterion {
    path: string; 
    values: string[];
}

export interface PreviewRecommendedApplications {
    client_ids: string[]
}

export const getRecommendedApplicationDraft = (accessToken: string, clientId: string) : Promise<RecommendedApplication> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)
    
    return fetchApiWrapper(`${settings.myDanfossApi.endpoint}/admin-actions/draft-recommended-applications/${clientId}`, {
        headers: requestHeaders,
        method: 'GET',
      });
}

export const getRecommendedApplicationPublished = (accessToken: string, clientId: string) : Promise<RecommendedApplication> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)
    
    return fetchApiWrapper(`${settings.myDanfossApi.endpoint}/admin-actions/published-recommended-applications/${clientId}`, {
        headers: requestHeaders,
        method: 'GET',
      });
}

export const getRecommendedApplicationsDraft = (accessToken: string, clientIds: string[]) : Promise<RecommendedApplication[]> => {
    const recommendedApps: RecommendedApplication[] = [];
    return Promise.allSettled(clientIds.map( async (clientId: string) =>  { 
        return getRecommendedApplicationDraft(accessToken, clientId );
    })).then((results) => {
        results.forEach(result => {
            if (result.status === "fulfilled" && result.value && result.value.client_id ) {
                recommendedApps.push(result.value);
            }
        })
        return Promise.resolve(recommendedApps);
    })
}

export const getRecommendedApplicationsPublished = (accessToken: string, clientIds: string[]) : Promise<RecommendedApplication[]> => {
    const recommendedApps: RecommendedApplication[] = [];
    return Promise.allSettled(clientIds.map( async (clientId: string) =>  { 
        return getRecommendedApplicationPublished(accessToken, clientId );
    })).then((results) => {
        results.forEach(result => {
            if (result.status === "fulfilled" && result.value && result.value.client_id ) {
                recommendedApps.push(result.value);
            }
        })
        return Promise.resolve(recommendedApps);
    })
}

export const getRecommendedApplications = (accessToken: string, clientIds: string[]) : Promise<RecommendedApplication[]> => {
    const recommendedApps: RecommendedApplication[] = [];
    return Promise.allSettled([
        getRecommendedApplicationsPublished(accessToken, clientIds),
        getRecommendedApplicationsDraft(accessToken, clientIds)
    ]).then((results) => {
        results.forEach(result => {
            if (result.status === "fulfilled") {
                recommendedApps.push(...result.value);
            }
        })
        return Promise.resolve(recommendedApps);
    })
}

export const getAllRecommendedApplicationsDraft = (accessToken: string) : Promise<RecommendedApplication[]> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)
    
    return fetchApiWrapper<RecommendedApplications>(`${settings.myDanfossApi.endpoint}/admin-actions/draft-recommended-applications`, {
        headers: requestHeaders,
        method: 'GET',
      }).then((result) => {
        return Promise.resolve(result.recommended_apps);
    });
}

export const getAllRecommendedApplicationsPublished = (accessToken: string) : Promise<RecommendedApplication[]> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)
    
    return fetchApiWrapper<RecommendedApplications>(`${settings.myDanfossApi.endpoint}/admin-actions/published-recommended-applications`, {
        headers: requestHeaders,
        method: 'GET',
    }).then((result) => {
        return Promise.resolve(result.recommended_apps);
    });
}

export const getAllRecommendedApplications = (accessToken: string) : Promise<RecommendedApplication[]> => {
    const recommendedApps: RecommendedApplication[] = []; 

    return Promise.allSettled([
        getAllRecommendedApplicationsPublished(accessToken),
        getAllRecommendedApplicationsDraft(accessToken)
    ]).then((results) =>  {
        results.forEach(result => {
            if (result.status === "fulfilled") {
                recommendedApps.push(...result.value);
            }
        })
        return Promise.resolve(recommendedApps);
    });

}

export const updateRecommendedApplicationDraft = (accessToken: string, recommendedApplication: RecommendedApplication): Promise<Response> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)
    
    return fetchApiWrapper(`${settings.myDanfossApi.endpoint}/admin-actions/draft-recommended-applications/${recommendedApplication.client_id}`, {
        body: JSON.stringify({criteria: recommendedApplication.criteria, all_users: recommendedApplication.all_users}),
        headers: requestHeaders,
        method: 'PUT',
      });

}

export const deleteRecommendedApplicationDraft = (accessToken: string, client_id: string): Promise<Response> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)
    
    return fetchApiWrapper(`${settings.myDanfossApi.endpoint}/admin-actions/draft-recommended-applications/${client_id}`, {
        headers: requestHeaders,
        method: 'DELETE',
      });

}

export const deleteRecommendedApplicationPublished = (accessToken: string, client_id: string): Promise<Response> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)
    
    return fetchApiWrapper(`${settings.myDanfossApi.endpoint}/admin-actions/published-recommended-applications/${client_id}`, {
        headers: requestHeaders,
        method: 'DELETE',
      });

}

export const publishRecommendedApplication = (accessToken: string, clientId: string): Promise<Response> => {
    if (!clientId) {
        return Promise.reject();
    }

    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)
    
    return fetchApiWrapper<Response>(`${settings.myDanfossApi.endpoint}/admin-actions/recommended-applications/publish/${clientId}`, {
        headers: requestHeaders,
        method: 'PUT',
    })

}

export const getPrevievRecommendedApplications = (accessToken: string, profileId: string): Promise<RecommendedApplications> => {

    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)
        
    return fetchApiWrapper<RecommendedApplications>(`${settings.myDanfossApi.endpoint}/users/${profileId}/recommended-applications?isPreview=true`, {
        headers: requestHeaders,
        method: 'GET',
      });
  }
