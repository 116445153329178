import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  listGridHeading: {
    backgroundColor: colors.webexuiGraySoft,
    minHeight: '40px',
    fontWeight: 600
  },
  listGridItem: {
    padding: '5px 8px 5px 8px',
    marginTop: '10px'
  },
  websiteForm: {
    width: '100%'
  },
  listGridSelect: {
    width: '5%',
    marginTop: '0px'
  },
  listGridName: {
    width: '40%'
  },
  listGridCriteria: {
    width: '45%',
    marginBottom: '12px'
  },
  listGridStatus: {
    width: '15%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  listGridPublishButton: {
    paddingLeft: '0px',
    width: '100%',
    textAlign: 'right'
  },
  listGridOdd: {
    backgroundColor: colors.webexuiGraySoft
  },
  subListGridOdd: {
    backgroundColor: colors.webexuiGrayBg
  },
  listGridEven: {
    backgroundColor: colors.webexuiWhite
  },
  listGridHeadingVariant: {
    minHeight: '40px',
    fontWeight: 600,
    backgroundColor: colors.webexuiGrayBg
  },
  listGridCountryVariant: {
    width: '16%'
  },
  listGridLanguageVariant: {
    width: '10%'
  },
  listGridNameVariant: {
    width: '34%'
  },
  listGridURLVariant: {
    width: '31%'
  },
  listGridDescriptionVariant: {
    width: '100%',
    paddingLeft: '38px',
    marginTop: '0'
  },
  listGrid: {
    minHeight: '40px'
  },
  show: {
    display: 'block',
    marginLeft: '32px'
  },
  hide: {
    display: 'none'
  },
  listGridIconPublish: {
    cursor: 'pointer',
    width: '40%',
    textAlign: 'right'
  },
  listGridIcon: {
    cursor: 'pointer',
    width: '45%',
    textAlign: 'right'
  },
  listGridIconVariants: {
    cursor: 'pointer',
    width: '8.8%',
    textAlign: 'right'
  },
  listGridIconVariantsPublish: {
    cursor: 'pointer',
    width: '1%',
    textAlign: 'right'
  },
  iconStyle: {
    top: '0',
    right: '0',
    display: 'inline-block',
    color: '#c0c0c0',
    width: '13px',
    height: '13px',
    margin: '6px 10px',
    pointerEvents: 'none'
  },
  iconStyleRotate: {
    top: '0',
    right: '0',
    display: 'inline-block',
    color: '#c0c0c0',
    width: '13px',
    height: '13px',
    margin: '6px 10px',
    pointerEvents: 'none',
    transform: 'rotate(180deg)'
  },
  toggleButtonContainer: {
    marginBottom: '20px',
    justifyContent: 'end'
  },
  confirm: {
    boxShadow: '0px -4px 0px #E2000F',
    borderRadius: '0px 0px 2px 2px',
    "&:hover": {
      boxShadow: '0px -4px 0px #E2000F, 0px 12px 17px 2px rgba(66, 78, 84, 0.14)'
    }
  },
  confirmButtons: {
    marginTop: '20px'
  },
  confirmPopup: {
    position: 'absolute',
    margin: '0 auto',
    maxWidth: '546px',
    [theme.breakpoints.up('sm')]: {
      margin: '0px 15px 0'
    },
    textAlign: 'left',
    paddingBottom: '23px',
    padding: '16px 35px 50px 35px !important'
  },
  confirmPopupContainer: {
    maxWidth: '1280px',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    top: '36px'
  },
  confirmPopupHeader: {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '20px 0 16px'
  },
  bannerContainer: {
    position: 'relative'
  },
  websContainer: {
    marginTop: '20px'
  },
  button: {

  },
  buttonLeft: {
    marginRight: '20px'
  },
  variantLabel: {
    paddingRight: '10px',
    marginBottom: '10px'
  }
})
);