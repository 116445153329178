import { action } from 'typesafe-actions';
import { ReferencesActionTypes } from './types';

import { ReferenceItemApiModel } from '../../API/references';
import { LoadingStatus } from '../types';

export interface GetDepartments {
	type: ReferencesActionTypes.GET_DEPARTMENTS;
}

export interface GetDepartmentsSucceed {
	type: ReferencesActionTypes.GET_DEPARTMENTS_SUCCEED;
	payload: ReferenceItemApiModel[];
}

export interface GetCompanyTypes {
	type: ReferencesActionTypes.GET_COMPANY_TYPES;
}

export interface GetCompanyTypesSucceed {
	type: ReferencesActionTypes.GET_COMPANY_TYPES_SUCCEED;
	payload: ReferenceItemApiModel[];
}

export interface GetAreasOfInterest {
	type: ReferencesActionTypes.GET_AREAS_OF_INTEREST
}

export interface GetAreasOfInterestSucceed {
	type: ReferencesActionTypes.GET_AREAS_OF_INTEREST_SUCCEED;
	payload: ReferenceItemApiModel[];
}

export interface AreasOfInterestLoadingStatus {
    type: ReferencesActionTypes.SET_AREAS_OF_INTEREST_LOADING_STATUS ;
    payload: LoadingStatus;
}

export interface CompanyTypesLoadingStatus {
    type: ReferencesActionTypes.SET_COMPANY_TYPES_LOADING_STATUS ;
    payload: LoadingStatus;
}

export interface DepartmentsLoadingStatus {
    type: ReferencesActionTypes.SET_DEPARTMENTS_LOADING_STATUS ;
    payload: LoadingStatus;
}

export const GetDepartmentsAction = (): GetDepartments =>
	action(ReferencesActionTypes.GET_DEPARTMENTS);

export const SetDepartments = (
	departments: ReferenceItemApiModel[]
): GetDepartmentsSucceed =>
	action(ReferencesActionTypes.GET_DEPARTMENTS_SUCCEED, departments);

export const SetDepartmentsLoadingStatus = (loadingStatus: LoadingStatus): DepartmentsLoadingStatus =>
    action(ReferencesActionTypes.SET_DEPARTMENTS_LOADING_STATUS, loadingStatus);

export const GetCompanyTypesAction = (): GetCompanyTypes =>
	action(ReferencesActionTypes.GET_COMPANY_TYPES);

export const SetCompanyTypes = (
	companyTypes: ReferenceItemApiModel[]
): GetCompanyTypesSucceed =>
	action(ReferencesActionTypes.GET_COMPANY_TYPES_SUCCEED, companyTypes);

export const SetCompanyTypesLoadingStatus = (loadingStatus: LoadingStatus): CompanyTypesLoadingStatus =>
    action(ReferencesActionTypes.SET_COMPANY_TYPES_LOADING_STATUS, loadingStatus);

export const GetAreasOfInterestAction = (): GetAreasOfInterest =>
	action(ReferencesActionTypes.GET_AREAS_OF_INTEREST);

export const SetAreasOfInterest = (
	areasOfInterest: ReferenceItemApiModel[]
): GetAreasOfInterestSucceed =>
	action(ReferencesActionTypes.GET_AREAS_OF_INTEREST_SUCCEED, areasOfInterest);

	export const SetAreasOfInterestLoadingStatus = (loadingStatus: LoadingStatus): AreasOfInterestLoadingStatus =>
    action(ReferencesActionTypes.SET_AREAS_OF_INTEREST_LOADING_STATUS, loadingStatus);


export type ReferencesActionType =
	| GetDepartmentsSucceed
	| GetDepartments
	| GetCompanyTypesSucceed
	| GetCompanyTypes
	| GetAreasOfInterestSucceed
	| GetAreasOfInterest
	| AreasOfInterestLoadingStatus
	| CompanyTypesLoadingStatus
	| DepartmentsLoadingStatus;
