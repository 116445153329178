export const languages = [
    { "id": "en", "text": "English" },
    { "id": "cs", "text": "Czech" },
    { "id": "sr", "text": "Serbian" },
    { "id": "da", "text": "Danish" },
    { "id": "de", "text": "German" },
    { "id": "et", "text": "Estonian" },
    { "id": "es", "text": "Spanish" },
    { "id": "fr", "text": "French" },
    { "id": "hr", "text": "Croatian" },
    { "id": "is", "text": "Icelandic" },
    { "id": "it", "text": "Italian" },
    { "id": "pl", "text": "Polish" },
    { "id": "lv", "text": "Latvian" },
    { "id": "lt", "text": "Lithuanian" },
    { "id": "hu", "text": "Hungarian" },
    { "id": "nl", "text": "Dutch" },
    { "id": "nb", "text": "Norwegian" },
    { "id": "pt", "text": "Portoguese" },
    { "id": "ro", "text": "Romanian" },
    { "id": "sk", "text": "Slovakian" },
    { "id": "sl", "text": "Slovenian" },
    { "id": "fi", "text": "Finnish" },
    { "id": "sv", "text": "Swedish" },
    { "id": "tr", "text": "Turkish" },
    { "id": "bg", "text": "Bulgarian" },
    { "id": "ru", "text": "Russian" },
    { "id": "uk", "text": "Ukranian" },
    { "id": "ko", "text": "Korean" },
    { "id": "zh", "text": "Chinese, simpl." },
    { "id": "zh-Hant", "text": "Chinese, trad." }
]

export const booleanValues = [
    {id: "True", text: "True"},
    {id: "False", text: "False"},
]