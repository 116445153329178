import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    bannerContainer: {
        position: 'relative'
    },
	blackText: {
		color: colors.webexuiBlack,
	},
    button: {
		marginRight: '20px'
	},
	buttonHidden: {
		display: 'none'
	},
	buttonLabel: {
		border: '1px solid #d8d8d9',
		padding: '6px 16px 7px',
		backgroundColor: colors.webexuiWhite,
		color: colors.webexuiBlack,
    	fontSize: '16px',
    	minWidth: '64px',
		boxSizing: 'border-box',
    	transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    	fontFamily: 'myriad-pro, Myriad Pro',
    	fontWeight: 600,
    	lineHeight: '1.75',
    	borderRadius: '4px',
    	textTransform: 'none',
		cursor: 'pointer'
	},
	formContainer: {
		position: 'relative',
		minWidth: '692px',
		width: '810px',
		minHeight: '698px'
	},
	buttonContainer: {
		marginTop:'20px',
		justifyContent: 'start'
	}, 
	richTextEditor: {
		width: '100%',
		maxWidth: '692px'
	},
	relevantWebsiteContainer: {
		marginBottom: '20px',
		cursor: 'pointer'
	},
	relevantWebsiteHeader: {
		fontWeight: 'bold'
	},

	applicationListGridHeading: {
		backgroundColor: colors.webexuiGraySoft,
		minHeight: '40px',
		fontWeight: 600
	},
	applicationListGridName: {
		width: '20%'
	},
	applicationListGridCriteria: {
		width: '45%',
	},
	applicationListGridStatus: {
		width: '15%',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
	applicationListGridItem: {
		padding: '5px 8px 5px 8px',
		marginTop: '5px'
	},
	applicationListGridEdit: {
		width: '15%',
		[theme.breakpoints.down('md')]: {
			minWidth: '140px'
		  }
	},
	editGridButton: {
		marginBottom: '12px',
		marginTop: '4px',
		height: '30px',
		minWidth: '160px',
		[theme.breakpoints.down('md')]: {
			minWidth: '130px',
			padding: '7px 7px 7px 7px'
		  }
	},
	editorContainer: {
		width: '698px'
	},
	buttonPreview: {
		margin: '32px 0px 0 20px'
	},
	bannerPopupApplication: {
		position: 'fixed',
		width: '372px',
		minHeight: '250px',
		textAlign: 'left',
		paddingBottom: '23px',
		padding: '16px 20px 20px 35px !important',
		left: 0,
		top: 100,
		right: 0,
		margin: 'auto'
	},
	linkText: {
	  fontSize: '18px',
	  fontWeight: 600,
	  letterSpacing: '0.18px',
	  lineHeight: '28px',
	  textDecoration: 'none',
	  color: colors.webexuiDarkGrayish,
	  cursor: 'pointer'
	},
	linkArrow: {
		top: '2px',
		width: '16px',
		height: '16px',
		position: 'relative',
		marginRight: '10px',
	},
	LinkContainer: {
		marginTop: '10px'
	}
})
);