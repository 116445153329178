import { takeLatest, all, put, fork } from 'redux-saga/effects';
import { getAuth0User, UserModel } from '../../API/users';
import { setCurrentUser, GetCurrentUser } from './actions';
import { UserActionTypes, } from './types';

export function* getCurrentUser(action: GetCurrentUser) : any  {
    
    const { payload: {user_id, accessTokenMyDanfossAccountApi} } = action;
    try {
        const user: UserModel = yield getAuth0User(user_id, accessTokenMyDanfossAccountApi); 
        yield put(setCurrentUser({user, userLoaded: true}));
    } catch (ex) {
        yield put(setCurrentUser({userLoaded: false}));
    }

}    

export function* watchGetCurrentUser() {
    yield takeLatest(UserActionTypes.GET_CURRENT_USER, getCurrentUser);
}

export function* userSagas() {
    yield all([
        fork(watchGetCurrentUser)
    ]);
}

export default userSagas;