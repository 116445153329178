import React, { useEffect, useState } from 'react';
import Main from '../../Main/Main';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../../../Store';
import settings from '../../../Config/settings';
import { useAuth0 } from '@auth0/auth0-react';
import DataLoader from '../../DataLoader';
import { Spinner } from '@danfoss/webex-ui';
import DashboardWrapper from '../UserDashboard/DashboardWrapper';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './styles';
import { LabeledSelectbox } from '../../RenderFields/RenderFields';
import { getCountryValues, getLanguageValues } from '../../Application/Criteria/utils'
import { reduxForm, Field } from 'redux-form';
import { getRelevantWebsites, RelevantWebsite, RelevantWebsites, getPrevievRelevantWebsites, RelevantWebsiteVariant, PreviewRelevantWebsite, PreviewRelevantWebsites } from '../../../API/relevantWebsite';
import { DashBoardItem } from '../UserDashboard/Types';

const PreviewWebsite: React.FC = () => {
	const stateLocations = useSelector((state: AppState) => state.location);

	const { getAccessTokenSilently } = useAuth0();
	const [dashboardItems, setDashboardItems] = useState([] as DashBoardItem[]);
	const [isLoading, setIsLoading] = useState(true);
	const [selectedLanguage, setSelectedLanguage] = useState('')
	const [selectedCountry, setSelectedCountry] = useState('')
	
	const classes = useStyles();
	const location = useLocation();
	
	useEffect(() => {

		const getRelWebsitesForPreview = async () => {
            const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
			await getPrevievRelevantWebsites(accessTokenMyDanfossApi, undefined, undefined);
			
            setIsLoading(false);
		}

		getRelWebsitesForPreview();

	}, [])

	useEffect(() => {
		
		const getRelWebsitesForPreview = async () => {
			const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
            const relevantWebsites = await getPrevievRelevantWebsites(accessTokenMyDanfossApi, selectedCountry, selectedLanguage);
			
			let items = [] as DashBoardItem[]

			if (!isLoading) {
				relevantWebsites.relevant_websites.sort((app1, app2) => app1.id.localeCompare(app2.id)).forEach(relWeb => {
					items.push({client_id: relWeb.id, name: relWeb.display_name, description: relWeb.description, url: relWeb.url, published: relWeb.published, dip_app_type: relWeb.dip_app_type});
				});
			}
			
			setDashboardItems(items);

            setIsLoading(false);
		}

		getRelWebsitesForPreview();
		
		
	}, [selectedLanguage, selectedCountry, isLoading])

	return (
		<Main breadCrumbs={{ items: [{ text: 'Preview overview', link: '/preview' }, { text: `Preview relevant websites`, link: location.pathname }] }}>
			<DataLoader referencesRequired={true} applicationsRequired={true} countriesRequired={true}>
				<Spinner visible={isLoading} ></Spinner>
				{!isLoading && (
					<form id="websitepreview">
						<Grid item >
							<Grid container >
								<Grid item >
									<Field
										id="country"
										name="country"
										label="Country"
										component={LabeledSelectbox}
										options={getCountryValues(stateLocations)}
										onChange={({ target }: { target: HTMLInputElement }) => {
											setSelectedCountry(target.value);
										}}
										className={classes.selectBox}
									/>
								</Grid>
							</Grid>
							<Grid container >
								<Grid item >
									<Field
										id="language"
										name="language"
										label="Language"
										component={LabeledSelectbox}
										options={getLanguageValues()}
										onChange={({ target }: { target: HTMLInputElement }) => {
											setSelectedLanguage(target.value);
										}}
										className={classes.selectBox}
									/>
								</Grid>
							</Grid>
						</Grid>
					</form>
				)}
				{!isLoading &&
				
					<DashboardWrapper
						areaSubject="Preview relevant websites"
						dashboardItems={dashboardItems}
						showMobileFlag={false}
					/>
				}
			
			</DataLoader>
		</Main>
	);
}

const PreviewWebsiteReduxForm = reduxForm({
	form: "websitepreview"
})(PreviewWebsite)

export default PreviewWebsiteReduxForm;
