import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    tableContainer: {
        position: 'relative',
        padding: theme.spacing(1, 0),
        gap: theme.spacing(1),
        minHeight: '50px'
    },
    spinner: {
        top: theme.spacing(2),
        marginTop: 0,
    },
    noData: {
        padding: theme.spacing(1),
        margin: theme.spacing(0, 'auto'),
    }
}));