import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    formContainer: {
        position: 'relative'
    },
    buttonContainer: {
        marginTop: '20px',
        justifyContent: 'end',
        width: '100%',
        textAlign: 'end'
    },
    translationRow: {
    },
    translationDetailsRow: {
        display: 'table'
    },
    translationDetailsSubRow: {
      borderBottom: '1px solid #E8E8E8',
      margin: '0px 20px 10px 43px'
    },
    translationDetailsItem: {
        display: 'table-cell',
        fontSize: '16px',
        maxWidth: '500px'
    },
    translationRowApplicationName:{
        width: '48%',
        cursor: 'pointer',
        overflow: 'auto',
        fontSize: '16px'
    },
    translationDetailsItemDescription: {
        marginBottom: '10px',
        width: '230px'
    },
    translationText: {
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center'
    },
    checkboxField: {
      "& > span": {
        fontWeight: 'normal'
    }
    },
    iconStyle: {
        top: '0',
        right: '0',
        display: 'inline-block',
        color: '#c0c0c0',
        width: '13px',
        height: '13px',
        margin: '6px 10px',
        pointerEvents: 'none'
    },
    iconStyleRotate: {
        top: '0',
        right: '0',
        display: 'inline-block',
        color: '#c0c0c0',
        width: '13px',
        height: '13px',
        margin: '6px 10px',
        pointerEvents: 'none',
        transform: 'rotate(180deg)'
    },
    translationRowIcon: {
        cursor: 'pointer',
        width: '29%',
        textAlign: 'right'
    },
    translationRowIconShowPublish: {
      cursor: 'pointer',
      width: '19%',
      textAlign: 'right'
  },
    translationForm: {
        width: '100%'
    },
    checkboxColumn: {
        width: '225px'
    },
    applicationSelectorItem: {
        display: 'table',
        marginBottom: '10px'
    },
    button: {
		
	  },
    buttonLeft: {
		 marginRight: '20px'
	  },
    rowContainer: {
        paddingLeft: '32px'
    },
    rowContainerUnpublish: {
        paddingLeft: '8px'
    },
    gray: {
        backgroundColor: colors.webexuiGraySoft
    },
    graySoft: {
        backgroundColor: colors.webexuiGrayBg
    },
    listGridHeading: {
        backgroundColor: colors.webexuiGraySoft,
        minHeight: '40px',
        fontWeight: 600
      },
      subListGridHeading: {
        backgroundColor: colors.webexuiGrayBg,
        minHeight: '40px',
        fontWeight: 600,
        paddingLeft: '25px'
      },
      subListGridHeadingUnpublish: {
        backgroundColor: colors.webexuiGrayBg,
        minHeight: '40px',
        fontWeight: 600
      },
      listGridSelect: {
        width: '40%',
        marginTop: '0px !important',
        fontWeight: 'bold'
      },
      listGridSelectLanguage: {
        width: '22%',
        marginTop: '0px !important',
        fontWeight: 'bold'
      },
      listGridSelectLanguageShowPublish: {
        width: '22%',
        marginTop: '0px !important',
        fontWeight: 'bold'
      },
      listGridItem: {
        padding: '5px 8px 5px 8px',
        marginTop: '10px'
      },
      listGridLanguage: {
        width: '16%'
      },
      listGridName: {
        width: '31%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      listGridStatus: {
        width: '20%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      listGridOdd: {
        backgroundColor: colors.webexuiGraySoft
      },
      listGridEven: {
        backgroundColor: colors.webexuiWhite
      },
      listGrid: {
        minHeight: '40px'
      },
      listGridIcon: {
        cursor: 'pointer',
        width: '40%',
        textAlign: 'right'
      },
      show: {
        display: 'block'
      },
      hide: {
        display: 'none'
      },
      checkboxLabel: {
        "& > span": {
            fontWeight: 'normal'
        }
    },
    bannerContainer: {
      position: 'relative'
    },
    confirm: {
      boxShadow: '0px -4px 0px #E2000F',
      borderRadius: '0px 0px 2px 2px',
      "&:hover": {
        boxShadow: '0px -4px 0px #E2000F, 0px 12px 17px 2px rgba(66, 78, 84, 0.14)'
      }
    },
    confirmButtons: {
      marginTop: '20px'
    },
    confirmPopup: {
      position: 'absolute',
      margin: '0 auto',
      maxWidth: '546px',
      maxHeight: '550px',
      overflow: 'auto',
      [theme.breakpoints.up('sm')]: {
        margin: '0px 15px 0'
      },
      textAlign: 'left',
      paddingBottom: '23px',
      padding: '16px 35px 50px 35px !important'
    },
    confirmPopupContainer: {
      maxWidth: '1280px',
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      top: '36px'
    },
    confirmPopupHeader: {
      fontSize: '24px',
      fontWeight: 'bold',
      margin: '20px 0 16px'
    },
    onlyLabel: {
      lineHeight: '32px',
      fontWeight: 'normal'
    },
    onlyLabelBold: {
      lineHeight: '32px',
      fontWeight: 'bold'
    },
    toggleButtonContainer: {
      marginBottom: '20px',
      justifyContent: 'end'
    },
    appsContainer: {
      marginTop: '20px'
    },
    statusRow: {
      width: '100px'
    },
    notAllowed: {
      minHeight: '40px',
      fontWeight: 600,
      color: colors.webexuiRedStrong,
      fontSize: '20px',
      paddingBottom: '25px'
    },
  
}));