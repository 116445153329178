import { UserModel, UserInfo } from "../../API/users";

export interface CurrentUser {
    user?: UserModel,
    userLoaded: boolean,
    impersonatedUser?: UserInfo
}
export enum UserActionTypes {
    SET_CURRENT_USER = '@@user/SET_CURRENT_USER',
    GET_CURRENT_USER = '@@user/GET_CURRENT_USER',
    SET_IMPERSONATED_USER = '@@user/SET_IMPERSONATED_USER',
    CLEAR_IMPERSONATED_USER = '@@user/CLEAR_IMPERSONATED_USER'
}

