import { RelevantWebsite } from '../../API/relevantWebsite';
import { ApplicationErrors, ApplicationWarnings } from '../ImportPopup/types';
import { prepareForCompare } from '../../Utils';
import DOMPurify from 'dompurify';

export const validateImportedData = (importedData: RelevantWebsite, userWebsite: RelevantWebsite): { errors: ApplicationErrors[], warnings: ApplicationWarnings[] } => {
    const errors: ApplicationErrors[] = [];
    const warnings: ApplicationWarnings[] = [];
    const warningsForWebsite: string[] = [];
    
    if (prepareForCompare(userWebsite.variants.map(variant => { return {...variant, description: DOMPurify.sanitize(variant.description)}})) === prepareForCompare(importedData.variants)) {
        warningsForWebsite.push(`${userWebsite.name} no changes to import`);
    } else if (!userWebsite.published) {
        warningsForWebsite.push(`Unpublished changes for ${userWebsite.name} will be overwritten by import`);
    } 

    if (warningsForWebsite.length > 0) {
        warnings.push({client_name: userWebsite.name, warnings: warningsForWebsite});
    }

    return { errors, warnings };
}

export const isUrl = (url: string) => {
    if (!url)
        return false;
    var res = url.match(/^(?:(?:https):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/g);
	return (res !== null)
}

export const validate = (values: any, props: any) => {
    let errors = {};

    if (values && values.url && !isUrl(values.url)) {
        errors = {url: ['The URL entered must be valid and start with "https://"']}
    }

    if (values?.name === '') {
        errors = {...errors, name: ['Website name cannot be empty']}
    }

    if (values?.url === '') {
        errors = {...errors, url: ['URL cannot be empty']}
    }
    
    return errors;
}