import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { useStyles } from './styles';
import { Button } from '@material-ui/core';
import { SignupFormPopupProps } from './types';
import parse from 'html-react-parser';

const SignupPopup: React.FC<SignupFormPopupProps> = ({ setShowSignupForm, text, header }) => {
    const classes = useStyles();
	
    return (
        <Container className={classes.container}>
            <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
                <div onClick={() => setShowSignupForm(false)}>
                    <img src={'/images/Close.svg'} className={classes.close} alt="" />
                </div>
            </Grid>
            <Grid container className={classes.loginHeader}>
                {header}
            </Grid>
            <Grid container spacing={2} className={classes.gridContainerLeftPadding}>
                <Grid item xs={12} className={classes.paneContainer}>
                    <Grid container spacing={2}>
                        <Grid item className={classes.pane}>
                            Log in
                        </Grid>
                        <Grid item className={classes.pane}>
                        <span className={classes.redBottomBorder}>Sign up</span>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div className={classes.paneunderline}>&nbsp;</div>
            <Grid container>
                <Grid item xs={12} sm={6} className={`${classes.gridContainerRightBorder} signupDescription`}>
                    {parse(text)}
                </Grid>
                <Grid item xs={12} sm={6} className={classes.PopupText}>
                    <form className={classes.form}>
                        <Grid container spacing={2} className={classes.TextboxContainer}>
                            <Grid item xs={12}>
                                Email<span className={classes.redDot}>&thinsp;*</span>
                                <input type="text" disabled={true} className={classes.input} />
                            </Grid>
                            <Grid item xs={12} className={classes.marginTop}>
                                Password<span className={classes.redDot}>&thinsp;*</span>
                                <input type="password" disabled={true} className={classes.input} />
                            </Grid>
                            <Grid item xs={12} className={classes.marginTop}>
                                Re-type Password<span className={classes.redDot}>&thinsp;*</span>
                                <input type="password" disabled={true} className={classes.input} />
                            </Grid>
                            <Grid item xs={12} className={classes.marginTop}>
                                First name<span className={classes.redDot}>&thinsp;*</span>
                                <input type="text" disabled={true} className={classes.input} />
                            </Grid>
                            <Grid item xs={12} className={classes.marginTop}>
                                Last name<span className={classes.redDot}>&thinsp;*</span>
                                <input type="text" disabled={true} className={classes.input} />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12} sm={1}>
                                        <input type="checkbox" disabled={true} name="acceptterms" value="" />
                                    </Grid>
                                    <Grid item xs={12} sm={11}>
                                    I agree to <span className={classes.checkboxLink}>Danfoss Profile terms of use</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12} sm={1}>
                                        <input type="checkbox" disabled={true} name="acceptterms" value="" />
                                    </Grid>
                                    <Grid item xs={12} sm={11}>
                                    I have read and understood the <span className={classes.checkboxLink}>Danfoss privacy policy</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.gridButtonContainerSignup}>
                            <Grid item xs={12} sm={12}>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="default"
                                    className={classes.button}
                                    disabled={true}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>

            </Grid>

        </Container>
    )
}

export default SignupPopup;
