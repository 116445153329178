import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    listGridHeading: {
        backgroundColor: colors.webexuiGraySoft,
        minHeight: '40px',
        fontWeight: 600
    },
    listGridItem: {
        padding: '5px 8px 5px 0px',
        marginTop: '10px'
    },
    websiteForm: {
        width: '100%'
    },
    listGridSelect: {
        width: '45%',
        marginTop: '0px'
    },
    listGridName: {
        width: '25%',
        overflow: 'auto',
        marginTop: '5px'
    },
    listGridHeader: {
        width: '15%'
    },
    listGridCountry: {
        width: '15%'
    },
    listGridCriteria: {
        width: '45%',
        marginBottom: '12px'
    },
    listGridStatus: {
        width: '15%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    listGridPublishButton: {
        paddingLeft: '0px',
        width: '100%',
        textAlign: 'right'
    },
    listGridOdd: {
        backgroundColor: colors.webexuiGraySoft
    },
    subListGridOdd: {
        backgroundColor: colors.webexuiGrayBg
    },
    listGridEven: {
        backgroundColor: colors.webexuiWhite
    },
    listGridHeadingVariant: {
        minHeight: '40px',
        fontWeight: 600,
        backgroundColor: colors.webexuiGrayBg
    },
    listGridCountryVariant: {
        width: '8%'
    },
    listGridLanguageVariant: {
        width: '10%'
    },
    listGridNameVariant: {
        width: '35%'
    },
    listGridURLVariant: {
        width: '35%'
    },
    listGridDescriptionVariant: {
        width: '100%',
        paddingLeft: '38px',
        marginTop: '0',
        fontStyle: 'italic'
    },
    listGrid: {
        minHeight: '40px'
    },
    show: {
        display: 'block'
    },
    hide: {
        display: 'none'
    },
    listGridIcon: {
        cursor: 'pointer',
        width: '40%',
        textAlign: 'right'
    },
    listGridIcon44: {
        cursor: 'pointer',
        width: '39%',
        textAlign: 'right'
    },
    listGridIcon59: {
        cursor: 'pointer',
        width: '54%',
        textAlign: 'right'
    },
    iconStyle: {
        top: '0',
        right: '0',
        display: 'inline-block',
        color: '#c0c0c0',
        width: '13px',
        height: '13px',
        margin: '6px 10px',
        pointerEvents: 'none'
    },
    iconStyleRotate: {
        top: '0',
        right: '0',
        display: 'inline-block',
        color: '#c0c0c0',
        width: '13px',
        height: '13px',
        margin: '6px 10px',
        pointerEvents: 'none',
        transform: 'rotate(180deg)'
    },
    buttonContainer: {
        marginTop: '20px',
        justifyContent: 'end',
        width: '100%'
    },
    listGridSelectLanguage: {
        width: '20%',
        marginTop: '0px !important',
        fontWeight: 'bold',
        paddingLeft: '38px'
    },
    subListGridHeading: {
        backgroundColor: colors.webexuiGrayBg,
        minHeight: '40px',
        fontWeight: 600
    },
    formContainer: {
        position: 'relative'
    },
    translationForm: {
        width: '100%'
    },
    translationRow: {
        
    },
    translationDetailsRow: {
        display: 'table'
    },
    translationDetailsSubRow: {
        marginBottom: '10px'
    },
    translationDetailsItem: {
        display: 'table-cell',
        fontSize: '16px',
        maxWidth: '500px'
    },
    translationRowApplicationName:{
        width: '20%',
        cursor: 'pointer'
    },
    translationDetailsItemDescription: {
        width: '174px',
        marginLeft: '-38px',
        marginBottom: '10px'
    },
    translationText: {
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center'
    },
    graySoft: {
        backgroundColor: colors.webexuiGrayBg
    },
    rowContainer: {
        
    },
    listGridItemTextContainer: {
        margin: '0px 25px 10px 80px',
        borderBottom: '1px solid #E8E8E8',
        overflow: 'auto',
        paddingLeft: '38px'
    },
    checkboxLabel: {
        "& > span": {
            fontWeight: 'normal'
        }
    }
})
);