import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	previewProfiles: {
		// padding: '5px 8px 5px 8px',
		marginTop: '10px'
	},
	heading: {
		fontWeight: 'bold'
	},
	currentProfile: {
		width: '50%',
		padding: '5px 8px 5px 0px',
	},
	otherProfiles: {
		width: '35%',
		padding: '5px 8px 5px 8px',
	},
	space: {
		width: '15%',
		padding: '5px 8px 5px 8px',
	},
	profileAttribute: {
		width: '30%'
	},
	profileValues: {
		width: '70%'
	},
	profileName: {
		width: '60%',
		cursor: 'pointer'
	},
	profileEdit: {
		width: '40%'
	},
	profileList: {
		minHeight: '40px'
	},
	selectedProfile: {
		color: 'red'
	},
	listGridOdd: {
		backgroundColor: colors.webexuiGraySoft,
		padding: '5px 8px 5px 8px'
	},
	listGridEven: {
		backgroundColor: colors.webexuiWhite,
		padding: '5px 8px 5px 8px'
	},
	editGridButton: {
		height: '30px',
		float: 'right',
		marginLeft: '5px',
		[theme.breakpoints.down('md')]: {
			padding: '7px 7px 7px 7px'
		}
	},
	addButton: {
		height: '30px',
		marginTop: '20px',
		[theme.breakpoints.down('md')]: {
			padding: '7px 7px 7px 7px'
		}
	},
	button: {
		marginRight: '20px',
		marginTop: '40px'
	},
	editProfilePopup: {
		position: 'absolute',
		margin: '0 auto',
		maxWidth: '800px',
		minWidth: '650px',
		[theme.breakpoints.up('sm')]: {
			margin: '0px 15px 0'
		},
		textAlign: 'left',
		paddingBottom: '23px',
		padding: '16px 35px 20px 35px !important'
	},
	editProfilePopupContainer: {
		maxWidth: '900px',
		display: 'flex',
		justifyContent: 'center',
		position: 'absolute',
		top: '0px'
	},
	editProfileGrid: {
		width: '580px'
	},
	editProfileGridItem: {
		width: '100%',
		margin: '0px 5px'
	},
	formFields: {
		overflow: 'auto',
		maxHeight: 'calc(100vh - 200px)',
		minWidth: '600px'
	},

}));