import React from 'react';
import Grid from '@material-ui/core/Grid';
import { MessageAreaStyle  } from '@danfoss/webex-ui/dist/mui';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useStyles} from './styles';
import { Button } from '@material-ui/core';
import { LoginFormPopupProps } from './types';
import parse from 'html-react-parser';

const LoginPopup: React.FC<LoginFormPopupProps> = ({ setShowLoginForm, text, header, applicationName, auth0ApplicationName }) => {
    const messageAreaStyle = MessageAreaStyle();
    const classes = useStyles();
    
    return (
        <Container className={classes.container}>
            <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
                <div onClick={() => setShowLoginForm(false)}>
                    <img src={'/images/Close.svg'} className={classes.close} alt="" />
                </div>
            </Grid>
            <Grid container className={classes.loginHeader}>
                {header}
            </Grid>
            <Grid container spacing={2}  className={classes.gridContainerLeftPadding}>
                <Grid item xs={12} className={classes.paneContainer}>
                    <Grid container spacing={2}>
                        <Grid item  className={classes.pane}>
                            <span className={classes.redBottomBorder}>Log in</span>
                        </Grid>
                        <Grid item className={classes.pane}>
                            Sign up
                        </Grid>
                    </Grid>   
                </Grid>
            </Grid>
            <div className={classes.paneunderline}>&nbsp;</div>
            <Grid container>
            <Grid item  xs={12} sm={6} className={classes.gridContainerRightBorder}>
                <form className={classes.form}>
                    <Grid container spacing={2} className={classes.TextboxContainer}>
                        <Grid item xs={12}>
                        Email<span className={classes.redDot}>&thinsp;*</span>
                            <input type="text" disabled={true} className={classes.input}/>
                        </Grid>
                        <Grid item xs={12} className={classes.marginTop}>
                        Password<span className={classes.redDot}>&thinsp;*</span>
                           <input type="password"  disabled={true} className={classes.input} />
                        </Grid>
                    </Grid>                      
                    <Grid container className={classes.gridButtonContainer}>
                        <Grid item xs={12} sm={12}>
                        <Button
                            type="button"
                            variant="contained"
                            color="default"
                            className={classes.button}
                            disabled={true}
                        >
                            Log in
                        </Button>
                        </Grid>
                    </Grid>
                </form>
                <Grid item xs={12} className={classes.gridContainer}>
                    <Typography className={`${messageAreaStyle.paragraph} ${classes.messageArea}`}>
                    <img src="/images/LinkArrow.svg" className={classes.linkArrow} alt=""></img>Forgot your password?
                    </Typography><span className={classes.informationIconContainer}><img src="/images/Information.svg" alt="" className={classes.informationIcon}/></span>
                </Grid>
            </Grid>
            <Grid item  xs={12} sm={6} className={classes.PopupText}>
                {parse(text)}
            </Grid>
            </Grid>
            
        </Container> 
    )
}

export default LoginPopup;
