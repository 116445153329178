import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: colors.webexuiGraySoft,
        fontWeight: 'bold',
    },
    headerCell: {
        padding: '5px 8px 5px 10px',
        height: 40,
        fontWeight: 600,
        fontSize: 16,
    },
    rowOdd: {
        backgroundColor: colors.webexuiGraySoft,
    },
    cell: {
        padding: '5px 8px 5px 10px',
        height: 40,
        fontSize: 16,
    }
}));