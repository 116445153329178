import React from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from './Styles';
import { DashBoardItemProps, DashBoardItem as DashBoardItemType, DashBoardItem } from './Types';
import Banner from './Banner';

const getItemsSorted = (dashBoardItems: DashBoardItem[], emulateMobile: boolean) => {
	let dashboardItemsSorted = [];
	
	dashboardItemsSorted = dashBoardItems.filter(item => item.url && item.dip_app_type != (emulateMobile ? 'client_app' : 'mobile_app')); 

	dashBoardItems.filter(item => item.url).forEach((item: DashBoardItemType) => {
		if (item.dip_app_type === (emulateMobile ? 'client_app' : 'mobile_app')) {
			dashboardItemsSorted.push(item);
		}
	});
	
	return dashboardItemsSorted;
} 

const showContent = (dashBoardItems: DashBoardItem[]) => {
	return (dashBoardItems.length > 0 );
}

const getBanners = (ItemsToMap: DashBoardItemType[], functions: any, classes: any, itemType: string) => {
	return ItemsToMap.map((dashboardItem: DashBoardItemType, index) => {
		const itemIcon = functions.getDashBoardItemIcon({ ...dashboardItem, dip_app_type: dashboardItem.dip_app_type });
		const showAsActive = functions.shouldItemBeShownAsActive(dashboardItem);

		let bannerTextClass = classes.bannerText

		if ((dashboardItem.name || '').length > 50) {
			bannerTextClass = classes.bannerTextSmallFont;
		}

		return (
			<Banner
				key={dashboardItem.name}
				bannerClick={() => functions.handleBannerClick(dashboardItem, showAsActive, functions.toggleInactiveAppPopup, functions.toggleClientAppPopup, functions.toggleMobileAppPopup)}
				bannerClass={showAsActive ? classes.banner : classes.bannerInactive}
				bannerIconBorderClass={classes.bannerIconBorder}
				bannerTextClass={bannerTextClass}
				bannerIcon={itemIcon}
				bannerIconHover={itemIcon.replace('.svg', showAsActive ? '_hover.svg' : '_Inactive_hover.svg')}
				bannerText={dashboardItem.name || ''}
				bannerInformationIconUrl={!!dashboardItem.description ? '/images/icons/Information_gray.svg' : ''}
				bannerInformationClick={() => functions.handleInformationIconClick(dashboardItem)}
				handleItemDisplay={(hidden: boolean) => functions.handleDashboardItemDisplay({...dashboardItem, hidden: !hidden}, itemType)}
				index={index}
				itemType={itemType}
				client_id={dashboardItem.client_id}
				showAsActive={showAsActive}
			/>
		);
	})
}

const DashBoardItems: React.FC<DashBoardItemProps> = ({ areaSubject, dashBoardItems, emulateMobile, getDashBoardItemIcon, shouldItemBeShownAsActive, functions, handleBannerClick, itemType }) => {
	const classes = useStyles();

	const ItemsToMap = getItemsSorted(dashBoardItems, emulateMobile);

	return showContent(dashBoardItems) ? (
		<Grid item xs={12}>
			<Grid item xs={12} className={classes.applicationTypeHeader}>
				{areaSubject}
			</Grid>
			<Grid
				container
				className={`${classes.applicationIconContainer}`}
				spacing={3}
			>
				{getBanners(ItemsToMap, {...functions, getDashBoardItemIcon, shouldItemBeShownAsActive, handleBannerClick}, classes, itemType)}
			</Grid>
		</Grid>
	) : null;
};

export default DashBoardItems;
