import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router-dom';
import Main from '../Main';
import { UserInfo } from '../../API/users';
import UserSearch from './UserSearch';
import UserEmailsInfoSection from './UserEmailsInfoSection';
import UserAuthInfoSection from './UserAuthInfoSection';
import { useStyles } from './styles';

const UserSupport: React.FC = () => {
    const classes = useStyles();
    const location = useLocation();
    const [user, setUser] = useState<UserInfo | null>(null);

    return (
        <Main breadCrumbs={{ items: [{ text: 'User support', link: location.pathname }] }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <UserSearch onUserChange={setUser} />
                </Grid>
                {user && (
                    <>
                        <Grid className={classes.field} item xs={12}>
                            Auth0 ID: <span className={classes.id}>{user.auth0_user_id}</span>
                        </Grid>
                        <Grid className={classes.field} item xs={12}>
                            Danfoss Identity ID: <span className={classes.id}>{user.danfoss_identity_id ?? '-'}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <UserAuthInfoSection user={user} />
                        </Grid>
                        <Grid item xs={12}>
                            <UserEmailsInfoSection user={user} />
                        </Grid>
                    </>
                )}
            </Grid>
        </Main>
    );
}

export default UserSupport;
